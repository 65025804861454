import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';
import { WorkReportForm } from '../models/form/work-report-form.model';

@Injectable({
  providedIn: 'root',
})
export class WorkReportFormService extends RequestService<WorkReportForm> {
  static PATH = '/work/report/form';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, WorkReportFormService.PATH, globalService.debugUrl);
  }
}
