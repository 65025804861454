import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FindRequest } from './find-request.model';
import { ImportRequest } from './import-request.model';
import { ListRequest } from './list-request.model';
import { NextRequest } from './next-request.model';
import { Page } from './page.type';
import { SearchRequest } from './search-request.model';

export class RequestService<T> {
  httpClient: HttpClient;
  path: string;
  debugUrl?: string;

  constructor(httpClient: HttpClient, path: string, debugUrl?: string) {
    this.httpClient = httpClient;
    this.path = path;
    this.debugUrl = debugUrl;
  }

  notify<RES>(id: number) {
    return this.httpClient.get<RES>(
      `${this.debugUrl ? this.debugUrl : environment.host}${
        this.path
      }/notify/${id}`
    );
  }

  create(param: T) {
    return this.httpClient.put<T>(
      `${this.debugUrl ? this.debugUrl : environment.host}${this.path}`,
      param
    );
  }

  update(param: T) {
    return this.httpClient.post<boolean>(
      `${this.debugUrl ? this.debugUrl : environment.host}${this.path}`,
      param
    );
  }

  addBatch(param: T) {
    return this.httpClient.put<number>(
      `${this.debugUrl ? this.debugUrl : environment.host}${this.path}/batch`,
      param
    );
  }

  // updateBatch(param: UpdateRequest) {
  //   return this.httpClient.post<number>(
  //     `${this.debugUrl ? this.debugUrl : environment.host}${this.path}/batch`,
  //     param
  //   );
  // }

  remove(id: number) {
    return this.httpClient.delete<number>(
      `${this.debugUrl ? this.debugUrl : environment.host}${this.path}/${id}`
    );
  }

  removeBatch(ids: number[]) {
    return this.httpClient.delete<number>(
      `${this.debugUrl ? this.debugUrl : environment.host}${this.path}/batch`,
      {
        body: ids,
      }
    );
  }

  findById(id: number) {
    return this.httpClient.get<T>(
      `${this.debugUrl ? this.debugUrl : environment.host}${this.path}/${id}`
    );
  }

  findOne<REQ extends FindRequest>(param: REQ) {
    return this.httpClient.post<T>(
      `${this.debugUrl ? this.debugUrl : environment.host}${this.path}/find`,
      param
    );
  }

  findAll<REQ extends ListRequest>(param: REQ) {
    return this.httpClient.post<T[]>(
      `${this.debugUrl ? this.debugUrl : environment.host}${this.path}/list`,
      param
    );
  }

  search<REQ extends SearchRequest>(param: REQ, page?: number, size?: number) {
    return this.httpClient.post<Page<T>>(
      `${this.debugUrl ? this.debugUrl : environment.host}${
        this.path
      }/search?page=${page ? page : 0}&size=${size ? size : 20}`,
      param
    );
  }

  searchV2<REQ extends SearchRequest>(
    param: REQ,
    page?: number,
    size?: number
  ) {
    return this.httpClient.post<Page<T>>(
      `${this.debugUrl ? this.debugUrl : environment.host}${
        this.path
      }/searchV2?page=${page ? page : 0}&size=${size ? size : 20}`,
      param
    );
  }

  next<REQ extends NextRequest>(param: REQ) {
    return this.httpClient.post<boolean>(
      `${this.debugUrl ? this.debugUrl : environment.host}${this.path}/next`,
      param
    );
  }

  range<RES>(orgId?: number) {
    return this.httpClient.get<RES>(
      `${this.debugUrl ? this.debugUrl : environment.host}${this.path}/range${
        orgId ? '?organizationId=' + orgId : ''
      }`
    );
  }

  reset(id: number) {
    return this.httpClient.get<boolean>(
      `${this.debugUrl ? this.debugUrl : environment.host}${
        this.path
      }/reset/${id}`
    );
  }

  importRecord(params: ImportRequest) {
    return this.httpClient.post<any>(
      `${this.debugUrl ? this.debugUrl : environment.host}${this.path}/import`,
      params
    );
  }

  exportRecord<REQ extends SearchRequest>(param: REQ) {
    return this.httpClient.post<any>(
      `${this.debugUrl ? this.debugUrl : environment.host}${this.path}/export`,
      param
    );
  }

  // downloadTemplate<REQ extends DownloadTemplateRequest>(param: REQ) {
  //   return this.httpClient.post<any>(
  //     `${this.debugUrl ? this.debugUrl : environment.host}${
  //       this.path
  //     }/downloadTemplate`,
  //     param
  //   );
  // }
}
