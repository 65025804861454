import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { GlobalService } from './../../providers/global.service';
import { LocationModel } from './location.model';

@Component({
  selector: 'app-location-cascader',
  templateUrl: './location-cascader.component.html',
  styleUrls: ['./location-cascader.component.scss'],
})
export class LocationCascaderComponent {
  @Input()
  set locationId(value: string | undefined) {
    if (value === undefined) return;
    this._locationId = value;

    this.locationIds = [];
    let id = value;
    if (id === '') {
    } else if (id === '000000') {
      this.locationIds.unshift(id);
    } else if (id.substring(2, 6) === '0000') {
      this.locationIds.unshift(id);
      this.locationIds.unshift(id.substring(0, 4) + '00');
    } else {
      this.locationIds.unshift(id);
      this.locationIds.unshift(id.substring(0, 4) + '00');
      this.locationIds.unshift(id.substring(0, 2) + '0000');
    }
  }

  get locationId(): string {
    return this._locationId!;
  }

  _locationId?: string;

  @Input() editable?: boolean = false;
  @Input()
  set forSearch(value: boolean) {
    this._forSearch = value;
    this.locationOptions = JSON.parse(
      JSON.stringify(this.globalService.locationData)
    );
    if (value) {
      for (const p of this.locationOptions) {
        if (!p.isLeaf) {
          for (const c of p.children!) {
            if (!c.isLeaf) {
              c.children?.unshift({
                label: '全市',
                value: c.value,
                parentId: c.value,
                level: 3,
                isLeaf: true,
              });
            }
          }
          p.children?.unshift({
            label: '全省',
            value: p.value,
            parentId: p.value,
            level: 2,
            isLeaf: true,
          });
        }
      }
      this.locationOptions.unshift({
        label: '全国',
        value: '000000',
        level: 1,
        isLeaf: true,
      });
      if (!this.locationId) {
        // this.locationIds = [];
        let id = (this.locationId = '000000');
        this.locationIds = [id];
      }
    }
  }

  get forSearch(): boolean {
    return this._forSearch!;
  }
  _forSearch?: boolean = false;

  @Input() patternStr?: string;
  @Input() range?: string[] = [];
  @Input() disabled?: boolean = false;

  @Output() focus = new EventEmitter<void>();
  @Output()
  locationChange = new EventEmitter<string>();

  locationIds: string[] = [];

  locationOptions: LocationModel[] = [];

  constructor(private globalService: GlobalService) {
    this.locationOptions = JSON.parse(
      JSON.stringify(this.globalService.locationData)
    );
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (!isNaN(+changes['locationId']?.currentValue)) {
  //     this.locationIds = [];
  //     let id = changes['locationId'].currentValue.toString();
  //     if (id === '') {
  //     } else if (id === '000000') {
  //       this.locationIds.unshift(id);
  //     } else if (id.substring(2, 6) === '0000') {
  //       this.locationIds.unshift(id);
  //       this.locationIds.unshift(id.substring(0, 4) + '00');
  //     } else {
  //       this.locationIds.unshift(id);
  //       this.locationIds.unshift(id.substring(0, 4) + '00');
  //       this.locationIds.unshift(id.substring(0, 2) + '0000');
  //     }
  //   }
  //   if (!changes['locationId'] && this.forSearch) {
  //     this.locationIds = [];
  //     let id = (this.locationId = '000000');
  //     this.locationIds = [id];
  //   }

  //   if (
  //     changes['range']?.currentValue &&
  //     changes['range'].currentValue.length >= 0
  //   ) {
  //     const pList: LocationModel[] = [];
  //     if (this.range!.length > 0) {
  //       this.locationOptions = JSON.parse(
  //         JSON.stringify(this.globalService.locationData)
  //       );
  //       for (const p of this.locationOptions) {
  //         const cList = [];
  //         for (const c of p.children!) {
  //           const dList = [];
  //           for (const d of c.children!) {
  //             if (this.range!.includes(d.value)) {
  //               dList.push(d);
  //             }
  //           }
  //           if (dList.length > 0) {
  //             c.children = dList;
  //             cList.push(c);
  //           }
  //         }
  //         if (cList.length > 0) {
  //           p.children = cList;
  //           pList.push(p);
  //         }
  //       }
  //     }
  //     this.locationOptions = pList;
  //   }

  //   if (changes['forSearch']?.currentValue) {
  //     for (const p of this.locationOptions) {
  //       if (!p.isLeaf) {
  //         for (const c of p.children!) {
  //           if (!c.isLeaf) {
  //             c.children?.unshift({
  //               label: '全市',
  //               value: c.value,
  //               parentId: c.value,
  //               level: 3,
  //               isLeaf: true,
  //             });
  //           }
  //         }
  //         p.children?.unshift({
  //           label: '全省',
  //           value: p.value,
  //           parentId: p.value,
  //           level: 2,
  //           isLeaf: true,
  //         });
  //       }
  //     }
  //     this.locationOptions.unshift({
  //       label: '全国',
  //       value: '000000',
  //       level: 1,
  //       isLeaf: true,
  //     });
  //   }
  //   // console.log(this.locationOptions);
  // }

  onLocationChange(values: string[]) {
    console.log(values);
    if (values.length === 0) {
      this.locationChange.emit('');
    } else {
      this.locationChange.emit(values[values.length - 1]);
    }
  }
  onFocus() {
    this.focus.emit();
  }

  get location(): string {
    let locations: string[] = [];
    const size = this.locationIds?.length;
    if (size === 0) {
      return '';
    }
    for (const p of this.locationOptions) {
      if (p.value === this.locationIds[0]) {
        if (size > 1) {
          for (const c of p.children!) {
            if (this.locationIds[1] === c.value) {
              if (size > 2) {
                for (const d of c.children!) {
                  if (this.locationIds[2] === d.value) {
                    locations.unshift(d.label);
                    break;
                  }
                }
              }
              locations.unshift(c.label);
              break;
            }
          }
        }
        locations.unshift(p.label);
        break;
      }
    }
    return locations.join('/');
  }
}
