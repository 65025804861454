export enum FileSource {
  MANUAL = 'MANUAL',
  SYS = 'SYS',
}

export const FileSourceMap = {
  [FileSource.MANUAL]: '手工上传',
  [FileSource.SYS]: '系统同步',
};

export const FileSourceList = [
  {
    label: FileSourceMap[FileSource.MANUAL],
    value: FileSource.MANUAL,
  },
  {
    label: FileSourceMap[FileSource.SYS],
    value: FileSource.SYS,
  },
];
