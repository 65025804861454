import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Form } from '../models/form/form.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class OmFormService extends RequestService<Form> {
  static PATH = '/om/form';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, OmFormService.PATH, globalService.debugUrl);
  }

  uploadFileWithoutProgress(file: File, formId?: number) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('formId', formId?.toString() ?? '-1');
    return this.httpClient.post<{
      formCode: string;
      formId: number;
      formName: string;
    }>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmFormService.PATH}/upload`,
      formData
    );
  }

  syn(formId: number): Observable<boolean> {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmFormService.PATH}/syn`,
      {
        formId,
        apiUrl: 'http://104beta.hzsksc.com:8082',
      }
    );
  }

  nextForm(id: number): Observable<number> {
    return this.httpClient.get<number>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmFormService.PATH}/next/${id}`
    );
  }

  prevForm(id: number): Observable<number> {
    return this.httpClient.get<number>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmFormService.PATH}/prev/${id}`
    );
  }
}
