import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IotDeviceCode } from '../models/iot/iot-device-code.model';
import { IotDevice } from '../models/iot/iot-device.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class OmIotDeviceService extends RequestService<IotDevice> {
  static PATH = '/om/iotdevice';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, OmIotDeviceService.PATH, globalService.debugUrl);
  }

  getCode(iotDevice: IotDevice) {
    return this.httpClient.post<IotDeviceCode>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmIotDeviceService.PATH}/getCode`,
      iotDevice
    );
  }
}
