import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FormHeader } from '../models/form/form-header.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class OmFormHeaderService extends RequestService<FormHeader> {
  static PATH = '/om/form/header';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, OmFormHeaderService.PATH, globalService.debugUrl);
  }

  findByMenuFormId(menuFormId: number): Observable<FormHeader[]> {
    return this.httpClient.post<FormHeader[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmFormHeaderService.PATH}/findByMenuFormId`,
      {
        menuFormId,
      }
    );
  }
}
