export enum FormCopiesType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export const FormCopiesTypeMap = {
  [FormCopiesType.SINGLE]: '单份',
  [FormCopiesType.MULTIPLE]: '多份',
};

export const FormCopiesTypeList = [
  {
    label: FormCopiesTypeMap[FormCopiesType.SINGLE],
    value: FormCopiesType.SINGLE,
  },
  {
    label: FormCopiesTypeMap[FormCopiesType.MULTIPLE],
    value: FormCopiesType.MULTIPLE,
  },
];
