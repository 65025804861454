export enum FormHeaderSearch {
  CLOSE = 'CLOSE',
  TEXT = 'TEXT',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  MONEY = 'MONEY',
  ENUM = 'ENUM',
}

export const FormHeaderSearchMap = {
  [FormHeaderSearch.CLOSE]: '关闭',
  [FormHeaderSearch.TEXT]: '文本',
  [FormHeaderSearch.DATE]: '日期',
  [FormHeaderSearch.NUMBER]: '数值',
  [FormHeaderSearch.MONEY]: '金额',
  [FormHeaderSearch.ENUM]: '选择',
};

export const FormHeaderSearchList = [
  {
    value: FormHeaderSearch.CLOSE,
    label: FormHeaderSearchMap[FormHeaderSearch.CLOSE],
  },
  {
    value: FormHeaderSearch.TEXT,
    label: FormHeaderSearchMap[FormHeaderSearch.TEXT],
  },
  {
    value: FormHeaderSearch.DATE,
    label: FormHeaderSearchMap[FormHeaderSearch.DATE],
  },
  {
    value: FormHeaderSearch.NUMBER,
    label: FormHeaderSearchMap[FormHeaderSearch.NUMBER],
  },
  {
    value: FormHeaderSearch.MONEY,
    label: FormHeaderSearchMap[FormHeaderSearch.MONEY],
  },
  {
    value: FormHeaderSearch.ENUM,
    label: FormHeaderSearchMap[FormHeaderSearch.ENUM],
  },
];
