export enum CellAlignmentType {
  left = 'left',
  right = 'right',
  center = 'center',
  justify = 'justify',
  top = 'top',
  middle = 'middle',
  bottom = 'bottom',
  baseline = 'baseline',
}

export const JustifyMap = {
  [CellAlignmentType.left]: 'left',
  [CellAlignmentType.right]: 'right',
  [CellAlignmentType.center]: 'center',
  [CellAlignmentType.justify]: 'space-between',
  [CellAlignmentType.top]: 'flex-start',
  [CellAlignmentType.middle]: 'center',
  [CellAlignmentType.bottom]: 'flex-end',
  [CellAlignmentType.baseline]: 'baseline',
};

export const AlignMap = {
  [CellAlignmentType.left]: 'flex-start',
  [CellAlignmentType.right]: 'flex-end',
  [CellAlignmentType.center]: 'center',
  [CellAlignmentType.justify]: 'space-between',
  [CellAlignmentType.top]: 'start',
  [CellAlignmentType.middle]: 'center',
  [CellAlignmentType.bottom]: 'end',
  [CellAlignmentType.baseline]: 'baseline',
};
