import { OrganizationType } from '../staff/organization-type.enum';
import { FormFlowAddressPosition } from './form-flow-address-position.model';
import { FormFlowAddress } from './form-flow-address.model';
import { FormFlowType } from './form-flow-type.enum';
import { UserInfo } from './user-info.model';
import { WorkFlowActionType } from './work-flow-action-type.enum';
import { WorkFlowSignerTaskType } from './work-flow-signer-task-type.enum';
export class FormFlowInfo {
  id?: number;
  formId?: number;
  tableId?: number;
  address?: string;
  formItemId?: number;
  firstRow?: number;
  firstCol?: number;
  step?: FormFlowType;
  musted?: boolean;
  counterSign?: boolean;
  execOrder?: number;
  organizationType?: OrganizationType;
  departmentId?: number;
  postId?: number;
  organizationId?: number;
  userIds?: Array<number>;
  userInfoList?: Array<UserInfo>;
  postKey?: string;
  formItemId1?: number;
  formItemId2?: number;
  formItemId3?: number;
  extAddress?: string;
  addressPositions?: Array<FormFlowAddressPosition>;
  addressList?: Array<FormFlowAddress>;
  signTaskType?: WorkFlowSignerTaskType;
  actionType?: WorkFlowActionType;
  enable?: boolean;
  formEnable?: boolean;
  userEditAble?: boolean;
  auto?: boolean;
  newAdded?: boolean;
  editAble?: boolean;
  update?: boolean;
  comment?: string;
  // 前端使用字段
  execOrderSpan?: number;
  execOrderSpanHeight?: number;
  needRm?: boolean;
  hint?: string;
  canSign?: boolean;
  maxTime?: number;
  myTurn?: boolean;
}
