import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QualityInspectionRegulation } from '../models/inspection/quality-inspection-regulation.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class InspectionQualityRegulationService extends RequestService<QualityInspectionRegulation> {
  static PATH = '/inspection/qualityRegulation';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(
      httpClient,
      InspectionQualityRegulationService.PATH,
      globalService.debugUrl
    );
  }
}
