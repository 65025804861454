import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SubFormRouterType } from '../models/form/sub-form-router-type.enum';
import { SubFormRouter } from '../models/form/sub-form-router.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectSubformService extends RequestService<SubFormRouter> {
  static PATH = '/om/subform/router';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, ProjectSubformService.PATH, globalService.debugUrl);
  }

  uploadFileWithoutProgress(
    file: File,
    projectId: number,
    type?: SubFormRouterType
  ) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('projectId', projectId.toString());
    if (type) {
      formData.append('type', type.toString());
    }
    return this.httpClient.post<string>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${ProjectSubformService.PATH}/upload`,
      formData
    );
  }
}
