import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UnknownPartReport } from '../models/reports/unknown-part-report.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class InspectionUnknownpartService extends RequestService<UnknownPartReport> {
  static PATH = '/inspection/unknownpart';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(
      httpClient,
      InspectionUnknownpartService.PATH,
      globalService.debugUrl
    );
  }
}
