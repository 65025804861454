import { LocationData } from './location.data';
export interface LocationInfo {
  id: string;
  name: string;
  level: number;
  parentId?: string;
}

export interface LocationModel {
  label: string;
  value: string;
  level: number;
  parentId?: string;
  isLeaf?: boolean;
  children?: LocationModel[];
}

export class LocationUtil {
  static getProvinces(): LocationInfo[] {
    let locations: LocationInfo[] = [];
    LocationData.filter(
      (location: LocationInfo) => location.level === 1,
      locations
    );
    return locations;
  }

  static getCities(provinceId: string): LocationInfo[] {
    let locations: LocationInfo[] = [];
    LocationData.filter(
      (location: LocationInfo) => location.parentId === provinceId,
      locations
    );
    return locations;
  }

  static getDistricts(cityId: string): LocationInfo[] {
    let locations: LocationInfo[] = [];
    LocationData.filter(
      (location: LocationInfo) => location.parentId === cityId,
      locations
    );
    return locations;
  }

  static getLocation(locationId: string): LocationInfo | undefined {
    for (const location of LocationData) {
      if (location.id === locationId) {
        return location;
      }
    }
    return undefined;
  }

  static getLocationName(locationId: string): string {
    if (locationId === '000000') {
      return '全国';
    }
    const provinceId = locationId.substring(0, 2) + '0000';
    const cityId = locationId.substring(0, 4) + '00';
    const province = this.getLocation(provinceId);
    const city = this.getLocation(cityId);
    const district = this.getLocation(locationId);
    let place =
      (province ? province.name : '') +
      (city ? city.name : '') +
      (district ? district.name : '');
    place = place.replace('市辖区', '');
    return place;
  }
}
