import { Pipe, PipeTransform } from '@angular/core';
import { Organization } from '../models/staff/organization.model';
import { OrganizationCategory } from '../models/staff/organization-category.enum';

@Pipe({
  name: 'prccFilter',
})
export class PrccFilterPipe implements PipeTransform {
  transform(items: Organization[], prccFilter: string): Organization[] {
    console.log('prccFilter', prccFilter);
    if (prccFilter.indexOf('.orgName') > 0) {
      return items.filter(
        (item) => item.category !== OrganizationCategory.INSTITUTION
      );
    }
    if (prccFilter.indexOf('.projectOrgName') > 0) {
      return items.filter(
        (item) => item.category === OrganizationCategory.INSTITUTION
      );
    }

    return items;
    // return items.filter((item) => item.organizationType === organizationType);
  }
}
