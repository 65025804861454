import { Pipe, PipeTransform } from '@angular/core';
import { ProjectTypeExt } from '../models/project/project-type-ext.model';

@Pipe({
  name: 'projectTypeExtFilter',
})
export class ProjectTypeExtFilterPipe implements PipeTransform {
  transform(items: ProjectTypeExt[], parentCode: string): ProjectTypeExt[] {
    console.log(parentCode);
    return items.filter((item) => item.parentCode === parentCode);
  }
}
