import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appDigitalFormat]',
})
export class DigitalFormatDirective {
  format?: string;

  constructor(private el: ElementRef, public ngModel: NgModel) {}

  @HostListener('blur') onblur() {
    let value = this.el.nativeElement.value;
    value = value.replace(/[^\d]/g, '');
    this.el.nativeElement.value = value;
  }

  @HostListener('input') onEvent() {
    let value = this.el.nativeElement.value;
    value = value.replace(/[^\d]/g, '');
    this.el.nativeElement.value = value;
    this.ngModel.control.setValue(value);
  }
}
