import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WorkReport } from '../models/form/work-report.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class WorkReportFileCountersignService extends RequestService<WorkReport> {
  static PATH = '/work/report/fileCountersign';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(
      httpClient,
      WorkReportFileCountersignService.PATH,
      globalService.debugUrl
    );
  }

  notNeedById(id: number) {
    return this.httpClient.get<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkReportFileCountersignService.PATH}/notNeedById/${id}`
    );
  }

  submitById(id: number) {
    return this.httpClient.get<number>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkReportFileCountersignService.PATH}/submitById/${id}`
    );
  }

  setNotNeedByFileGroupId(fileGroupId: number) {
    return this.httpClient.get<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${
        WorkReportFileCountersignService.PATH
      }/setNotNeedByFileGroupId/${fileGroupId}`
    );
  }

  submitByFileGroupId(fileGroupId: number) {
    return this.httpClient.get<number>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${
        WorkReportFileCountersignService.PATH
      }/submitByFileGroupId/${fileGroupId}`
    );
  }
}
