<div class="alert-dialog">
	<div class="alert-dialog-header">{{data.title}}</div>
	<div
		class="alert-dialog-body {{data.contentStyle ?  data.contentStyle : ''}} {{data.type === type.wait ? 'wait' : ''}}">

		<p *ngIf="data.type !== type.wait" [innerHtml]="data.content">
		</p>

		<object *ngIf="data.type === type.wait" data="assets/wait.svg" type="image/svg+xml"></object>

		<div class="alert-dialog-body-prompt" *ngIf="data.type === type.prompt">
			<div class="label">
				确认前，请输入大写Y：
			</div>
			<input type="text" [(ngModel)]="m">
		</div>
		<div class="alert-dialog-body-prompt" *ngIf="data.type === type.option">
			<div class="label">
				{{data.hint}}
			</div>
			<select [(ngModel)]="m">
				<option *ngFor="let item of data.options" [value]="item.value">{{item.name}}</option>
			</select>
		</div>
		<div class="alert-dialog-body-prompt" *ngIf="data.type === type.text">
			<div class="label">
				{{data.hint}}
			</div>
			<input [(ngModel)]="m" />
		</div>
		<div class="alert-dialog-body-prompt" *ngIf="data.type === type.date">
			<div class="label">
				{{data.hint}}
			</div>
			<d-datepicker-pro [format]="'yyyy-MM-dd'" [(ngModel)]="d">
			</d-datepicker-pro>
		</div>
		<div class="alert-dialog-body-prompt" *ngIf="data.type === type.datetime">
			<div class="label">
				{{data.hint}}
			</div>
			<d-datepicker-pro [format]="'yyyy-MM-dd HH:mm:ss'" [(ngModel)]="d">
			</d-datepicker-pro>
		</div>
	</div>
	<div class="alert-dialog-footer">
		<button nz-button nzType="primary" *ngIf="data.type !== type.wait" (click)="cancel()">{{ data.cancelBtnText
			}}</button>

		<button nz-button nzType="primary"
			*ngIf="data.type === type.confirm || data.type === type.option || data.type === type.date"
			(click)="confirm()">{{
			data.confirmBtnText
			}}</button>
		<button nz-button nzType="primary" *ngIf="data.type === type.prompt" (click)="confirm()"
			[disabled]="m !== 'Y'">{{
			data.confirmBtnText
			}}</button>
		<button nz-button nzType="primary" *ngIf="data.type === type.text" (click)="confirm()" [disabled]="!m">{{
			data.confirmBtnText
			}}</button>
		<button nz-button nzType="default" *ngIf="data.type === type.wait">
			{{ data.content }}
		</button>
	</div>
</div>