import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuleRegulation } from '../models/project/rule-regulation.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectRuleRegulationService extends RequestService<RuleRegulation> {
  static PATH = '/project/ruleRegulation';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(
      httpClient,
      ProjectRuleRegulationService.PATH,
      globalService.debugUrl
    );
  }
}
