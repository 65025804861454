import { Pipe, PipeTransform } from '@angular/core';
import { FormCopiesType } from '../models/form/form-copies-type.enum';
import { ProjectSectionUnitFormInfo } from '../models/project/project-section-unit-form-info.model';

@Pipe({
  name: 'formCopyFilter',
})
export class FormCopyFilterPipe implements PipeTransform {
  transform(items: ProjectSectionUnitFormInfo[]): ProjectSectionUnitFormInfo[] {
    return items.filter(
      (item) => item.formCopiesType === FormCopiesType.MULTIPLE
    );
  }
}
