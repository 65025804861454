import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appNumberFormat]',
})
export class NumberFormatDirective {
  format?: string;

  constructor(private el: ElementRef, public ngModel: NgModel) {}

  @HostListener('blur') onblur() {
    let text = this.el.nativeElement.value.toString();
    if (isNaN(+text) || text === '') {
      this.el.nativeElement.value = '';
      return;
    }
    this.el.nativeElement.value = +text;
  }

  @HostListener('input') onEvent() {
    let value = this.el.nativeElement.value;
    // 先把非数字的都替换掉，除了数字和"."
    value = value.replace(/[^\d.]/g, '');
    // 只保留第一个"."，清除多余的
    value = value.replace(/\.{2,}/g, '.');
    value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
    // 只能输入两位小数
    // value = value.replace(/^(\-|\+)*(\d+)\.(\d\d).*$/, '$1$2.$3');
    // 如果没有小数点，首位不能为类似于 01、02数字
    if (value.indexOf('.') < 0 && value !== '') {
      value = parseFloat(value);
    }
    // 如果第一位是负号，则允许添加
    if (this.el.nativeElement.value.charAt(0) === '-') {
      value = '-' + value;
    }
    // if (this.el.nativeElement.value.charAt(0) === '+') {
    //   value = '+' + value;
    // }
    this.el.nativeElement.value = value;
    this.ngModel.control.setValue(value);
  }
}
