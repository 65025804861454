<div class="grid" *ngIf="record.attachs && record.attachs.length > 0">
	<div class="row">
		<div class="column {{currentFileId === record.docPdfId! ? 'active' : ''}}" [style.width]="300 + 'px'">
			<format-data [contentStr]="record.name ?? record.sn" patternStr="E+300" (click)="chooseMain() ">
			</format-data>
		</div>
	</div>
	<div class="row">
		<div class="column text-center" [style.width]="300 + 'px'">
			附件
		</div>
	</div>
	<div class="row" *ngFor="let item of record.attachs; let index = index">
		<div class="column {{currentFileId === item.fileId! ? 'active' : ''}}" [style.width]="300 + 'px'">
			<format-data [contentStr]="item.fileName" patternStr="E+300" (click)="chooseAttach(index) ">
			</format-data>
		</div>
	</div>
</div>

<div class="preview-dialog" [style.minHeight]="height + 'px'" [style.minWidth]="width + 'px'">
	<div class="preview-dialog-header">
		<div class="action">
			<button class="color-primary" nz-button nzType="text" (click)="zoomIn()">
				<span nz-icon nzType="zoom-in" nzTheme="outline"></span>
			</button>
			<button class="color-primary" nz-button nzType="text">
				<span nz-icon nzType="border-outer" nzTheme="outline" (click)="originSize()"></span>
			</button>
			<button class="color-primary" nz-button nzType="text" (click)="zoomOut()">
				<span nz-icon nzType="zoom-out" nzTheme="outline"></span>
			</button>
		</div>
	</div>
	<!-- <div class="preview-dialog-body">
		<pdf-viewer [src]="environment.fileHost + currentFileId" [render-text]="true" [zoom-scale]="'page-fit'"
			[original-size]="false" style="width: 100%; height: 100%" [zoom]="pdfZoom" [(page)]="pdfPages"
			[c-maps-url]="'assets/cmaps'" (pages-initialized)="onPageLoad($event)">
		</pdf-viewer>
	</div> -->


	<div class="preview-dialog-body" [ngSwitch]="currentFileType">
		<img class="preview" *ngSwitchCase="FileType.IMAGE" [src]="environment.fileHost + currentFileId" alt=""
			style="">
		<!-- <iframe *ngSwitchCase="FilePreviewType.PDF" [src]="data.fileList[index] | safeResourceUrl">
			</iframe> -->


		<pdf-viewer *ngSwitchCase="FileType.PDF" [src]="environment.fileHost + currentFileId"
			[c-maps-url]="'assets/cmaps'" [render-text]="true" [zoom-scale]="'page-fit'" [original-size]="false"
			style="width: 100%; height: 100%" [zoom]="pdfZoom" [(page)]="pdfPages"
			(pages-initialized)="onPageLoad($event)">
		</pdf-viewer>

		<p *ngSwitchDefault>
			暂时无法预览这个格式的文件
		</p>

	</div>
</div>
<div class="dialog">
	<div class="dialog-header">补充信息</div>
	<div class="dialog-body">
		<div class="grid">
			<div class="row" *ngIf="!headerMap['sectionId']?.hidden">
				<div class="column background-page text-right" [style.width]="110 + 'px'">
					对应标段
				</div>
				<div class="column" [style.width]="480 + 'px'">
					<select [(ngModel)]="record.sectionId">
						<option [value]="undefined" disabled>选择标段</option>
						<option *ngFor="let item of sectionList" [value]="item.id">{{item.name}}</option>
						<!-- <option [value]="'多标段'">多标段</option> -->
					</select>
				</div>
			</div>

			<div class="row" *ngIf="!headerMap['constructionOrgName']?.hidden">
				<div class="column background-page text-right" [style.width]="110 + 'px'">
					施工单位
				</div>
				<div class="column" [style.width]="480 + 'px'">
					<select [(ngModel)]="record.constructionOrgName">
						<option [value]="undefined" disabled>选择施工单位</option>
						<option *ngFor="let item of consOrgNameList" [value]="item">{{item}}</option>
					</select>
				</div>
			</div>

			<div class="row" *ngIf="!headerMap['supervisionOrgName']?.hidden">
				<div class="column background-page text-right" [style.width]="110 + 'px'">
					监理单位
				</div>
				<div class="column " [style.width]="480 + 'px'">
					<select [(ngModel)]="record.supervisionOrgName">
						<option [value]="undefined" disabled>选择监理单位</option>
						<option *ngFor="let item of supOrgNameList" [value]="item">{{item}}</option>
					</select>
				</div>
			</div>

			<div class="row" *ngFor="let header of headerList">
				<div class="column background-page text-right" [style.width]="110 + 'px'">
					{{header.headerName}}
				</div>
				<div *ngIf="header.fieldName !== '归属月份'" class="column" [style.width]="480 + 'px'">
					<input *ngIf="header.format === FormHeaderFormat.TEXT && header.search !== FormHeaderSearch.ENUM"
						type="text" [disabled]="header.fixedParamValue === '-'" placeholder="输入{{header.headerName}}"
						[(ngModel)]="record[header.fieldName!]">
					<input *ngIf="header.format === FormHeaderFormat.NUMBER" type="text"
						[disabled]="header.fixedParamValue === '-'" placeholder="输入{{header.headerName}}"
						[(ngModel)]="record[header.fieldName!]" appDigitalFormat>
					<input *ngIf="header.format === FormHeaderFormat.MONEY" type="text"
						[disabled]="header.fixedParamValue === '-'" placeholder="输入{{header.headerName}}"
						[(ngModel)]="record[header.fieldName!]" appAmountFormat>
					<select *ngIf="header.format === FormHeaderFormat.TEXT && header.search === FormHeaderSearch.ENUM"
						[(ngModel)]="record[header.fieldName!]">
						<option *ngFor="let item of optionMap[header.fieldName!]" [value]="item.value">
							{{item.label}}
						</option>
					</select>
					<nz-date-picker *ngIf="header.format === FormHeaderFormat.DATE"
						[nzDisabled]="header.fixedParamValue === '-'" [(ngModel)]="record[header.fieldName!]"
						[nzSuffixIcon]="''" nzPlaceHolder="输入{{header.headerName}}" nzFormat="yyyy-MM-dd">
					</nz-date-picker>
				</div>
				<div *ngIf="header.fieldName === '归属年月'" class="column" [style.width]="480 + 'px'">
					<nz-date-picker [nzMode]="'month'" [(ngModel)]="record[header.fieldName!]" [nzSuffixIcon]="''"
						[nzDisabled]="header.fixedParamValue === '-'" nzPlaceHolder="输入{{header.headerName}}"
						nzFormat="yyyy-MM-dd">
					</nz-date-picker>
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-footer">
		<button nz-button nzType="default" (click)="cancel()">取消</button>
		<button nz-button nzType="primary" (click)="confirm()" [disabled]="!canConfirm">确认</button>
	</div>
</div>