export enum IDCardType {
  CHINESE_ID_CARD = 'CHINESE_ID_CARD',
  CHINESE_PASSPORT = 'CHINESE_PASSPORT',
  TRAVEL_PERMIT_4_HKMC = 'TRAVEL_PERMIT_4_HKMC',
  TRAVEL_PERMIT_4_TW = 'TRAVEL_PERMIT_4_TW',
  FOREIGN_PASSPORT = 'FOREIGN_PASSPORT',
}

export const IDCardTypeMap = {
  [IDCardType.CHINESE_ID_CARD]: '中国居民身份证',
  [IDCardType.CHINESE_PASSPORT]: '中国护照',
  [IDCardType.TRAVEL_PERMIT_4_HKMC]: '港澳居民来往内地通行证',
  [IDCardType.TRAVEL_PERMIT_4_TW]: '台湾居民来往大陆通行证',
  [IDCardType.FOREIGN_PASSPORT]: '外国护照',
};

export const IDCardTypeList = [
  {
    value: IDCardType.CHINESE_ID_CARD,
    label: IDCardTypeMap[IDCardType.CHINESE_ID_CARD],
  },
  {
    value: IDCardType.CHINESE_PASSPORT,
    label: IDCardTypeMap[IDCardType.CHINESE_PASSPORT],
  },
  {
    value: IDCardType.TRAVEL_PERMIT_4_HKMC,
    label: IDCardTypeMap[IDCardType.TRAVEL_PERMIT_4_HKMC],
  },
  {
    value: IDCardType.TRAVEL_PERMIT_4_TW,
    label: IDCardTypeMap[IDCardType.TRAVEL_PERMIT_4_TW],
  },
  {
    value: IDCardType.FOREIGN_PASSPORT,
    label: IDCardTypeMap[IDCardType.FOREIGN_PASSPORT],
  },
];
