import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'detection-design',
  templateUrl: './detection-design.component.html',
  styleUrls: ['./detection-design.component.scss'],
})
export class DetectionDesignComponent {
  val: string = '';

  oldVal = '';

  realVal: number[] = [];

  editing = false;

  inited = false;

  @Input()
  set initValue(val: string | undefined) {
    if (val && !this.inited) {
      this.inited = true;
      setTimeout(() => {
        this.val = val.replaceAll('<br/>', ' ').replaceAll('&ensp;', ' ');
        this.onValChange();
      }, 100);
    }
  }

  @Output()
  designValChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  onValChange() {
    this.editing = false;
    if (this.val === this.oldVal) {
      return;
    }
    this.realVal = [];
    const numList = this.val
      .replaceAll('/', ' ')
      .split(' ')
      .map((item) => (item !== '' ? +item : NaN));
    for (const item of numList) {
      if (!isNaN(item)) {
        this.realVal.push(item);
      }
    }
    if (this.realVal.length > 2) {
      this.realVal = this.realVal.slice(0, 2);
    }
    this.val = this.realVal.join(' ');
    this.oldVal = this.val;
    this.designValChange.emit(this.realVal);
  }

  constructor() {}
}
