<div class="view-container">
    <div class="left-panel" *ngIf="!hideLeft && projectInspection">
        <div class="grid flow" [style.width]="300 + 'px'">
            <div class="row sticky" [style.top]="0">
                <div class="column background-primary color-white pointer" [style.width]="40 + 'px'"
                    (click)="hideLeftChange(true)">
                    ←
                </div>
                <div class="column background-primary color-white" [style.width]="260 + 'px'">
                    <format-data [contentStr]="projectInspectionTitle" patternStr="E+256">
                    </format-data>
                </div>
            </div>
            <div class="row">
                <div class="column background-page even" [style.width]="300 + 'px'" (click)="cHide = !cHide">
                    <span> </span>
                    <span>施工报表[{{cN}}/{{workFlowInfoListC.length}}]</span>
                    <span>{{cHide ? '↑' : '↓'}}</span>
                </div>
            </div>
            <div class="row clickable {{item.workFlowKey === currentWorkFlowInfo?.workFlowKey ? 'active' : ''}}"
                *ngFor="let item of (cHide ? [] : workFlowInfoListC); let index = index"
                (click)="chooseMain(item.workFlowKey!)">
                <div class="column text-center" [style.width]="20 + 'px'">
                    {{index + 1}}
                </div>
                <div class="column {{item.operateEnable && item.status ? 'color-dark-danger ' : 'color-light'}}"
                    [style.width]="50 + 'px'">
                    {{!!item.status ? item.status! !== WorkFlowStatus.WAIT ? WorkFlowStatusMap[item.status!] : ('待' +
                    FormFlowTypeMap[item.step!]) : '未创建'}}
                </div>
                <div class="column {{item.formCompleted ? 'finished' : ''}} {{!!item.workFlowId && preparedSignIds.includes(item.workFlowId!.toString()) && item.operateEnable ? 'prepared' : ''}}"
                    [style.width]="210 + 'px'">
                    <format-data [contentStr]="item.workFlowTitle"
                        patternStr="E+200{{!item.workFlowId ? '@@Cbdc3c7' : ''}}">
                    </format-data>
                    <span class="check"
                        *ngIf="item.workFlowId && preparedSignIds.includes(item.workFlowId!.toString())">√</span>
                    <span class="finish-mark" *ngIf="item.formCompleted">完</span>
                </div>
                <div class="column text-center pointer" [style.width]="20 + 'px'">
                    <span nz-icon nzType="more" nzTheme="outline" nz-dropdown [nzDropdownMenu]="menu3"></span>
                </div>
                <!-- TODO 菜单功能 -->
                <nz-dropdown-menu #menu3="nzDropdownMenu">
                    <ul nz-menu>
                        <li class="color-primary" *ngIf="item.formCopiesType === FormCopiesType.MULTIPLE"
                            (click)="addCopy(item, item.sectionId!, item.sectionItemId!)" nz-menu-item>
                            新增
                        </li>
                        <!-- <li nz-menu-item>上传 - PDF签完稿</li> -->
                        <li nz-menu-item>下载 - PDF原始稿</li>
                        <li nz-menu-item (click)="download(item.latestPdfFileId!)">下载 - PDF签完稿</li>
                        <!-- <li nz-menu-item>上移</li>
                    <li nz-menu-item>下移</li> -->
                    </ul>
                </nz-dropdown-menu>
            </div>
            <div class="row">
                <div class="column background-page even" [style.width]="300 + 'px'" (click)="sHide = !sHide">
                    <span> </span>
                    <span>监理报表[{{sN}}/{{workFlowInfoListS.length}}]</span>
                    <span>{{sHide ? '↑' : '↓'}}</span>
                </div>
            </div>
            <div class="row clickable {{item.workFlowKey === currentWorkFlowInfo?.workFlowKey ? 'active' : ''}}"
                *ngFor="let item of (sHide ? [] : workFlowInfoListS); let index = index"
                (click)="chooseMain(item.workFlowKey!)">
                <div class="column text-center" [style.width]="20 + 'px'">
                    {{index + 1}}
                </div>
                <div class="column {{item.operateEnable && item.status ? 'color-dark-danger ' : 'color-light'}}"
                    [style.width]="50 + 'px'">
                    {{!!item.status ? item.status! !== WorkFlowStatus.WAIT ? WorkFlowStatusMap[item.status!] : ('待' +
                    FormFlowTypeMap[item.step!]) : '未创建'}}
                </div>
                <div class="column {{!!item.workFlowId && preparedSignIds.includes(item.workFlowId!.toString()) && item.operateEnable ? 'prepared' : ''}}"
                    [style.width]="210 + 'px'">
                    <format-data [contentStr]="item.workFlowTitle"
                        patternStr="E+200{{!item.workFlowId ? '@@Cbdc3c7' : ''}}">
                    </format-data>
                    <span class="check"
                        *ngIf="item.workFlowId && preparedSignIds.includes(item.workFlowId!.toString())">√</span>
                </div>
                <div class="column text-center pointer" [style.width]="20 + 'px'">
                    <span nz-icon nzType="more" nzTheme="outline" nz-dropdown [nzDropdownMenu]="menu3"></span>
                </div>
                <!-- TODO 菜单功能 -->
                <nz-dropdown-menu #menu3="nzDropdownMenu">
                    <ul nz-menu>
                        <li class="color-primary" *ngIf="item.formCopiesType === FormCopiesType.MULTIPLE"
                            (click)="addCopy(item, item.sectionId!, item.sectionItemId!)" nz-menu-item>
                            新增
                        </li>
                        <!-- <li nz-menu-item>上传 - PDF签完稿</li> -->
                        <li nz-menu-item>下载 - PDF原始稿</li>
                        <li nz-menu-item (click)="download(item.latestPdfFileId!)">下载 - PDF签完稿</li>
                        <!-- <li nz-menu-item>上移</li>
                                <li nz-menu-item>下移</li> -->
                    </ul>
                </nz-dropdown-menu>
            </div>
            <div class="row">
                <div class="column background-page even" [style.width]="300 + 'px'" (click)="csHide = !csHide">
                    <span> </span>
                    <span>自检报告（工地试验室）[{{csN}}/{{workFlowInfoListCS.length}}]</span>
                    <span>{{csHide ? '↑' : '↓'}}</span>
                </div>
            </div>
            <div class="row clickable {{item.workFlowKey === currentWorkFlowInfo?.workFlowKey ? 'active' : ''}}"
                *ngFor="let item of (csHide ? [] : workFlowInfoListCS); let index = index"
                (click)="chooseMain(item.workFlowKey!)">
                <div class="column text-center" [style.width]="20 + 'px'">
                    {{index + 1}}
                </div>
                <div class="column color-light" [style.width]="50 + 'px'">
                    {{!item.status ? '未收到' : item.status === WorkFlowStatus.FINISH ? '合格' :
                    WorkFlowStatusMap[item.status!]}}
                </div>
                <div class="column {{!!item.workFlowId && preparedSignIds.includes(item.workFlowId!.toString()) && item.operateEnable ? 'prepared' : ''}}"
                    [style.width]="210 + 'px'">
                    <format-data [contentStr]="item.workFlowTitle"
                        patternStr="E+200{{!item.workFlowId ? '@@Cbdc3c7' : ''}}">
                    </format-data>
                    <span class="check"
                        *ngIf="item.workFlowId && preparedSignIds.includes(item.workFlowId!.toString())">√</span>
                </div>
                <div class="column text-center pointer" [style.width]="20 + 'px'">
                    <span nz-icon nzType="more" nzTheme="outline" nz-dropdown [nzDropdownMenu]="menu3"></span>
                </div>
                <!-- TODO 菜单功能 -->
                <nz-dropdown-menu #menu3="nzDropdownMenu">
                    <ul nz-menu>
                        <!-- <li nz-menu-item>新增</li> -->
                        <li class="color-primary" *ngIf="item.formCopiesType === FormCopiesType.MULTIPLE"
                            (click)="addCopy(item, item.sectionId!, item.sectionItemId!)" nz-menu-item>
                            新增
                        </li>
                        <!-- <li nz-menu-item>上传 - PDF签完稿</li> -->
                        <li nz-menu-item>下载 - PDF原始稿</li>
                        <li nz-menu-item (click)="download(item.latestPdfFileId!)">下载 - PDF签完稿</li>
                        <!-- <li nz-menu-item>上移</li>
                                                        <li nz-menu-item>下移</li> -->
                    </ul>
                </nz-dropdown-menu>
            </div>
            <div class="row">
                <div class="column background-page even" [style.width]="300 + 'px'" (click)="ssHide = !ssHide">
                    <span> </span>
                    <span>抽检报告（中心试验室）[{{ssN}}/{{workFlowInfoListSS.length}}]</span>
                    <span class="color-white">{{ssHide ? '↑' : '↓'}}</span>
                </div>
            </div>
            <div class="row clickable {{item.workFlowKey === currentWorkFlowInfo?.workFlowKey ? 'active' : ''}}"
                *ngFor="let item of (ssHide ? [] : workFlowInfoListSS); let index = index"
                (click)="chooseMain(item.workFlowKey!)">
                <div class="column text-center" [style.width]="20 + 'px'">
                    {{index + 1}}
                </div>
                <div class="column {{item.operateEnable && item.status ? 'color-dark-danger ' : 'color-light'}}"
                    [style.width]="50 + 'px'">
                    {{!item.status ? '未收到' : item.status === WorkFlowStatus.FINISH ? '合格' :
                    WorkFlowStatusMap[item.status!]}}
                </div>
                <div class="column {{!!item.workFlowId && preparedSignIds.includes(item.workFlowId!.toString()) && item.operateEnable ? 'prepared' : ''}}"
                    [style.width]="210 + 'px'">
                    <format-data [contentStr]="item.workFlowTitle"
                        patternStr="E+200{{!item.workFlowId ? '@@Cbdc3c7' : ''}}">
                    </format-data>
                    <span class="check"
                        *ngIf="item.workFlowId && preparedSignIds.includes(item.workFlowId!.toString())">√</span>
                </div>
                <div class="column text-center pointer" [style.width]="20 + 'px'">
                    <span nz-icon nzType="more" nzTheme="outline" nz-dropdown [nzDropdownMenu]="menu3"></span>
                </div>
                <!-- TODO 菜单功能 -->
                <nz-dropdown-menu #menu3="nzDropdownMenu">
                    <ul nz-menu>
                        <li class="color-primary" *ngIf="item.formCopiesType === FormCopiesType.MULTIPLE"
                            (click)="addCopy(item, item.sectionId!, item.sectionItemId!)" nz-menu-item>
                            新增
                        </li>
                        <!-- <li nz-menu-item>上传 - PDF签完稿</li> -->
                        <li nz-menu-item>下载 - PDF原始稿</li>
                        <li nz-menu-item (click)="download(item.latestPdfFileId!)">下载 - PDF签完稿</li>
                        <!-- <li nz-menu-item>上移</li>
                                                        <li nz-menu-item>下移</li> -->
                    </ul>
                </nz-dropdown-menu>
            </div>
            <div class="row" *ngIf="!!currentWorkFlowInfo || !!workReport">
                <div class="column" [style.height]="'11rem'">

                </div>
            </div>
        </div>

        <div class="footer" *ngIf="!!currentWorkFlowInfo">
            <div class="grid">
                <div class="row">
                    <div class="column background-page" [style.width]="300 + 'px'">
                        <b>附件</b>
                    </div>
                </div>
                <div class="row clickable"
                    *ngFor="let item of (workReport ? [] : (currentWorkFlowInfo?.attachFiles ?? [])); let index = index">
                    <div class="column text-center" [style.width]="20 + 'px'">
                        {{index + 1}}
                    </div>
                    <div class="column" [style.width]="260 + 'px'">
                        <format-data class="color-grey" [contentStr]="item.fileName ?? ''" patternStr="E+260"
                            (click)="previewAttachment(item.fileId!)" *ngIf="!item.editing">
                        </format-data>
                    </div>
                    <div class="column text-center pointer" [style.width]="20 + 'px'">
                        <span nz-icon nzType="more" nzTheme="outline" nz-dropdown [nzDropdownMenu]="menu5"></span>
                    </div>
                    <nz-dropdown-menu #menu5="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-menu-item (click)="download(item.fileId!)">
                                下载
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                </div>
                <div class="row clickable" *ngFor="let item of currentWorkFlowInfo?.appendFiles; let index = index;">
                    <div class="column" [style.width]="20 + 'px'">
                        {{currentWorkFlowInfo!.attachFiles!.length+ index + 1}}
                    </div>
                    <div class="column text-left" [style.width]="260 + 'px'">
                        <format-data class="color-primary" [contentStr]="item.fileName ?? ''" patternStr="E+260"
                            (click)="previewAttachment(item.fileId!)" *ngIf="!item.editing">
                        </format-data>

                        <input *ngIf="item.editing" type="text" [(ngModel)]="item.fileName"
                            (blur)="onFileNameChange(index)">
                    </div>
                    <div class="column text-center pointer" [style.width]="20 + 'px'" nz-dropdown
                        [nzDropdownMenu]="menu4">
                        <span nz-icon nzType="more" nzTheme="outline"></span>
                    </div>
                    <nz-dropdown-menu #menu4="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-menu-item (click)="item.editing = true">
                                重命名
                            </li>
                            <li nz-menu-item (click)="download(item.fileId!)">
                                下载
                            </li>
                            <li nz-menu-item (click)="removeAttachment(index)">
                                删除
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                </div>

                <div class="row clickable" *ngFor="let item of workReport?.attachs; let index = index;">
                    <div class="column" [style.width]="20 + 'px'">
                        {{(currentWorkFlowInfo.attachFiles ? currentWorkFlowInfo.attachFiles.length : 0) + index + 1}}
                    </div>
                    <div class="column text-left {{item.editing ? 'background-white' : ''}}" [style.width]="260 + 'px'">
                        <format-data class="color-primary" [contentStr]="item.fileName ?? ''" patternStr="E+260"
                            (click)="previewAttachment(item.fileId!)" *ngIf="!item.editing">
                        </format-data>

                        <input *ngIf="item.editing" type="text" [(ngModel)]="item.fileName"
                            (blur)="onReportFileNameChange(index)">
                    </div>
                    <div class="column text-center pointer" [style.width]="20 + 'px'" nz-dropdown
                        [nzDropdownMenu]="menu4">
                        <span nz-icon nzType="more" nzTheme="outline"></span>
                    </div>
                    <nz-dropdown-menu #menu4="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-menu-item (click)="item.editing = true">
                                重命名
                            </li>
                            <li nz-menu-item (click)="download(item.fileId!)">
                                下载
                            </li>
                            <li nz-menu-item (click)="removeReportAttachment(index)">
                                删除
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                </div>

                <div class="row"
                    *ngIf="!workReport && !!currentWorkFlowInfo?.operateEnable && currentWorkFlowInfo?.status === WorkFlowStatus.WAIT && currentWorkFlowInfo?.fileCountersignStatus !== FileCountersignStatus.STARTED">
                    <div class="column" [style.width]="300 + 'px'">
                        <button class="color-primary" nz-button nzType="text">
                            +上传附件
                            <input type="file" multiple="multiple" (change)="uploadAppend($event)">
                        </button>
                    </div>
                </div>

                <div class="row" *ngIf="!!workReport">
                    <div class="column" [style.width]="300 + 'px'">
                        <button class="color-primary" nz-button nzType="text">
                            +上传附件
                            <input type="file" multiple="multiple" (change)="uploadReportAppend($event)">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="grid left" [style.width]="300 + 'px'" *ngIf="!hideLeft && !projectInspection">
        <div class="row sticky" [style.top]="0">
            <div class="column background-primary color-white pointer" [style.width]="40 + 'px'"
                (click)="hideLeftChange(true)">
                ←
            </div>
            <div class="column background-primary color-white" [style.width]="260 + 'px'">
                <format-data
                    [contentStr]="!!currentWorkFlowInfo?.fileGroupName ? currentWorkFlowInfo?.fileGroupName : (currentWorkFlowInfo?.workFlowTitle ?? '')"
                    patternStr="E+256">
                </format-data>
            </div>
        </div>
        <div class="row" *ngIf="countersignFlow && isDevOrg">
            <div class="column background-page" [style.width]="300 + 'px'">
                <b>会签单</b>
            </div>
        </div>
        <div *ngIf="countersignFlow && isDevOrg"
            class="row clickable {{countersignFlow.workFlowKey === currentWorkFlowInfo?.workFlowKey ? 'active' : ''}}"
            (click)="chooseMain(countersignFlow.workFlowKey!)">
            <div class="column text-center" [style.width]="20 + 'px'">
                {{index + 1}}
            </div>
            <div class="column {{countersignFlow.operateEnable ? 'color-dark-danger ' : 'color-light'}}"
                [style.width]="50 + 'px'">
                {{!!countersignFlow.status ? countersignFlow.status! !== WorkFlowStatus.WAIT ?
                WorkFlowStatusMap[countersignFlow.status!] : ('待' +
                FormFlowTypeMap[countersignFlow.step!]) : '未创建'}}
            </div>
            <div class="column {{countersignFlow.workFlowId && preparedSignIds.includes(countersignFlow.workFlowId!.toString()) && countersignFlow.operateEnable ? 'prepared' : ''}}"
                [style.width]="210 + 'px'">
                <format-data [contentStr]="countersignFlow.workFlowTitle" patternStr="E+200">
                </format-data>
                <span class="check"
                    *ngIf="countersignFlow.workFlowId && preparedSignIds.includes(countersignFlow.workFlowId!.toString())">√</span>
            </div>
            <div class="column text-center pointer" [style.width]="20 + 'px'">
                <span nz-icon nzType="more" nzTheme="outline" nz-dropdown [nzDropdownMenu]="menu4"></span>
            </div>
            <!-- TODO 菜单功能 -->
            <nz-dropdown-menu #menu4="nzDropdownMenu">
                <ul nz-menu>
                    <!-- <li nz-menu-item>新增</li> -->
                    <!-- <li nz-menu-item>上传 - PDF签完稿</li> -->
                    <li nz-menu-item>下载 - PDF原始稿</li>
                    <li nz-menu-item (click)="download(countersignFlow.latestPdfFileId!)">下载 - PDF签完稿</li>
                    <!-- <li nz-menu-item>上移</li>
                    <li nz-menu-item>下移</li> -->
                </ul>
            </nz-dropdown-menu>
        </div>
        <div class="row">
            <div class="column background-page" [style.width]="300 + 'px'">
                <b>主文件</b>
            </div>
        </div>
        <div class="row clickable {{item.workFlowKey === currentWorkFlowInfo?.workFlowKey ? 'active' : ''}}"
            *ngFor="let item of workFlowInfoList | counterSignFilter; let index = index"
            (click)="chooseMain(item.workFlowKey!)">
            <div class="column text-center" [style.width]="20 + 'px'">
                {{index + 1}}
            </div>
            <div class="column {{item.operateEnable && item.status ? 'color-dark-danger ' : 'color-light'}}"
                [style.width]="50 + 'px'">
                {{!!item.status ? item.status! !== WorkFlowStatus.WAIT ? WorkFlowStatusMap[item.status!] : ('待' +
                FormFlowTypeMap[item.step!]) : '未创建'}}
            </div>
            <div class="column {{!!item.workFlowId && preparedSignIds.includes(item.workFlowId!.toString()) && item.operateEnable ? 'prepared' : ''}}"
                [style.width]="210 + 'px'">
                <format-data [contentStr]="item.workFlowTitle"
                    patternStr="E+200{{!item.workFlowId ? '@@Cbdc3c7' : ''}}">
                </format-data>
                <span class="check"
                    *ngIf="item.workFlowId && preparedSignIds.includes(item.workFlowId!.toString())">√</span>
            </div>
            <div class="column text-center pointer" [style.width]="20 + 'px'">
                <span nz-icon nzType="more" nzTheme="outline" nz-dropdown [nzDropdownMenu]="menu3"></span>
            </div>
            <!-- TODO 菜单功能 -->
            <nz-dropdown-menu #menu3="nzDropdownMenu">
                <ul nz-menu>
                    <!-- <li nz-menu-item>新增</li> -->
                    <!-- <li nz-menu-item>上传 - PDF签完稿</li> -->
                    <li nz-menu-item>下载 - PDF原始稿</li>
                    <li nz-menu-item (click)="download(item.latestPdfFileId!)">下载 - PDF签完稿</li>
                    <!-- <li nz-menu-item>上移</li>
                    <li nz-menu-item>下移</li> -->
                </ul>
            </nz-dropdown-menu>
        </div>
        <div class="row">
            <div class="column" [style.width]="300 + 'px'">
            </div>
        </div>
        <div class="row">
            <div class="column background-page" [style.width]="300 + 'px'">
                <b>附件</b>
            </div>
        </div>
        <div class="row clickable"
            *ngFor="let item of (workReport ? [] : (currentWorkFlowInfo?.attachFiles ?? [])); let index = index">
            <div class="column text-center" [style.width]="20 + 'px'">
                {{index + 1}}
            </div>
            <div class="column" [style.width]="260 + 'px'">
                <format-data class="color-grey" [contentStr]="item.fileName ?? ''" patternStr="E+260"
                    (click)="previewAttachment(item.fileId!)" *ngIf="!item.editing">
                </format-data>
            </div>
            <div class="column text-center pointer" [style.width]="20 + 'px'">
                <span nz-icon nzType="more" nzTheme="outline" nz-dropdown [nzDropdownMenu]="menu5"></span>
            </div>
            <nz-dropdown-menu #menu5="nzDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item (click)="download(item.fileId!)">
                        下载
                    </li>
                </ul>
            </nz-dropdown-menu>
        </div>

        <div class="row clickable" *ngFor="let item of currentWorkFlowInfo?.appendFiles; let index = index;">
            <div class="column" [style.width]="20 + 'px'">
                {{currentWorkFlowInfo!.attachFiles!.length+ index + 1}}
            </div>
            <div class="column text-left" [style.width]="260 + 'px'">
                <format-data class="color-primary" [contentStr]="item.fileName ?? ''" patternStr="E+260"
                    (click)="previewAttachment(item.fileId!)" *ngIf="!item.editing">
                </format-data>

                <input *ngIf="item.editing" type="text" [(ngModel)]="item.fileName" (blur)="onFileNameChange(index)">
            </div>
            <div class="column text-center pointer" [style.width]="20 + 'px'" nz-dropdown [nzDropdownMenu]="menu4">
                <span nz-icon nzType="more" nzTheme="outline"></span>
            </div>
            <nz-dropdown-menu #menu4="nzDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item (click)="item.editing = true">
                        重命名
                    </li>
                    <li nz-menu-item (click)="download(item.fileId!)">
                        下载
                    </li>
                    <li nz-menu-item (click)="removeAttachment(index)">
                        删除
                    </li>
                </ul>
            </nz-dropdown-menu>
        </div>

        <div class="row clickable" *ngFor="let item of workReport?.attachs; let index = index;">
            <div class="column" [style.width]="20 + 'px'">
                {{currentWorkFlowInfo!.attachFiles!.length+ index + 1}}
            </div>
            <div class="column text-left {{item.editing ? 'background-white' : ''}}" [style.width]="260 + 'px'">
                <format-data class="color-primary" [contentStr]="item.fileName ?? ''" patternStr="E+260"
                    (click)="previewAttachment(item.fileId!)" *ngIf="!item.editing">
                </format-data>

                <input *ngIf="item.editing" type="text" [(ngModel)]="item.fileName"
                    (blur)="onReportFileNameChange(index)">
            </div>
            <div class="column text-center pointer" [style.width]="20 + 'px'" nz-dropdown [nzDropdownMenu]="menu4">
                <span nz-icon nzType="more" nzTheme="outline"></span>
            </div>
            <nz-dropdown-menu #menu4="nzDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item (click)="item.editing = true">
                        重命名
                    </li>
                    <li nz-menu-item (click)="download(item.fileId!)">
                        下载
                    </li>
                    <li nz-menu-item (click)="removeReportAttachment(index)">
                        删除
                    </li>
                </ul>
            </nz-dropdown-menu>
        </div>

        <div class="row"
            *ngIf="!workReport && !!currentWorkFlowInfo?.operateEnable && currentWorkFlowInfo?.status === WorkFlowStatus.WAIT && currentWorkFlowInfo?.fileCountersignStatus !== FileCountersignStatus.STARTED">
            <div class="column" [style.width]="300 + 'px'">
                <button class="color-primary" nz-button nzType="text">
                    +上传附件
                    <input type="file" multiple="multiple" (change)="uploadAppend($event)">
                </button>
            </div>
        </div>

        <div class="row" *ngIf="!!workReport">
            <div class="column" [style.width]="300 + 'px'">
                <button class="color-primary" nz-button nzType="text">
                    +上传附件
                    <input type="file" multiple="multiple" (change)="uploadReportAppend($event)">
                </button>
            </div>
        </div>
    </div>

    <div class="grid middle" *ngIf="!workReport">
        <div class="row sticky background-module" [style.top]=" 0">
            <div class="column background-primary color-white pointer" *ngIf="hideLeft" [style.width]="40 + 'px'"
                (click)="hideLeftChange(false)">
                →
            </div>
            <div class="column space-between"
                [style.width]="(centerWidth + (!hideLeft ? 0 : -40) + (!hideRight ? 0 : -40)) + 'px'">
                <div class="action">
                    <!-- <button class="color-primary" nz-button nzType="text" (click)="zoomIn()">
                        <span nz-icon nzType="zoom-in" nzTheme="outline"></span>
                    </button>
                    <button class="color-primary" nz-button nzType="text">
                        <span nz-icon nzType="border-outer" nzTheme="outline" (click)="originSize()"></span>
                    </button>
                    <button class="color-primary" nz-button nzType="text" (click)="zoomOut()">
                        <span nz-icon nzType="zoom-out" nzTheme="outline"></span>
                    </button> -->
                </div>
                <h4>签字 | 盖章</h4>
                <button class="color-primary" nz-button nzType="text" nz-dropdown [nzDropdownMenu]="menu2"
                    [style.margin-right]="'10px'" [nzPlacement]="'bottomRight'">
                    ↓ 下载主文件
                </button>
                <nz-dropdown-menu #menu2="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item (click)="download(currentWorkFlowInfo!.latestPdfFileId!)">
                            下载PDF原始稿
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>

            <div class="column background-primary color-white pointer" *ngIf="hideRight" [style.width]="40 + 'px'"
                (click)="hideRightChange(false)">
                ←
            </div>
        </div>

        <div class="preview-body" *ngIf="currentAttachFileId" [ngSwitch]="currentFileType">
            <img class="preview" *ngSwitchCase="FileType.IMAGE" [src]="environment.fileHost + currentAttachFileId">

            <pdf-viewer *ngSwitchCase="FileType.PDF" [src]="environment.fileHost + currentAttachFileId"
                [c-maps-url]="'assets/cmaps'" [render-text]="true" [zoom-scale]="'page-fit'" [original-size]="false"
                style="width: 100%; height: 100%" [zoom]="pdfZoom" [(page)]="pdfPages"
                (pages-initialized)="onPageLoad($event)">
            </pdf-viewer>

            <p *ngSwitchDefault>
                暂时无法预览这个格式的文件
            </p>

        </div>

        <div class="preview-body" *ngIf="!currentAttachFileId">
            <div class="sign-container" *ngIf="!!currentWorkFlowInfo && !!currentWorkFlowInfo.signFiles && inited">
                <div class="sign-file" [style.width]="info.pdfWidth + 'pt'" [style.height]="info.pdfHeight + 'pt'"
                    *ngFor="let info of (currentWorkFlowInfo.signFiles![0]?.imageInfos ?? []); let page = index;"
                    (drop)="onDrop($event, page)" (dragover)="allowDrop($event)">
                    <span class="hint color-dark-danger" *ngIf="isDebug">
                        Width: {{info.pdfWidth}}
                        Height: {{info.pdfHeight}}
                    </span>
                    <img [src]="environment.fileHost + info.fileId" alt="" />
                    <div class="sign-widget"
                        *ngFor="let item of normalWidgetMap[currentWorkFlowInfo.workFlowId!][page] ?? []"
                        [style.left]="item.signFieldPosition!.left! + 'pt'"
                        [style.top]="item.signFieldPosition!.top! + 'pt'"
                        [style.height]="item.signFieldPosition!.signFieldHeight + 'px'"
                        [style.width]="item.signFieldPosition!.signFieldWidth + 'px'">
                        <span class="hint color-primary" *ngIf="isDebug">
                            {{item.flowId}}
                        </span>
                        <img *ngIf="item.sealFieldImgFileId" [src]="environment.fileHost + item.sealFieldImgFileId"
                            alt="" [style.width]="'100%'" [style.height]="'100%'">
                    </div>
                    <div class="sign-widget current {{item.musted ? 'must' : ''}}"
                        *ngFor="let item of currentWidgetMap[currentWorkFlowInfo.workFlowId!][page] ?? []; let itemIndex = index"
                        [style.left]="item.signFieldPosition!.left! + 'pt'"
                        [style.top]="item.signFieldPosition!.top! + 'pt'"
                        [style.height]="item.dragable && item.signFieldType === WorkFlowSignFieldType.REMARK_FIELD ? 'auto' : item.signFieldPosition!.signFieldHeight + 'pt'"
                        [style.width]="item.signFieldPosition!.signFieldWidth + 'pt'" [draggable]="!!item.dragable"
                        (dragstart)="onDragableMoveStart(page, itemIndex, item.dragable)">
                        <span class="hint color-dark-danger " *ngIf="isDebug">
                            Left: {{item.signFieldPosition!.left!}}
                            Top: {{item.signFieldPosition!.top!}}
                            Right: {{item.signFieldPosition!.left! + item.signFieldPosition!.signFieldWidth!}}
                            Bottom: {{item.signFieldPosition!.top! + item.signFieldPosition!.signFieldHeight!}}
                        </span>
                        <textarea *ngIf="item.signFieldType === WorkFlowSignFieldType.REMARK_FIELD && !item.dragable"
                            [style.width]="'100%'" [style.resize]="'none'" [style.height]="'100%'" maxlength="256"
                            (blur)="checkSignPrepared()" [(ngModel)]="item.content">
						</textarea>

                        <p [style.background]="'white'"
                            *ngIf="item.signFieldType === WorkFlowSignFieldType.REMARK_FIELD && item.dragable"
                            [style.height]="item.dragable ? 'auto' : '100%'" [style.width]="'100%'"
                            (blur)="checkSignPrepared()" contenteditable="true" [textContent]="item.content"
                            (input)="onInput($event, item, page)">
                        </p>
                        <!-- TODO 测试样式 -->
                        <span *ngIf="item.signFieldType === WorkFlowSignFieldType.RADIO_FIELD && item.dataSourceObj"
                            class="{{rMap[item.dataSourceObj.group] === item.dataSourceObj.value ? 'color-primary' : ''}} fake-radio"
                            [style.width]="'100%'" [style.fontSize]="'16px'">
                            {{rMap[item.dataSourceObj.group] === item.dataSourceObj.value ? '☑' : '□'}}
                        </span>

                        <input *ngIf="item.signFieldType === WorkFlowSignFieldType.RADIO_FIELD && item.dataSourceObj"
                            [(ngModel)]="rMap[item.dataSourceObj.group]" type="radio" [name]="item.dataSourceObj.group"
                            [value]="item.dataSourceObj.value" (ngModelChange)="checkSignPrepared()" />

                        <nz-date-picker [(ngModel)]="item.contentDate" (ngModelChange)="onFieldDateChange(item)"
                            [style.width]="'100%'" nzPlaceHolder="设置日期"
                            [nzFormat]="item.signFieldPosition!.signFieldWidth! > 88 ? 'yyyy年M月d日' : 'yyyy.M.d'"
                            [nzSuffixIcon]="''" [nzAllowClear]="false"
                            *ngIf="item.signFieldType === WorkFlowSignFieldType.DATE_FIELD">
                        </nz-date-picker>

                        <img *ngIf="item.signFieldType === WorkFlowSignFieldType.SIGN_FIELD" [style.width]="'100%'"
                            [style.height]="'100%'" [src]="environment.fileHost + item.sealFieldImgFileId" alt="">

                        <img *ngIf="item.signFieldType === WorkFlowSignFieldType.SEAL_FIELD" [style.width]="'100%'"
                            [style.height]="'100%'" [src]="environment.fileHost + item.sealFieldImgFileId" alt="">

                        <span class="remove" (click)="removeDragable(page, itemIndex)" *ngIf="!!item.dragable"
                            [style.bottom]="-24 + 'px'" [style.right]="0">
                            X
                        </span>

                    </div>
                </div>
                <pdf-viewer
                    *ngIf="!currentWorkFlowInfo.signFiles[0]?.imageInfos && currentWorkFlowInfo.signFiles[0]?.fileId"
                    [src]="environment.fileHost + currentWorkFlowInfo.signFiles![0].fileId"
                    [c-maps-url]="'assets/cmaps'" [render-text]="true" [zoom-scale]="'page-fit'" [original-size]="false"
                    style="width: 100%; height: 100%" [zoom]="pdfZoom" [(page)]="pdfPages"
                    (pages-initialized)="onPageLoad($event)">
                </pdf-viewer>
            </div>
        </div>
    </div>

    <div class="right-panel" *ngIf="!hideRight && !workReport">
        <div class="grid flow">
            <div class="row sticky" [style.top]="0">
                <div class="column background-primary color-white" [style.width]="260 + 'px'">
                    执行流程
                </div>
                <div class="column background-primary color-white pointer" [style.width]="40 + 'px'"
                    (click)="hideRightChange(true)">
                    →
                </div>
            </div>
            <div class="row sticky" [style.top]="2.5 + 'rem'">
                <div class="column background-page" [style.width]="20 + 'px'">
                    顺序
                </div>
                <div class="column background-page" [style.width]="50 + 'px'">
                    动作
                </div>
                <div class="column background-page" [style.width]="210 + 'px'">
                    执行人
                </div>
                <div class="column background-page" [style.width]="20 + 'px'">
                    操作
                </div>
            </div>
            <div class="row {{item.hiddenTrigger ? 'border-bottom-black' : ''}}"
                *ngFor="let item of historyList | historyFilter; let hisIdx = index;">
                <div class="column color-light" [style.width]="20 + 'px'">
                    {{item.execNodeOrder}}
                </div>
                <div class="column spin color-light" *ngIf="!!item.execOrderSpanHeight && !item.hide"
                    [style.width]="20 + 'px'" [style.height]="(2.5 * item.execOrderSpanHeight) + 'rem'">
                    {{item.execNodeOrder}}
                </div>
                <div class="column status {{item.hiddenTrigger && item.status !== WorkFlowStatus.FINISH ? 'color-danger ' : 'color-light'}}"
                    [style.width]="50 + 'px'">
                    {{ item.type! !== FormFlowType.FINISH && item.type! !== FormFlowType.REJECT && item.type! !==
                    FormFlowType.CANCEL ? FormFlowTypeMap[item.type!] : ''}}
                    <br *ngIf="item.type && item.type! !== FormFlowType.FINISH && item.type! !== FormFlowType.REJECT && item.type! !==
                    FormFlowType.CANCEL" />
                    {{item.hiddenTrigger ? WorkFlowHistoryStatusMap[item.status!] : ''}}
                </div>
                <div class="column status" [style.flexDirection]="'column'" [style.width]="210 + 'px'"
                    [style.height]="item.comment ? (60 + 'px') : (40 + 'px')">
                    <div class="top-container" *ngIf="item.type! !== FormFlowType.FINISH">
                        <div class="name-container">
                            <div class="top-name">
                                <format-data class="hint" [contentStr]="item.nameLabel?item.nameLabel:''"
                                    [patternStr]="'E|90'">
                                </format-data>
                            </div>
                        </div>
                        <div class="top-time color-light">{{item.operateTime | date:'yyyy-MM-dd HH:mm'}}</div>
                    </div>
                    <div class="middle-container" *ngIf="item.type! !== FormFlowType.FINISH">
                        <format-data class="hint font-12" [contentStr]="item.executorPost?item.executorPost:''"
                            [patternStr]="'E|200'">
                        </format-data>
                    </div>
                    <div class="middle-container" *ngIf="item.comment && item.type! !== FormFlowType.FINISH">
                        <format-data class="hint color-danger" [contentStr]="item.comment" [patternStr]="'E|200'">
                        </format-data>
                    </div>

                    <div class="middle-container" *ngIf="item.type! === FormFlowType.FINISH">
                        <!-- <format-data class="hint color-danger" [contentStr]="item.operateTime | date:'yyyy-MM-dd HH:mm'" [patternStr]="'E|200'">
                        </format-data> -->
                        <span class="color-light">{{item.operateTime | date:'yyyy-MM-dd HH:mm'}}</span>
                    </div>
                </div>
                <div class="column text-center pointer" [style.width]="20 + 'px'">
                    <span *ngIf="!item.hiddenTrigger" nz-dropdown [nzDropdownMenu]="menuHis" nz-icon nzType="more"
                        nzTheme="outline"></span>
                    <span (click)="trig(item)" *ngIf="item.hiddenTrigger">{{!item.hide ? '↑' : '↓'}}</span>
                </div>
                <nz-dropdown-menu #menuHis="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item (click)="read(item.fileId!)">
                            阅读此节点PDF
                        </li>
                        <li nz-menu-item (click)="download(item.fileId!)">
                            下载此节点PDF
                        </li>
                        <!-- <li nz-menu-item (click)="rollBack(item)">
                            回退到此点
                        </li> -->
                    </ul>
                </nz-dropdown-menu>
            </div>
            <div class="row"
                *ngFor="let item of (currentWorkFlowInfo?.status === WorkFlowStatus.WAIT ? currentWorkFlowInfo?.formFlows : []); let flowIdx = index;">
                <div class="column {{!item.formEnable ||  !item.editAble && !item.canSign  ? 'color-light' : ''}} {{item.myTurn ? 'background-selected' : ''}}"
                    [style.width]="20 + 'px'">
                    {{item.execOrder}}
                </div>
                <div class="column spin {{!item.formEnable || !item.editAble && !item.canSign  ? 'color-light' : ''}} {{!item.myTurn ? 'background-selected' : ''}}"
                    *ngIf="!!item.execOrderSpanHeight" [style.width]="20 + 'px'"
                    [style.height]="(2.5 * item.execOrderSpanHeight) + 'rem'">
                    {{item.execOrder}}
                </div>
                <div class="column {{!item.formEnable || !item.editAble && !item.canSign ? 'color-light' : ''}} {{item.step === FormFlowType.SIGN && item.editAble && flowEditMode ? 'editable' : ''}}  {{item.myTurn ? 'background-selected' : ''}}"
                    [style.width]="50 + 'px'">
                    <span *ngIf="!item.editAble || !flowEditMode || item.step !== FormFlowType.SIGN">
                        {{item.step !== FormFlowType.SIGN ? FormFlowTypeMap[item.step!] :
                        WorkFlowActionTypeMap[item.actionType!]}}
                    </span>
                    <select *ngIf="item.step === FormFlowType.SIGN && item.editAble && flowEditMode"
                        [(ngModel)]="item.actionType" (ngModelChange)="actionTypeChange(flowIdx)"
                        [disabled]="!item.formEnable">
                        <option *ngFor="let item of WorkFlowActionTypeChangeableList" [value]="item.value">
                            {{item.label}}
                        </option>
                    </select>
                </div>
                <div class="column" [style.flexDirection]="'column'" [style.width]="210 + 'px'"
                    [style.height]="!item.userInfoList ? (40 + 'px') : (item.userInfoList.length * 40 + 'px')">
                    <user-select-popover
                        class="{{item.myTurn && myUserInfo.userId === userInfo.userId ? 'background-selected' : ''}}"
                        *ngFor="let userInfo of item.userInfoList; let userIdx = index;" [flow]="item"
                        [userInfo]="userInfo" [userList]="userList" [archiveTreeNode]="postTreeNodeFull"
                        [required]="!!item.musted && item.userInfoList!.length < 2"
                        [disabled]="!userInfo.editAble || !item.userEditAble"
                        [showTime]="item.step !== FormFlowType.CC && item.step !== FormFlowType.PRESENT"
                        [editable]="!!userInfo.editAble && !!flowEditMode && !!item.formEnable"
                        (userInfoChange)="userInfoChange($event, flowIdx, userIdx)">
                    </user-select-popover>
                </div>

                <div class="column text-center {{item.myTurn ? 'background-selected' : ''}}" [style.width]="20 + 'px'"
                    *ngIf="item.editAble && !flowEditMode"></div>
                <div class="column text-center" [style.flexDirection]="'column'" [style.width]="20 + 'px'"
                    *ngIf="!item.editAble && !flowEditMode">
                    <div class="cell pointer  {{item.myTurn && myUserInfo.userId === userInfo.userId ? 'background-selected' : ''}}"
                        *ngFor="let userInfo of item.userInfoList; let userIdx = index;">
                        <span *ngIf="!userInfo.editAble" nz-icon nzType="more" nzTheme="outline" nz-dropdown
                            [nzDropdownMenu]="menu1"></span>
                        <nz-dropdown-menu #menu1="nzDropdownMenu">
                            <ul nz-menu>
                                <li nz-menu-item (click)="read(userInfo.fileId!)">
                                    阅读此节点PDF
                                </li>
                                <li nz-menu-item (click)="download(userInfo.fileId!)">
                                    下载此节点PDF
                                </li>
                            </ul>
                        </nz-dropdown-menu>
                    </div>
                </div>
                <div class="column text-center pointer {{item.myTurn ? 'background-selected' : ''}}"
                    [style.width]="20 + 'px'" *ngIf="flowEditMode">
                    <span nz-icon nzType="more" nzTheme="outline" nz-dropdown [nzDropdownMenu]="menu0"
                        *ngIf="item.userEditAble"></span>
                    <nz-dropdown-menu #menu0="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-menu-item (click)="addPrCC(false, true, flowIdx)">
                                +抄送
                            </li>
                            <li nz-menu-item (click)="addPrCC(false, false, flowIdx)">
                                +呈递
                            </li>
                            <li nz-menu-item
                                [nzDisabled]="(item.step !== FormFlowType.CC && item.step !== FormFlowType.PRESENT) || (item.newAdded && item.auto) "
                                (click)="removeFlow(flowIdx)">
                                × 删除动作
                            </li>
                            <li nz-menu-item [nzDisabled]="true"
                                *ngIf="item.execOrder !== currentWorkFlowInfo!.formFlows![flowIdx + 1]?.execOrder"
                                (click)="merge(flowIdx)">
                                - 合并[步骤]
                            </li>
                            <li nz-menu-item [nzDisabled]="true"
                                *ngIf="item.execOrder === currentWorkFlowInfo!.formFlows![flowIdx + 1]?.execOrder"
                                (click)="split(flowIdx)">
                                = 拆分[步骤]
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                </div>
            </div>
        </div>

        <div class="footer"
            *ngIf="!flowEditMode && currentWorkFlowInfo && currentWorkFlowInfo?.status === WorkFlowStatus.WAIT && !isOp">


            <!-- 待签字就一种特殊情况 -->
            <div class="grid" [style.width]="'100%'"
                *ngIf="!currentWorkFlowInfo?.fileCountersign && currentWorkFlowInfo?.fileGroupCountersignStatus === FileCountersignStatus.STARTED && isDevOrg">
                <h1 class="hint">
                    文件会签期间<br> 主文件锁定
                    <br> 只操作会签单
                    <br>
                </h1>
            </div>

            <div class="grid"
                *ngIf="!(!currentWorkFlowInfo?.fileCountersign && currentWorkFlowInfo?.fileGroupCountersignStatus === FileCountersignStatus.STARTED && isDevOrg)">
                <div class="row">
                    <div class="column" [style.width]="310 + 'px'"></div>
                </div>
                <div class="row"
                    *ngIf="!currentWorkFlowInfo.operateEnable && !(isDevOrg && currentWorkFlowInfo.fileGroupCountersignStatus === FileCountersignStatus.WAIT)">
                    <div class="column pointer background-primary color-white" [style.width]="80 + 'px'"
                        (click)="pressSign()">
                        催签
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">

                    </div>
                </div>

                <div class="row" *ngIf="!!currentWorkFlowInfo.operateEnable">
                    <div class="column {{signing || !preparedIds.includes(currentWorkFlowInfo!.workFlowId!.toString()) ? 'disabled' : 'pointer'}} background-dark-danger color-white"
                        [style.width]="80 + 'px'" (click)="sign(false)">
                        {{ signing ? (FormFlowTypeMap[currentWorkFlowInfo.step!] + '完成中') : ("确认" +
                        FormFlowTypeMap[currentWorkFlowInfo.step!]) }}
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">

                    </div>
                </div>

                <div class="row"
                    *ngIf="batchCount > 1 && !!currentWorkFlowInfo.operateEnable && currentWorkFlowInfo.step === FormFlowType.SIGN">
                    <div class="column {{preparedSignIds.length === 0 || signing ? 'disabled' : 'pointer'}} background-dark-danger color-white"
                        [style.width]="80 + 'px'" (click)="sign(true)">
                        {{signing ? '签字完成中' : "批量签字" }}
                    </div>
                    <div class="column color-dark-danger text-left" [style.width]="230 + 'px'">
                        签字信息完备： {{preparedSignIds.length}} / {{batchCount}}
                    </div>
                </div>

                <!-- 建设单位 -->
                <div class="row"
                    *ngIf="currentWorkFlowInfo.fileGroupCountersignStatus === FileCountersignStatus.WAIT && isDevOrg">
                    <div class="column pointer background-primary color-white" [style.width]="80 + 'px'"
                        (click)="counterSignStart()">
                        发起会签
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">

                    </div>
                </div>
                <div class="row"
                    *ngIf="currentWorkFlowInfo.fileGroupCountersignStatus === FileCountersignStatus.WAIT && isDevOrg">
                    <div class="column pointer background-light" [style.width]="80 + 'px'"
                        (click)="counterSignCancel()">
                        无需会签
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">

                    </div>
                </div>

                <div class="row"
                    *ngIf="currentWorkFlowInfo.fileGroupCountersignStatus !== FileCountersignStatus.STARTED && !currentWorkFlowInfo.fileCountersign || currentWorkFlowInfo.fileCountersign">
                    <div class="column pointer background-light" [style.width]="80 + 'px'"
                        (click)="editModeChange(true)">
                        修改流程
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">
                        修改尚未执行的流程
                    </div>
                </div>

                <div class="row"
                    *ngIf="currentWorkFlowInfo.fileCountersign && currentWorkFlowInfo.operateEnable || !currentWorkFlowInfo.fileCountersign && !currentWorkFlowInfo.myFill && !!currentWorkFlowInfo.willMyTurn || currentWorkFlowInfo.fileGroupCountersignStatus === FileCountersignStatus.WAIT && isDevOrg">
                    <div class="column pointer background-light" [style.width]="80 + 'px'" (click)="reject()">
                        退回
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">

                    </div>
                </div>

                <div class="row" *ngIf="!!currentWorkFlowInfo.myFill && !currentWorkFlowInfo.fileCountersign">
                    <div class="column pointer  background-light" [style.width]="80 + 'px'" (click)="cancel()">
                        撤回
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">

                    </div>
                </div>
            </div>

        </div>

        <!-- 编辑流程模式 -->
        <div class="footer" *ngIf="flowEditMode && !isOp">
            <div class="grid">
                <div class="row">
                    <div class="column" [style.width]="310 + 'px'"></div>
                </div>
                <div class="row">
                    <div class="column pointer background-primary color-white" [style.width]="80 + 'px'"
                        (click)="submitFlow()">
                        提交流程
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">
                        提交修改后的流程
                    </div>
                </div>
                <div class="row">
                    <div class="column pointer background-primary color-white" [style.width]="80 + 'px'"
                        (click)="editModeChange(false)">
                        取消修改
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">
                        不修改流程
                    </div>
                </div>
            </div>
        </div>

        <!-- 签字撤回或退回 -->
        <div *ngIf="!isOp && !!currentWorkFlowInfo?.myFill && (currentWorkFlowInfo?.status === WorkFlowStatus.CANCLE || currentWorkFlowInfo?.status === WorkFlowStatus.REJECT)"
            class="footer">
            <div class="grid"
                *ngIf="!currentWorkFlowInfo?.fileCountersign || currentWorkFlowInfo?.fileCountersign && (currentWorkFlowInfo?.fileGroupCountersignStatus === FileCountersignStatus.WAIT || currentWorkFlowInfo?.fileGroupCountersignStatus === FileCountersignStatus.NOT_NEED)">
                <div class="row">
                    <div class="column" [style.width]="310 + 'px'"></div>
                </div>
                <div class="row">
                    <div class="column pointer background-primary color-white" [style.width]="80 + 'px'"
                        (click)="edit()">
                        修改
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">
                    </div>
                </div>
                <div class="row">
                    <div class="column pointer background-light" [style.width]="80 + 'px'" (click)="remove()">
                        删除
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">
                    </div>
                </div>
            </div>

            <div class="grid" [style.width]="'100%'"
                *ngIf="currentWorkFlowInfo?.fileCountersign && currentWorkFlowInfo?.fileGroupCountersignStatus !== FileCountersignStatus.WAIT && currentWorkFlowInfo?.fileGroupCountersignStatus !== FileCountersignStatus.NOT_NEED">
                <h1 class="hint">
                    主文件等待修改<br> 会签单不能操作
                </h1>
            </div>
        </div>

        <!-- 签字完成。会签单完成不显示操作栏 -->
        <div class="footer" *ngIf="!isOp && currentWorkFlowInfo?.status === WorkFlowStatus.FINISH">
            <div class="grid" *ngIf="currentWorkFlowInfo?.myFill && !currentWorkFlowInfo?.fileCountersign">
                <div class="row">
                    <div class="column" [style.width]="310 + 'px'"></div>
                </div>
                <div class="row">
                    <div class="column pointer  background-light" [style.width]="80 + 'px'" (click)="cancel()">
                        撤回
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">

                    </div>
                </div>
            </div>

            <div class="grid"
                *ngIf="!!currentWorkFlowInfo?.fileReceiveReplyOperateEnable && !!currentWorkFlowInfo?.fileReceive && currentWorkFlowInfo?.fileReceiveReplyStatus === 'WAIT'">
                <div class="row">
                    <div class="column" [style.width]="310 + 'px'"></div>
                </div>
                <div class="row">
                    <div class="column pointer  background-light" [style.width]="80 + 'px'" (click)="reply()">
                        回复
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">

                    </div>
                </div>
            </div>

            <div class="grid" [style.width]="'100%'" *ngIf="currentWorkFlowInfo?.fileCountersign">
                <h1 class="hint">
                    文件会签单完成
                </h1>
            </div>
        </div>



        <div *ngIf="isOp" class="footer">
            <div class="grid">
                <div class="row">
                    <div class="column" [style.width]="310 + 'px'"></div>
                </div>
                <div class="row">
                    <div class="column pointer background-light" [style.width]="80 + 'px'" (click)="remove(true)">
                        删除
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">
                    </div>
                </div>
            </div>
        </div>

    </div>

    <work-flow-fill *ngIf="workReport" [workReport]="workReport" [hideLeft]="hideLeft" [reportAttach]="reportAttach"
        (hideLeftChanged)="hideLeftChange($event)" (startFlow)="startFlow($event)" (closeFlow)="closeFlow($event)"
        (saveFlow)="saveFlow($event)" (cancelDraftFlow)="cancelDraftFlow()">
    </work-flow-fill>
</div>