<nz-layout class="app-layout">
	<nz-header>
		<div class="header-logo">
			<span nz-icon nzType="caret-left" nzTheme="fill" (click)="isCollapsed = !isCollapsed"></span>
			<img src="./assets/logo.svg" alt="logo" (click)="isCollapsed = !isCollapsed">
			<span class="font-reeji" (click)="homePage()">104国道杭州河庄至衙前段工程数智建管平台</span>
			<breadcrumb></breadcrumb>
		</div>
		<div *ngIf="!!userInfo">
			<button class="color-primary" nz-button nzType="text" (click)="home()">
				待办事项( <span class="color-danger">{{count}}</span> )
			</button>
			<span class="color-primary">{{userInfo.name}}</span>
			<span class="color-grey" *ngIf="postInfo">
				({{orgInfo.shortName ?? orgInfo.name}}
			</span>
			<span class="color-grey" *ngIf="postInfo">
				/ {{postInfo.departmentFullName}}
			</span>
			<span class="color-grey" *ngIf="postInfo">
				/ {{postInfo.name}})
			</span>
			<span class="mit">备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index"
					target="_blank">{{mit}}</a></span>
			<button class="logout" nz-button nzType="primary" (click)="logout()">退出登录</button>
		</div>
	</nz-header>
	<nz-layout>
		<nz-sider class="menu-sidebar" nzCollapsible nzWidth="12rem" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed"
			[nzTrigger]="null">
			<ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
				<li nz-submenu [nzOpen]='openIndex === index' [nzTitle]="menu.title" [nzIcon]="menu.icon ?? ''"
					*ngFor="let menu of fullMenuList; let index = index;" (nzOpenChange)="onOpen(index)">
					<ul class="submenu" *ngFor="let item of menu.children">
						<li nz-menu-item nzMatchRouter>
							<a [routerLink]="item.url" routerLinkActive="active" (click)="resetSearchConfig(item.url)">
								<span
									class="{{!!auditUrlMap[item.url] && !!auditUrlMap[item.url].readAble ? '' : 'color-grey'}}">
									&emsp;{{item.title}}
								</span>
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</nz-sider>
		<nz-content>
			<div class="inner-content">
				<router-outlet></router-outlet>
			</div>
		</nz-content>
	</nz-layout>
</nz-layout>