import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[mouseLeave]',
})
export class MouseLeaveDirective {
  @Output()
  onLeave: EventEmitter<void> = new EventEmitter();

  constructor() {}

  @HostListener('mouseleave') mouseleave(eventData: Event) {
    this.onLeave.emit();
  }
}
