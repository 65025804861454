import { OrganizationType } from './../models/staff/organization-type.enum';
import { Pipe, PipeTransform } from '@angular/core';
import { Post } from '../models/staff/post.model';

@Pipe({
  name: 'orgTypeFilter',
})
export class OrgTypeFilterPipe implements PipeTransform {
  transform(items: Post[], organizationType: OrganizationType): Post[] {
    return items.filter((item) => item.organizationType === organizationType);
  }
}
