import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { EventService, EventType } from './event.service';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private _snapshots: ActivatedRouteSnapshot[] = [];

  constructor(private eventService: EventService) {}

  set snapshots(snapshots: ActivatedRouteSnapshot[]) {
    this._snapshots = snapshots;

    this.eventService.broadcast(EventType.BreadcrumbEvent);
  }

  getBreadcrumbs() {
    const breadcrumbs: Breadcrumb[] = [];
    let fullPath = '';
    for (const snapshot of this._snapshots) {
      if (!snapshot.routeConfig?.data?.['title']) {
        continue;
      }
      fullPath += '/' + snapshot.url.join('/');
      // console.log(snapshot.data['title']);
      breadcrumbs.push({
        title: snapshot.routeConfig?.data?.['title'],
        url: fullPath,
        abstract: snapshot.routeConfig?.data?.['abstract'],
      });
    }

    return breadcrumbs;
  }
}

export type Breadcrumb = {
  title: string;
  url: string;
  abstract?: boolean;
};
