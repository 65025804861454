import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SignUrlInfo } from '../models/form/sign-url-info.model';
import { WorkFlowTaskInfo } from '../models/form/work-flow-task-info.model';
import { WorkFlowTask } from '../models/form/work-flow-task.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class WorkFlowTaskService extends RequestService<WorkFlowTask> {
  static PATH = '/work/flow/task';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, WorkFlowTaskService.PATH, globalService.debugUrl);
  }

  getSignUrl(workFlowId: number) {
    return this.httpClient.get<SignUrlInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowTaskService.PATH}/getSignUrlInfo/${workFlowId}`
    );
  }

  listWorkFlowTaskByWorkOrderId(workOrderId: number) {
    return this.httpClient.get<WorkFlowTaskInfo[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowTaskService.PATH}/listWorkFlowTaskByWorkOrderId/${workOrderId}`
    );
  }

  listWorkFlowTaskByCopyWorkOrderId(workOrderId: number) {
    return this.httpClient.get<WorkFlowTaskInfo[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${
        WorkFlowTaskService.PATH
      }/listWorkFlowTaskByCopyWorkOrderId/${workOrderId}`
    );
  }

  listWorkFlowTaskByWorkFlowId(workFlowId: number) {
    return this.httpClient.get<WorkFlowTaskInfo[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowTaskService.PATH}/listWorkFlowTaskByWorkFlowId/${workFlowId}`
    );
  }
}
