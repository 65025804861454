import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent implements OnInit {
  @Input()
  data!: AlertDialogConfig;

  type = AlertDialogType;
  m: any = undefined;
  d: Date = new Date();
  constructor() {}

  ngOnInit() {
    if (
      this.data.type === AlertDialogType.option &&
      this.data.options &&
      this.data.options.length > 0
    ) {
      this.m = this.data.options![0].value;
    }
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    if (
      this.data.type === AlertDialogType.option ||
      this.data.type === AlertDialogType.text
    ) {
      this.data.onConfirm!(this.m);
    } else if (
      this.data.type === AlertDialogType.date ||
      this.data.type === AlertDialogType.datetime
    ) {
      this.data.onConfirm!(this.d);
    } else {
      this.data.onConfirm!();
    }
  }
}

export class AlertDialogConfig {
  title!: string;
  content!: string;
  type: AlertDialogType = AlertDialogType.simple;
  hint?: '';
  options?: { value: string | number; name: string }[] = [];
  cancelBtnText!: string;
  onCancel!: () => void;
  confirmBtnText?: string;
  onConfirm?: (data?: any) => void;
  contentStyle?: '' | 'danger' | 'success' | 'warning' | 'primary';
}

export enum AlertDialogType {
  confirm,
  simple,
  prompt,
  wait,
  option,
  date,
  datetime,
  text,
}
