export enum WorkFlowSignFieldType {
  SIGN_FIELD = 'SIGN_FIELD',
  REMARK_FIELD = 'REMARK_FIELD',
  DATE_FIELD = 'DATE_FIELD',
  RADIO_FIELD = 'RADIO_FIELD',
  SEAL_FIELD = 'SEAL_FIELD',
}

export const WorkFlowSignFieldTypeMap = {
  [WorkFlowSignFieldType.SIGN_FIELD]: '签名',
  [WorkFlowSignFieldType.REMARK_FIELD]: '备注',
  [WorkFlowSignFieldType.DATE_FIELD]: '日期',
  [WorkFlowSignFieldType.RADIO_FIELD]: '单选',
  [WorkFlowSignFieldType.SEAL_FIELD]: '印章',
};

export const WorkFlowSignFieldTypeList = [
  {
    value: WorkFlowSignFieldType.SIGN_FIELD,
    label: WorkFlowSignFieldTypeMap[WorkFlowSignFieldType.SIGN_FIELD],
  },
  {
    value: WorkFlowSignFieldType.REMARK_FIELD,
    label: WorkFlowSignFieldTypeMap[WorkFlowSignFieldType.REMARK_FIELD],
  },
  {
    value: WorkFlowSignFieldType.DATE_FIELD,
    label: WorkFlowSignFieldTypeMap[WorkFlowSignFieldType.DATE_FIELD],
  },
  {
    value: WorkFlowSignFieldType.RADIO_FIELD,
    label: WorkFlowSignFieldTypeMap[WorkFlowSignFieldType.RADIO_FIELD],
  },
  {
    value: WorkFlowSignFieldType.SEAL_FIELD,
    label: WorkFlowSignFieldTypeMap[WorkFlowSignFieldType.SEAL_FIELD],
  },
];
