import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  setData(key: string, value: any): void {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  }

  getData(key: string): any {
    let value = localStorage.getItem(key);
    if (!value) {
      return value;
    }
    try {
      value = JSON.parse(value);
    } catch (e) {
    } finally {
      return value;
    }
  }

  clearData(key: string): void {
    localStorage.removeItem(key);
  }

  clearAll(): void {
    localStorage.clear();
  }
}
