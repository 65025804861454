import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organization } from '../models/staff/organization.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class StaffOrganizationService extends RequestService<Organization> {
  static PATH = '/staff/organization';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, StaffOrganizationService.PATH, globalService.debugUrl);
  }
}
