import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrganizationProject } from '../models/staff/organization-project.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class StaffOrganizationProjectService extends RequestService<OrganizationProject> {
  static PATH = '/staff/organization/project';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(
      httpClient,
      StaffOrganizationProjectService.PATH,
      globalService.debugUrl
    );
  }
}
