import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormAttachment } from '../models/form/form-attachment.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class OmFormItemAttachmentService extends RequestService<FormAttachment> {
  static PATH = '/om/form/item/attachment';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, OmFormItemAttachmentService.PATH, globalService.debugUrl);
  }
}
