<div class="preview-dialog" [style.minHeight]="height + 'px'" [style.minWidth]="width + 'px'">
	<div class="preview-dialog-header">
		<button class="color-white" nz-button nzType="text" *ngIf="!data.disabled" class="color-white"
			(click)="remove()">
			<span nz-icon nzType="delete" nzTheme="outline"></span> 删除文件
		</button>
		<div class="file-list">
			<div class="file-list-item entry" *ngFor="let file of data.fileList; let i = index"
				[class.active]="i === index">
				<div class="cover" (click)="changePreview(i)">
					<img *ngIf="fileTypes[i]"
						[src]="fileTypes[i] === FilePreviewType.Image ? environment.fileHost + data.fileList[i] : FilePreviewTypeListMap[fileTypes[i]].cover"
						alt="">
				</div>
			</div>
			<div class="file-list-item entry" *ngIf="data.fileList.length < data.limit && !data.disabled">
				<div class="cover">
					<img [src]="FilePreviewTypeListMap[data.previewType!].cover" alt="">
				</div>

				<div class="progress" *ngIf="progress.uploading" [style.width]="(100 - progress.progress) + '%'">
				</div>

				<div class="add" *ngIf="!progress.uploading">
					<span nz-icon nzType="file-add" nzTheme="outline"></span>
				</div>

				<input class="file" type="file" *ngIf="!progress.uploading" (change)="upload($event)"
					[multiple]="data.limit > 1">
			</div>


		</div>
		<div class="action">
			<button class="color-white" nz-button nzType="text" *ngIf="fileTypes[index] === FilePreviewType.PDF">
				<span nz-icon nzType="left-circle" nzTheme="outline" (click)="prePage()"></span>
			</button>
			<span *ngIf="fileTypes[index] === FilePreviewType.PDF">
				{{pdfPages}} / {{pdfTotal}}
			</span>
			<button class="color-white" nz-button nzType="text" *ngIf="fileTypes[index] === FilePreviewType.PDF">
				<span nz-icon nzType="right-circle" nzTheme="outline" (click)="nextPage()"></span>
			</button>
			<button class="color-white" nz-button nzType="text">
				<span nz-icon nzType="border-outer" nzTheme="outline" (click)="originSize()"></span>
			</button>
			<!-- <button class="color-white" *ngIf="data.screenType !==  ViewScreenType.HalfBottom">
				<i class="icon icon-select-vertical-layout" (click)="viewVertical()"></i>
			</button> -->

			<button class="color-white" nz-button nzType="text" (click)="zoomIn()">
				<span nz-icon nzType="zoom-in" nzTheme="outline"></span>
			</button>
			<button class="color-white" nz-button nzType="text" (click)="zoomOut()">
				<span nz-icon nzType="zoom-out" nzTheme="outline"></span>
			</button>
			<button class="color-white" nz-button nzType="text" (click)="download()">
				<span nz-icon nzType="vertical-align-bottom" nzTheme="outline"></span>
			</button>
			<button class="color-white" nz-button nzType="text" (click)="dismiss()">
				<span nz-icon nzType="close" nzTheme="outline"></span>
			</button>
		</div>
	</div>
	<div class="preview-dialog-body" [ngSwitch]="fileTypes[index]">
		<img class="preview" *ngSwitchCase="FilePreviewType.Image" [src]="environment.fileHost + data.fileList[index]"
			alt="" style="">
		<!-- <iframe *ngSwitchCase="FilePreviewType.PDF" [src]="data.fileList[index] | safeResourceUrl">
		</iframe> -->


		<pdf-viewer *ngSwitchCase="FilePreviewType.PDF" [src]="environment.fileHost + data.fileList[index]"
			[c-maps-url]="'assets/cmaps'" [render-text]="true" [zoom-scale]="'page-fit'" [original-size]="false"
			style="width: 100%; height: 100%" [zoom]="pdfZoom" [(page)]="pdfPages"
			(pages-initialized)="onPageLoad($event)">
		</pdf-viewer>

		<p *ngSwitchDefault>
			暂时无法预览这个格式的文件
		</p>

	</div>
</div>