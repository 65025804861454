import { BreadcrumbService } from './../../providers/breadcrumb.service';
import {
  EventService,
  EventType,
} from 'src/app/modules/shared/providers/event.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Breadcrumb } from '../../providers/breadcrumb.service';
import { Router } from '@angular/router';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  breadcrumbs: Breadcrumb[] = [];

  subsciption = () => this.onChange();
  constructor(
    private events: EventService,
    private breadcrumbService: BreadcrumbService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.events.on(EventType.BreadcrumbEvent, this.subsciption);
    this.onChange();
  }

  ngOnDestroy(): void {
    this.events.destroyListener(EventType.BreadcrumbEvent, this.subsciption);
  }

  onChange() {
    this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
  }

  route(breadcrumb: Breadcrumb) {
    if (breadcrumb.abstract) {
      return;
    }
    this.router.navigateByUrl(breadcrumb.url);
  }
}
