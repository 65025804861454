import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { GlobalService } from '../global.service';
import { Response } from './respponse.type';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private globalService: GlobalService,
    private message: NzMessageService
  ) {}

  intercept<T>(
    request: HttpRequest<Response<T>>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    let authReq = this.globalService.isLogin
      ? request.clone({
          setHeaders: {
            token: this.globalService.token,
          },
        })
      : request;

    return next.handle(authReq).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          let response = event.body as Response<T>;
          if (!response.success) {
            this.handleBusinessError(response);
          }
          return event.clone({ body: response.data ? response.data : null });
        }
        return event;
      }),
      catchError((err) => this.handleNetworkError(err))
    );
  }

  private handleBusinessError(response: Response<any>) {
    // this.toastService.open({
    //   value: [
    //     {
    //       severity: 'error',
    //       summary: '请求失败',
    //       content: response.message,
    //     },
    //   ],
    //   life: 3000,
    // });

    this.message.error('请求失败:' + response.message);
    if (response.message === 'token无效') {
      this.globalService.logout();
    }
  }

  private handleNetworkError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // this.toastService.open({
      //   value: [
      //     {
      //       severity: 'error',
      //       summary: '网络错误',
      //       content: '无法和服务器连接，请检查网络是否正常',
      //     },
      //   ],
      //   life: 3000,
      // });

      this.message.error('网络错误:无法和服务器连接，请检查网络是否正常');
    } else {
      // this.toastService.open({
      //   value: [
      //     {
      //       severity: 'error',
      //       summary: `${error.status}`,
      //       content: error.error,
      //     },
      //   ],
      //   life: 3000,
      // });
      this.message.error(`${error.status}:${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }
}
