import { WorkReportAttach } from './work-report-attach.model';
import { WorkReportBase } from './work-report-base.model';
import { WorkReportDocType } from './work-report-doc-type.enum';
import { WorkReportForm } from './work-report-form.model';
export class WorkReport extends WorkReportBase {
  workFlowId?: number;
  copyWorkReportId?: number;
  form?: WorkReportForm;
  attachs?: Array<WorkReportAttach>;
  docType?: WorkReportDocType;
  docId?: string;
  docPdfId?: string;
  docName?: string;
  thirdBusinessIds?: string;
  comment?: string;
  autoCreate?: boolean;
  clearInstructionReplyId?: boolean;
  clearFileCountersignId?: boolean;
  // 前端用
  isInit?: boolean;
}
