import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UnqualifiedReport } from '../models/reports/unqualified-report.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class InspectionUniqualifiedService extends RequestService<UnqualifiedReport> {
  static PATH = '/inspection/uniqualified';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(
      httpClient,
      InspectionUniqualifiedService.PATH,
      globalService.debugUrl
    );
  }
}
