import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FormPreviewComponent } from '../shared/components/form-preview/form-preview.component';
import { AuthGuard } from '../shared/providers/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { MainComponent } from './pages/main/main.component';
import { CustomSignComponent } from '../shared/components/custom-sign/custom-sign.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { CustomMenuFormDetailComponent } from '../main/pages/custom-menu-form/custom-menu-form-detail/custom-menu-form-detail.component';
import { CustomMenuFormPreviewComponent } from '../main/pages/custom-menu-form/custom-menu-form-preview/custom-menu-form-preview.component';
import { CustomMenuFormSignComponent } from '../main/pages/custom-menu-form/custom-menu-form-sign/custom-menu-form-sign.component';

const customMenuForm: Routes = [
  {
    path: 'add/:id',
    data: {
      title: '报表添加',
    },
    component: CustomMenuFormDetailComponent,
  },
  {
    path: 'detail/:id',
    data: {
      title: '报表填写',
    },
    component: CustomMenuFormDetailComponent,
  },
  {
    path: 'copy/:id',
    data: {
      title: '报表修正',
    },
    component: CustomMenuFormDetailComponent,
  },
  {
    path: 'preview/:id',
    data: {
      title: '报表预览',
    },
    component: CustomMenuFormPreviewComponent,
  },
  {
    path: 'sign/:id',
    data: {
      title: '报表签字',
    },
    component: CustomMenuFormSignComponent,
  },
  {
    path: 'admin/:id',
    data: {
      title: '报表签字',
    },
    component: CustomMenuFormSignComponent,
  },
];

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    loadChildren: () => import('../main/main.module').then((m) => m.MainModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'signin',
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'preview/:id',
    component: FormPreviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sign',
    component: CustomSignComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: 'custom-form',
    children: customMenuForm,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
