import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[detectionBiasVal]',
})
export class DetectionBiasValDirective {
  format?: string;

  charset1 = '0123456789 .±';

  charset2 = '0123456789 .,，≥≤';

  charset3 = '0123456789 .,，+-';

  constructor(private el: ElementRef, public ngModel: NgModel) {}

  @HostListener('blur') onblur() {
    let value = this.el.nativeElement.value;
    let charset = this.charset1;
    if (value.indexOf('±') > -1) {
      charset = this.charset1;
    } else if (value.indexOf('≥') > -1 || value.indexOf('≤') > -1) {
      charset = this.charset2;
    } else if (value.indexOf('+') > -1 || value.indexOf('-') > -1) {
      charset = this.charset3;
    }
    let realValue = '';
    for (let i = 0; i < value.length; i++) {
      if (charset.indexOf(value[i]) === -1) {
        continue;
      } else {
        realValue += value[i];
      }
    }
    this.el.nativeElement.value = realValue;
    this.ngModel.control.setValue(realValue);
  }

  @HostListener('input') onInputEvent() {
    let value = this.el.nativeElement.value;
    let charset = this.charset1;
    if (value.indexOf('±') > -1) {
      charset = this.charset1;
    } else if (value.indexOf('≥') > -1 || value.indexOf('≤') > -1) {
      charset = this.charset2;
    } else if (value.indexOf('+') > -1 || value.indexOf('-') > -1) {
      charset = this.charset3;
    }
    let realValue = '';
    for (let i = 0; i < value.length; i++) {
      if (charset.indexOf(value[i]) === -1) {
        continue;
      } else {
        realValue += value[i];
      }
    }
    this.el.nativeElement.value = realValue;
    this.ngModel.control.setValue(realValue);
  }
}
