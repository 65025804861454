import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import zh from '@angular/common/locales/zh';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, zh_CN } from 'ng-zorro-antd/i18n';
import { IconsProviderModule } from '../../icons-provider.module';
import { WorkFlowFillComponent } from '../main/components/custom-form/work-flow-fill/work-flow-fill.component';
import { CustomMenuFormDetailComponent } from '../main/pages/custom-menu-form/custom-menu-form-detail/custom-menu-form-detail.component';
import { CustomMenuFormPreviewComponent } from '../main/pages/custom-menu-form/custom-menu-form-preview/custom-menu-form-preview.component';
import { CustomMenuFormSignComponent } from '../main/pages/custom-menu-form/custom-menu-form-sign/custom-menu-form-sign.component';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './pages/app/app.component';
import { LoginComponent } from './pages/login/login.component';
import { MainComponent } from './pages/main/main.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    NotFoundComponent,
    CustomMenuFormDetailComponent,
    CustomMenuFormPreviewComponent,
    CustomMenuFormSignComponent,
    WorkFlowFillComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    IconsProviderModule,
  ],
  providers: [{ provide: NZ_I18N, useValue: zh_CN }],
  bootstrap: [AppComponent],
})
export class AppModule {}
