import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProjectSectionUnitPlanBatchUpdateRequest } from '../models/project/project-section-unit-plan-batch-update-request.model';
import { ProjectSectionUnitProgress } from '../models/project/project-section-unit-progress.model';
import { GlobalService } from '../providers/global.service';
import { FileResponse } from '../providers/request/file-response.model';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectPlanService extends RequestService<ProjectSectionUnitProgress> {
  static PATH = '/project/plan';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, ProjectPlanService.PATH, globalService.debugUrl);
  }

  uploadFileWithoutProgress(file: File, projectId: number, sectionId: number) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('projectId', projectId.toString());
    formData.append('sectionId', sectionId.toString());
    return this.httpClient.post<FileResponse>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${ProjectPlanService.PATH}/upload`,
      formData
    );
  }

  getFxNames(data: ProjectSectionUnitPlanBatchUpdateRequest) {
    return this.httpClient.post<string[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${ProjectPlanService.PATH}/getFxNames`,
      data
    );
  }

  getFxSummary(data: ProjectSectionUnitPlanBatchUpdateRequest) {
    return this.httpClient.post<{ fxCount: number; fxSectionCount: number }>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${ProjectPlanService.PATH}/getFxSummary`,
      data
    );
  }

  batchUpdate(data: ProjectSectionUnitPlanBatchUpdateRequest) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${ProjectPlanService.PATH}/batchUpdate`,
      data
    );
  }
}
