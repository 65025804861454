export enum FormPagesType {
  SINGLE = 'SINGLE',
  DOUBLE = 'DOUBLE',
  SCALABLE = 'SCALABLE',
  COPYABLE = 'COPYABLE',
}

export const FormPagesTypeMap = {
  [FormPagesType.SINGLE]: '单页',
  [FormPagesType.DOUBLE]: '双页',
  [FormPagesType.SCALABLE]: '可扩',
  [FormPagesType.COPYABLE]: '可拷',
};

export const FormPagesTypeList = [
  {
    value: FormPagesType.SINGLE,
    label: FormPagesTypeMap[FormPagesType.SINGLE],
  },
  {
    value: FormPagesType.DOUBLE,
    label: FormPagesTypeMap[FormPagesType.DOUBLE],
  },
  {
    value: FormPagesType.SCALABLE,
    label: FormPagesTypeMap[FormPagesType.SCALABLE],
  },
  {
    value: FormPagesType.COPYABLE,
    label: FormPagesTypeMap[FormPagesType.COPYABLE],
  },
];
