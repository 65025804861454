import { OperateType } from './operate-type.enum';
export class RecordBase {
  id?: number;
  operateType?: OperateType;
  operator?: string;
  operateLog?: string;
  updateTime?: number | Date;
  createTime?: number | Date;
  modifiedTime?: number | Date;
  deleted?: boolean;
}
