import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Menu } from '../models/menu/menu.model';
import { GlobalService } from '../providers/global.service';
import { FileResponse } from '../providers/request/file-response.model';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class OmMenuService extends RequestService<Menu> {
  static PATH = '/om/menu';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, OmMenuService.PATH, globalService.debugUrl);
  }

  getConfigMenus() {
    return this.httpClient.get<Menu[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmMenuService.PATH}/getConfigMenus`
    );
  }

  getUserMenus() {
    return this.httpClient.get<Menu[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmMenuService.PATH}/getUserMenus`
    );
  }

  uploadFileWithoutProgress(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<FileResponse>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmMenuService.PATH}/upload`,
      formData
    );
  }
}
