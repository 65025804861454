import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'ng-devui';
import { ExcelTableRowColumn } from 'src/app/modules/shared/models/form/excel-table-row-column.model';
import { FormAttachmentSearchRequest } from 'src/app/modules/shared/models/form/form-attachment-search-request.model';
import { FormAttachment } from 'src/app/modules/shared/models/form/form-attachment.model';
import { FormFlowSearchRequest } from 'src/app/modules/shared/models/form/form-flow-search-request.model';

import { FormFlow } from 'src/app/modules/shared/models/form/form-flow.model';
import { FormItemSearchRequest } from 'src/app/modules/shared/models/form/form-item-search-request.model';
import { FormItemType } from 'src/app/modules/shared/models/form/form-item-type.enum';
import { FormItemWidgetTypeList } from 'src/app/modules/shared/models/form/form-item-widget-type.enum';
import { FormItem } from 'src/app/modules/shared/models/form/form-item.model';
import { Form } from 'src/app/modules/shared/models/form/form.model';
import { Department } from 'src/app/modules/shared/models/staff/department.model';
import {
  OrganizationType,
  OrganizationTypeMap,
} from 'src/app/modules/shared/models/staff/organization-type.enum';
import { Post } from 'src/app/modules/shared/models/staff/post.model';
import { NzTreeNode } from 'src/app/modules/shared/models/tree-node.model';
import { OrderType } from 'src/app/modules/shared/providers/request/order-type.enum';
import { Page } from 'src/app/modules/shared/providers/request/page.type';
import { OmFormItemAttachmentService } from 'src/app/modules/shared/services/om-form-item-attachment.service';
import { OmFormItemFlowService } from 'src/app/modules/shared/services/om-form-item-flow.service';
import { OmFormItemService } from 'src/app/modules/shared/services/om-form-item.service';
import { OmFormService } from 'src/app/modules/shared/services/om-form.service';
import { StaffDepartmentService } from 'src/app/modules/shared/services/staff-department.service';
import { StaffPostService } from 'src/app/modules/shared/services/staff-post.service';
import {
  FormFlowType,
  FormFlowTypeMap,
} from '../../models/form/form-flow-type.enum';

@Component({
  selector: 'app-form-preview',
  templateUrl: './form-preview.component.html',
  styleUrls: ['./form-preview.component.scss'],
})
export class FormPreviewComponent {
  @ViewChild('scrollVar') scrollVar?: ElementRef<any>;
  @ViewChild('scrollConst') scrollConst?: ElementRef<any>;
  selectedTabIndex = 0;
  selectedFormItemId?: number;
  selectedFormItem!: FormItem;

  // config?: ExcelTable;

  form?: Form;

  // jsonText: string = '';

  OrderType = OrderType;

  FormItemWidgetTypeList = FormItemWidgetTypeList;

  varSearchRequest = new FormItemSearchRequest();

  constSearchRequest = new FormItemSearchRequest();

  varList: FormItem[] = [];

  constList: FormItem[] = [];

  flowList: FormFlow[] = [];

  attachmentList: FormAttachment[] = [];

  FormFlowTypeMap = FormFlowTypeMap;

  FormFlowType = FormFlowType;

  postTreeNode: NzTreeNode[] = [];

  postMap: { [key: string]: Post } = {};

  constructor(
    private modalService: ModalService,
    private omFormService: OmFormService,
    private omFormItemService: OmFormItemService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private omFormItemFlowService: OmFormItemFlowService,
    private omFormItemAttachmentService: OmFormItemAttachmentService,
    private staffDepartmentService: StaffDepartmentService,
    private staffPostService: StaffPostService
  ) {}

  ngOnInit(): void {
    this.getForm();
  }

  getForm() {
    this.omFormService
      .findById(this.activatedRoute.snapshot.params['id'])
      .subscribe((res: Form) => {
        if (res) {
          this.form = res;
          // this.config = res.data;
          // this.jsonText = JSON.stringify(this.config);
          this.prepareConfig();
          this.onVarSearch();
          this.searchConst();
          this.getAllFlows();
          this.getAllAttachment();
          this.getOrganizationDepartment();
        }
      });
  }

  private getOrganizationDepartment(): void {
    this.staffDepartmentService
      .findAll({})
      .subscribe((departments: Department[]) => {
        console.log(departments);
        const departmentMap: { [key: string]: Department } = {};
        const orgTypeMap: { [key: string]: Department[] } = {};
        for (const department of departments) {
          departmentMap[department.id!] = department;
          if (!orgTypeMap[department.organizationType!]) {
            orgTypeMap[department.organizationType!] = [];
          }
          orgTypeMap[department.organizationType!].push(department);
          department.postList = [];
        }
        this.staffPostService.findAll({}).subscribe((posts: Post[]) => {
          for (const post of posts) {
            // TODO: 考虑不设上级部门的情况
            const department = departmentMap[post.departmentId!];
            post.organizationType = department.organizationType;
            department.postList!.push(post);
            this.postMap[post.id!] = post;
          }

          const trreNodes: NzTreeNode[] = [];
          for (const type of Object.keys(orgTypeMap)) {
            const tNode: NzTreeNode = {
              value: type,
              label: OrganizationTypeMap[type as OrganizationType],
              children: [],
            };
            for (const department of orgTypeMap[type as OrganizationType]) {
              const dNode: NzTreeNode = {
                value: department.id,
                label: department.fullName,
                children: [],
              };
              for (const post of department.postList ?? []) {
                const pNode: NzTreeNode = {
                  value: post.id,
                  label: post.name,
                  isLeaf: true,
                };
                dNode.children!.push(pNode);
              }
              tNode.children!.push(dNode);
            }

            trreNodes.push(tNode);
          }
          this.postTreeNode = trreNodes;
        });
      });
  }

  getAllFlows() {
    this.omFormItemFlowService
      .findAll<FormFlowSearchRequest>({
        formId: this.form!.id,
      })
      .subscribe((res: Array<FormFlow>) => {
        console.log(res);
        this.flowList = res ?? [];
        for (const item of this.flowList) {
          if (!!item.organizationType && !!item.departmentId && !!item.postId) {
            item.postCascaderValue = [
              item.organizationType,
              item.departmentId,
              item.postId,
            ];
          }
        }
      });
  }

  getAllAttachment() {
    this.omFormItemAttachmentService
      .findAll<FormAttachmentSearchRequest>({
        formId: this.form!.id,
      })
      .subscribe((res: Array<FormAttachment>) => {
        console.log(res);
        this.attachmentList = res ?? [];
      });
  }

  prepareConfig() {
    this.varSearchRequest.formId = this.form!.id;
    this.varSearchRequest.rowColOrder = OrderType.ASC;
    this.varSearchRequest.colRowOrder = OrderType.ASC;
    this.varSearchRequest.type = FormItemType.VARIABLES;

    this.constSearchRequest.formId = this.form!.id;
    this.constSearchRequest.rowColOrder = OrderType.ASC;
    this.constSearchRequest.colRowOrder = OrderType.ASC;
    this.constSearchRequest.type = FormItemType.CONSTANT;
  }

  varOrderChange(key: string) {
    if (key === 'rowColOrder') {
      this.varSearchRequest.rowColOrder =
        this.varSearchRequest.rowColOrder === OrderType.ASC
          ? OrderType.DESC
          : OrderType.ASC;
    }
    if (key === 'colRowOrder') {
      this.varSearchRequest.colRowOrder =
        this.varSearchRequest.colRowOrder === OrderType.ASC
          ? OrderType.DESC
          : OrderType.ASC;
    }
    this.searchVar();
  }

  onVarSearch() {
    if (this.varSearchRequest.widget?.toString() === 'undefined') {
      this.varSearchRequest.widget = undefined;
    }
    if (
      this.varSearchRequest.musted?.toString() === 'undefined' ||
      this.varSearchRequest.musted === undefined
    ) {
      this.varSearchRequest.musted = undefined;
    } else {
      this.varSearchRequest.musted =
        this.varSearchRequest.musted?.toString() === 'true';
    }
    this.searchVar();
  }

  searchVar() {
    this.omFormItemService
      .search(this.varSearchRequest, 0, 10000)
      .subscribe((res: Page<FormItem>) => {
        this.varList = res?.content ?? [];
      });
  }

  constOrderChange(key: string) {
    if (key === 'rowColOrder') {
      this.constSearchRequest.rowColOrder =
        this.constSearchRequest.rowColOrder === OrderType.ASC
          ? OrderType.DESC
          : OrderType.ASC;
    }
    if (key === 'colRowOrder') {
      this.constSearchRequest.colRowOrder =
        this.constSearchRequest.colRowOrder === OrderType.ASC
          ? OrderType.DESC
          : OrderType.ASC;
    }
    this.searchConst();
  }

  searchConst() {
    this.omFormItemService
      .search(this.constSearchRequest, 0, 10000)
      .subscribe((res: Page<FormItem>) => {
        this.constList = res?.content ?? [];
      });
  }

  // onItemUpdate(item: FormItem) {
  //   this.omFormItemService.update(item).subscribe((res) => {
  //     if (res) {
  //       const config = JSON.parse(this.jsonText);
  //       for (const row of config?.rows ?? []) {
  //         for (const col of row.columns ?? []) {
  //           if (col.formItem?.id === item.id) {
  //             col.formItem = item;
  //           }
  //         }
  //       }
  //       this.jsonText = JSON.stringify(config);
  //       this.config = config;
  //       if (
  //         item.widget === FormItemWidgetType.SIGN ||
  //         item.widget === FormItemWidgetType.SEAL
  //       ) {
  //         this.getAllFlows();
  //       }
  //     }
  //   });
  // }

  onCellSelected(cell: ExcelTableRowColumn) {
    const item = cell.formItem;
    this.selectedFormItemId = item?.id;
    if (item?.type === FormItemType.CONSTANT) {
      this.selectedTabIndex = 3;
      for (let i = 0; i < this.constList.length; i++) {
        if (this.constList[i].id === item.id) {
          setTimeout(() => {
            if (this.scrollConst) {
              this.scrollConst.nativeElement.parentElement.scrollTo({
                top: i * 40,
                behavior: 'smooth',
              });
            }
          }, 50);
        }
      }
    }
    if (item?.type === FormItemType.VARIABLES) {
      this.selectedTabIndex = 0;
      for (let i = 0; i < this.varList.length; i++) {
        if (this.varList[i].id === item.id) {
          setTimeout(() => {
            if (this.scrollVar) {
              this.scrollVar.nativeElement.parentElement.scrollTo({
                top: i * 40,
                behavior: 'smooth',
              });
            }
          }, 50);
        }
      }
    }
  }

  // onPostCascaderValueChange(flow: FormFlow) {
  //   if (flow.postCascaderValue?.length === 3) {
  //     flow.organizationType = flow.postCascaderValue[0];
  //     flow.departmentId = flow.postCascaderValue[1];
  //     flow.postId = flow.postCascaderValue[2];
  //   }
  //   this.omFormItemFlowService.update(flow).subscribe((res) => {
  //     if (!res) {
  //       this.getAllFlows();
  //     }
  //   });
  // }

  selectItem(item: FormItem) {
    this.selectedFormItem = item;
    this.selectedFormItemId = item?.id;
  }

  // upload(event: any) {
  //   const files = event.target['files'];
  //   console.log(files);
  //   this.omFormService
  //     .uploadFileWithoutProgress(files[0], this.form!.id)
  //     .subscribe(() => {
  //       event.target['value'] = '';
  //       this.getForm();
  //     });
  // }

  // onFormChange() {
  //   this.omFormService.update(this.form!).subscribe((res: boolean) => {
  //     if (!res) {
  //       this.getForm();
  //     }
  //   });
  // }

  // flowMoveUp(flow: FormFlow) {
  //   this.omFormItemFlowService.moveUp(flow.id).subscribe((res: boolean) => {
  //     if (res) {
  //       this.getAllFlows();
  //     }
  //   });
  // }

  // flowMoveDown(flow: FormFlow) {
  //   this.omFormItemFlowService.moveDown(flow.id).subscribe((res: boolean) => {
  //     if (res) {
  //       this.getAllFlows();
  //     }
  //   });
  // }

  // addCC(flow: FormFlow) {
  //   this.omFormItemFlowService.cc(flow.id).subscribe((res: boolean) => {
  //     if (res) {
  //       this.getAllFlows();
  //     }
  //   });
  // }

  // flowRemove(flow: FormFlow) {
  //   this.omFormItemFlowService.remove(flow.id!).subscribe((res: number) => {
  //     if (res) {
  //       this.getAllFlows();
  //     }
  //   });
  // }

  // removeAttachment(item: FormAttachment) {
  //   const results = this.modalService.open({
  //     backdropCloseable: false,
  //     component: AlertDialogComponent,
  //     onClose: () => {},
  //     data: {
  //       title: '删除',
  //       content: '确认删除？',
  //       cancelBtnText: '取消',
  //       confirmBtnText: '确认',
  //       contentStyle: 'danger',
  //       type: AlertDialogType.confirm,
  //       onCancel: () => {
  //         results.modalInstance.hide();
  //       },
  //       onConfirm: () => {
  //         this.omFormItemAttachmentService.remove(item.id!).subscribe((res) => {
  //           if (res) {
  //             this.getAllAttachment();
  //           }
  //         });
  //         results.modalInstance.hide();
  //       },
  //     },
  //   });
  // }

  // addAttachment() {
  //   this.omFormItemAttachmentService
  //     .create({ formId: this.form!.id! })
  //     .subscribe((res: FormAttachment) => {
  //       if (res) {
  //         this.getAllAttachment();
  //       }
  //     });
  // }

  // onAttachmentUpdate(item: FormAttachment) {
  //   this.omFormItemAttachmentService.update(item).subscribe((res) => {
  //     if (res) {
  //       this.getAllAttachment();
  //     }
  //   });
  // }
}
