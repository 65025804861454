<div class="dialog">
	<div class="dialog-header">
		退回会签单
	</div>
	<div class="dialog-body">
		<div class="grid">
			<div class="row validate">
				<div class="column background-page text-right" [style.width]="90 + 'px'">
					<span class="color-danger">*</span> 退回理由
				</div>
				<div class="column" [style.width]="410 + 'px'">
					<input type="text" placeholder="输入退回理由" [(ngModel)]="record.rejectReason">
				</div>
			</div>
		</div>
		<br>
		<div class="grid">
			<div class="row">
				<div class="column background-page" [style.width]="500 + 'px'">
					指定要同时退回的主文件
				</div>
			</div>
			<div class="row" *ngFor="let item of data.data?.options ?? []" (click)="choose(item.value!)">
				<div class="column" [style.width]="40 + 'px'">
					<span class="color-primary" *ngIf="selected.includes(item.value!)" nz-icon nzType="check-square"
						nzTheme="outline">
					</span>
					<span class="color-primary" *ngIf="!selected.includes(item.value!)" nz-icon nzType="border"
						nzTheme="outline">
					</span>
					<!-- {{selected.includes(item.value!) ? '☑' : '□'}} -->
				</div>
				<div class="column {{selected.includes(item.value!) ? 'color-primary' : '' }}"
					[style.width]="460 + 'px'">
					<format-data [contentStr]="item.label"></format-data>
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-footer">
		<button nz-button nzType="default" (click)="cancel()">取消</button>
		<button nz-button nzType="primary" (click)="confirm()" [disabled]="!canConfirm">确认</button>
	</div>
</div>