export enum FormHeaderFormat {
  TEXT = 'TEXT',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  MONEY = 'MONEY',
}

export const FormHeaderFormatMap = {
  [FormHeaderFormat.TEXT]: '文本',
  [FormHeaderFormat.DATE]: '日期',
  [FormHeaderFormat.NUMBER]: '数值',
  [FormHeaderFormat.MONEY]: '金额',
};

export const FormHeaderFormatList = [
  {
    value: FormHeaderFormat.TEXT,
    label: FormHeaderFormatMap[FormHeaderFormat.TEXT],
  },
  {
    value: FormHeaderFormat.DATE,
    label: FormHeaderFormatMap[FormHeaderFormat.DATE],
  },
  {
    value: FormHeaderFormat.NUMBER,
    label: FormHeaderFormatMap[FormHeaderFormat.NUMBER],
  },
  {
    value: FormHeaderFormat.MONEY,
    label: FormHeaderFormatMap[FormHeaderFormat.MONEY],
  },
];
