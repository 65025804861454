import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import * as moment from 'moment';
import { GlobalService } from '../../providers/global.service';
import { OrderType } from '../../providers/request/order-type.enum';
import { FilePreviewType } from '../file-preview-entry/file-preview-entry.component';
import {
  TableColumnType,
  TableColumnSearchConfig,
  TableConfig,
} from '../table-pro/table.config';

@Component({
  selector: 'grid-pro',
  templateUrl: './grid-pro.component.html',
  styleUrls: ['./grid-pro.component.scss'],
})
export class GridProComponent {
  title = '';
  checkWidth = 40;
  indexWidth = 56;
  hasGroup = false;
  @Input() stickyTop: number = 0;

  @Input()
  set config(value: TableConfig) {
    this._config = value;
    if (value) {
      this.preapreConfig();
    }
  }

  get config() {
    return this._config;
  }
  _config!: TableConfig;

  @Input()
  set tableData(value: any[]) {
    this._tableData = value;
    if (value) {
      this.afterDataLoad();
      this.detectChangesWithTimeout();
    }
  }

  get tableData() {
    return this._tableData;
  }

  _tableData!: any[];

  @Input() currentPage?: number;
  @Input() currentPageSize?: number;
  @Output()
  calcResultChange = new EventEmitter<string>();

  @Output()
  queryPanelStateChange = new EventEmitter<boolean>();
  @Output()
  searchClick = new EventEmitter<any>();
  @Output()
  resetClick = new EventEmitter<any>();
  @Output()
  checkChange = new EventEmitter<boolean[]>();
  @Output()
  checkAllChange = new EventEmitter<boolean>();
  @Output()
  afterEdit = new EventEmitter<{ row: number; key: string }>();

  columnType = TableColumnType;

  columnSortType = OrderType;
  Array = Array;
  FilePreviewType = FilePreviewType;
  treeModelCache: any = {};

  /**
   * 是否全选
   * @memberof AdminComponent
   */
  @Input()
  set isCheckAll(value: boolean) {
    this._isCheckAll = value;
    this.onCheckAllChange();
  }

  get isCheckAll() {
    return this._isCheckAll;
  }

  _isCheckAll: boolean = false;

  /**
   * 是否打开搜索栏
   * @memberof AdminComponent
   */
  isQueryOpen = true;

  /**
   * 搜索条的行数
   * @memberof AdminComponent
   */
  queryRow = 0;

  /**
   * 每一列数据的开始位置 px
   * @memberof AdminComponent
   */
  culumnPositionsStart: number[] = [];

  /**
   * 每一列数据的结束位置 px
   * @memberof AdminComponent
   */
  culumnPositionsEnd: number[] = [];

  /**
   * 搜索按钮所在的列位置 index
   * @memberof AdminComponent
   */
  queryButtonIndex = -1;

  /**
   * 表格总宽度
   * @memberof AdminComponent
   */
  totalWidth = 0;

  firstTitleRow: {
    colspan: number;
    title: string;
    isGroup?: boolean;
    index: number;
    sortable?: boolean;
    headerLink?: string;
    headerComment?: string;
    separator?: boolean;
  }[] = [];

  secondTitleRow: {
    title: string;
    index: number;
    sortable?: boolean;
    headerLink?: string;
    headerComment?: string;
    separator?: boolean;
  }[] = [];

  /**
   * 表格冻结宽度
   * @memberof AdminComponent
   */
  freezeWidth = 0;

  needFreezeWithReactiveScreen = false;

  /**
   * 区域选择缓存
   *
   * @memberof AdminComponent
   */
  lastPositionRow = -1;
  lastPositionColumn = -1;
  startPositionRow = -1;
  startPositionColumn = -1;
  mouseDown = false;
  selectedMatrix: boolean[][] = [];

  /**
   * 每行的配置项
   * @memberof TableProComponent
   */

  tableSearchConfig: TableColumnSearchConfig[] = [];

  checkList: boolean[] = [];
  formCopy: any[] = [];

  highLightIndex = 0;

  dataWaitForConfig = false;

  set calcResult(value: string) {
    this.calcResultChange.emit(value);
  }

  timeout: any = undefined;

  private detectChangesWithTimeout() {
    setTimeout(() => {
      // console.log('this.ref.detectChanges()');
      // this.ref.detectChanges();
    });
  }

  detectChangesWithoutTimeout() {
    // this.ref.detectChanges();
  }

  constructor(
    private globalService: GlobalService,
    private elementRef: ElementRef,
    private ref: ChangeDetectorRef
  ) {
    // ref.detach();
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.resize();
    // }, 100);
    window.addEventListener('resize', () => {
      this.resize();
    });
  }

  private resize() {
    this.needFreezeWithReactiveScreen =
      this.totalWidth >= this.elementRef.nativeElement.parentNode.clientWidth;
    this.detectChangesWithTimeout();
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (!!changes['tableData'] && !!changes['tableData'].currentValue) {
  //     // console.log('tableData', this._tableData);
  //     this.afterDataLoad();
  //   }
  //   this.resize();
  // }

  ngOnInit(): void {}

  linkto(url?: string) {
    if (url) {
    }
  }

  onQueryFocus(index: number) {
    this.queryButtonIndex = index;
    this.detectChangesWithoutTimeout();
  }

  queryPanelToggle() {
    this.isQueryOpen = !this.isQueryOpen;
    if (!this.isQueryOpen) {
      this.reset();
    }
    this.queryPanelStateChange.emit(this.isQueryOpen);
    this.detectChangesWithoutTimeout();
  }

  changeSortType(index: number) {
    this.queryButtonIndex = index;
    // TODO ：原逻辑，注释后请勿删除，以免需求又改回去
    // switch (this.tableSearchConfig[index].sort) {
    //   case undefined:
    //     this.tableSearchConfig[index].sort = OrderType.DESC;
    //     break;
    //   case OrderType.DESC:
    //     this.tableSearchConfig[index].sort = OrderType.ASC;
    //     break;
    //   case OrderType.ASC:
    //     this.tableSearchConfig[index].sort = undefined;
    //     break;
    // }
    // TODO: 新逻辑
    for (let i = 0; i < this.tableSearchConfig.length; i++) {
      if (i !== index) {
        this.tableSearchConfig[i].sort = this.tableSearchConfig[i].sortDefault;
      }
    }
    this.tableSearchConfig[index].sort =
      this.tableSearchConfig[index].sort === OrderType.ASC
        ? OrderType.DESC
        : OrderType.ASC;
    this.highLightIndex = index;
    this.search();
  }

  private preapreConfig() {
    this.tableSearchConfig = [];
    let index = 0;
    let width = 0;
    if (this._config.hasCheckAll) {
      width += this.checkWidth;
    }
    if (this._config.hasIndex) {
      width += this.indexWidth;
    }
    if (this._config.hideQuery) {
      this.isQueryOpen = false;
    }
    for (const column of this._config.columns) {
      this.culumnPositionsStart.push(width);
      width += column.width;
      this.culumnPositionsEnd.push(width);
      if (index < this._config.freezeColumn!) {
        this.freezeWidth = width;
      }
      if (!!column.group) {
        this.hasGroup = true;
        this.secondTitleRow.push({
          title: column.name,
          index,
          sortable: column.sortable,
          headerLink: column.headerLink,
          headerComment: column.headerComment,
          separator: column.separator,
        });
        if (
          this.firstTitleRow.length > 0 &&
          this.firstTitleRow[this.firstTitleRow.length - 1].isGroup &&
          this.firstTitleRow[this.firstTitleRow.length - 1].title ===
            column.group
        ) {
          this.firstTitleRow[this.firstTitleRow.length - 1].colspan++;
          this.firstTitleRow[this.firstTitleRow.length - 1].separator =
            column.separator;
        } else {
          this.firstTitleRow.push({
            colspan: 1,
            title: column.group,
            isGroup: true,
            index,
            separator: column.separator,
          });
        }

        // if (
        //   index > 0 &&
        //   this._config.columns[index - 1].group === column.group
        // ) {
        //   column.groupWidth =
        //     this._config.columns[index - 1].groupWidth! + column.width - 1;
        // } else {
        //   column.groupWidth = column.width - 1;
        // }
      } else {
        this.firstTitleRow.push({
          colspan: 1,
          title: column.name,
          index,
          sortable: column.sortable,
          headerLink: column.headerLink,
          headerComment: column.headerComment,
          separator: column.separator,
        });
      }

      this.tableSearchConfig.push({
        sort: column.sortDefault,
        sortDefault: column.sortDefault,
        value:
          column.type === TableColumnType.location
            ? column.defaultLocationId
            : undefined,
        value2: undefined,
      });
      if (column.queryable) {
        if (this.queryButtonIndex === -1) {
          this.queryButtonIndex = index;
        }
        switch (column.type) {
          case TableColumnType.date:
          case TableColumnType.time:
          case TableColumnType.datetime:
          case TableColumnType.digital:
          case TableColumnType.amount:
          case TableColumnType.percent:
            if (column.queryKey2) {
              this.queryRow = 2;
            }
            break;
          case TableColumnType.text:
          // case TableColumnType.subject:
          // case TableColumnType.subjectSummary:
          case TableColumnType.options:
            if (this.queryRow < 1) {
              this.queryRow = 1;
            }
            break;
          default:
        }
      }
      index++;
    }
    this.totalWidth = width;

    if (this.dataWaitForConfig) {
      this.afterDataLoad();
    }

    this.resize();
  }

  disabledDate =
    (minDate?: Date, maxDate?: Date) =>
    (current: Date): boolean =>
      (!!minDate && current.getTime() < minDate.getTime()) ||
      (!!maxDate && current.getTime() > maxDate.getTime());

  private afterDataLoad() {
    if (this._config === undefined) {
      this.dataWaitForConfig = true;
      return;
    }
    this.selectedMatrix = [];
    this.checkList = [];
    let cache: boolean[] = [];
    for (let i = 0; i < this._config.columns.length; i++) {
      cache.push(false);
    }

    for (let i = 0; i < this._tableData.length; i++) {
      this.selectedMatrix.push([...cache]);
      this.checkList.push(false);
    }
    this._isCheckAll = false;
    this.formCopy = [];

    for (const raw of this._tableData) {
      const copy: any = {};
      for (const config of this._config.columns) {
        if (raw[config.key] === undefined || isNaN(+raw[config.key])) {
          continue;
        }
        if (config.type === TableColumnType.percent) {
          copy[config.key] = raw[config.key] / 100;
        }
        if (config.type === TableColumnType.amount) {
          copy[config.key] = raw[config.key] / 100;
        }
        if (
          config.type === TableColumnType.date ||
          config.type === TableColumnType.datetime
        ) {
          copy[config.key] = new Date(raw[config.key]);
        }
      }
      this.formCopy.push(copy);
    }
    this.dataWaitForConfig = false;
  }

  onDateBlur(row: number, key: string) {
    const date = new Date(this.formCopy[row][key]);
    if (isNaN(date.getTime())) {
      this.formCopy[row][key] = undefined;
    } else {
      this.formCopy[row][key] = date.getTime();
    }
    this._tableData[row][key] = this.formCopy[row][key];

    this.onAfterCopyEdit(row, key);
  }

  onAfterCopyEdit(row: number, key: string) {
    for (const config of this._config.columns) {
      if (
        this.formCopy[row][config.key] === undefined ||
        isNaN(+this.formCopy[row][config.key])
      ) {
        continue;
      }
      if (config.type === TableColumnType.percent) {
        this._tableData[row][config.key] =
          +this.formCopy[row][config.key] * 100;
      }
      if (config.type === TableColumnType.amount) {
        this._tableData[row][config.key] =
          +this.formCopy[row][config.key] * 100;
      }
    }

    this.onAfterEdit(row, key);
  }

  mapOptions(
    obj: any,
    key: string,
    options: {
      value: string | number | boolean;
      label: string;
    }[],
    undefinedOptionHint?: string
  ): string {
    let opts: any = {};
    for (const opt of options) {
      opts[opt.value.toString()] = opt.label;
    }
    return `${
      obj[key] !== undefined && opts[obj[key]]
        ? opts[obj[key]]
        : !!undefinedOptionHint
        ? undefinedOptionHint
        : ''
    }`;
  }

  mapStatus(obj: any, key: string, patternOpts: { [key: string]: string }) {
    return `${
      obj[key] !== undefined
        ? patternOpts[obj[key]]
          ? patternOpts[obj[key]]
          : ''
        : ''
    }`;
  }

  onCheckAllChange() {
    this.checkList.fill(this._isCheckAll);
    this.checkChange.emit([...this.checkList]);
    this.checkAllChange.emit(this._isCheckAll);
    this.detectChangesWithoutTimeout();
    // console.log('onCheckAllChange');
  }

  onCheckRowChange() {
    let isCheckAll = true;
    for (const check of this.checkList) {
      isCheckAll &&= check;
    }
    this._isCheckAll = isCheckAll;
    this.checkChange.emit([...this.checkList]);
    console.log([...this.checkList]);
    // console.log('onCheckRowChange');
  }

  selectWithKeyboard(row: number, col: number) {
    if (this._config.columns[col].type === TableColumnType.actions) {
      return;
    }
    if (
      !this.globalService.ctrlPressing &&
      !this.globalService.metaPressing &&
      !this.globalService.shiftPressing
    ) {
      if (this._config.columns[col].preview) {
        this._config.columns[col].preview?.callback(row);
      }
      return;
    }
    if (this.startPositionColumn !== -1 && this.startPositionRow !== -1) {
      this.handleSelectArea(row, col);
    } else {
      this.resetSelectArea(row, col);
    }
    this.detectChangesWithoutTimeout();
  }

  selectWithMouseStart(row: number, col: number) {
    if (
      !this.globalService.ctrlPressing &&
      !this.globalService.metaPressing &&
      !this.globalService.shiftPressing
    ) {
      // // console.log(`mouse only: from (${row}, ${col})`);
      this.resetSelectArea(row, col);
      this.mouseDown = true;
    }
    this.detectChangesWithoutTimeout();
  }

  selectWithMouseMove(row: number, col: number) {
    if (this.mouseDown) {
      // // console.log(`mouse only: pass (${row}, ${col})`);
      this.handlerSelectAreWithMouse(row, col);
    }
    this.detectChangesWithoutTimeout();
  }

  selectWithMouseEnd(row: number, col: number) {
    if (this.mouseDown) {
      // // console.log(`mouse only: to (${row}, ${col})`);
      this.handlerSelectAreWithMouse(row, col);
      this.mouseDown = false;
    }
    this.detectChangesWithoutTimeout();
  }

  private resetSelectArea(row: number, col: number) {
    this.startPositionRow = row;
    this.startPositionColumn = col;
    this.lastPositionRow = row;
    this.lastPositionColumn = col;
    for (const row of this.selectedMatrix) {
      row.fill(false);
    }
    this.selectedMatrix[row][col] = true;
  }

  private handleSelectArea(row: number, col: number) {
    if (this.globalService.ctrlPressing || this.globalService.metaPressing) {
      this.selectedMatrix[row][col] = !this.selectedMatrix[row][col];
      this.lastPositionRow = row;
      this.lastPositionColumn = col;
      this.scanMatrix();
    }
    if (this.globalService.shiftPressing) {
      this.handlerSelectAreWithMouse(row, col);
    }
  }

  private handlerSelectAreWithMouse(row: number, col: number) {
    if (row === this.lastPositionRow && col === this.lastPositionColumn) {
      this.selectedMatrix[row][col] = true;
      this.lastPositionRow = row;
      this.lastPositionColumn = col;
    } else {
      const startRow = row < this.lastPositionRow ? row : this.lastPositionRow;
      const startCol =
        col < this.lastPositionColumn ? col : this.lastPositionColumn;
      const endRow = row > this.lastPositionRow ? row : this.lastPositionRow;
      const endCol =
        col > this.lastPositionColumn ? col : this.lastPositionColumn;

      // // console.log(`from (${startRow}, ${startCol}) to (${endRow}, ${endCol})`);
      for (let i = 0; i < this.selectedMatrix.length; i++) {
        for (let j = 0; j < this.selectedMatrix[i].length; j++) {
          if (startRow <= i && i <= endRow && startCol <= j && j <= endCol) {
            // // console.log(`fill (${i}, ${j})`);
            this.selectedMatrix[i][j] = true;
          } else {
            this.selectedMatrix[i][j] = false;
          }
        }
      }
    }
    this.scanMatrix();
  }

  private scanMatrix() {
    let startRow = -1;
    let startCol = -1;
    let endRow = -1;
    let endCol = -1;

    let typeSet: Set<TableColumnType> = new Set<TableColumnType>();
    let selectData: { data: any; type: TableColumnType }[] = [];

    for (let i = 0; i < this.selectedMatrix.length; i++) {
      for (let j = 0; j < this.selectedMatrix[i].length; j++) {
        if (this.selectedMatrix[i][j] === true) {
          if (startRow === -1) {
            startRow = i;
            endRow = startRow;
          }
          if (startCol === -1) {
            startCol = j;
            endCol = startCol;
          }
          if (i < startRow) {
            startRow = i;
          }
          if (i > endRow) {
            endRow = i;
          }
          if (j < startCol) {
            startCol = j;
          }
          if (j > endCol) {
            endCol = j;
          }
          typeSet.add(this._config.columns[j].type);
          selectData.push({
            type: this._config.columns[j].type,
            data: this._tableData[i][this._config.columns[j].key],
          });
        }
      }
    }
    this.startPositionRow = startRow;
    this.startPositionColumn = startCol;

    if (startRow === -1 && startCol === -1) {
      this.lastPositionColumn = -1;
      this.lastPositionRow = -1;
    }

    // 判断是否有条件符合试算
    if (
      // 选中的数量小于2个
      selectData.length < 2 ||
      // 选中的含有文本
      typeSet.has(TableColumnType.text) ||
      // 选中的数据类型超过2种
      Array.from(typeSet).length > 2 ||
      // 选中的数据类型有两种，但并不是数字和百分数，而且数量也不是2个
      (Array.from(typeSet).length === 2 &&
        !(
          (typeSet.has(TableColumnType.digital) &&
            typeSet.has(TableColumnType.percent)) ||
          (typeSet.has(TableColumnType.amount) &&
            typeSet.has(TableColumnType.percent)) ||
          (typeSet.has(TableColumnType.digital) &&
            typeSet.has(TableColumnType.amount))
        ) &&
        selectData.length === 2)
    ) {
      // console.error('条件不成立！');
      this.calcResult = '';
      return;
    }
    // // console.log('条件成立，需要试算');
    if (Array.from(typeSet).length === 2) {
      if (
        typeSet.has(TableColumnType.amount) &&
        typeSet.has(TableColumnType.percent)
      ) {
        this.calcResult = `乘积: ${(
          (+selectData[0].data * +selectData[1].data) /
          100 /
          100
        ).toFixed(2)}`;
      }
      if (
        typeSet.has(TableColumnType.digital) &&
        typeSet.has(TableColumnType.percent)
      ) {
        this.calcResult = `乘积: ${(
          (+selectData[0].data * +selectData[1].data) /
          100
        ).toFixed(2)}`;
      }
    } else {
      const type = Array.from(typeSet)[0];
      switch (type) {
        case TableColumnType.digital:
          this.calcDigital(selectData.map<number>((a) => +a.data));
          break;
        case TableColumnType.amount:
          this.calcAmount(selectData.map<number>((a) => +a.data));
          break;
        case TableColumnType.percent:
          this.calcPercent(selectData.map<number>((a) => +a.data));
          break;
        case TableColumnType.date:
          this.calcDate(selectData.map<number>((a) => +a.data));
          break;
        case TableColumnType.time:
          this.calcTime(selectData.map<number>((a) => +a.data));
          break;
        case TableColumnType.datetime:
          this.calcDateTime(selectData.map<number>((a) => +a.data));
          break;
      }
    }
    //  console.log(this.calcResult);
    this.detectChangesWithoutTimeout();
  }

  private calcDigital(selectData: number[]) {
    const sum = selectData.reduce((a, b) => a + b);
    const sorted = selectData.sort((a, b) => a - b);
    if (selectData.length === 2) {
      this.calcResult = `求和: ${sum}, 平均: ${Math.round(
        sum / selectData.length
      ).toFixed(2)}, 差值: ${Math.abs(selectData[0] - selectData[1]).toFixed(
        2
      )}`;
    } else {
      this.calcResult = `求和: ${sum}, 平均: ${Math.round(
        sum / selectData.length
      ).toFixed(2)}, 排序(${selectData.length}项): ${sorted
        .map((a) => a)
        .join(', ')}`;
    }
  }

  private calcAmount(selectData: number[]) {
    const sum = selectData.reduce((a, b) => a + b);
    const sorted = selectData.sort((a, b) => a - b);
    const r = 100;
    if (selectData.length === 2) {
      this.calcResult = `求和: ${(sum / r).toFixed(2)}, 平均: ${(
        Math.round(sum / selectData.length) / r
      ).toFixed(2)}, 差值: ${(
        Math.abs(selectData[0] - selectData[1]) / r
      ).toFixed(2)}`;
    } else {
      this.calcResult = `求和: ${(sum / r).toFixed(2)}, 平均: ${(
        Math.round(sum / selectData.length) / r
      ).toFixed(2)}, 排序(${selectData.length}项): ${sorted
        .map((a) => (a / r).toFixed(2))
        .join(', ')}`;
    }
  }

  private calcPercent(selectData: number[]) {
    const sum = selectData.reduce((a, b) => a + b);
    const sorted = selectData.sort((a, b) => a - b);
    const r = 100;
    if (selectData.length === 2) {
      this.calcResult = `求和: ${sum / r}%, 平均: ${(
        Math.round(sum / selectData.length) / r
      ).toFixed(2)}%, 差值: ${Math.abs(selectData[0] - selectData[1]) / r}%`;
    } else {
      this.calcResult = `求和: ${sum / r}%, 平均: ${(
        Math.round(sum / selectData.length) / r
      ).toFixed(2)}%, 排序(${selectData.length}项): ${sorted
        .map((a) => a / r)
        .join('%,')}%`;
    }
  }

  private calcDateTime(selectData: number[]) {
    const sorted = selectData.sort((a, b) => a - b);
    if (selectData.length === 2) {
      const date1 = new Date(selectData[0]);
      const date2 = new Date(selectData[1]);
      const seconds = Math.abs(moment(date1).diff(moment(date2), 'seconds'));
      const day = 24 * 3600;
      const dayOff = Math.floor(seconds / day);
      const secondOff = seconds % day;
      this.calcResult = `差值: ${dayOff}天 ${moment()
        .startOf('day')
        .second(secondOff)
        .format('HH:mm:ss')}`;
    } else {
      this.calcResult = `排序(${selectData.length}项): ${sorted
        .map((a) =>
          moment(a).locale('zh_cn').format('YYYY-MM-DD \\[dd] HH:mm:ss')
        )
        .join(', ')}`;
    }
  }

  private calcDate(selectData: number[]) {
    const sorted = selectData.sort((a, b) => a - b);
    if (selectData.length === 2) {
      this.calcResult = `差值: ${moment(
        new Date(selectData[1]).toDateString()
      ).diff(new Date(selectData[0]).toDateString(), 'days')}天`;
    } else {
      this.calcResult = `排序(${selectData.length}项): ${sorted
        .map((a) => moment(a).locale('zh_cn').format('YYYY-MM-DD \\[dd]'))
        .join(', ')}`;
    }
  }

  private calcTime(selectData: number[]) {
    const sorted = selectData.sort((a, b) => a - b);
    if (selectData.length === 2) {
      const date1 = new Date(selectData[0]);
      const date2 = new Date(selectData[1]);
      this.calcResult = `差值: ${moment()
        .startOf('day')
        .second(
          Math.abs(
            moment({
              hour: date1.getHours(),
              minute: date1.getMinutes(),
              second: date1.getSeconds(),
            }).diff(
              moment({
                hour: date2.getHours(),
                minute: date2.getMinutes(),
                second: date2.getSeconds(),
              }),
              'seconds'
            )
          )
        )
        .format('HH:mm:ss')}`;
    } else {
      this.calcResult = `排序(${selectData.length}项): ${sorted
        .map((a) => moment(a).locale('zh_cn').format('HH:mm:ss'))
        .join(', ')}`;
    }
  }

  get queryButtonPosition(): number {
    if (
      this.culumnPositionsEnd.length === 0 ||
      this.totalWidth === 0 ||
      this.queryButtonIndex === -1
    ) {
      return 0;
    }
    return this.totalWidth - this.culumnPositionsEnd[this.queryButtonIndex];
  }

  get queryButtonPositionInFreezebar(): number {
    if (
      this.culumnPositionsEnd.length === 0 ||
      this.freezeWidth === 0 ||
      this.queryButtonIndex === -1
    ) {
      return 0;
    }
    return this.freezeWidth - this.culumnPositionsEnd[this.queryButtonIndex];
  }

  copy() {
    // console.log(this.copy);
    // TODO: 按照选中的格子拷贝内容
  }

  onLocationChange(locationId: string, row: number, key: string) {
    this._tableData[row][key] = locationId;
    this.afterEdit.emit({ row, key });
  }

  onLocationSearchChange(locationId: string, index: number) {
    // console.log(locationId);
    this.tableSearchConfig[index].value = locationId;
  }

  onTreeSelectChange(events: any, index: number) {
    // console.log(events);
    if (events.length === 0) {
      this.tableSearchConfig[index].value = undefined;
    } else {
      this.tableSearchConfig[index].value = events[events.length - 1];
    }
  }

  onFileListChange(fileUrls: string[], row: number, key: string) {
    this._tableData[row][key] = fileUrls;
    this.afterEdit.emit({ row, key });
  }

  search() {
    console.log(this.tableSearchConfig);
    const data: { [key: string]: any } = {};
    // console.log(this.tableSearchConfig);
    for (let i = 0; i < this.tableSearchConfig.length; i++) {
      const item = this.tableSearchConfig[i];
      if (
        this._config.columns[i].sortKey !== undefined &&
        item.sort !== undefined &&
        item.sort.toString() !== 'undefined'
      ) {
        data[this._config.columns[i].sortKey!] = item.sort;
      }
      if (
        this._config.columns[i].queryKey !== undefined &&
        item.value !== undefined &&
        item.value !== null &&
        item.value.toString() !== 'undefined'
      ) {
        data[this._config.columns[i].queryKey!] = this.parseValueByType(
          item.value,
          this._config.columns[i].type
        );
      }
      if (
        this._config.columns[i].queryKey2 !== undefined &&
        item.value2 !== undefined &&
        item.value2 !== null &&
        item.value2.toString() !== 'undefined'
      ) {
        data[this._config.columns[i].queryKey2!] = this.parseValueByType(
          item.value2,
          this._config.columns[i].type
        );
      }
    }

    this.searchClick.emit(data);
  }

  private parseValueByType(value: any, type: TableColumnType) {
    switch (type) {
      case TableColumnType.date:
      case TableColumnType.datetime:
      case TableColumnType.time:
        return value ? new Date(value).getTime() : undefined;
      case TableColumnType.options:
        return value && value !== 'undefined' ? value : undefined;
      case TableColumnType.location:
        return value && value === '000000' ? undefined : value;
      case TableColumnType.amount:
        return !isNaN(+value) ? Math.round(+value * 100) : undefined;
      case TableColumnType.percent:
        return !isNaN(+value) ? Math.round(+value * 100) : undefined;
      case TableColumnType.digital:
        return !isNaN(+value) ? +value : undefined;
      case TableColumnType.file:
        return value === 'true' ? true : value === 'false' ? false : undefined;
      // case TableColumnType.subject:
      //   return !isNaN(+value) ? +value : undefined;
      default:
        return !!value ? value : undefined;
    }
  }

  reset() {
    for (const config of this.tableSearchConfig) {
      config.sort = config.sortDefault;
      config.value = undefined;
      config.value2 = undefined;
    }

    // this.detectChangesWithoutTimeout();
    this.search();
    // this.resetClick.emit();
  }

  onAfterEdit(row: number, key: string) {
    this.afterEdit.emit({ row, key });
  }
}
