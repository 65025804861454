<div class="grid" *ngIf="needSideBar">
	<div class="row clickable {{item.workFlowId === currentWorkFlowInfo?.workFlowId ? 'active' : ''}} {{item.waitSign ? 'color-danger' : ''}}"
		*ngFor="let item of workFlowInfoList; let index = index" (click)="chooseMain(index)">
		<div class="column text-center" [style.width]="40 + 'px'">
			{{index + 1}}
		</div>
		<div class="column" [style.width]="260 + 'px'">
			<format-data
				[contentStr]="item.waitSign ? '[待签]' + item.signFiles![0].fileName : item.signFiles![0].fileName"
				patternStr="E+260">
			</format-data>
		</div>
	</div>
	<div class="row">
		<div class="column" [style.width]="40 + 'px'">
		</div>
		<div class="column text-left" [style.width]="260 + 'px'">
			<b>附件</b>
		</div>
	</div>
	<div class="row clickable {{currentAttachFileId === item.fileId! ? 'active' : ''}}"
		*ngFor="let item of currentWorkFlowInfo?.attachFiles ?? []; let index = index">
		<div class="column text-center" [style.width]="40 + 'px'">
			{{index + 1}}
		</div>
		<div class="column" [style.width]="260 + 'px'">
			<format-data [contentStr]="item.fileName" patternStr="E+260" (click)="chooseAttach(index)">
			</format-data>
		</div>
	</div>

	<div class="row" *ngIf="!!currentWorkFlowInfo?.signAddAttach">
		<div class="column text-center" [style.width]="300 + 'px'">
			<b>添加附件</b>（若有方需添加）
		</div>
	</div>

	<div class="row" *ngFor="let item of currentWorkFlowInfo?.appendFiles; let index = index;">
		<div class="column background-page" [style.width]="40 + 'px'">
			{{index + 1}}
		</div>
		<div class="column text-left" [style.width]="180 + 'px'">
			<format-data class="color-primary" [contentStr]="item.fileName ?? ''" patternStr="E+580"
				(click)="previewAttachment(index)" *ngIf="!item.editing">
			</format-data>

			<input *ngIf="item.editing" type="text" [(ngModel)]="item.fileName"
				(ngModelChange)="onFileNameChange(index)">
		</div>
		<div class="column" [style.width]="40 + 'px'">
			<button nz-button nzType="text" class="color-primary" (click)="item.editing = !item.editing">
				{{!item.editing ? '改名' : '保存'}}
			</button>
		</div>
		<div class="column" [style.width]="40 + 'px'">
			<button nz-button nzType="text" (click)="removeAttachment(index)">删除</button>
		</div>
	</div>

	<div class="row" *ngIf="!!currentWorkFlowInfo?.signAddAttach">
		<div class="column" [style.width]="300 + 'px'">
			<button class="color-primary" nz-button nzType="text">
				+上传外部文件
				<input type="file" multiple="multiple" (change)="uploadAppend($event)">
			</button>
		</div>
	</div>
</div>

<div class="preview-dialog" [style.minHeight]="height + 'px'" [style.minWidth]="width + 'px'">
	<div class="preview-dialog-header" [style.right]="0">
		<span *ngIf="singlePage">&ensp;</span>
		<button class="color-primary" nz-button nzType="text" (click)="dismiss()" *ngIf="!singlePage">
			<span nz-icon nzType="close" nzTheme="outline"></span> 关闭
		</button>
		<div *ngIf="freeSigner" class="action">
			<div *ngFor="let item of freeSigner.signFieldWidgetInfos ?? []" class="dragable" [draggable]="true"
				(dragstart)="onDragStart(item)">
				<textarea *ngIf="item.signFieldType === WorkFlowSignFieldType.REMARK_FIELD" [style.width]="'100%'"
					[style.resize]="'none'" [style.height]="'100%'" [(ngModel)]="item.content" maxlength="256">
				</textarea>

				<input *ngIf="item.signFieldType === WorkFlowSignFieldType.RADIO_FIELD && item.dataSourceObj"
					[(ngModel)]="rMap[item.dataSourceObj.group]" type="radio" [name]="item.dataSourceObj.group"
					[value]="item.dataSourceObj.value" />

				<nz-date-picker (ngModelChange)="onFieldDateChange(item)" [(ngModel)]="item.contentDate"
					nzPlaceHolder="设置日期" nzFormat="yyyy年M月d日" [nzSuffixIcon]="''"
					*ngIf="item.signFieldType === WorkFlowSignFieldType.DATE_FIELD">
				</nz-date-picker>

				<img *ngIf="item.signFieldType === WorkFlowSignFieldType.SIGN_FIELD" [style.width]="'100%'"
					[style.height]="'100%'" [src]="environment.fileHost + item.sealFieldImgFileId" alt="">

				<img *ngIf="item.signFieldType === WorkFlowSignFieldType.SEAL_FIELD" [style.width]="'100%'"
					[style.height]="'100%'" [src]="environment.fileHost + item.sealFieldImgFileId" alt="">

				<div class="cover">
					{{WorkFlowSignFieldTypeMap[item.signFieldType!]}}
				</div>
			</div>
		</div>
		<div class="action" *ngIf="!!currentWorkFlowInfo?.waitSign">
			<div class="btnlike background-grey" (click)="reject()">
				退回
			</div>
			<div class="btnlike background-danger color-white" (click)="sign(false)">
				{{signing ? '签字完成中' : "确认签字"}}
			</div>
			<div class="btnlike background-danger color-white" (click)="sign(true)" *ngIf="workFlowInfoList.length > 1">
				{{signing ? '签字完成中' : "全部签字"}}
			</div>
			<!-- <div class="btnlike background-primary color-white" (click)="next()">
				下一待签
			</div> -->
		</div>
		<div class="action">
			<button class="color-primary" nz-button nzType="text" (click)="zoomIn()">
				<span nz-icon nzType="zoom-in" nzTheme="outline"></span>
			</button>
			<button class="color-primary" nz-button nzType="text">
				<span nz-icon nzType="border-outer" nzTheme="outline" (click)="originSize()"></span>
			</button>
			<button class="color-primary" nz-button nzType="text" (click)="zoomOut()">
				<span nz-icon nzType="zoom-out" nzTheme="outline"></span>
			</button>
		</div>
	</div>
	<div class="preview-dialog-body" *ngIf="currentAttachFileId">
		<pdf-viewer [src]="environment.fileHost + currentAttachFileId" [c-maps-url]="'assets/cmaps'"
			[render-text]="true" [zoom-scale]="'page-fit'" [original-size]="false" style="width: 100%; height: 100%"
			[zoom]="pdfZoom" [(page)]="pdfPages" (pages-initialized)="onPageLoad($event)">
		</pdf-viewer>
	</div>
	<div class="preview-dialog-body" *ngIf="!currentAttachFileId">
		<div class="sign-container" *ngIf="!!currentWorkFlowInfo && !!currentWorkFlowInfo.signFiles && inited">
			<div class="sign-file" [style.width]="info.pdfWidth + 'px'" [style.height]="info.pdfHeight + 'px'"
				*ngFor="let info of currentWorkFlowInfo.signFiles![0].imageInfos; let page = index;"
				(drop)="onDrop($event, page)" (dragover)="allowDrop($event)">
				<span class="hint color-danger" *ngIf="isDebug">
					Width: {{info.pdfWidth}}
					Height: {{info.pdfHeight}}
				</span>
				<img [src]="environment.fileHost + info.fileId" alt="" />
				<div class="sign-widget"
					*ngFor="let item of normalWidgetMap[currentWorkFlowInfo.workFlowId!][page] ?? []"
					[style.left]="item.signFieldPosition!.left! + 'px'"
					[style.top]="item.signFieldPosition!.top! + 'px'"
					[style.height]="item.signFieldPosition!.signFieldHeight + 'px'"
					[style.width]="item.signFieldPosition!.signFieldWidth + 'px'">
					<span class="hint color-primary" *ngIf="isDebug">
						{{item.flowId}}
					</span>
					<img *ngIf="item.sealFieldImgFileId" [src]="environment.fileHost + item.sealFieldImgFileId" alt=""
						[style.width]="'100%'" [style.height]="'100%'">
				</div>
				<div class="sign-widget current {{item.musted ? 'must' : ''}}"
					*ngFor="let item of currentWidgetMap[currentWorkFlowInfo.workFlowId!][page] ?? []; let itemIndex = index"
					[style.left]="item.signFieldPosition!.left! + 'px'"
					[style.top]="item.signFieldPosition!.top! + 'px'"
					[style.height]="item.dragable && item.signFieldType === WorkFlowSignFieldType.REMARK_FIELD ? 'auto' : item.signFieldPosition!.signFieldHeight + 'px'"
					[style.width]="item.signFieldPosition!.signFieldWidth + 'px'" [draggable]="!!item.dragable"
					(dragstart)="onDragableMoveStart(page, itemIndex, item.dragable)">
					<span class="hint color-danger" *ngIf="isDebug">
						Left: {{item.signFieldPosition!.left!}}
						Top: {{item.signFieldPosition!.top!}}
						Right: {{item.signFieldPosition!.left! + item.signFieldPosition!.signFieldWidth!}}
						Bottom: {{item.signFieldPosition!.top! + item.signFieldPosition!.signFieldHeight!}}
					</span>
					<textarea *ngIf="item.signFieldType === WorkFlowSignFieldType.REMARK_FIELD && !item.dragable"
						[style.width]="'100%'" [style.resize]="'none'" [style.height]="'100%'" maxlength="256"
						[(ngModel)]="item.content">
					</textarea>

					<p [style.background]="'white'"
						*ngIf="item.signFieldType === WorkFlowSignFieldType.REMARK_FIELD && item.dragable"
						[style.height]="item.dragable ? 'auto' : '100%'" [style.width]="'100%'" contenteditable="true"
						[textContent]="item.content" (input)="onInput($event, item, page)">
					</p>
					<!-- TODO 测试样式 -->
					<span *ngIf="item.signFieldType === WorkFlowSignFieldType.RADIO_FIELD && item.dataSourceObj"
						class="{{rMap[item.dataSourceObj.group] === item.dataSourceObj.value ? 'color-primary' : ''}} fake-radio"
						[style.width]="'100%'" [style.fontSize]="'16px'">
						{{rMap[item.dataSourceObj.group] === item.dataSourceObj.value ? '☑' : '□'}}
					</span>

					<input *ngIf="item.signFieldType === WorkFlowSignFieldType.RADIO_FIELD && item.dataSourceObj"
						[(ngModel)]="rMap[item.dataSourceObj.group]" type="radio" [name]="item.dataSourceObj.group"
						[value]="item.dataSourceObj.value" />

					<nz-date-picker [(ngModel)]="item.contentDate" (ngModelChange)="onFieldDateChange(item)"
						[style.width]="'100%'" nzPlaceHolder="设置日期"
						[nzFormat]="item.signFieldPosition!.signFieldWidth! > 88 ? 'yyyy年M月d日' : 'yyyy.M.d'"
						[nzSuffixIcon]="''" [nzAllowClear]="false"
						*ngIf="item.signFieldType === WorkFlowSignFieldType.DATE_FIELD">
					</nz-date-picker>

					<img *ngIf="item.signFieldType === WorkFlowSignFieldType.SIGN_FIELD" [style.width]="'100%'"
						[style.height]="'100%'" [src]="environment.fileHost + item.sealFieldImgFileId" alt="">

					<img *ngIf="item.signFieldType === WorkFlowSignFieldType.SEAL_FIELD" [style.width]="'100%'"
						[style.height]="'100%'" [src]="environment.fileHost + item.sealFieldImgFileId" alt="">

					<span class="remove" (click)="removeDragable(page, itemIndex)" *ngIf="!!item.dragable"
						[style.bottom]="-24 + 'px'" [style.right]="0">
						X
					</span>

				</div>
			</div>
		</div>
	</div>

</div>