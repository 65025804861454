import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InitWorkReportRequest } from '../models/form/init-work-report-request.model';
import { MaterialInspection } from '../models/inspection/material-inspection.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class InspectionMaterialService extends RequestService<MaterialInspection> {
  static PATH = '/inspection/material';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, InspectionMaterialService.PATH, globalService.debugUrl);
  }

  generateReport(ids: number[]) {
    return this.httpClient.post<InitWorkReportRequest>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${InspectionMaterialService.PATH}/generateInitWorkReportRequest`,
      ids
    );
  }
}
