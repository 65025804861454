<div class="entry" (click)="showPanel()" dPopover bsStyle="common" [content]="customTemplate" [position]="'bottom-left'"
	[visible]="isPanelShown" [controlled]="false" [popoverStyle]="{ padding: '0'}">

	<div>
		{{result}}
	</div>

</div>

<ng-template #customTemplate>
	<div class="panel" mouseLeave (onLeave)="onLeave()">
		<div class="panel-header">
			<button (click)="fullYear()" class="primary">
				全年有效
			</button>
			<button (click)="onLeave()" class="primary">
				确认
			</button>
		</div>
		<div class="panel-body">
			<div class="item-container ">
				<div class="item" *ngFor="let item of list" (click)="m1Select(item)">
					<span class="{{m1 === item ? 'color-primary' : 'color-light'}}">
						{{item.toString().padStart(2, '0')}}月
					</span>
				</div>
			</div>
			<div class="item-container ">
				<div class="item" *ngFor="let item of map[m1 -1]" (click)="d1Select(item)">
					<span class="{{d1 === item ? 'color-primary' : 'color-light'}}">
						{{item.toString().padStart(2, '0')}}日
					</span>
				</div>
			</div>
			<div class="item-container ">
				<div class="item" *ngFor="let item of list" (click)="m2Select(item)">
					<span class="{{m2 === item ? 'color-primary' : 'color-light'}}">
						{{item.toString().padStart(2, '0')}}月
					</span>
				</div>
			</div>
			<div class="item-container ">
				<div class="item" *ngFor="let item of map[m2 -1]" (click)="d2Select(item)">
					<span class="{{d2 === item ? 'color-primary' : 'color-light'}}">
						{{item.toString().padStart(2, '0')}}日
					</span>
				</div>
			</div>
		</div>
	</div>
</ng-template>