import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProjectType } from '../models/project/project-type.model';
import { GlobalService } from '../providers/global.service';
import { FileResponse } from '../providers/request/file-response.model';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectTypeService extends RequestService<ProjectType> {
  static PATH = '/project/type';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, ProjectTypeService.PATH, globalService.debugUrl);
  }

  uploadFileWithoutProgress(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<FileResponse>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${ProjectTypeService.PATH}/upload`,
      formData
    );
  }
}
