import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SignUrlInfo } from '../models/form/sign-url-info.model';
import { WorkFlowBatchSignRequest } from '../models/form/work-flow-batch-sign-request.model';
import { WorkFlowInfo } from '../models/form/work-flow-info.model';
import { WorkFlowRefectSignRequest } from '../models/form/work-flow-refect-sign-request.model';
import { WorkFlowSignRequest } from '../models/form/work-flow-sign-request.model';
import { WorkFlowUserTaskSummary } from '../models/form/work-flow-user-task-summary.model';
import { WorkFlowUser } from '../models/form/work-flow-user.model';
import { WorkReportUser } from '../models/form/work-report-user.model';
import { CheckSignAuthRequest } from '../models/staff/check-sign-auth-request.model';
import { SendSmsCodeRequest } from '../models/staff/send-sms-code-request.model';
import { SignAuthInfo } from '../models/staff/sign-auth-info.model';
import { GlobalService } from '../providers/global.service';
import { Page } from '../providers/request/page.type';
import { RequestService } from '../providers/request/request.service';
import { SearchRequest } from '../providers/request/search-request.model';

@Injectable({
  providedIn: 'root',
})
export class OpenApiSignOrderService extends RequestService<WorkFlowUser> {
  static PATH = '/open/api/signorder';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, OpenApiSignOrderService.PATH, globalService.debugUrl);
  }

  rejectSign(params: WorkFlowRefectSignRequest) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OpenApiSignOrderService.PATH}/rejectSign`,
      params
    );
  }

  confirmSign(params: WorkFlowSignRequest) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OpenApiSignOrderService.PATH}/confirmSign`,
      params
    );
  }

  batchConfirmSign(params: WorkFlowBatchSignRequest) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OpenApiSignOrderService.PATH}/batchConfirmSign`,
      params
    );
  }

  getSignUrl(workFlowId: number) {
    return this.httpClient.get<SignUrlInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OpenApiSignOrderService.PATH}/getSignUrlInfo/${workFlowId}`
    );
  }

  sendSmsCode(request: SendSmsCodeRequest) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OpenApiSignOrderService.PATH}/sendSmsCode`,
      request
    );
  }

  getSignAuthInfo(token: string) {
    return this.httpClient.get<SignAuthInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OpenApiSignOrderService.PATH}/getSignAuthInfo/${token}`
    );
  }

  getTaskSummary() {
    return this.httpClient.get<WorkFlowUserTaskSummary[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OpenApiSignOrderService.PATH}/getTaskSummary`
    );
  }

  getWorkFlowInfo(token: string) {
    return this.httpClient.get<WorkFlowInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OpenApiSignOrderService.PATH}/getWorkFlowInfo/${token}`
    );
  }

  getWorkFlowInfoByWorkFlowId(token: string, workFlowId: string | number) {
    return this.httpClient.get<WorkFlowInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${
        OpenApiSignOrderService.PATH
      }/getWorkFlowInfoByWorkFlowId/${workFlowId}/${token}`
    );
  }

  searchWorkReport<REQ extends SearchRequest>(
    params: REQ,
    page?: number,
    size?: number
  ) {
    return this.httpClient.post<Page<WorkReportUser>>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OpenApiSignOrderService.PATH}/searchWorkReport?page=${
        page ? page : 0
      }&size=${size ? size : 20}`,
      params
    );
  }

  checkSignAuthInfo(info: CheckSignAuthRequest) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OpenApiSignOrderService.PATH}/checkSignAuthInfo`,
      info
    );
  }

  groupFileAdd(data: {
    fileName: string;
    fileNum: number;
    groupEnable: boolean;
    test: boolean;
  }) {
    return this.httpClient.post(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OpenApiSignOrderService.PATH}/submitTest`,
      data
    );
  }
}
