import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Project } from '../models/project/project.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends RequestService<Project> {
  static PATH = '/project';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, ProjectService.PATH, globalService.debugUrl);
  }
}
