<span class="content" [style.color]="logicColor" [innerHtml]="innerHTMLStr | safeHtml" dPopover
	[content]="customTemplate" [trigger]="'hover'" [position]="['bottom','top', 'right',  'left']"
	[controlled]="!!notEllipsisStr && !doNotShowEllipsis" [popoverStyle]="{ backgroundColor: 'white' }"
	[appendToBody]="true">
</span>

<ng-template #customTemplate>
	<p class="hint">{{notEllipsisStr}}</p>
</ng-template>

<ng-content></ng-content>