import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MaterialBatch } from '../models/material/material-batch.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class MaterialBatchService extends RequestService<MaterialBatch> {
  static PATH = '/material/batch';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, MaterialBatchService.PATH, globalService.debugUrl);
  }
}
