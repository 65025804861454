import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginForm } from '../models/login/login-form.model';
import { LoginInfo } from '../models/login/login-info.model';
import { User } from '../models/staff/user.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class StaffUserService extends RequestService<User> {
  static PATH = '/staff/user';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, StaffUserService.PATH, globalService.debugUrl);
  }

  getSmsCode(phone: string) {
    return this.httpClient.get<string[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${StaffUserService.PATH}/code/${phone}`
    );
  }

  login(loginForm: LoginForm) {
    return this.httpClient.post<LoginInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${StaffUserService.PATH}/login`,
      loginForm
    );
  }

  createTestUsers(user: User) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${StaffUserService.PATH}/createTestUsers`,
      {
        organizationId: user.organizationId,
        postId: user.postId,
        users: [user],
      }
    );
  }
}
