import { FormFlowType } from '../form/form-flow-type.enum';
import { WorkFlowSignFieldInfo } from './work-flow-sign-field-info.model';

export class FlowInfo {
  flowId?: number;
  flowType?: FormFlowType;
  singerOrder?: number;
  signNodeOrder?: number;
  signFields?: Array<WorkFlowSignFieldInfo>;
}
