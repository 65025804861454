<nz-tabset [nzSelectedIndex]="tabId">
	<nz-tab *ngFor="let item of config" [nzTitle]="item.name! + '(' + fileType + ')'">
		<div class="grid" *ngIf="!previewMode">
			<div class="row">
				<div class="column" [style.height]="44 + 'pt'"></div>
			</div>
			<div class="row" *ngFor="let row of item.rowHeights; let rowIndex = index;">
				<div [style.height]="(row! * hRate) + 'pt'"
					class="column {{!!row && row > 0 ? 'coordinate' : ''}}  {{rowIndex === selectedRowIndex ? 'active' : 'inactive'}} {{rowIndex === config[0].rows!.length - 1 ? 'last' : ''}}">
					{{rowIndex + 1}}
				</div>
			</div>
		</div>

		<table [style.width]="(item.width! * wRate) + 'pt'">
			<colgroup>
				<col span="1" *ngFor="let perWidth of item.colWidths"
					[style.width]="(perWidth / item.width!) * 100 + '%'">
			</colgroup>
			<thead class="sticky" [style.top]="0" *ngIf="!previewMode">
				<tr [style.height]="20 + 'pt'" class="background-white">
					<th class="{{colIndex === selectedColIndex ? 'active' : 'inactive'}}"
						*ngFor="let perWidth of item.colWidths; let colIndex = index;">
						{{cellPos(colIndex)}}
					</th>
				</tr>
			</thead>

			<tbody>
				<tr [style.height]="24 + 'pt'"> </tr>
				<tr *ngFor="let row of item.rows; let rowIndex = index;" [style.height]="(row.height! * hRate) + 'pt'">
					<td *ngFor="let col of row.columns; let colIndex = index;" [colSpan]="col.colSpan ?? 1"
						[rowSpan]="col.rowSpan ?? 1" [style.height]="(row.height! * hRate) + 'pt'" class="{{col.borderStyle?.top ? 'border-top' : ''}} {{col.borderStyle?.right ? 'border-right' : ''}} 
			{{col.borderStyle?.bottom ? 'border-bottom' : ''}} {{col.borderStyle?.left ? 'border-left' : ''}} {{col.formItem?.hint ? 'hint' : ''}} {{col.wrapText ? 'wrap-text' : ''}}
			{{col.rowIndex === selectedRowIndex && col.colIndex === selectedColIndex ? 'active' : ''}} "
						(click)="cellSelected(col)" [nzTooltipTitle]="col.formItem?.hint" [nzTooltipPlacement]="'top'"
						nz-tooltip>
						<div [style.height]="'100%'"
							[style.justifyContent]="col.align ? JustifyMap[col.align] : 'center'"
							[style.alignItems]="col.valign ? AlignMap[col.valign] : 'center'">
							<span [style.fontSize]="(col.fontSize ?? 10) * fRate + 'pt'"
								[style.textAlign]="col.align ?? 'center'"
								[style.lineHeight]="(col.fontSize ? col.fontSize + 2 : 12) * fRate + 'pt'"
								[style.fontWeight]="col.fontWeight ?? 'normal'" [style.maxHeight]="'100%'"
								[style.writingMode]="col.textVertical ? 'vertical-rl' : 'horizontal-tb'"
								[innerHTML]="col.value ?? '' | safeHtml "></span>

							<img *ngFor="let item of col.images" [src]="'data:image/*;base64,' + item.data" alt=""
								[style.position]="'absolute'" [style.top]="item.top + 'pt'"
								[style.left]="item.left + 'pt'" [style.height]="(item.bottom! - item.top!) + 'pt'"
								[style.width]="(item.right! - item.left!) + 'pt'"
								[alt]="'dx1:' + item.dx1 + ',dy1:' + item.dy1 + ',dx2:' + item.dx2 + ',dy2:' + item.dy2 + ', item.left:' + item.left + ',item.right:' + item.right + ',item.top:' + item.top + ',item.bottom:' + item.bottom">
						</div>
					</td>
				</tr>
				<div class="print-border top {{isVertical ? 'vertical' : 'horizontal'}}">
				</div>
				<div class="print-border left {{isVertical ? 'vertical' : 'horizontal'}}">
				</div>
				<div class="print-border right {{isVertical ? 'vertical' : 'horizontal'}}">
				</div>
				<div class="print-border bottom {{isVertical ? 'vertical' : 'horizontal'}}">
				</div>
				<div class="print-border-paper top {{isVertical ? 'vertical' : 'horizontal'}}">
				</div>
				<div class="print-border-paper left {{isVertical ? 'vertical' : 'horizontal'}}">
				</div>
				<div class="print-border-paper right {{isVertical ? 'vertical' : 'horizontal'}}">
				</div>
				<div class="print-border-paper bottom {{isVertical ? 'vertical' : 'horizontal'}}">
				</div>
			</tbody>
		</table>
	</nz-tab>
</nz-tabset>