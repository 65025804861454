import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzTreeNode } from '../../models/tree-node.model';
import { EventService, EventType } from '../../providers/event.service';
import { GlobalService } from '../../providers/global.service';
import { ProjectItemType } from '../../models/project/project-item-type.enum';
import { ProjectSectionItem } from '../../models/project/project-section-item.model';
import { OrganizationType } from '../../models/staff/organization-type.enum';

@Component({
  selector: 'wbs-cascader',
  templateUrl: './wbs-cascader.component.html',
  styleUrls: ['./wbs-cascader.component.scss'],
})
export class WbsCascaderComponent {
  onChange() {
    const section = this.globalService.wbsTitleTree;
    const sectionIds: number[] = [];
    for (const item of this.globalService.orgInfo.projectSections ?? []) {
      sectionIds.push(item.sectionId!);
    }
    const treeNodes: NzTreeNode[] = [];
    for (const item of section?.child ?? []) {
      if (
        !sectionIds.includes(item.sectionId!) &&
        this.globalService.orgInfo.type !== OrganizationType.OPERATE
      ) {
        continue;
      }
      treeNodes.push(this.buildTreeNodes(item, true));
    }
    this.unitTreeNodes = treeNodes;
    this.globalService.selectUnitCache = this.globalService.selectUnitCache ?? [
      this.unitTreeNodes[0].value,
      this.unitTreeNodes[0].value,
    ];
    if (this._unitCascaderValue.length === 0) {
      this._unitCascaderValue = this.globalService.selectUnitCache;
      this.unitCascaderValueChange.emit(this._unitCascaderValue);
    }
  }

  subsciption = () => this.onChange();

  @Input()
  set unitCascaderValue(value: any[]) {
    this._unitCascaderValue = value;
  }

  get unitCascaderValue(): any[] {
    return this._unitCascaderValue;
  }

  _unitCascaderValue: any[] = [];

  @Output() unitCascaderValueChange = new EventEmitter<any[]>();

  unitTreeNodes: NzTreeNode[] = [];

  constructor(
    private globalService: GlobalService,
    private events: EventService
  ) {}

  ngOnInit(): void {
    this.events.on(EventType.WbsTitleChangeEvent, this.subsciption);
    this.onChange();
  }

  ngOnDestroy(): void {
    this.events.destroyListener(
      EventType.WbsTitleChangeEvent,
      this.subsciption
    );
  }

  onUnitCascaderValueChange() {
    if (this._unitCascaderValue.length !== 0) {
      this.unitCascaderValueChange.emit(this._unitCascaderValue);
    }
  }

  onUnitCascaderClear() {
    this.unitCascaderValue = [];
    this.onUnitCascaderValueChange();
  }

  private buildTreeNodes(
    item: ProjectSectionItem,
    hasAllNode?: boolean
  ): NzTreeNode {
    const node: NzTreeNode = {
      label: (item.code ?? '') + ' ' + item.name,
      value: `${item.type}#${
        item.type === ProjectItemType.SECTION ||
        item.type === ProjectItemType.PROJECT_SECTION
          ? item.id
          : item.code
      }`,
      children:
        hasAllNode && item.child
          ? [
              {
                label: '全部',
                value: `${item.type}#${
                  item.type === ProjectItemType.SECTION ||
                  item.type === ProjectItemType.PROJECT_SECTION
                    ? item.id
                    : item.code
                }`,
                isLeaf: true,
              },
            ]
          : [],
      isLeaf: false,
    };
    for (const child of item.child ?? []) {
      node.children!.push(this.buildTreeNodes(child, hasAllNode));
    }
    if (node.children?.length === 0) {
      node.isLeaf = true;
    }
    return node;
  }
}
