import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService, ModalService } from 'ng-devui';
import {
  AlertDialogComponent,
  AlertDialogType,
} from 'src/app/modules/shared/components/alert-dialog/alert-dialog.component';
import { FilePreviewTypeListMap } from 'src/app/modules/shared/components/file-preview-entry/file-preview-entry.component';
import { FileType } from 'src/app/modules/shared/models/form/file-type.enum';
import { WorkReportDocType } from 'src/app/modules/shared/models/form/work-report-doc-type.enum';
import { WorkReport } from 'src/app/modules/shared/models/form/work-report.model';
import { WorkReportService } from 'src/app/modules/shared/services/work-report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-custom-menu-form-preview',
  templateUrl: './custom-menu-form-preview.component.html',
  styleUrls: ['./custom-menu-form-preview.component.scss'],
})
export class CustomMenuFormPreviewComponent {
  @Input('config')
  data?: WorkReportFilePreviewConfig;

  FilePreviewTypeListMap = FilePreviewTypeListMap;
  FileType = FileType;
  // transformableImageElementRef!: TransformableElement;

  currentFileId = '';

  currentFileType = FileType.PDF;

  environment = environment;

  height = 0;
  width = 0;
  // index = 0;

  pdfZoom = 1;
  pdfPages = 1;
  pdfTotal = 0;

  workReport: WorkReport = new WorkReport();

  WorkReportDocType = WorkReportDocType;

  loading: any;
  singlePage = false;
  resp: any = {};

  constructor(
    private elementRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private workReportService: WorkReportService,
    private loadingService: LoadingService
  ) {
    this.loading = this.loadingService.open();
  }

  @HostListener('window:message', ['$event'])
  handleMessage($event: any) {
    if ($event.origin !== location.origin) {
      return;
    }
    if ($event.data.type === 'redir') {
      console.log($event.data);
      this.resp = $event.data;
    }
  }

  ngOnInit(): void {
    if (this.data && this.data.workReport) {
      this.workReport = this.handleDataBeforeInit(this.data.workReport);
      this.prepare();
      console.log(this.workReport);
    } else {
      this.singlePage = true;
      this.workReportService
        .findById(this.activatedRoute.snapshot.params['id'])
        .subscribe((response) => {
          this.workReport = this.handleDataBeforeInit(response);
          this.prepare();
          console.log(this.workReport);
        });
    }
  }

  private prepare() {
    if (this.workReport.docType === WorkReportDocType.PDF_SIGNED) {
      this.workReport.docPdfId = this.workReport.signFilesList![0];
    } else {
      if (
        this.workReport.signFilesList &&
        this.workReport.signFilesList.length > 0
      ) {
        this.workReport.docPdfId = this.workReport.signFilesList![0];
      } else if (
        this.workReport.signHalfFilesList &&
        this.workReport.signHalfFilesList.length > 0
      ) {
        this.workReport.docPdfId = this.workReport.signHalfFilesList![0];
      } else {
        this.workReport.docPdfId = this.workReport.pdfFilesList![0];
      }
    }
    this.currentFileId = this.workReport.docPdfId ?? '';
  }

  chooseMain() {
    this.currentFileId = this.workReport.docPdfId!;
    this.currentFileType = FileType.PDF;
  }

  downloadAttach(index: number) {
    const downloadLink = document.createElement('a');
    const fileUrl =
      environment.fileHost + this.workReport.attachs![index].fileId!;
    downloadLink.href = fileUrl;
    downloadLink.target = '_blank';
    downloadLink.download = this.workReport.attachs![index].fileName ?? '';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  chooseAttach(index: number) {
    this.currentFileId = this.workReport.attachs![index].fileId!;
    this.currentFileType = this.workReport.attachs![index].fileType!;
  }

  private handleDataBeforeInit(response: WorkReport): WorkReport {
    const data = JSON.parse(JSON.stringify(response)) as WorkReport;
    try {
      if (data.pdfFiles) {
        data.pdfFilesList = JSON.parse(data.pdfFiles);
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (data.signHalfFiles) {
        data.signHalfFilesList = JSON.parse(data.signHalfFiles);
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (data.signFiles) {
        data.signFilesList = JSON.parse(data.signFiles);
      }
    } catch (e) {
      console.log(e);
    }
    return data;
  }

  ngAfterViewInit(): void {
    window.addEventListener('resize', () => {
      this.resize();
    });
    setTimeout(() => {
      this.resize();
    }, 100);
  }

  dismiss(changed?: boolean) {
    if (this.singlePage) {
      if (window.opener) {
        window.opener.postMessage(this.resp);
      }
      window.close();
    } else {
      this.data?.onDismiss(changed);
    }
  }

  onPageLoad(event: any) {
    this.loading.loadingInstance.close();
    this.pdfTotal = event.source.pdfDocument._pdfInfo.numPages;
    this.resize();
  }

  prePage() {
    if (this.pdfPages > 1) {
      this.pdfPages--;
    }
  }

  nextPage() {
    if (this.pdfPages < this.pdfTotal) {
      this.pdfPages++;
    }
  }

  originSize() {
    this.pdfZoom = 1;
  }

  zoomIn() {
    this.pdfZoom += 0.1;
    if (this.pdfZoom > 2) {
      this.pdfZoom = 2;
    }
  }

  zoomOut() {
    this.pdfZoom -= 0.1;
    if (this.pdfZoom < 0.5) {
      this.pdfZoom = 0.5;
    }
  }

  download(index: number) {
    let url = '';
    try {
      if (index === 0) {
        url = this.workReport.signFilesList![0];
      }
      if (index === 1) {
        url = this.workReport.signHalfFilesList![0];
      }
      if (index === 2) {
        url = this.workReport.pdfFilesList![0];
      }
      if (index === 3) {
        url = this.workReport.docId!;
      }
    } catch (e) {
      console.log(e);
    }
    if (url) {
      const downloadLink = document.createElement('a');
      const fileUrl = environment.fileHost + url;
      downloadLink.href = fileUrl;
      downloadLink.target = '_blank';
      downloadLink.download = this.workReport.docName ?? '';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }

  remove() {
    // TODO 为什么删除之后还存在
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '删除',
        content: '确认删除？',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.confirm,
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: () => {
          this.workReportService.remove(this.workReport.id!).subscribe(() => {
            this.dismiss(true);
          });
          results.modalInstance.hide();
        },
      },
    });
  }

  resize() {
    this.height = document.body.clientHeight;
    this.width =
      this.workReport.attachs && this.workReport.attachs.length > 0
        ? document.body.clientWidth - 300
        : document.body.clientWidth;
  }
}

export class WorkReportFilePreviewConfig {
  workReport!: WorkReport;
  disabled?: boolean = false;
  onDismiss!: (change?: boolean) => void;
}
