<table [style.width]="totalWidth + 'px'">
	<colgroup>
		<col span="1" *ngIf="config.hasCheckAll" [style.width]="(checkWidth / totalWidth) * 100 + '%'">
		<col span="1" *ngIf="config.hasIndex" [style.width]="(indexWidth / totalWidth) * 100 + '%'">
		<col span="1" *ngFor="let column of config.columns;" [style.width]="(column.width / totalWidth) * 100 + '%'">
	</colgroup>
	<thead class="sticky" [style.top]="0">
		<tr>
			<th [colSpan]="config.columns.length + (config.hasCheckAll ? 1 : 0) + (config.hasIndex ? 1 : 0)">
				<ng-content></ng-content>
			</th>
		</tr>
		<tr class="{{hasGroup ? 'has-group' : ''}}">
			<th *ngIf="config.hasCheckAll" [rowSpan]="hasGroup ? 2 : 1"
				class="{{config.freezeColumn! > 0 ? 'left-sticky' :''}}" [style.left]="0 + 'px'">
				<div class="cell">
					<input type="checkbox" (change)="onCheckAllChange()" [(ngModel)]="_isCheckAll">
				</div>
			</th>
			<th *ngIf="config.hasIndex" [rowSpan]="hasGroup ? 2 : 1"
				class="{{config.freezeColumn! > 0 ? 'left-sticky' :''}}"
				[style.left]="(!config.freezeColumn ? 0 : config.hasCheckAll ? checkWidth : 0) + 'px'">
				<div class="cell">
					<span nz-icon nzType="filter" nzTheme="fill" *ngIf="queryRow > 0 && config.hasIndex"
						class="state-toggle {{isQueryOpen ? 'on' : ''}}" (click)="queryPanelToggle()"></span>
					<span (click)="queryPanelToggle()">序号</span>
				</div>
			</th>
			<th *ngFor="let column of firstTitleRow;" [rowSpan]="hasGroup ? column.isGroup ? 1 : 2 : 1"
				[colSpan]="column.colspan"
				class="{{config.freezeColumn! > column.index ? 'left-sticky' :''}} {{column.separator ? 'separator' : ''}} {{config.freezeColumn! === column.index + 1 ? 'freeze' : ''}}"
				[style.left]="(config.freezeColumn! > column.index && culumnPositionsStart.length > column.index ? culumnPositionsStart[column.index!] : 0) + 'px'">
				<div class="cell">
					<span nz-icon nzType="filter" nzTheme="fill"
						*ngIf="queryRow > 0 && !config.hasIndex && column.index === 0"
						class="state-toggle {{isQueryOpen ? 'on' : ''}} filter" (click)="queryPanelToggle()"></span>
					<span class="color-warning" *ngIf="column.headerComment">? </span>

					<span nz-popover nzType="primary" [nzPopoverContent]="column.headerComment"
						class="{{column.headerLink? 'underline-link' : ''}}  {{!!column.headerComment ? 'underline-comment' :''}}"
						(click)="linkto(column.headerLink)" [innerHtml]="column.title">
					</span>

					<span
						class="state-toggle {{!!tableSearchConfig[column.index].sort && column.index === highLightIndex ? 'on' : ''}}"
						*ngIf="column.sortable" (click)="changeSortType(column.index)" nz-icon
						[nzType]="tableSearchConfig[column.index!].sort === columnSortType.ASC ? 'arrow-up' : 'arrow-down'"
						nzTheme="outline">
					</span>
				</div>
			</th>
		</tr>
		<tr *ngIf="hasGroup" class="{{hasGroup ? 'has-group' : ''}}">
			<th *ngFor="let column of secondTitleRow"
				class="{{config.freezeColumn! > column.index ? 'left-sticky' :''}} {{column.separator ? 'separator' : ''}} {{config.freezeColumn! === column.index + 1 ? 'freeze' : ''}}"
				[style.left]="(config.freezeColumn! > column.index && culumnPositionsStart.length > column.index ? culumnPositionsStart[column.index!] : 0) + 'px'">
				<div class="cell">
					<span nz-icon nzType="filter" nzTheme="fill"
						*ngIf="queryRow > 0 && !config.hasIndex && column.index === 0"
						class="state-toggle {{isQueryOpen ? 'on' : ''}} filter" (click)="queryPanelToggle()"></span>
					<span class="color-warning" *ngIf="column.headerComment">? </span>

					<span nz-popover nzType="primary" [nzPopoverContent]="column.headerComment"
						class="{{column.headerLink? 'underline-link' : ''}}  {{!!column.headerComment ? 'underline-comment' :''}}"
						(click)="linkto(column.headerLink)" [innerHtml]="column.title">
					</span>

					<span
						class="state-toggle {{!!tableSearchConfig[column.index].sort && column.index === highLightIndex ? 'on' : ''}}"
						*ngIf="column.sortable" (click)="changeSortType(column.index)" nz-icon
						[nzType]="tableSearchConfig[column.index!].sort === columnSortType.ASC ? 'arrow-up' : 'arrow-down'"
						nzTheme="outline">
					</span>
				</div>
			</th>
		</tr>
	</thead>

	<!-- <tbody>
		<tr *ngFor="let row of item.rows; let rowIndex = index;" [style.height]="(row.height! * hRate) + 'pt'">
			<td *ngFor="let col of row.columns; let colIndex = index;" [colSpan]="col.colSpan ?? 1"
				[rowSpan]="col.rowSpan ?? 1" [style.height]="(row.height! * hRate) + 'pt'" class="{{col.borderStyle?.top ? 'border-top' : ''}} {{col.borderStyle?.right ? 'border-right' : ''}} 
			{{col.borderStyle?.bottom ? 'border-bottom' : ''}} {{col.borderStyle?.left ? 'border-left' : ''}} {{col.formItem?.hint ? 'hint' : ''}} {{col.wrapText ? 'wrap-text' : ''}}
			{{col.rowIndex === selectedRowIndex && col.colIndex === selectedColIndex ? 'active' : ''}} "
				(click)="cellSelected(col)" [nzTooltipTitle]="col.formItem?.hint" [nzTooltipPlacement]="'top'"
				nz-tooltip>
			</td>
		</tr>
	</tbody> -->
</table>