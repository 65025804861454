<div class="entry">

	<div class="cover" (click)="preview()">
		<img *ngIf="fileType !== FilePreviewType.Image" [src]="!!cover ? cover : FilePreviewTypeListMap[fileType].cover"
			alt="">
		<img *ngIf="fileType === FilePreviewType.Image"
			[src]="fileList.length === 0 ? FilePreviewTypeListMap[fileType].cover : environment.fileHost + fileList[0]"
			alt="">
	</div>

	<div class="progress" *ngIf="progress.uploading" [style.width]="(100 - progress.progress) + '%'">
	</div>

	<div class="add {{required ? 'color-danger' : 'color-primary'}}"
		*ngIf="fileList.length === 0 && !progress.uploading && !disabled">
		<span nz-icon nzType="plus-circle" nzTheme="outline"></span>
		{{addText}}
	</div>

	<div class="count" *ngIf="fileList.length > 1">
		{{fileList.length}}
	</div>

	<input class="file" type="file" [accept]="FilePreviewTypeListMap[fileType].accept"
		*ngIf="fileList.length === 0 && !progress.uploading && !disabled" (change)="upload($event)"
		[multiple]="limit > 1" [disabled]="disabled">


</div>