export enum FormItemType {
  CONSTANT = 'CONSTANT',
  VARIABLES = 'VARIABLES',
}

export const FormItemTypeMap = {
  [FormItemType.CONSTANT]: '常量',
  [FormItemType.VARIABLES]: '变量',
};

export const FormItemTypeList = [
  {
    value: FormItemType.CONSTANT,
    label: FormItemTypeMap[FormItemType.CONSTANT],
  },
  {
    value: FormItemType.VARIABLES,
    label: FormItemTypeMap[FormItemType.VARIABLES],
  },
];
