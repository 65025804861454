export enum FormFlowType {
  FINISH = 'FINISH',
  REJECT = 'REJECT',
  CANCEL = 'CANCEL',
  FILL_IN = 'FILL_IN',
  SIGN = 'SIGN',
  SEAL = 'SEAL',
  CC = 'CC',
  PRESENT = 'PRESENT',
  SIGN_SEAL = 'SIGN_SEAL',
}

export const FormFlowTypeMap = {
  [FormFlowType.FILL_IN]: '填写',
  [FormFlowType.SIGN]: '签字',
  [FormFlowType.SEAL]: '盖章',
  [FormFlowType.SIGN_SEAL]: '签章', // ['签字', '盖章']
  [FormFlowType.CC]: '抄送',
  [FormFlowType.PRESENT]: '呈递',
  [FormFlowType.CANCEL]: '撤回',
  [FormFlowType.FINISH]: '完成',
  [FormFlowType.REJECT]: '退回',
};

export const FormFlowTypeList = [
  {
    value: FormFlowType.FILL_IN,
    label: FormFlowTypeMap[FormFlowType.FILL_IN],
  },
  {
    value: FormFlowType.SIGN,
    label: FormFlowTypeMap[FormFlowType.SIGN],
  },
  {
    value: FormFlowType.SEAL,
    label: FormFlowTypeMap[FormFlowType.SEAL],
  },
  {
    value: FormFlowType.SIGN_SEAL,
    label: FormFlowTypeMap[FormFlowType.SIGN_SEAL],
  },
  {
    value: FormFlowType.CC,
    label: FormFlowTypeMap[FormFlowType.CC],
  },
  {
    value: FormFlowType.PRESENT,
    label: FormFlowTypeMap[FormFlowType.PRESENT],
  },
  {
    value: FormFlowType.CANCEL,
    label: FormFlowTypeMap[FormFlowType.CANCEL],
  },
  {
    value: FormFlowType.FINISH,
    label: FormFlowTypeMap[FormFlowType.FINISH],
  },
  {
    value: FormFlowType.REJECT,
    label: FormFlowTypeMap[FormFlowType.REJECT],
  },
];
