import { Component, HostListener } from '@angular/core';
import {
  Router,
  ActivationEnd,
  NavigationEnd,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { filter, pluck, buffer, map } from 'rxjs';
import { BreadcrumbService } from 'src/app/modules/shared/providers/breadcrumb.service';
import { GlobalService } from 'src/app/modules/shared/providers/global.service';
import { CharWidthCalcUtil } from 'src/app/modules/shared/utils/char-width-calc.util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private router: Router,
    private globalService: GlobalService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof ActivationEnd),
        pluck('snapshot'),
        buffer(
          this.router.events.pipe(
            filter((ev: any) => ev instanceof NavigationEnd)
          )
        ),
        map((events: ActivatedRouteSnapshot[]) => events.reverse())
      )
      .subscribe((x: ActivatedRouteSnapshot[]) => {
        this.breadcrumbService.snapshots = x;
      });
    setTimeout(() => {
      this.calcCharWidth();
    }, 100);
  }

  @HostListener('window:keyup', ['$event'])
  keyEventUp(event: KeyboardEvent) {
    this.globalService.metaPressing = event.metaKey;
    this.globalService.ctrlPressing = event.ctrlKey;
    this.globalService.shiftPressing = event.shiftKey;
  }

  @HostListener('window:keydown', ['$event'])
  keyEventDown(event: KeyboardEvent) {
    this.globalService.metaPressing = event.metaKey;
    this.globalService.ctrlPressing = event.ctrlKey;
    this.globalService.shiftPressing = event.shiftKey;
  }

  private calcCharWidth() {
    for (const i of CharWidthCalcUtil.ascii) {
      this.globalService.s12[i] = CharWidthCalcUtil.calcCharWidth(i, 12);
      this.globalService.s13[i] = CharWidthCalcUtil.calcCharWidth(i, 13);
      this.globalService.s14[i] = CharWidthCalcUtil.calcCharWidth(i, 14);
      this.globalService.s16[i] = CharWidthCalcUtil.calcCharWidth(i, 16);
      this.globalService.s12[' '] =
        CharWidthCalcUtil.calcCharWidth('1 2', 12) -
        this.globalService.s12['1'] -
        this.globalService.s12['2'];
      this.globalService.s13[' '] =
        CharWidthCalcUtil.calcCharWidth('1 2', 13) -
        this.globalService.s13['1'] -
        this.globalService.s13['2'];
      this.globalService.s14[' '] =
        CharWidthCalcUtil.calcCharWidth('1 2', 14) -
        this.globalService.s14['1'] -
        this.globalService.s14['2'];
      this.globalService.s16[' '] =
        CharWidthCalcUtil.calcCharWidth('1 2', 16) -
        this.globalService.s16['1'] -
        this.globalService.s16['2'];
    }
  }
}
