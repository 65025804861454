import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProjectSectionItemTreeRequest } from '../models/project/project-section-item-tree-request.model';
import { ProjectSectionItem } from '../models/project/project-section-item.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectSectionItemService extends RequestService<ProjectSectionItem> {
  static PATH = '/project/section/item';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, ProjectSectionItemService.PATH, globalService.debugUrl);
  }

  tree(params: ProjectSectionItemTreeRequest) {
    return this.httpClient.post<ProjectSectionItem>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${ProjectSectionItemService.PATH}/tree`,
      params
    );
  }
}
