import { Pipe, PipeTransform } from '@angular/core';
import { FormItem } from '../models/form/form-item.model';

@Pipe({
  name: 'tableItemFilter',
})
export class TableItemFilterPipe implements PipeTransform {
  transform(items: FormItem[], tableId: number): FormItem[] {
    return items.filter((item) => item.tableId === tableId);
  }
}
