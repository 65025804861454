import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MaterialModel } from '../models/material/material-model.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class MaterialModelService extends RequestService<MaterialModel> {
  static PATH = '/material/model';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, MaterialModelService.PATH, globalService.debugUrl);
  }

  generateCode(param: MaterialModel) {
    return this.httpClient.post<string>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${MaterialModelService.PATH}/generateCode`,
      param
    );
  }
}
