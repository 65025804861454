import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MenuForm } from '../models/menu/menu-form.model';
import { GlobalService } from '../providers/global.service';
import { FileResponse } from '../providers/request/file-response.model';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class OmMenuFormService extends RequestService<MenuForm> {
  static PATH = '/om/menu/form';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, OmMenuFormService.PATH, globalService.debugUrl);
  }

  uploadFileWithoutProgress(file: File, formId?: number) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('formId', formId?.toString() ?? '-1');
    return this.httpClient.post<FileResponse>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmMenuFormService.PATH}/upload`,
      formData
    );
  }
}
