import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DialogConfig } from 'src/app/modules/shared/components/alert-dialog/dialog-config';
import { TableColumnOption } from 'src/app/modules/shared/components/table-pro/table.config';
import { WorkFlowRefectSignRequest } from 'src/app/modules/shared/models/form/work-flow-refect-sign-request.model';
import { WorkFlowUserService } from 'src/app/modules/shared/services/work-flow-user.service';

@Component({
  selector: 'app-dialog-counter-sign-reject',
  templateUrl: './dialog-counter-sign-reject.component.html',
  styleUrls: ['./dialog-counter-sign-reject.component.scss'],
})
export class DialogCounterSignRejectComponent {
  @Input()
  data!: DialogConfig<{
    options: { value: number; label: string }[];
    workFlowId: number;
  }>;

  submiting = false;

  selected: number[] = [];

  record: WorkFlowRefectSignRequest = new WorkFlowRefectSignRequest();

  constructor(
    private message: NzMessageService,
    private workFlowUserService: WorkFlowUserService
  ) {}

  ngOnInit() {
    this.record.workFlowId = this.data.data!.workFlowId;
    this.record.rejectReason = '';
    this.record.togetherWorkFlowIds = [];
    this.selected = [];
    if (this.data.data!.options.length === 1) {
      this.selected.push(this.data.data!.options![0].value! as number);
    }
  }

  isNaN(content: number) {
    return window.isNaN(content);
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    this.submit();
  }

  choose(flowId: number) {
    let index = this.selected.indexOf(flowId);
    if (index > -1) {
      this.selected.splice(index, 1);
    } else {
      this.selected.push(flowId);
    }
  }

  private handleDataBeforeConfirm(
    response: WorkFlowRefectSignRequest
  ): WorkFlowRefectSignRequest {
    const data = JSON.parse(
      JSON.stringify(response)
    ) as WorkFlowRefectSignRequest;
    data.togetherWorkFlowIds = this.selected;
    return data;
  }

  get canConfirm() {
    if (this.submiting || !this.record.rejectReason) {
      return false;
    }

    return true;
  }

  private submit() {
    this.message.info('正在保存');
    this.submiting = true;

    this.workFlowUserService
      .rejectSign(this.handleDataBeforeConfirm(this.record))
      .subscribe((response: boolean) => {
        this.submiting = false;
        this.message.success('保存成功');
        if (response) {
          this.data.onConfirm!();
        }
      });
  }
}
