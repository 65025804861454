import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExcelTable } from 'src/app/modules/shared/models/form/excel-table.model';
import { CommonService } from '../../services/common.service';
import { ExcelTableRowColumn } from 'src/app/modules/shared/models/form/excel-table-row-column.model';
import { FormItem } from '../../models/form/form-item.model';
import {
  AlignMap,
  JustifyMap,
} from '../../models/form/cell-alignment-type.enum';

@Component({
  selector: 'spreadsheet-lite',
  templateUrl: './spreadsheet-lite.component.html',
  styleUrls: ['./spreadsheet-lite.component.scss'],
})
export class SpreadsheetLiteComponent implements OnInit {
  selectedRowIndex = -1;
  selectedColIndex = -1;
  tabId = -1;

  rowPadding = 40;
  colPadding = 40;

  t2pt = 0.02556;

  AlignMap = AlignMap;
  JustifyMap = JustifyMap;

  get wRate() {
    if (!this._config) {
      return 1;
    }
    if (this.isVertical) {
      if (this._config[0].height! > 800) {
        return 1.05;
      } else {
        return 1.01;
      }
    } else {
      if (this._config[0].height! > 550) {
        return 1.08;
      } else {
        return 1.02;
      }
    }
  }

  get fileType() {
    if (!this._config) {
      return '';
    }
    if (this.isVertical) {
      if (this._config[0].height! > 800) {
        return '超长';
      } else {
        return '正常';
      }
    } else {
      if (this._config[0].height! > 550) {
        return '超长';
      } else {
        return '正常';
      }
    }
  }

  get hRate() {
    if (!this._config) {
      return 1;
    }
    if (this.isVertical) {
      if (this._config[0].height! > 800) {
        return 0.85;
      } else {
        return 0.97;
      }
    } else {
      if (this._config[0].height! > 550) {
        return 0.85;
      } else {
        return 0.97;
      }
    }
  }
  fRate = 1;

  // @Input()
  get isVertical() {
    if (!this._config) {
      return true;
    }
    return this._config[0].height! > this._config[0].width!;
  }

  @Output()
  onCellSelected = new EventEmitter<ExcelTableRowColumn>();

  @Input()
  previewMode = false;

  @Input()
  set config(value: ExcelTable[]) {
    console.log(value);
    this._config = value;
    if (value.length > 0) {
      this.tabId = this._config[0].tableId!;
    }
  }

  get config(): ExcelTable[] {
    return this._config;
  }

  @Input()
  set selectedCellFormItem(value: FormItem) {
    if (value === undefined) {
      return;
    }
    for (const item of this._config) {
      for (const r of item.rows ?? []) {
        for (const c of r.columns ?? []) {
          if (c.formItem?.id === value.id) {
            this.tabId = item.tableId!;
            this.selectedRowIndex = c.rowIndex!;
            this.selectedColIndex = c.colIndex!;
            return;
          }
        }
      }
    }
  }

  // result: { [key: string]: any } = {};

  private _config!: ExcelTable[];

  constructor(private commonService: CommonService) {
    console.log(window.devicePixelRatio);
  }

  ngOnInit(): void {}

  upload(event: any, key: string) {
    const files = event.target['files'];
    console.log(files);
    // this.commonService
    //   .uploadFileWithoutProgress(files[0])
    //   .subscribe((url: string) => {
    //     if (url) {
    //       this.result[key] = url;
    //     }
    //   });
  }

  copy(configIndex: number, rowIndex: number) {
    for (const r of this._config[configIndex].rows ?? []) {
      for (const c of r.columns ?? []) {
        if (c.firstRow! < rowIndex && c.lastRow! >= rowIndex) {
          c.lastRow!++;
          c.rowSpan!++;
        }
      }
    }
    const rowCopy = JSON.parse(
      JSON.stringify(this._config[configIndex].rows![rowIndex])
    );
    this._config[configIndex].rows!.splice(rowIndex, 0, rowCopy);
  }

  cellSelected(cell: ExcelTableRowColumn) {
    this.selectedRowIndex = cell.rowIndex!;
    this.selectedColIndex = cell.colIndex!;
    this.onCellSelected.emit(cell);
  }

  submit() {
    console.log(this.config);
  }

  cellPos(n: number) {
    const ordA = 'A'.charCodeAt(0);
    const ordZ = 'Z'.charCodeAt(0);
    const len = ordZ - ordA + 1;
    let s = '';
    while (n >= 0) {
      s = String.fromCharCode((n % len) + ordA) + s;
      n = Math.floor(n / len) - 1;
    }
    return s;
  }
}
