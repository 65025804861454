import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzCascaderOption } from 'ng-zorro-antd/cascader';
import { FormFlowPostInfo } from '../../models/form/form-flow-post-info.model';

@Component({
  selector: 'flow-position-cascader',
  templateUrl: './flow-position-cascader.component.html',
  styleUrls: ['./flow-position-cascader.component.scss'],
})
export class FlowPositionCascaderComponent {
  @Input()
  set flowPositionInfo(value: FormFlowPostInfo) {
    this._flowPositionInfo = value;
    this.postCascaderValue = [];
    if (value) {
      if (value.organizationType) {
        this.postCascaderValue.push(value.organizationType);
      }
      if (value.departmentId) {
        this.postCascaderValue.push(value.departmentId);
      }
      if (value.postId) {
        this.postCascaderValue.push(value.postId);
      }
    }
  }

  get flowPositionInfo(): FormFlowPostInfo {
    return this._flowPositionInfo;
  }

  private _flowPositionInfo!: FormFlowPostInfo;

  @Input()
  set options(value: NzCascaderOption[]) {
    this._options = value ?? [];
  }

  get options(): NzCascaderOption[] {
    return this._options;
  }

  private _options: NzCascaderOption[] = [];

  postCascaderValue: any[] = [];

  @Output()
  onInfoChange: EventEmitter<FormFlowPostInfo> =
    new EventEmitter<FormFlowPostInfo>();

  constructor() {}

  onPostCascaderValueChange() {
    if (this.postCascaderValue && this.postCascaderValue.length > 1) {
      this._flowPositionInfo.organizationType = this.postCascaderValue[0];
      this._flowPositionInfo.departmentId = this.postCascaderValue[1];
      this._flowPositionInfo.postId = this.postCascaderValue[2];
    } else {
      this._flowPositionInfo.organizationType = undefined;
      this._flowPositionInfo.departmentId = undefined;
      this._flowPositionInfo.postId = undefined;
    }
    this.onInfoChange.emit(this._flowPositionInfo);
  }
}
