import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DialogConfig } from 'src/app/modules/shared/components/alert-dialog/dialog-config';
import { CheckSignAuthRequest } from 'src/app/modules/shared/models/staff/check-sign-auth-request.model';
import {
  SignAuthMode,
  SignAuthModeMap,
} from 'src/app/modules/shared/models/staff/sign-auth-mode.enum';
import { SmsCodeType } from 'src/app/modules/shared/models/staff/sms-code-type.enum';
import { OmUserPersonService } from 'src/app/modules/shared/services/om-user-person.service';
import { OpenApiSignOrderService } from 'src/app/modules/shared/services/open-api-signorder.service';
import { Md5 } from 'ts-md5';
@Component({
  selector: 'app-dialog-sign-pwd',
  templateUrl: './dialog-sign-pwd.component.html',
  styleUrls: ['./dialog-sign-pwd.component.scss'],
})
export class DialogSignPwdComponent {
  @Input() data!: DialogConfig<CheckSignAuthRequest>;

  SignAuthModeMap = SignAuthModeMap;
  m: string = '';
  title: string = '';
  constructor(
    private omUserPersonService: OmUserPersonService,
    private openApiSignOrderService: OpenApiSignOrderService,
    private message: NzMessageService
  ) {}

  ngOnInit() {
    this.title =
      this.data.data?.signAuthMode === SignAuthMode.SIGN_PWD
        ? '请输入签名密码'
        : '请输入验证码';
    if (this.data.data?.signAuthMode !== SignAuthMode.SIGN_PWD) {
      if (this.data.data?.token) {
        this.openApiSignOrderService
          .sendSmsCode({
            token: this.data.data.token,
            type: SmsCodeType.SIGN_AUTH,
          })
          .subscribe((res) => {
            if (res) {
              this.message.info('验证码已发送');
            } else {
              this.message.error('验证码发送失败');
            }
          });
      } else {
        this.omUserPersonService
          .sendSmsCode(SmsCodeType.SIGN_AUTH)
          .subscribe((res) => {
            if (res) {
              this.message.info('验证码已发送');
            } else {
              this.message.error('验证码发送失败');
            }
          });
      }
    }
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    if (this.data.data?.token) {
      this.openApiSignOrderService
        .checkSignAuthInfo({
          signAuthMode: this.data.data?.signAuthMode,
          signPwd:
            this.data.data?.signAuthMode === SignAuthMode.SIGN_PWD
              ? Md5.hashStr(this.m).toString()
              : this.m,
          token: this.data.data.token,
        })
        .subscribe((res) => {
          if (res) {
            this.data.onConfirm!();
          }
        });
    } else {
      this.omUserPersonService
        .checkSignAuthInfo({
          signAuthMode: this.data.data?.signAuthMode,
          signPwd:
            this.data.data?.signAuthMode === SignAuthMode.SIGN_PWD
              ? Md5.hashStr(this.m).toString()
              : this.m,
        })
        .subscribe((res) => {
          if (res) {
            this.data.onConfirm!();
          }
        });
    }
  }
}
