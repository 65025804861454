<div class="dialog">
	<div class="dialog-header">{{record.formStarted ? 'GL105' : ''}}报告上传成功，请补充信息
	</div>
	<div class="dialog-body">
		<div class="grid">
			<div class="row validate">
				<div class="column background-page text-right" [style.width]="80 + 'px'">
					<span class="color-danger">*</span> 编号
				</div>
				<div class="column" [style.width]="250 + 'px'">
					<input type="text" [(ngModel)]="record.sn">
				</div>
			</div>

			<div class="row validate" *ngIf="record.formStarted">
				<div class="column background-page text-right" [style.width]="80 + 'px'">
					<span class="color-danger">*</span> 开工日期
				</div>
				<div class="column" [style.width]="250 + 'px'">
					<nz-date-picker [nzSuffixIcon]="''" [(ngModel)]="record.formStartDateDate"
						nzPlaceHolder="YYYY-MM-DD" nzFormat="yyyy-MM-dd">
					</nz-date-picker>
				</div>
			</div>

			<div class="row" *ngIf="record.formStarted">
				<div class="column background-page text-right" [style.width]="80 + 'px'">
					<span class="color-danger">*</span> 表单版本
				</div>
				<div class="column {{record.formVersion === FormVersion.OLD_VERSION ? 'color-danger' : ''}}"
					[style.width]="250 + 'px'">
					<select [(ngModel)]="record.formVersion" (ngModelChange)="onVersionChange()">
						<option *ngFor="let item of FormVersionList" [value]="item.value">{{item.label}}</option>
					</select>
				</div>
			</div>

			<div class="row {{record.formVersion === FormVersion.OLD_VERSION ? 'validate' : ''}}"
				*ngIf="record.formStarted">
				<div class="column background-page text-right" [style.width]="80 + 'px'">
					<span class="color-danger" *ngIf="record.formVersion === FormVersion.OLD_VERSION">*</span> 完工日期
				</div>
				<div class="column" [style.width]="250 + 'px'">
					<nz-date-picker [nzSuffixIcon]="''" [(ngModel)]="record.formFinishDateDate"
						nzPlaceHolder="YYYY-MM-DD" nzFormat="yyyy-MM-dd"
						[nzDisabled]="record.formVersion === FormVersion.NEW_VERSION">
					</nz-date-picker>
				</div>
			</div>


			<div *ngIf="record.formCompleted" class="row validate">
				<div class="column background-page text-right" [style.width]="80 + 'px'">
					<span class="color-danger">*</span> 完工日期
				</div>
				<div class="column" [style.width]="250 + 'px'">
					<nz-date-picker [nzSuffixIcon]="''" [(ngModel)]="record.formFinishDateDate"
						nzPlaceHolder="YYYY-MM-DD" nzFormat="yyyy-MM-dd">
					</nz-date-picker>
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-footer">
		<button nz-button nzType="default" (click)="cancel()">取消</button>
		<button nz-button nzType="primary" (click)="confirm()" [disabled]="!canConfirm">确认</button>
	</div>
</div>