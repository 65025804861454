import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MenuAudit } from '../models/menu/menu-audit.model';
import { CopyMenuAuditRequest } from '../models/staff/copy-menu-audit-request.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class OmMenuAuditService extends RequestService<MenuAudit> {
  static PATH = '/om/menu/audit';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, OmMenuAuditService.PATH, globalService.debugUrl);
  }

  batchUpdate(data: MenuAudit[]) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmMenuAuditService.PATH}/batchUpdate`,
      data
    );
  }

  copy(req: CopyMenuAuditRequest) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmMenuAuditService.PATH}/copy`,
      req
    );
  }
}
