export enum WorkReportDocType {
  DEFAULT = 'DEFAULT',
  EXCEL = 'EXCEL',
  WORD = 'WORD',
  PDF_WAIT_SIGN = 'PDF_WAIT_SIGN',
  PDF_SIGNED = 'PDF_SIGNED',
}

export const WorkReportDocTypeMap = {
  [WorkReportDocType.DEFAULT]: '默认',
  [WorkReportDocType.EXCEL]: 'Excel',
  [WorkReportDocType.WORD]: 'Word',
  [WorkReportDocType.PDF_WAIT_SIGN]: 'PDF待签',
  [WorkReportDocType.PDF_SIGNED]: 'PDF已签',
};
