export enum OrganizationCategory {
  COMPANY = 'COMPANY',
  INSTITUTION = 'INSTITUTION',
}

export const OrganizationCategoryMap = {
  [OrganizationCategory.COMPANY]: '单位',
  [OrganizationCategory.INSTITUTION]: '机构',
};

export const OrganizationCategoryList = [
  {
    value: OrganizationCategory.COMPANY,
    label: OrganizationCategoryMap[OrganizationCategory.COMPANY],
  },
  {
    value: OrganizationCategory.INSTITUTION,
    label: OrganizationCategoryMap[OrganizationCategory.INSTITUTION],
  },
];
