import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from 'ng-devui';
import { last, map } from 'rxjs';
import { GlobalService } from 'src/app/modules/shared/providers/global.service';
import { environment } from 'src/environments/environment';
import { EventService, EventType } from '../../providers/event.service';
import { FileResponse } from '../../providers/request/file-response.model';
import { UploadProgress } from '../../providers/request/upload-progress.type';
import { CommonService } from '../../services/common.service';
import {
  FilePreviewComponent,
  ViewScreenType,
} from './../file-preview/file-preview.component';

@Component({
  selector: 'file-preview-entry',
  templateUrl: './file-preview-entry.component.html',
  styleUrls: ['./file-preview-entry.component.scss'],
})
export class FilePreviewEntryComponent implements OnInit {
  @Input() fileType: FilePreviewType = FilePreviewType.All;
  @Input() fileList: string[] = [];
  @Input() limit: number = Infinity;
  @Input() addText: string = '';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() cover: string = '';

  @Output() fileChange = new EventEmitter<string[]>();

  FilePreviewTypeListMap = FilePreviewTypeListMap;
  FilePreviewType = FilePreviewType;

  environment = environment;

  uploadProgresses: UploadProgress[] = [];

  progress: UploadProgress = {
    progress: 0,
    uploading: false,
    msg: '',
  };

  constructor(
    private commonService: CommonService,
    private events: EventService,
    private globalService: GlobalService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {}

  preview() {
    if (this.fileList.length === 0) {
      return;
    }
    if (this.globalService.halfBottom) {
      this.events.broadcast(EventType.FilePreviewVerticalEvent, {
        fileList: this.fileList,
        previewType: this.fileType,
        limit: +this.limit,
        disabled: true,
        screenType: ViewScreenType.HalfBottom,
      });
    } else {
      const results = this.modalService.open({
        backdropCloseable: false,
        component: FilePreviewComponent,
        onClose: () => {},
        data: {
          fileList: this.fileList,
          previewType: this.fileType,
          limit: +this.limit,
          disabled: this.disabled,
          onDismiss: (changeToVertical: boolean) => {
            // this.fileList = fileList;
            // this.fileChange.emit(this.fileList);
            if (changeToVertical) {
              this.events.broadcast(EventType.FilePreviewVerticalEvent, {
                fileList: this.fileList,
                previewType: this.fileType,
                limit: +this.limit,
                disabled: true,
                screenType: ViewScreenType.HalfBottom,
              });
            }
            results.modalInstance.hide();
          },
          onFileChange: (fileList: string[]) => {
            this.fileList = fileList;
            this.fileChange.emit(this.fileList);
          },
        },
      });
    }
  }

  private getEventMessage(event: HttpEvent<any>, index: number) {
    if (event.type === HttpEventType.UploadProgress) {
      const percentDone = Math.round((100 * event.loaded) / (event.total ?? 0));
      this.uploadProgresses[index].progress = percentDone;
      this.uploadProgresses[index].uploading = true;
    }

    this.progress.progress =
      this.uploadProgresses.reduce((acc, cur) => acc + cur.progress, 0) /
      this.uploadProgresses.length;

    if (
      event.type === HttpEventType.ResponseHeader ||
      event.type === HttpEventType.Response
    ) {
      this.uploadProgresses[index].uploading = false;
    }

    let uploading = false;
    for (const p of this.uploadProgresses) {
      if (p.uploading) {
        uploading = true;
        break;
      }
    }
    this.progress.uploading = uploading;

    if (event.type === HttpEventType.Response) {
      return event.body;
    }
  }

  upload(event: any) {
    let count = 0;
    const files = event.target.files;
    this.uploadProgresses = [];
    for (const file of files) {
      if (count >= +this.limit) {
        break;
      }
      const index = count;
      count++;
      this.uploadProgresses.push({
        progress: 0,
        uploading: true,
        msg: '',
      });
      this.commonService
        .uploadFile(file)
        .pipe(
          map((event) => this.getEventMessage(event, index)),
          last()
        )
        .subscribe((data: FileResponse) => {
          console.log(data);
          event.target['value'] = '';
          if (data.fileId) {
            this.fileList.push(data.fileId);
            this.fileChange.emit(this.fileList);
          }
        });
    }
  }
}

export enum FilePreviewType {
  Image = 'Image',
  Excel = 'Excel',
  Word = 'Word',
  PPT = 'PPT',
  PDF = 'PDF',
  All = 'All',
}

export const FilePreviewTypeListMap = {
  [FilePreviewType.Image]: {
    type: FilePreviewType.Image,
    name: '图片',
    accept: 'image/*',
    cover: './assets/imgs/img-pics.png',
  },
  [FilePreviewType.Excel]: {
    name: '表格',
    accept: '.xlsx, .xls',
    cover: './assets/imgs/img-sheet.png',
  },
  [FilePreviewType.Word]: {
    name: '文档',
    accept: '.doc, .docx',
    cover: './assets/imgs/img-doc.png',
  },
  [FilePreviewType.PPT]: {
    name: '演示文稿',
    accept: '.ppt, .pptx',
    cover: './assets/imgs/img-slides.png',
  },
  [FilePreviewType.PDF]: {
    name: 'PDF',
    accept: '.pdf',
    cover: './assets/imgs/img-pdf.png',
  },
  [FilePreviewType.All]: {
    name: '任意文件',
    accept: '*',
    cover: './assets/imgs/img-file.png',
  },
};
