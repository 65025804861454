import { RecordBase } from '../../providers/request/record-base.model';

export class OrganizationMember extends RecordBase {
  projectId?: number;
  organizationId?: number;
  memberId?: number;
  memberName?: string;
  userId?: number;
  userName?: string;
  main?: boolean = false;
  sortOrder?: number;
  comment?: string;
  // 前端自用
  dpuValue: number[] = [];
}
