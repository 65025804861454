export enum DetectionType {
  DESIGN = 'DESIGN',
  BIAS = 'BIAS',
  REAL = 'REAL',
  PASS = 'PASS',
}

export const DetectionTypeMap = {
  [DetectionType.DESIGN]: '规定或设计值',
  [DetectionType.BIAS]: '偏差值',
  [DetectionType.REAL]: '实测值',
  [DetectionType.PASS]: '合格率',
};

export const DetectionTypeList = [
  {
    value: DetectionType.DESIGN,
    label: DetectionTypeMap[DetectionType.DESIGN],
  },
  {
    value: DetectionType.BIAS,
    label: DetectionTypeMap[DetectionType.BIAS],
  },
  {
    value: DetectionType.REAL,
    label: DetectionTypeMap[DetectionType.REAL],
  },
  {
    value: DetectionType.PASS,
    label: DetectionTypeMap[DetectionType.PASS],
  },
];
