import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProjectSectionUnit } from '../models/project/project-section-unit.model';
import { GlobalService } from '../providers/global.service';
import { FileResponse } from '../providers/request/file-response.model';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectWbsService extends RequestService<ProjectSectionUnit> {
  static PATH = '/project/wbs';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, ProjectWbsService.PATH, globalService.debugUrl);
  }

  uploadFileWithoutProgress(file: File, projectId: number, sectionId: number) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('projectId', projectId.toString());
    formData.append('sectionId', sectionId.toString());
    return this.httpClient.post<FileResponse>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${ProjectWbsService.PATH}/uploadV2`,
      formData
    );
  }
}
