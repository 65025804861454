import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {
  @Input() from?: number;
  @Input() to?: number;
  @Input() disabled?: boolean = false;
  @Output() onChange: EventEmitter<{
    from: number;
    to: number;
  }> = new EventEmitter<{
    from: number;
    to: number;
  }>();

  list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  map = [
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28,
    ],
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30,
    ],
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30,
    ],
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30,
    ],
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30,
    ],
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
  ];

  m1 = 1;
  d1 = 1;

  m2 = 1;
  d2 = 1;

  isPanelShown = false;

  constructor() {}

  ngOnInit(): void {
    if (this.from) {
      this.m1 = Math.floor(this.from / 100);
      this.d1 = this.from % 100;
    }
    if (this.to) {
      this.m2 = Math.floor(this.to / 100);
      this.d2 = this.to % 100;
    }
  }

  fullYear() {
    this.m1 = 1;
    this.d1 = 1;
    this.m2 = 12;
    this.d2 = 31;
    this.select();
    this.onLeave();
  }

  showPanel() {
    if (this.disabled) {
      return;
    }
    this.isPanelShown = true;
  }

  onLeave() {
    this.isPanelShown = false;
  }

  select() {
    this.onChange.emit({
      from: this.m1 * 100 + this.d1,
      to: this.m2 * 100 + this.d2,
    });
  }

  m1Select(d: number) {
    this.m1 = +d;
    this.d1 =
      this.d1 >= this.map[+d - 1].length ? this.map[+d - 1].length : this.d1;
    this.select();
  }

  m2Select(d: number) {
    this.m2 = +d;
    this.d2 =
      this.d2 >= this.map[+d - 1].length ? this.map[+d - 1].length : this.d2;
    this.select();
  }

  d1Select(d: number) {
    this.d1 = +d;
    this.select();
  }

  d2Select(d: number) {
    this.d2 = +d;
    this.select();
  }

  get result() {
    if (this.m1 === 1 && this.d1 === 1 && this.m2 === 12 && this.d2 === 31) {
      return '全年适用';
    }
    if (+this.m1 > +this.m2) {
      return `${this.m1.toString().padStart(2, '0')}月${this.d1
        .toString()
        .padStart(2, '0')}日~次年${this.m2
        .toString()
        .padStart(2, '0')}月${this.d2.toString().padStart(2, '0')}日`;
    }
    return `${this.m1.toString().padStart(2, '0')}月${this.d1
      .toString()
      .padStart(2, '0')}日~${this.m2.toString().padStart(2, '0')}月${this.d2
      .toString()
      .padStart(2, '0')}日`;
  }
}
