import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BatchUpdateByFormIdRequest } from '../models/form/batch-update-by-form-id-request.model';
import { FormItem } from '../models/form/form-item.model';
import { GetWidgetDataRequest } from '../models/form/get-widget-data-request.model';
import { WidgetDataSource } from '../models/form/widget-data-source.model';
import { WidgetData } from '../models/form/widget-data.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class OmFormItemService extends RequestService<FormItem> {
  static PATH = '/om/form/item';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, OmFormItemService.PATH, globalService.debugUrl);
  }

  widgetDataSources() {
    return this.httpClient.get<WidgetDataSource[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmFormItemService.PATH}/widgetDataSources`
    );
  }

  getWidgetData(request: GetWidgetDataRequest) {
    return this.httpClient.post<WidgetData[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmFormItemService.PATH}/getWidgetData`,
      request
    );
  }

  batchUpdateByFormId(request: BatchUpdateByFormIdRequest) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmFormItemService.PATH}/batchUpdateByFormId`,
      request
    );
  }
}
