import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GenerateBatchCodeResponse } from '../models/material/generate-batch-code-response.model';
import { MaterialWh } from '../models/material/material-wh.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class MaterialWhService extends RequestService<MaterialWh> {
  static PATH = '/material/wh';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, MaterialWhService.PATH, globalService.debugUrl);
  }

  generateBatchNo(req: MaterialWh) {
    return this.httpClient.post<GenerateBatchCodeResponse>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${MaterialWhService.PATH}/generateBatchNo`,
      req
    );
  }
}
