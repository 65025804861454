import { IDCardFileRequest } from './../models/staff/id-card-file-request.model';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../providers/global.service';
import {
  FileFullResponse,
  FileResponse,
} from '../providers/request/file-response.model';
import { IDCardFileResponse } from '../models/staff/id-card-file-response.model';
import { WeatherInfo } from '../models/weather/weather-info.model';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  static PATH = '/common';
  constructor(
    public httpClient: HttpClient,
    public globalService: GlobalService
  ) {}

  uploadFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.request<FileFullResponse>(
      new HttpRequest(
        'POST',
        `${
          this.globalService.debugUrl
            ? this.globalService.debugUrl
            : environment.host
        }/common/file/upload`,
        formData,
        {
          reportProgress: true,
        }
      )
    );
  }

  uploadFileWithoutProgress(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<FileResponse>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }/common/file/upload`,
      formData
    );
  }

  loadLargeFile(url: string) {
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  ocrIdCard(iDCardFileRequest: IDCardFileRequest) {
    return this.httpClient.post<IDCardFileResponse>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }/common/ocr/idcard/uploadUrl`,
      iDCardFileRequest
    );
  }

  getWeather(date: string) {
    return this.httpClient.post<WeatherInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }/common/weather/get`,
      {
        locationId: '330109',
        date,
      }
    );
  }
}
