import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormLayout } from 'ng-devui';
import { zip, catchError, throwError } from 'rxjs';
import { LoginInfo } from 'src/app/modules/shared/models/login/login-info.model';
import { LoginType } from 'src/app/modules/shared/models/login/login-type.enum';
import { OrganizationProjectListRequest } from 'src/app/modules/shared/models/staff/organization-project-list-request.model';
import { OrganizationProject } from 'src/app/modules/shared/models/staff/organization-project.model';
import { Organization } from 'src/app/modules/shared/models/staff/organization.model';
import { PlatUser } from 'src/app/modules/shared/models/staff/plat-user.model';
import { User } from 'src/app/modules/shared/models/staff/user.model';
import { GlobalService } from 'src/app/modules/shared/providers/global.service';
import { OmUserPersonService } from 'src/app/modules/shared/services/om-user-person.service';
import { StaffOrganizationService } from 'src/app/modules/shared/services/staff-organization.service';
import { StaffOrganizationProjectService } from 'src/app/modules/shared/services/staff-organization-project.service';
import { StaffUserService } from 'src/app/modules/shared/services/staff-user.service';
import { Md5 } from 'ts-md5';

// declare var VANTA: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  // @ViewChild('vanta') vanta!: ElementRef;
  form: UntypedFormGroup;
  phone: UntypedFormControl;
  password: UntypedFormControl;
  rememberPassword: boolean;
  submitting: boolean = false;
  layoutDirection: FormLayout = FormLayout.Vertical;

  query = '';
  init = false;

  mit = '';
  constructor(
    private globalService: GlobalService,
    private omUserPersonService: OmUserPersonService,
    private staffUserService: StaffUserService,
    private staffOrganizationService: StaffOrganizationService,
    private staffOrganizationProjectService: StaffOrganizationProjectService
  ) {
    const loginInfo = this.globalService.loginInfo;
    this.mit = this.globalService.mit;
    this.phone = new UntypedFormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^1[0-9]{10}$'),
        // Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$|^1[0-9]{10}$")
      ])
    );
    this.password = new UntypedFormControl(
      '',
      Validators.compose([
        Validators.required,
        // Validators.pattern("^(?=.*?[A-Z]*)(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]*).{8,24}$")
      ])
    );
    this.form = new UntypedFormGroup({
      phone: this.phone,
      password: this.password,
    });
    if (loginInfo) {
      this.phone.setValue(loginInfo.phone);
      this.password.setValue(loginInfo.password);
    }
    this.rememberPassword = true;
  }

  ngOnInit(): void {
    this.query = window.location.href.split('?')[1] ?? '';
    this.init = true;
    if (!!this.query && window.location.href.indexOf('login') > -1) {
      this.globalService.clearLoginInfo();
      this.globalService.passwordFree = true;
      this.loginWithoutPassword();
    } else {
      if (window.location.href.indexOf('signin') !== -1) {
        this.globalService.passwordFree = false;
      } else {
        location.href = '/#/not-found';
        this.globalService.clearLoginInfo();
      }
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // VANTA.NET({
    //   el: '.view-container',
    //   mouseControls: true,
    //   touchControls: true,
    //   gyroControls: false,
    //   minHeight: 200.0,
    //   minWidth: 200.0,
    //   scale: 1.0,
    //   scaleMobile: 1.0,
    //   color: 0x3fbbff,
    //   backgroundColor: 0x15253c,
    // });
  }

  loginWithoutPassword(): void {
    const params = new URLSearchParams(this.query);
    this.omUserPersonService
      .loginWithoutPwd(params.get('token') ?? '')
      .subscribe((loginOutInfo: LoginInfo) => {
        if (loginOutInfo) {
          this.globalService.token = loginOutInfo.token!;
          this.prepareUserInfo(loginOutInfo);
        } else {
          if (!!params.get('redirectFail')) {
            location.href = params.get('redirectFail')!;
          } else {
            this.query = '';
          }
        }
      });
  }

  login(loginInfo: any): void {
    this.submitting = true;
    this.omUserPersonService
      .login({
        phone: loginInfo.phone,
        password: Md5.hashStr(loginInfo.password).toString(),
        loginType: LoginType.PASSWORD,
      })
      .pipe(
        catchError((err) => {
          this.submitting = false;
          return throwError(err);
        })
      )
      .subscribe((loginOutInfo: LoginInfo) => {
        this.submitting = false;
        if (loginOutInfo) {
          this.globalService.token = loginOutInfo.token!;
          this.prepareUserInfo(loginOutInfo);
        }
      });
  }

  private prepareUserInfo(info: LoginInfo) {
    zip(
      this.omUserPersonService.getPlatUser(),
      this.staffUserService.findById(info.uid!),
      this.staffOrganizationService.findById(info.organizationId!),
      this.staffOrganizationProjectService.findAll<OrganizationProjectListRequest>(
        {
          organizationId: info.organizationId,
          projectId: this.globalService.projectId,
        }
      )
    ).subscribe(
      (responses: [PlatUser, User, Organization, OrganizationProject[]]) => {
        this.globalService.userInfo = Object.assign(responses[0], responses[1]);
        const org = responses[2];
        if (responses[3].length > 0) {
          org.projectSections = responses[3][0].projectSections;
        }
        this.globalService.orgInfo = org;
        this.globalService.login(info);
      }
    );
  }
}
