import { Pipe, PipeTransform } from '@angular/core';
import { WorkFlowInfo } from '../models/form/work-flow-info.model';

@Pipe({
  name: 'counterSignFilter',
})
export class CounterSignFilterPipe implements PipeTransform {
  transform(items: WorkFlowInfo[]): WorkFlowInfo[] {
    return items.filter((item) => !item.fileCountersign);
  }
}
