export enum WorkFlowStatus {
  DRAFT = 'DRAFT',
  WAIT = 'WAIT',
  FINISH = 'FINISH',
  CANCLE = 'CANCLE',
  EXPIRE = 'EXPIRE',
  REJECT = 'REJECT',
  FAIL = 'FAIL',
}

export const WorkFlowStatusMap = {
  [WorkFlowStatus.DRAFT]: '修改稿',
  [WorkFlowStatus.WAIT]: '待签字',
  [WorkFlowStatus.FINISH]: '已完成',
  [WorkFlowStatus.CANCLE]: '已撤回',
  [WorkFlowStatus.EXPIRE]: '已过期',
  [WorkFlowStatus.REJECT]: '已退回',
  [WorkFlowStatus.FAIL]: '已失败',
};

export const WorkFlowHistoryStatusMap = {
  [WorkFlowStatus.DRAFT]: '草稿',
  [WorkFlowStatus.WAIT]: '待签',
  [WorkFlowStatus.FINISH]: '完成',
  [WorkFlowStatus.CANCLE]: '撤回',
  [WorkFlowStatus.EXPIRE]: '过期',
  [WorkFlowStatus.REJECT]: '退回',
  [WorkFlowStatus.FAIL]: '失败',
};

export const WorkFlowStatusList = [
  {
    label: '修改稿',
    value: WorkFlowStatus.DRAFT,
  },
  {
    label: '待签字',
    value: WorkFlowStatus.WAIT,
  },
  {
    label: '已完成',
    value: WorkFlowStatus.FINISH,
  },
  {
    label: '已撤回',
    value: WorkFlowStatus.CANCLE,
  },
  {
    label: '已过期',
    value: WorkFlowStatus.EXPIRE,
  },
  {
    label: '已退回',
    value: WorkFlowStatus.REJECT,
  },
  {
    label: '已失败',
    value: WorkFlowStatus.FAIL,
  },
];
