import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ExcelTable } from '../models/form/excel-table.model';
import { WorkReportUploadResponse } from '../models/form/work-report-upload-response.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';
import { Observable } from 'rxjs';
import { ListEnumValuesRequest } from '../models/form/list-enum-values-request.model';
import { InitWorkReportRequest } from '../models/form/init-work-report-request.model';
import { SignUrlInfo } from '../models/form/sign-url-info.model';
import { WorkFlowRefectSignRequest } from '../models/form/work-flow-refect-sign-request.model';
import { WorkReportDocType } from '../models/form/work-report-doc-type.enum';
import { WorkReport } from '../models/form/work-report.model';

@Injectable({
  providedIn: 'root',
})
export class WorkReportService extends RequestService<WorkReport> {
  static PATH = '/work/report';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, WorkReportService.PATH, globalService.debugUrl);
  }

  rejectSign(params: WorkFlowRefectSignRequest) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkReportService.PATH}/rejectSign`,
      params
    );
  }

  init(params: InitWorkReportRequest): Observable<WorkReport> {
    return this.httpClient.post<ExcelTable>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkReportService.PATH}/init`,
      params
    );
  }

  getSignUrl(workOrderId: number) {
    return this.httpClient.get<SignUrlInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkReportService.PATH}/getSignUrlInfo/${workOrderId}`
    );
  }

  findByMenuFormId(formId: string) {
    return this.httpClient.get<ExcelTable>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkReportService.PATH}/byMenuFormId/${formId}`
    );
  }

  submit(workOrder: WorkReport) {
    return this.httpClient.post<number>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkReportService.PATH}/submit`,
      workOrder
    );
  }

  uploadFileWithoutProgress(file: File, docType: WorkReportDocType) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('docType', docType.toString());
    formData.append('convertToPdf', 'true');
    return this.httpClient.post<WorkReportUploadResponse>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkReportService.PATH}/upload`,
      formData
    );
  }

  copyById(id: number) {
    return this.httpClient.get<WorkReport>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkReportService.PATH}/copyById/${id}`
    );
  }

  addingContent(workOrder: WorkReport) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkReportService.PATH}/addingContent`,
      workOrder
    );
  }

  listEnumValues(req: ListEnumValuesRequest) {
    return this.httpClient.post<string[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkReportService.PATH}/listEnumValues`,
      req
    );
  }

  removeByAdmin(id: number) {
    return this.httpClient.get<number>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkReportService.PATH}/removeByAdmin/${id}`
    );
  }
}
