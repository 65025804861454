import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IotDeviceType } from '../models/iot/iot-device-type.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class OmIotDeviceTypeService extends RequestService<IotDeviceType> {
  static PATH = '/om/iotdevice/type';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, OmIotDeviceTypeService.PATH, globalService.debugUrl);
  }
}
