import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SignUrlInfo } from '../models/form/sign-url-info.model';
import { WorkFlowBatchInfo } from '../models/form/work-flow-batch-info.model';
import { WorkFlowBatchSignRequest } from '../models/form/work-flow-batch-sign-request.model';
import { WorkFlowCancleRequest } from '../models/form/work-flow-cancle-request.model';
import { WorkFlowInfo } from '../models/form/work-flow-info.model';
import { WorkFlowRefectSignRequest } from '../models/form/work-flow-refect-sign-request.model';
import { WorkFlowSignRequest } from '../models/form/work-flow-sign-request.model';
import { WorkFlowUserTaskSummary } from '../models/form/work-flow-user-task-summary.model';
import { WorkFlowUser } from '../models/form/work-flow-user.model';
import { WorkReportUser } from '../models/form/work-report-user.model';
import { GlobalService } from '../providers/global.service';
import { Page } from '../providers/request/page.type';
import { RequestService } from '../providers/request/request.service';
import { SearchRequest } from '../providers/request/search-request.model';

@Injectable({
  providedIn: 'root',
})
export class WorkFlowUserService extends RequestService<WorkFlowUser> {
  static PATH = '/work/flow/user';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, WorkFlowUserService.PATH, globalService.debugUrl);
  }

  urgeSign(workFlowId: number) {
    return this.httpClient.get<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowUserService.PATH}/urgeSign/${workFlowId}`
    );
  }

  rejectSign(params: WorkFlowRefectSignRequest) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowUserService.PATH}/rejectSign`,
      params
    );
  }

  cancleSign(params: WorkFlowCancleRequest) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowUserService.PATH}/cancleSign`,
      params
    );
  }

  confirmSign(params: WorkFlowSignRequest) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowUserService.PATH}/confirmSign`,
      params
    );
  }

  batchConfirmSign(params: WorkFlowBatchSignRequest) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowUserService.PATH}/batchConfirmSign`,
      params
    );
  }

  getSignUrl(workFlowId: number) {
    return this.httpClient.get<SignUrlInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowUserService.PATH}/getSignUrlInfo/${workFlowId}`
    );
  }

  getGroupWorkFlowInfoList(workFlowId: number) {
    return this.httpClient.get<WorkFlowBatchInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowUserService.PATH}/getGroupWorkFlowInfoList/${workFlowId}`
    );
  }

  getTaskSummary() {
    return this.httpClient.get<WorkFlowUserTaskSummary[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowUserService.PATH}/getTaskSummary`
    );
  }

  getWorkFlowInfo(workFlowId: number) {
    return this.httpClient.get<WorkFlowInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowUserService.PATH}/getWorkFlowInfo/${workFlowId}`
    );
  }

  searchWorkReport<REQ extends SearchRequest>(
    params: REQ,
    page?: number,
    size?: number
  ) {
    return this.httpClient.post<Page<WorkReportUser>>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowUserService.PATH}/searchWorkReport?page=${
        page ? page : 0
      }&size=${size ? size : 20}`,
      params
    );
  }

  read(workFlowId: number) {
    return this.httpClient.get<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowUserService.PATH}/read/${workFlowId}`
    );
  }

  getUrgeSignMsg(workFlowId: number) {
    return this.httpClient.get<string>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowUserService.PATH}/getUrgeSignMsg/${workFlowId}`
    );
  }

  batchChangeSign(data: WorkFlowInfo) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkFlowUserService.PATH}/batchChangeSign`,
      data
    );
  }
}
