import { Pipe, PipeTransform } from '@angular/core';
import { WorkFlowTaskInfo } from '../models/form/work-flow-task-info.model';

@Pipe({
  name: 'historyFilter',
})
export class HistoryFilterPipe implements PipeTransform {
  transform(items: WorkFlowTaskInfo[]): WorkFlowTaskInfo[] {
    console.log('items', items);
    return items.filter((item) => item.hiddenTrigger || !item.hide);
  }
}
