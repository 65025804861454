import { RecordBase } from '../../providers/request/record-base.model';
import { BloodType } from './blood-type.enum';
import { EducationType } from './education-type.enum';
import { Gender } from './gender.enum';
import { IDCardType } from './id-card-type.enum';
import { MarriedStatus } from './married-status.enum';
import { OrganizationProjectSection } from './organization-project-section.model';
import { OrganizationType } from './organization-type.enum';
import { PoliticalStatus } from './political-status.enum';
import { UserPost } from './user-post.model';
import { UserSkillItem } from './user-skill-item.model';

export class User extends RecordBase {
  [key: string]: any;
  projectId?: number;
  projectSections?: Array<OrganizationProjectSection>;
  sectionDisplay?: string;
  organizationId?: number;
  organizationName?: string;
  organizationShortName?: string;
  organizationNamePinYinFirst?: string;
  organizationType?: OrganizationType;
  departmentId?: number;
  departmentName?: string;
  departmentFullName?: string;
  departmentId2?: number;
  departmentName2?: string;
  departmentFullName2?: string;
  mainPost?: boolean;
  postId?: number;
  postName?: string;
  postFullName?: string;
  postId2?: number;
  postName2?: string;
  postFullName2?: string;
  otherPosts?: Array<UserPost>;
  performance?: boolean;
  performance2?: boolean;
  postPerformance?: boolean;
  name?: string;
  gender?: Gender;
  idCardType?: IDCardType = IDCardType.CHINESE_ID_CARD;
  idCardNo?: string;
  idCardFace?: string;
  idCardFaceList?: Array<string> = [];
  idCardEmblem?: string;
  idCardEmblemList?: Array<string> = [];
  idCardList?: Array<string> = [];
  idCardAddress?: string;
  idCardExpDate?: number;
  idCardExpDateDate?: Date;
  idCardIssueDate?: number;
  idCardIssueDateDate?: Date;
  idCardIssueOrg?: string;
  nativePlace?: string;
  liveAddress?: string;
  phone?: string;
  addPlatUser?: boolean;
  needSign?: boolean = false;
  password?: string;
  ethnic?: string;
  politicalStatus?: PoliticalStatus;
  birthday?: number;
  birthdayDate?: Date;
  age?: number;
  height?: number;
  weight?: number;
  bloodType?: BloodType;
  marriedStatus?: MarriedStatus;
  childrenCount?: number;
  selfOnly?: boolean;
  educationType?: EducationType;
  status?: boolean;
  entryDate?: number;
  entryDateDate?: Date;
  leaveDate?: number;
  leaveDateDate?: Date;
  leave?: boolean;
  leaveComment?: string;
  skillItems?: Array<UserSkillItem> = [
    new UserSkillItem(),
    new UserSkillItem(),
  ];
  skillFileList?: Array<string> = [];
  emergencyContact?: string;
  emergencyPhone?: string;
  comment?: string;
  outCode?: string;
  // 前端使用字段
  currentAddPlatUser?: boolean;
  currentNeedSign?: boolean;
  orgSectionDisplay?: string;
  checked?: boolean;
  departPostValue?: any[] = [];
  departPostValue2?: any[] = [];

  orgSpan?: number = 1;
  depSpan?: number = 1;
  postSpan?: number = 1;

  userId?: number;
}
