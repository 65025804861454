import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ModalService } from 'ng-devui';
import { NzMessageService } from 'ng-zorro-antd/message';
import { zip, catchError, throwError } from 'rxjs';
import {
  AlertDialogComponent,
  AlertDialogType,
} from 'src/app/modules/shared/components/alert-dialog/alert-dialog.component';
import { FilePreviewType } from 'src/app/modules/shared/components/file-preview-entry/file-preview-entry.component';
import { ExcelTable } from 'src/app/modules/shared/models/form/excel-table.model';
import { FileSource } from 'src/app/modules/shared/models/form/file-source.enum';
import { FormFlowInfo } from 'src/app/modules/shared/models/form/form-flow-info.model';
import {
  FormFlowTypeMap,
  FormFlowType,
} from 'src/app/modules/shared/models/form/form-flow-type.enum';
import { FormHeaderListRequest } from 'src/app/modules/shared/models/form/form-header-list-request.model';
import { FormHeader } from 'src/app/modules/shared/models/form/form-header.model';
import { FormItemType } from 'src/app/modules/shared/models/form/form-item-type.enum';
import {
  FormItemWidgetTypeList,
  FormItemWidgetType,
  FormItemWidgetTypeMap,
} from 'src/app/modules/shared/models/form/form-item-widget-type.enum';
import { FormPagesType } from 'src/app/modules/shared/models/form/form-pages-type.enum';
import { UserInfo } from 'src/app/modules/shared/models/form/user-info.model';
import { WidgetData } from 'src/app/modules/shared/models/form/widget-data.model';
import {
  WorkFlowActionTypeChangeableList,
  WorkFlowActionTypeMap,
  WorkFlowActionType,
} from 'src/app/modules/shared/models/form/work-flow-action-type.enum';
import { WorkFlowSignFieldType } from 'src/app/modules/shared/models/form/work-flow-sign-field-type.enum';
import {
  WorkFlowSignerTaskTypeList,
  WorkFlowSignerTaskType,
} from 'src/app/modules/shared/models/form/work-flow-signer-task-type.enum';
import { WorkFlowHistoryStatusMap } from 'src/app/modules/shared/models/form/work-flow-status.enum';
import { WorkFlowTaskInfo } from 'src/app/modules/shared/models/form/work-flow-task-info.model';
import { WorkFlowTask } from 'src/app/modules/shared/models/form/work-flow-task.model';
import { WorkReportDocType } from 'src/app/modules/shared/models/form/work-report-doc-type.enum';
import { WorkReportUploadResponse } from 'src/app/modules/shared/models/form/work-report-upload-response.model';
import { WorkReport } from 'src/app/modules/shared/models/form/work-report.model';
import { DepartmentListRequest } from 'src/app/modules/shared/models/staff/department-list-request.model';
import { Department } from 'src/app/modules/shared/models/staff/department.model';
import { OrganizationCategory } from 'src/app/modules/shared/models/staff/organization-category.enum';
import { OrganizationExtListRequest } from 'src/app/modules/shared/models/staff/organization-ext-list-request.model';
import { OrganizationExtSearchRequest } from 'src/app/modules/shared/models/staff/organization-ext-search-request.model';
import { OrganizationListRequest } from 'src/app/modules/shared/models/staff/organization-list-request.model';
import { OrganizationMember } from 'src/app/modules/shared/models/staff/organization-member.model';
import { OrganizationType } from 'src/app/modules/shared/models/staff/organization-type.enum';
import { Organization } from 'src/app/modules/shared/models/staff/organization.model';
import { Post } from 'src/app/modules/shared/models/staff/post.model';
import { UserListRequest } from 'src/app/modules/shared/models/staff/user-list-request.model';
import { User } from 'src/app/modules/shared/models/staff/user.model';
import { NzTreeNode } from 'src/app/modules/shared/models/tree-node.model';
import {
  EventService,
  EventType,
} from 'src/app/modules/shared/providers/event.service';
import { GlobalService } from 'src/app/modules/shared/providers/global.service';
import { FileResponse } from 'src/app/modules/shared/providers/request/file-response.model';
import { CommonService } from 'src/app/modules/shared/services/common.service';
import { OmFormHeaderService } from 'src/app/modules/shared/services/om-form-header.service';
import { OmFormItemService } from 'src/app/modules/shared/services/om-form-item.service';
import { OmUserOrgService } from 'src/app/modules/shared/services/om-user-org.service';
import { StaffDepartmentService } from 'src/app/modules/shared/services/staff-department.service';
import { StaffOrganizationMemberService } from 'src/app/modules/shared/services/staff-organization-member.service';
import { StaffOrganizationService } from 'src/app/modules/shared/services/staff-organization.service';
import { StaffPostService } from 'src/app/modules/shared/services/staff-post.service';
import { StaffUserService } from 'src/app/modules/shared/services/staff-user.service';
import { WorkFlowTaskService } from 'src/app/modules/shared/services/work-flow-task.service';
import { WorkReportService } from 'src/app/modules/shared/services/work-report.service';
import { environment } from 'src/environments/environment';
import { DialogGl105DateComponent } from '../../../dialogs/qa/dialog-gl105-date/dialog-gl105-date.component';
import { CustomMenuFormFillDialogComponent } from '../../../pages/custom-menu-form/custom-menu-form-fill-dialog/custom-menu-form-fill-dialog.component';
import { WorkReportAttach } from 'src/app/modules/shared/models/form/work-report-attach.model';

@Component({
  selector: 'work-flow-fill',
  templateUrl: './work-flow-fill.component.html',
  styleUrls: ['./work-flow-fill.component.scss'],
})
export class WorkFlowFillComponent {
  searchRequest = new OrganizationExtSearchRequest();

  environment = environment;

  @Input()
  set workReport(value: WorkReport) {
    this._workReport = value;
    this.getForm();
    this.getAllUser();
  }

  get workReport() {
    return this._workReport;
  }

  @Input()
  set reportAttach(v: Array<WorkReportAttach>) {
    if (!this.workReport) {
      return;
    }
    this.workReport.attachs = v;
  }

  _workReport!: WorkReport;

  FilePreviewType = FilePreviewType;

  WorkReportDocType = WorkReportDocType;

  FormItemWidgetTypeList = FormItemWidgetTypeList;

  FormFlowTypeMap = FormFlowTypeMap;

  FormFlowType = FormFlowType;

  postTreeNodeFull: NzTreeNode[] = [];

  orgTypeMap: { [key: string]: Organization[] } = {};

  WorkFlowSignerTaskTypeList = WorkFlowSignerTaskTypeList;

  WorkFlowSignerTaskType = WorkFlowSignerTaskType;

  userList: User[] = [];

  disabledAddress: string[] = [];

  FormPagesType = FormPagesType;

  projectWidgetData: WidgetData[] = [];

  orgList: Organization[] = [];

  orgMap: { [key: string]: Organization } = {};
  postMap: { [key: string]: Post } = {};
  userData: User[] = [];

  orgMemberMap: { [key: string]: OrganizationMember } = {};
  orgMemberInfoMap: { [key: string]: OrganizationMember[] } = {};

  submited = false;

  needFill = false;

  @Input()
  set hideLeft(value: boolean) {
    this._hideLeft = value;
    this.resize();
  }

  get hideLeft() {
    return this._hideLeft;
  }

  @Output() hideLeftChanged = new EventEmitter<boolean>();

  _hideLeft = false;

  hideRight = false;

  centerWidth = 760;

  resp: any = {};

  WorkFlowActionTypeChangeableList = WorkFlowActionTypeChangeableList;

  WorkFlowActionTypeMap = WorkFlowActionTypeMap;

  WorkFlowHistoryStatusMap = WorkFlowHistoryStatusMap;

  historyList: WorkFlowTaskInfo[] = [];

  hideHistoryFlowId: number[] = [];

  interval: any = undefined;

  @Output() startFlow = new EventEmitter<number>();
  @Output() closeFlow = new EventEmitter<number>();
  @Output() saveFlow = new EventEmitter<number>();
  @Output() cancelDraftFlow = new EventEmitter<void>();

  constructor(
    private modalService: ModalService,
    private workReportService: WorkReportService,
    private workFLowTaskService: WorkFlowTaskService,
    private commonService: CommonService,
    private omUserOrgService: OmUserOrgService,
    private staffOrganizationService: StaffOrganizationService,
    private staffOrganizationMemberService: StaffOrganizationMemberService,
    private staffDepartmentService: StaffDepartmentService,
    private staffPostService: StaffPostService,
    private staffUserService: StaffUserService,
    private globalService: GlobalService,
    private omFormItemService: OmFormItemService,
    private events: EventService,
    private omFormHeaderService: OmFormHeaderService,
    private nzMessageService: NzMessageService
  ) {
    this.interval = setInterval(() => {
      this.save();
    }, 300 * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  getAllUser() {
    this.staffUserService
      .findAll<UserListRequest>({
        projectId: this.globalService.projectId,
      })
      .subscribe((res) => {
        this.userData = res;
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.resize();
    }, 100);
    window.addEventListener('resize', () => {
      this.resize();
    });
  }

  hideLeftChange(v: boolean) {
    this.hideLeftChanged.emit(v);
  }

  hideRightChange(v: boolean) {
    this.hideRight = v;
    this.resize();
  }

  private resize() {
    const width = document.body.clientWidth - 10;
    let c = width;
    if (!this.hideLeft) {
      c -= 300;
    }
    if (!this.hideRight) {
      c -= 300;
    }
    this.centerWidth = c;
  }

  private calcExecOrderSpan() {
    if (
      !this.workReport?.form?.formFlows ||
      this.workReport?.form?.formFlows?.length === 0
    ) {
      return;
    }
    for (const flow of this.workReport.form.formFlows) {
      flow.execOrderSpan = undefined;
      flow.execOrderSpanHeight = undefined;
    }
    let idx = this.workReport.form.formFlows.length - 1;

    while (idx > 0) {
      const flow = this.workReport.form.formFlows[idx];
      const prev = this.workReport.form.formFlows[idx - 1];

      if (flow.execOrder === prev.execOrder) {
        prev.execOrderSpanHeight =
          (flow.execOrderSpanHeight ?? flow.userInfoList!.length) +
          prev.userInfoList!.length;
        flow.execOrderSpanHeight = undefined;

        prev.execOrderSpan = (flow.execOrderSpan ?? 1) + 1;
        flow.execOrderSpan = undefined;
      }
      // 可能有问题，execOrderSpan这个干嘛的没有管，你再看看
      // flow.execOrder = idx;
      // if (+flow.execOrder! - +prev.execOrder! > 1) {
      //   flow.execOrder = +prev.execOrder! + 1;
      // }
      idx--;
    }
    let jump = 0;
    let execOrder = 0;
    for (
      let index = 0;
      index < this.workReport.form.formFlows.length;
      index++
    ) {
      const flow = this.workReport.form.formFlows[index];
      if (jump > 0) {
        jump--;
        flow.execOrder = execOrder - 1;
        continue;
      }
      if (flow.execOrderSpan) {
        flow.execOrder = execOrder;
        jump = flow.execOrderSpan - 1;
        execOrder++;
        continue;
      } else {
        flow.execOrder = execOrder;
      }
      execOrder++;
    }
  }

  prccChange(e: { ids?: string[]; musted?: boolean }, isCC: boolean) {
    const [ids, musted] = [e.ids, e.musted];
    if (!ids) {
      return;
    }
    const cache: number[] = [];
    const cacheOrgList: OrganizationMember[] = [];
    for (const id of ids) {
      for (const org of this.orgList) {
        if (org.id?.toString() === id.toString()) {
          if (org.category === OrganizationCategory.COMPANY) {
            cache.push(org.id!);
            const orgInfo = this.orgMap[org.id!];
            const member = new OrganizationMember();
            member.memberId = org.id!;
            member.projectId = orgInfo.projectId;
            member.organizationId = undefined;
            member.memberName = orgInfo.name;
            cacheOrgList.push(member);
          } else {
            cache.push(org.mainMemberId!);
            cacheOrgList.push(this.orgMemberMap[org.mainMemberId!]);
            for (const key in this.orgMemberMap) {
              const value = this.orgMemberMap[key];
              if (value.organizationId == org.id) {
                if (value.memberId !== org.mainMemberId) {
                  cache.push(value.memberId!);
                  cacheOrgList.push(value);
                }
              }
            }
          }
        }
      }
    }
    for (const item of this.workReport?.form?.formFlows ?? []) {
      if (
        item.step === (isCC ? FormFlowType.CC : FormFlowType.PRESENT) &&
        item.newAdded &&
        item.auto
      ) {
        if (!cache.includes(item.organizationId!)) {
          item.needRm = true;
          continue;
        } else {
          const i = cache.indexOf(item.organizationId!);
          cache.splice(i, 1);
          cacheOrgList.splice(i, 1);
        }
      }
    }
    for (let i = 0; i < cache.length; i++) {
      this.addPrCC(true, isCC, -1, cache[i], cacheOrgList[i], musted);
    }
    this.workReport!.form!.formFlows = this.workReport?.form?.formFlows?.filter(
      (item) => !item.needRm
    );
    this.calcExecOrderSpan();
  }

  actionTypeChange(index: number) {
    console.log('actionTypeChange: ', index);
    // 修改执行顺序
    const flow = this.workReport?.form?.formFlows![index]!;
    const prev = this.workReport?.form?.formFlows![index - 1];
    const next = this.workReport?.form?.formFlows![index + 1];
    let actNum = 0;
    if (flow.actionType !== WorkFlowActionType.SIGN_SYNC) {
      if (next && next.execOrder === flow.execOrder) {
        actNum += 1;
      }
      if (prev && prev.execOrder === flow.execOrder) {
        actNum += 1;
        this.workReport!.form!.formFlows![index].execOrder!++;
      }
    } else {
      if (
        next &&
        next.execOrder !== flow.execOrder &&
        next.actionType === WorkFlowActionType.SIGN_SYNC
      ) {
        actNum -= 1;
      }
      if (
        prev &&
        prev.execOrder !== flow.execOrder &&
        prev.actionType === WorkFlowActionType.SIGN_SYNC
      ) {
        actNum -= 1;
        this.workReport!.form!.formFlows![index].execOrder!--;
      }
    }

    for (
      let i = index + 1;
      i < this.workReport!.form!.formFlows!.length ?? 0;
      i++
    ) {
      this.workReport!.form!.formFlows![i].execOrder! += actNum;
    }

    // 修改人员信息

    if (
      flow.actionType !== WorkFlowActionType.ALL &&
      flow.actionType !== WorkFlowActionType.ANY
    ) {
      if (this.workReport!.form!.formFlows![index].userInfoList?.length! > 1) {
        this.workReport!.form!.formFlows![index].userInfoList = [
          this.workReport!.form!.formFlows![index].userInfoList![0],
        ];
      }
    } else {
      if (this.workReport!.form!.formFlows![index].userInfoList?.length === 1) {
        const user = this.workReport!.form!.formFlows![index].userInfoList![0];
        if (user.userId) {
          const userInfo = new UserInfo();
          userInfo.auto = true;
          this.workReport!.form!.formFlows![index].userInfoList?.push(userInfo);
        }
      }
    }
    this.calcExecOrderSpan();
  }

  merge(index: number) {
    const flow = this.workReport?.form?.formFlows![index];
    const next = this.workReport?.form?.formFlows![index + 1];
    if (!next) {
      return;
    }
    next.execOrder = flow!.execOrder;
    for (
      let i = index + 2;
      i < this.workReport!.form!.formFlows!.length ?? 0;
      i++
    ) {
      this.workReport!.form!.formFlows![i].execOrder! -= 1;
    }
    this.calcExecOrderSpan();
  }

  split(index: number) {
    for (
      let i = index + 1;
      i < this.workReport!.form!.formFlows!.length ?? 0;
      i++
    ) {
      this.workReport!.form!.formFlows![i].execOrder! += 1;
    }
    this.calcExecOrderSpan();
  }

  download(history: WorkFlowTask) {
    const downloadLink = document.createElement('a');
    const fileUrl = environment.fileHost + history.fileId!;
    downloadLink.href = fileUrl;
    downloadLink.target = '_blank';
    downloadLink.download = history.fileId ?? '';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  read(history: WorkFlowTask) {
    window.open(environment.fileHost + history.fileId, '_blank');
  }

  private getOrganizationDepartment(): void {
    zip(
      this.staffOrganizationService.findAll<OrganizationListRequest>({
        projectId: this.globalService.projectId,
        projectSections: this.workReport!.sectionId
          ? [
              {
                sectionId: this.workReport!.sectionId,
              },
            ]
          : undefined,
      }),
      this.staffDepartmentService.findAll<DepartmentListRequest>({
        projectId: this.globalService.projectId,
      }),
      this.staffPostService.findAll({ type: null }),
      this.omFormItemService.getWidgetData({
        projectId: this.globalService.projectId,
        sectionId: this.workReport!.sectionId,
        type: FormItemWidgetType.PROJECT,
      }),
      this.staffUserService.findAll<UserListRequest>({
        projectSections: this.workReport!.sectionId
          ? [
              {
                sectionId: this.workReport!.sectionId,
              },
            ]
          : undefined,
        projectId: this.globalService.projectId,
        // hasPartTimeJob: true,
      }),
      this.staffOrganizationMemberService.findAll({
        projectId: this.globalService.projectId,
        projectSections: this.workReport!.sectionId
          ? [
              {
                sectionId: this.workReport!.sectionId,
              },
            ]
          : undefined,
      }),
      this.omUserOrgService.findAll<OrganizationExtListRequest>({
        projectId: this.globalService.projectId,
        projectSections: this.workReport!.sectionId
          ? [
              {
                sectionId: this.workReport!.sectionId,
              },
            ]
          : undefined,
      })
    ).subscribe(
      ([orgs, departments, posts, widgetData, users, orgMembers, orgExts]) => {
        // 0. 在构造树之前获取其他数据
        this.userList = users;
        this.projectWidgetData = widgetData ?? [];
        // 1. 计算履约人员数量
        const orgSealUserIdMap: { [key: string]: number[] } = {};
        const orgSealUserMap: { [key: string]: User[] } = {};
        for (const orgExt of orgExts ?? []) {
          if (orgExt.sealUsers && orgExt.sealUsers.length > 0) {
            orgSealUserIdMap[orgExt.organizationId!] = orgExt.sealUsers.map(
              (info) => info.userId!
            );
          }
        }
        const performanceCountMap: { [key: string]: number } = {};
        const postUserMap: { [key: string]: User[] } = {};
        for (const user of users) {
          if (user.performance) {
            performanceCountMap[
              `${user.organizationId}#${user.departmentId}#${user.postId}`
            ] = performanceCountMap[
              `${user.organizationId}#${user.departmentId}#${user.postId}`
            ]
              ? performanceCountMap[
                  `${user.organizationId}#${user.departmentId}#${user.postId}`
                ] + 1
              : 1;
          }
          if (!postUserMap[user.postId!]) {
            postUserMap[user.postId!] = [];
          }
          postUserMap[user.postId!].push(user);
          if (orgSealUserIdMap[user.organizationId!]?.includes(user.id!)) {
            if (!orgSealUserMap[user.organizationId!]) {
              orgSealUserMap[user.organizationId!] = [];
            }
            let exIdx = -1;
            for (
              let i = 0;
              i < orgSealUserMap[user.organizationId!].length;
              i++
            ) {
              if (orgSealUserMap[user.organizationId!][i].id === user.id) {
                exIdx = i;
                break;
              }
            }
            if (exIdx === -1) {
              orgSealUserMap[user.organizationId!].push(user);
            } else {
              if (user.mainPost) {
                orgSealUserMap[user.organizationId!][exIdx] = user;
              }
            }
          }
        }
        // 2. 获取机构成员数据
        for (const org of orgMembers ?? []) {
          this.orgMemberMap[org.memberId!] = org;
        }

        // 3. 构造树

        const orgMap: { [key: string]: Organization } = {};
        for (const org of orgs) {
          org.departmentList = [];
          orgMap[org.id!] = org;
        }
        this.orgMap = orgMap;
        // TODO: 可能出现一种情况，建设单位拉不到

        const departmentMap: { [key: string]: Department } = {};
        const departMapByOrgType: { [key: string]: Department[] } = {};
        for (const department of departments) {
          if (!departMapByOrgType[department.organizationType!]) {
            departMapByOrgType[department.organizationType!] = [];
          }
          department.postList = [];
          departmentMap[department.id!] = department;
          departMapByOrgType[department.organizationType!].push(department);
        }

        const postMap: { [key: string]: Post } = {};
        for (const post of posts) {
          if (post.departmentId && departmentMap[post.departmentId!]) {
            // TODO 建设单位似乎没有部门
            departmentMap[post.departmentId!].postList!.push(post);
          }
          postMap[post.id!] = post;
        }

        for (const department of departments) {
          if (
            !!department.organizationId &&
            orgMap[department.organizationId!]
          ) {
            orgMap[department.organizationId!].departmentList!.push(department);
          }
        }

        this.orgList = orgs;

        // this.orgMap = orgMap;

        // const treeNodesForType: { [key: string]: NzTreeNode[] } = {};
        const treeNodesForAll: NzTreeNode[] = [];
        for (const org of orgs) {
          if (org.category === OrganizationCategory.INSTITUTION) {
            continue;
          }
          // ORG层的值结构为 单位类型#单位ID
          const tNode: NzTreeNode = {
            value: `${org.type}#${org.id}`,
            label: org.shortName ?? org.name,
            children: [],
          };
          for (const department of departMapByOrgType[org.type!] ?? []) {
            const dNode: NzTreeNode = {
              value: `${org.type}#${org.id}#${department.id}`,
              label: department.name,
              children: [],
            };
            for (const post of department.postList ?? []) {
              if (post.name === '专监' || post.name === '对口专监') {
                continue;
              }
              const pNode: NzTreeNode = {
                value: `${org.type}#${org.id}#${department.id}#${post.id}`,
                label: `${post.name}${post.performance ? '[履约]' : ''}`,
                children: [],
              };
              for (const user of postUserMap[post.id!] ?? []) {
                if (
                  user.organizationId === org.id &&
                  user.departmentId === department.id
                ) {
                  pNode.children?.push({
                    value: `${org.type}#${org.id}#${department.id}#${post.id}#${user.id}`,
                    label: `${user.name}${post.performance ? '[履约]' : ''}`,
                    isLeaf: true,
                  });
                }
              }
              if (pNode.children?.length === 0) {
                pNode.children?.push({
                  value: `-`,
                  label: `请添加人员`,
                  isLeaf: true,
                  disabled: true,
                });
              }
              dNode.children!.push(pNode);
            }
            if (dNode.children!.length > 0) {
              tNode.children!.push(dNode);
            }
          }

          // 特殊处理单位类型
          if (org.type === OrganizationType.SUPERVISE) {
            // 专监 -96
            const zjNode: NzTreeNode = {
              value: `${org.type}#${org.id}#-96`,
              label: '专监',
              children: [],
            };
            for (const department of departMapByOrgType[
              OrganizationType.SUPERVISE
            ] ?? []) {
              for (const post of department.postList ?? []) {
                if (
                  post.name &&
                  (post.name.indexOf('专监') > -1 ||
                    post.name.indexOf('试验检测工程师') > -1) &&
                  post.name !== '对口专监' &&
                  post.name !== '专监'
                ) {
                  const pNode: NzTreeNode = {
                    value: `${org.type}#${org.id}#-96#${post.id}`,
                    label: `${post.name}${post.performance ? '[履约]' : ''}`,
                    children: [],
                  };
                  for (const user of postUserMap[post.id!] ?? []) {
                    if (
                      user.organizationId === org.id &&
                      user.departmentId === department.id
                    ) {
                      pNode.children?.push({
                        value: `${org.type}#${org.id}#-96#${post.id}#${user.id}`,
                        label: `${user.name}${
                          post.performance ? '[履约]' : ''
                        }`,
                        isLeaf: true,
                      });
                    }
                  }
                  if (pNode.children?.length === 0) {
                    pNode.children?.push({
                      value: `-`,
                      label: `请添加人员`,
                      isLeaf: true,
                      disabled: true,
                    });
                  }
                  zjNode.children!.push(pNode);
                }
              }
            }
            if (zjNode.children!.length > 0) {
              tNode.children!.push(zjNode);
            }
            // 对口专监 -95
            const dkNode: NzTreeNode = {
              value: `${org.type}#${org.id}#-95`,
              label: '对口专监',
              children: [],
            };
            for (const department of departMapByOrgType[
              OrganizationType.SUPERVISE
            ] ?? []) {
              for (const post of department.postList ?? []) {
                if (
                  post.name &&
                  (post.name.indexOf('专监') > -1 ||
                    post.name.indexOf('试验检测工程师') > -1) &&
                  post.name !== '对口专监' &&
                  post.name !== '专监'
                ) {
                  const pNode: NzTreeNode = {
                    value: `${org.type}#${org.id}#-95#${post.id}`,
                    label: `${post.name}${post.performance ? '[履约]' : ''}`,
                    children: [],
                  };
                  for (const user of postUserMap[post.id!] ?? []) {
                    if (
                      user.organizationId === org.id &&
                      user.departmentId === department.id
                    ) {
                      pNode.children?.push({
                        value: `${org.type}#${org.id}#-95#${post.id}#${user.id}`,
                        label: `${user.name}${
                          post.performance ? '[履约]' : ''
                        }`,
                        isLeaf: true,
                      });
                    }
                  }
                  if (pNode.children?.length === 0) {
                    pNode.children?.push({
                      value: `-`,
                      label: `请添加人员`,
                      isLeaf: true,
                      disabled: true,
                    });
                  }
                  dkNode.children!.push(pNode);
                }
              }
            }
            if (dkNode.children!.length > 0) {
              tNode.children!.push(dkNode);
            }
          }

          // 履约人员 -97
          if (org.type === OrganizationType.CONSTRUCTION) {
            const pfNode: NzTreeNode = {
              value: `${org.type}#${org.id}#-97`,
              label: '履约',
              children: [],
            };
            for (const department of departMapByOrgType[
              OrganizationType.CONSTRUCTION
            ] ?? []) {
              for (const post of department.postList ?? []) {
                if (!!post.performance) {
                  const pNode: NzTreeNode = {
                    value: `${org.type}#${org.id}#-97#${post.id}`,
                    label: `${post.name}${post.performance ? '[履约]' : ''}`,
                    children: [],
                  };
                  for (const user of postUserMap[post.id!] ?? []) {
                    if (
                      user.organizationId === org.id &&
                      user.departmentId === department.id
                    ) {
                      pNode.children?.push({
                        value: `${org.type}#${org.id}#-97#${post.id}#${user.id}`,
                        label: `${user.name}${
                          post.performance ? '[履约]' : ''
                        }`,
                        isLeaf: true,
                      });
                    }
                  }
                  if (pNode.children?.length === 0) {
                    pNode.children?.push({
                      value: `-`,
                      label: `请添加人员`,
                      isLeaf: true,
                      disabled: true,
                    });
                  }
                  pfNode.children!.push(pNode);
                }
              }
            }
            if (pfNode.children!.length > 0) {
              tNode.children!.push(pfNode);
            }
          }

          // 持章人 -98
          const czNode: NzTreeNode = {
            value: `${org.type}#${org.id}#-98`,
            label: '持章人',
            children: [],
          };
          for (const user of orgSealUserMap[org.id!] ?? []) {
            czNode.children?.push({
              value: `${org.type}#${org.id}#-98#${user.postId}#${user.id}`,
              label: `${user.name}${
                postMap[user.postId!]?.performance ? '[履约]' : ''
              }`,
              isLeaf: true,
            });
          }
          if (czNode.children?.length === 0) {
            czNode.children?.push({
              value: `-`,
              label: `请添加人员`,
              isLeaf: true,
              disabled: true,
            });
          }

          tNode.children!.push(czNode);

          if (tNode.children!.length > 0) {
            treeNodesForAll.push(tNode);
          }
        }
        this.postTreeNodeFull = treeNodesForAll;
      }
    );
  }

  userInfoChange(user: UserInfo, flowIdx: number, userIdx: number) {
    console.log(
      'userInfoChange: ',
      user,
      ', flowIdx: ',
      flowIdx,
      ', userIdx: ',
      userIdx
    );
    const flow = this.workReport!.form!.formFlows![flowIdx];
    flow.userInfoList![userIdx] = user;
    if (
      flow.actionType === WorkFlowActionType.ANY ||
      flow.actionType === WorkFlowActionType.ALL
    ) {
      if (user.userId) {
        if (flow.userInfoList!.length === userIdx + 1) {
          const userInfo = new UserInfo();
          userInfo.auto = true;
          flow.userInfoList!.push(userInfo);
        }
      } else {
        flow.userInfoList!.splice(userIdx, 1);
      }
    }

    if (flow.address) {
      let display: string[] = [];
      for (const user of flow.userInfoList ?? []) {
        display.push(user.userName!);
      }
      const content = flow.execOrder + display.join('、');
      setTimeout(() => {
        this.events.broadcast(EventType.SignUserSelected, {
          address: flow.address,
          content,
        });
      }, 100);
    }
    this.calcExecOrderSpan();
  }

  getHistory() {
    if (!!this.workReport?.copyWorkReportId) {
      return this.workFLowTaskService.listWorkFlowTaskByCopyWorkOrderId(
        this.workReport.copyWorkReportId
      );
    } else {
      return this.workFLowTaskService.listWorkFlowTaskByWorkOrderId(
        this.workReport?.id!
      );
    }
  }

  getForm() {
    if (this.workReport.code === 'GL046') {
      this.workReport.filledUserId = this.globalService.userInfo?.id;
    }
    this.omFormHeaderService
      .findAll<FormHeaderListRequest>({
        formId: this.workReport.form!.formId!,
      })
      .subscribe((headers: FormHeader[]) => {
        if (headers && headers.length > 0) {
          this.needFill = true;
        }
      });
    this.handleDisabledAddress(this.workReport.form!.formFlows ?? []);
    try {
      if (!!this.workReport.orginFiles) {
        this.workReport.orginFilesList = JSON.parse(this.workReport.orginFiles);
      }
    } catch (error) {}
    try {
      if (!!this.workReport.pdfFiles) {
        this.workReport.pdfFilesList = JSON.parse(this.workReport.pdfFiles);
      }
    } catch (error) {}
    try {
      if (!!this.workReport.signFiles) {
        this.workReport.signFilesList = JSON.parse(this.workReport.signFiles);
      }
    } catch (error) {}
    for (const flow of this.workReport.form?.formFlows ?? []) {
      if (!flow.userInfoList || flow.userInfoList?.length === 0) {
        if (flow.step === FormFlowType.FILL_IN) {
          const userInfo = new UserInfo();
          userInfo.organizationType =
            this.globalService.userInfo?.organizationType;
          userInfo.organizationId = this.globalService.userInfo?.organizationId;
          userInfo.departmentId = this.globalService.userInfo?.departmentId;
          userInfo.postId = this.globalService.userInfo?.postId;
          userInfo.userId = this.globalService.userInfo?.id;
          userInfo.userName = this.globalService.userInfo?.name;
          userInfo.performance = this.globalService.userInfo?.performance;
          userInfo.postPerformance =
            this.globalService.userInfo?.postPerformance;
          flow.userInfoList = [userInfo];
        } else {
          const userInfo = new UserInfo();
          userInfo.organizationType = flow.organizationType;
          userInfo.organizationId = flow.organizationId;
          userInfo.departmentId = flow.departmentId;
          userInfo.postId = flow.postId;
          flow.userInfoList = [userInfo];
        }
      }
      if (
        flow.actionType === WorkFlowActionType.ANY ||
        flow.actionType === WorkFlowActionType.ALL
      ) {
        if (flow.userInfoList[flow.userInfoList.length - 1].userId) {
          const userInfo = new UserInfo();
          userInfo.auto = true;
          flow.userInfoList.push(userInfo);
        }
      }
      if (flow.address) {
        let display: string[] = [];
        for (const user of flow.userInfoList ?? []) {
          display.push(user.userName!);
        }
        const content = flow.execOrder + ' ' + display.join('、');
        setTimeout(() => {
          this.events.broadcast(EventType.SignUserSelected, {
            address: flow.address,
            content,
          });
        }, 100);
      }
    }

    if (this.workReport.id || this.workReport.copyWorkReportId) {
      this.getHistory().subscribe((res: WorkFlowTaskInfo[]) => {
        // TODO: 有可能出现多个历史, 根据workFlowId去分组
        this.historyList = res;
        for (const flow of this.historyList) {
          flow.execOrderSpan = undefined;
          // TODO 测试用
          // flow.executorName = 'test';

          // flow.executorProperties = "履约;持章人;专监";
          // flow.comment = "这是测试这是测试这是测试这是测试这是测试这是测试这是测试这是测试";
          // flow.executorPost = "这是测试这是测试这是测试这是测试这是测试这是测试这是测试这是测试";
          flow.nameLabel = flow.executorName;
          if (flow.executorProperties) {
            const list = flow.executorProperties.split(';');
            for (const property of list) {
              const str = '[' + property + ']';
              flow.nameLabel = flow.nameLabel + str;
            }
          }
        }
        let idx = this.historyList.length - 1;

        while (idx > 0) {
          const flow = this.historyList[idx];
          const prev = this.historyList[idx - 1];

          if (flow.execOrder === prev.execOrder) {
            prev.execOrderSpan = (flow.execOrderSpan ?? 1) + 1;
            flow.execOrderSpan = undefined;
          }
          idx--;
        }
        for (let i = 0; i < this.historyList.length; i++) {
          const cur = this.historyList[i];
          const next = this.historyList[i + 1];
          cur.hide = true;
          if (cur.workFlowId !== next?.workFlowId) {
            cur.hiddenTrigger = true;
          }
        }
      });
    }
    this.calcExecOrderSpan();
    this.getOrganizationDepartment();
  }

  trig(history: WorkFlowTask) {
    const cur = JSON.parse(JSON.stringify(this.historyList));
    for (const item of cur) {
      if (item.workFlowId === history.workFlowId) {
        item.hide = !item.hide;
      }
    }
    this.historyList = cur;
  }

  removeFlow(index: number) {
    const flow = this.workReport?.form?.formFlows![index];
    if (
      flow?.newAdded ||
      (!flow?.newAdded &&
        flow?.step !== FormFlowType.PRESENT &&
        flow?.step !== FormFlowType.CC)
    ) {
    }
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '删除' + (flow?.step === FormFlowType.CC ? '抄送' : '呈递'),
        content: '确认删除？',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.confirm,
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: () => {
          results.modalInstance.hide();
          this.workReport!.form!.formFlows!.splice(index, 1);
          this.calcExecOrderSpan();
        },
      },
    });
  }

  addPrCC(
    auto: boolean,
    isCC: boolean,
    index: number,
    id?: number,
    org?: OrganizationMember,
    musted?: boolean
  ) {
    const flow = new FormFlowInfo();
    flow.step = isCC ? FormFlowType.CC : FormFlowType.PRESENT;
    flow.newAdded = true;
    flow.enable = true;
    flow.formEnable = true;
    flow.auto = auto;
    flow.musted = musted;
    flow.signTaskType = WorkFlowSignerTaskType.ALL;
    if (id) {
      flow.organizationId = id;
      flow.organizationType = this.orgList.find((o) => +o.id! === +id!)?.type;
    }
    if (this.workReport?.form?.formFlows) {
      if (index === -1) {
        const last =
          this.workReport.form.formFlows[
            this.workReport.form.formFlows.length - 1
          ];
        flow.execOrder = last.execOrder! + 1;
        this.workReport.form.formFlows.push(flow);
      } else {
        const prev = this.workReport.form.formFlows[index];
        const next = this.workReport.form.formFlows[index + 1];

        flow.execOrder = prev.execOrder! + 1;
        let actNum = 1;
        if (prev && next && prev.execOrder === next.execOrder) {
          actNum += 1;
        }
        for (
          let i = index + 1;
          i < this.workReport.form.formFlows.length;
          i++
        ) {
          this.workReport.form.formFlows[i].execOrder! += actNum;
        }
        this.workReport.form.formFlows.splice(index + 1, 0, flow);
      }
    } else {
      this.workReport!.form!.formFlows = [flow];
      flow.execOrder = 1;
    }

    if (org?.userId) {
      this.staffUserService.findById(org.userId).subscribe((res: User) => {
        const userInfo = new UserInfo();
        userInfo.organizationType = res.organizationType;
        userInfo.organizationId = res.organizationId;
        userInfo.departmentId = res.departmentId;
        userInfo.postId = res.postId;
        userInfo.userId = res.id;
        userInfo.userName = res.name;
        userInfo.performance = res.performance;
        userInfo.postPerformance = res.postPerformance;
        flow.userInfoList = [userInfo];
      });
    } else {
      flow.userInfoList = [new UserInfo()];
    }
    this.calcExecOrderSpan();
  }

  private handleDisabledAddress(formFlowInfos: FormFlowInfo[]) {
    const disabledAddressSet = new Set<string>();
    for (const flow of formFlowInfos) {
      if (
        flow.step === FormFlowType.SIGN ||
        flow.step === FormFlowType.SIGN_SEAL ||
        flow.step === FormFlowType.SEAL
      ) {
        for (const item of flow.addressList ?? []) {
          if (
            item.signFieldType === WorkFlowSignFieldType.SIGN_FIELD ||
            item.signFieldType === WorkFlowSignFieldType.SEAL_FIELD
          ) {
            flow.musted = !!item.musted;
          }
          disabledAddressSet.add(item.address!);
        }
        disabledAddressSet.add(flow.address!);
      }
    }
    this.disabledAddress = Array.from(disabledAddressSet);
  }

  onFlowDisableChange($event: { address: string; disabled: boolean }) {
    for (const flow of this.workReport?.form?.formFlows ?? []) {
      if (flow.address === $event.address) {
        flow.enable = !$event.disabled;
        flow.formEnable = !$event.disabled;
      }
    }
  }

  onChange(config: ExcelTable[]) {
    this.workReport!.form!.formData = config;
  }

  upload(event: any, docType: WorkReportDocType) {
    const files = event.target['files'];
    this.workReportService
      .uploadFileWithoutProgress(files[0], docType)
      .subscribe((url: WorkReportUploadResponse) => {
        // event.target['value'] = '';
        if (url) {
          this.workReport!.numbersOfPdfPages = url.numbersOfPdfPages;
          this.workReport!.orginFilesList = [url.fileId!];
          this.workReport!.orginFiles = JSON.stringify(
            this.workReport!.orginFilesList
          );
          this.workReport!.pdfFilesList = [url.pdfFileId!];
          this.workReport!.pdfFiles = JSON.stringify(
            this.workReport?.pdfFilesList
          );
          this.workReport!.docType = docType;
          this.workReport!.docId = url.fileId;
          this.workReport!.docName = url.fileName;
          if (docType === WorkReportDocType.PDF_SIGNED) {
            this.workReport!.signFilesList = [url.pdfFileId!];
            this.workReport!.signFiles = JSON.stringify(
              this.workReport?.signFilesList
            );
          } else {
            this.workReport!.signFilesList = [];
            this.workReport!.signFiles = '[]';
          }
        }
      });
  }

  checkBeforeConfirm(): string {
    if (this.workReport!.docType !== WorkReportDocType.DEFAULT) {
      return '';
    }
    const groupMap: { [key: string]: string | undefined } = {};
    for (const item of this.workReport!.form!.formData ?? []) {
      for (const row of item.rows ?? []) {
        for (const cell of row.columns ?? []) {
          if (this.disabledAddress.includes(cell.address!)) {
            continue;
          }
          if (
            !!cell.formItem &&
            !!cell.formItem.musted &&
            !!cell.formItem.widget &&
            cell.formItem.type !== FormItemType.CONSTANT &&
            cell.formItem.widget !== FormItemWidgetType.EMPTY
          ) {
            if (cell.formItem?.widget === FormItemWidgetType.RADIO) {
              if (!groupMap[cell.formItem?.dataSourceObject.group]) {
                groupMap[cell.formItem?.dataSourceObject.group] = '';
              }
              if (cell.value === '☑') {
                groupMap[cell.formItem?.dataSourceObject.group] = cell.address;
              }
            } else if (cell.formItem?.widget === FormItemWidgetType.IMAGE) {
              if (!cell.imgFileIds || cell.imgFileIds.length === 0) {
                return `必填的${
                  FormItemWidgetTypeMap[cell.formItem?.widget]
                }未填写`;
              }
            } else if (cell.formItem?.widget === FormItemWidgetType.DATE) {
              if (!cell.value) {
                return `必填的${
                  FormItemWidgetTypeMap[cell.formItem?.widget]
                }未填写`;
              }
              let ds = cell.value
                .replace('年', '-')
                .replace('月', '-')
                .replace('日', '')
                .replace('一', '')
                .replace('二', '')
                .replace('三', '')
                .replace('四', '')
                .replace('五', '')
                .replace('六', '')
                .replace('日', '')
                .replace('分', '')
                .replace('时', ':')
                .replace(' 星期', '');
              if (ds[ds.length - 1] === ':') {
                ds += '00';
              }

              if (!moment(ds).isValid()) {
                return `必填的日期不合法`;
              }
            } else if (!cell.value) {
              return `必填的${
                FormItemWidgetTypeMap[cell.formItem?.widget]
              }未填写`;
            }
          }
        }
      }
    }
    for (const key in groupMap) {
      if (!groupMap[key]) {
        return `必填的单选未选择`;
      }
    }
    return '';
  }

  get canConfirm(): boolean {
    if (this.workReport?.docType === WorkReportDocType.PDF_SIGNED) {
      return true;
    }
    for (const item of this.workReport!.form!.formFlows ?? []) {
      if (!item.musted || !item.enable || !item.formEnable) {
        continue;
      }
      if (
        item.step === FormFlowType.SIGN ||
        item.step === FormFlowType.SIGN_SEAL ||
        item.step === FormFlowType.SEAL
      ) {
        if (!item.userInfoList || item.userInfoList.length === 0) {
          return false;
        }
        if (!item.userInfoList[0].userId) {
          return false;
        }
      }
    }
    return true;
  }

  confirm() {
    if (this.submiting || !this.canConfirm) {
      return;
    }
    this.submited = true;
    const content = this.checkBeforeConfirm();
    if (!!content) {
      const result = this.modalService.open({
        backdropCloseable: false,
        component: AlertDialogComponent,
        onClose: () => {},
        data: {
          title: '填写未完成',
          content,
          confirmBtnText: '确认',
          cancelBtnText: '确认',
          contentStyle: 'danger',
          type: AlertDialogType.simple,
          onCancel: () => {
            result.modalInstance.hide();
          },
        },
      });
      return;
    }
    if (
      this.workReport!.numbersOfPdfPages &&
      this.workReport!.numbersOfPdfPages > 0
    ) {
      const results = this.modalService.open({
        backdropCloseable: false,
        component: this.needFill
          ? CustomMenuFormFillDialogComponent
          : DialogGl105DateComponent,
        onClose: () => {},
        data: {
          data: this.workReport,
          onCancel: () => {
            results.modalInstance.hide();
          },
          onConfirm: (data: WorkReport) => {
            this.workReport = data;
            results.modalInstance.hide();
            this.submit();
          },
        },
      });
    } else {
      this.submit();
    }
  }

  previewAttachment(fileId: string) {
    window.open(environment.fileHost + fileId, '_blank');
  }

  uploadAttachment(event: any) {
    const files: FileList = event.target['files'];
    const fileList = Array.from(files);
    zip(
      fileList.map((file: File) => {
        return this.commonService.uploadFileWithoutProgress(file);
      })
    ).subscribe((res: FileResponse[]) => {
      event.target['value'] = '';
      if (this.workReport!.attachs === undefined) {
        this.workReport!.attachs = [];
      }
      for (const url of res) {
        this.workReport!.attachs!.push({
          fileName: url.fileName,
          fileSource: FileSource.MANUAL,
          fileId: url.fileId,
          fileType: url.fileType,
        });
      }
    });
  }

  removeAttachment(index: number) {
    this.workReport!.attachs!.splice(index, 1);
  }

  submiting = false;

  removeDraft() {
    if (!this.workReport!.id || this.submiting) {
      return;
    }
    this.submiting = true;
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '删除',
        content: this.workReport!.fileCountersign
          ? '会签单删除后，主文件依然保留。确认删除？'
          : '删除后，此报表所有记录均被删除！',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.prompt,
        onCancel: () => {
          this.submiting = false;
          results.modalInstance.hide();
        },
        onConfirm: () => {
          this.workReportService
            .remove(this.workReport!.id!)
            .pipe(
              catchError((err) => {
                this.submiting = false;
                return throwError(err);
              })
            )
            .subscribe((res: number) => {
              this.submiting = false;
              if (res) {
                // this.back();
                this.closeFlow.emit(this.workReport.workFlowId);
              }
            });
          results.modalInstance.hide();
        },
      },
    });
  }

  private handleBeforeSubmit(isDraft?: boolean) {
    const workReport = JSON.parse(
      JSON.stringify(this.workReport)
    ) as WorkReport;
    for (const table of workReport?.form?.formData ?? []) {
      for (const row of table.rows ?? []) {
        for (const cell of row.columns ?? []) {
          if (cell.type === FormItemType.VARIABLES) {
            if (cell.formItem?.widget === FormItemWidgetType.PROJECT) {
              cell.formItem.dataSourceCascader = [];
            }
            if (
              cell.formItem?.widget === FormItemWidgetType.FLOW_CC ||
              cell.formItem?.widget === FormItemWidgetType.FLOW_PRESENT
            ) {
              if (!isDraft) {
                cell.value = cell.valueHtml;
              } else {
                cell.value = JSON.stringify(cell.valueCascader ?? []);
              }
            }
          }
        }
      }
    }
    for (const flow of workReport?.form?.formFlows ?? []) {
      if (flow.step === FormFlowType.FILL_IN) {
        const userInfo = new UserInfo();
        userInfo.organizationType =
          this.globalService.userInfo?.organizationType;
        userInfo.organizationId = this.globalService.userInfo?.organizationId;
        userInfo.departmentId = this.globalService.userInfo?.departmentId;
        userInfo.postId = this.globalService.userInfo?.postId;
        userInfo.userId = this.globalService.userInfo?.id;
        userInfo.userName = this.globalService.userInfo?.name;
        userInfo.performance = this.globalService.userInfo?.performance;
        userInfo.postPerformance = this.globalService.userInfo?.postPerformance;
        userInfo.organizationName =
          this.globalService.userInfo?.organizationName;
        userInfo.organizationShortName =
          this.globalService.userInfo?.organizationShortName;
        userInfo.departmentName = this.globalService.userInfo?.departmentName;
        userInfo.postName = this.globalService.userInfo?.postName;
        flow.userInfoList = [userInfo];
      }
      if (
        !isDraft &&
        (flow.actionType === WorkFlowActionType.ANY ||
          flow.actionType === WorkFlowActionType.ALL)
      ) {
        flow.userInfoList?.pop();
      }
      flow.userIds = [];
      for (const user of flow.userInfoList ?? []) {
        if (user.userId) {
          flow.userIds.push(user.userId);
        }
      }
      // flow.userInfoList = (flow.userInfoList ?? []).filter((u) => !!u.userId);
    }
    return workReport;
  }

  save() {
    if (this.submiting) {
      return;
    }
    this.submiting = true;
    if (!!this.workReport?.id) {
      this.workReportService
        .update(this.handleBeforeSubmit(true))
        .pipe(
          catchError((err) => {
            this.submiting = false;
            return throwError(err);
          })
        )
        .subscribe((res: boolean) => {
          this.submiting = false;
          if (res) {
            this.nzMessageService.success('保存成功');
            this.saveFlow.emit(this.workReport.workFlowId);
          }
        });
    } else {
      this.workReport!.projectId = this.globalService.projectId;
      this.workReportService
        .create(this.handleBeforeSubmit(true))
        .pipe(
          catchError((err) => {
            this.submiting = false;
            return throwError(err);
          })
        )
        .subscribe((res: WorkReport) => {
          this.submiting = false;
          if (res) {
            this.nzMessageService.success('保存成功');
            this.saveFlow.emit(res.workFlowId);
          }
        });
    }
  }

  submit() {
    this.submiting = true;
    this.workReport!.projectId = this.globalService.projectId;
    this.workReportService
      .submit(this.handleBeforeSubmit())
      .pipe(
        catchError((err) => {
          this.submiting = false;
          return throwError(err);
        })
      )
      .subscribe((res: number) => {
        this.submiting = false;
        if (res) {
          this.workReportService.findById(res).subscribe((workReport) => {
            this.startFlow.emit(workReport.workFlowId);
          });
        }
      });
  }

  cancelDraft() {
    this.cancelDraftFlow.emit();
  }
}
