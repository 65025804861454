import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceType } from '../models/device/device-type.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceTypeService extends RequestService<DeviceType> {
  static PATH = '/device/type';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, DeviceTypeService.PATH, globalService.debugUrl);
  }
}
