import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { TransformableElement } from 'ng-devui';
import { last, map } from 'rxjs';
import { UploadProgress } from '../../providers/request/upload-progress.type';
import {
  FilePreviewType,
  FilePreviewTypeListMap,
} from '../file-preview-entry/file-preview-entry.component';
import { CommonService } from './../../services/common.service';
import { environment } from 'src/environments/environment';
import { FileResponse } from '../../providers/request/file-response.model';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent implements OnInit {
  @Input('config')
  data!: FilePreviewConfig;

  ViewScreenType = ViewScreenType;

  FilePreviewTypeListMap = FilePreviewTypeListMap;
  FilePreviewType = FilePreviewType;
  transformableImageElementRef!: TransformableElement;

  environment = environment;

  height = 0;
  width = 0;
  index = 0;
  uploadProgresses: UploadProgress[] = [];

  pdfZoom = 1;
  pdfPages = 1;
  pdfTotal = 0;

  progress: UploadProgress = {
    progress: 0,
    uploading: false,
    msg: '',
  };

  fileTypes: FilePreviewType[] = [];

  constructor(
    private elementRef: ElementRef,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    window.addEventListener('resize', () => {
      this.resize();
    });
    setTimeout(() => {
      this.elementRef.nativeElement.parentElement.style.background =
        'transparent';

      this.onFileListChange(true);
      this.resize();
      this.changePreview(this.data.index ?? 0);
    }, 100);
  }

  dismiss(changeToVertical?: boolean) {
    if (this.transformableImageElementRef) {
      this.transformableImageElementRef.removeElementListener();
    }
    this.data.onDismiss(changeToVertical);
  }

  onPageLoad(event: any) {
    this.pdfTotal = event.source.pdfDocument._pdfInfo.numPages;
  }

  prePage() {
    if (this.pdfPages > 1) {
      this.pdfPages--;
    }
  }

  nextPage() {
    if (this.pdfPages < this.pdfTotal) {
      this.pdfPages++;
    }
  }

  originSize() {
    if (this.fileTypes[this.index] === FilePreviewType.Image) {
      this.transformableImageElementRef.setOriginalScale();
    }
    if (this.fileTypes[this.index] === FilePreviewType.PDF) {
      this.pdfZoom = 1;
    }
  }

  zoomIn() {
    if (this.fileTypes[this.index] === FilePreviewType.Image) {
      this.transformableImageElementRef.zoomIn();
    }
    if (this.fileTypes[this.index] === FilePreviewType.PDF) {
      this.pdfZoom += 0.1;
      if (this.pdfZoom > 2) {
        this.pdfZoom = 2;
      }
    }
  }

  zoomOut() {
    if (this.fileTypes[this.index] === FilePreviewType.Image) {
      this.transformableImageElementRef.zoomOut();
    }
    if (this.fileTypes[this.index] === FilePreviewType.PDF) {
      this.pdfZoom -= 0.1;
      if (this.pdfZoom < 0.5) {
        this.pdfZoom = 0.5;
      }
    }
  }

  download() {
    const downloadLink = document.createElement('a');
    const url = this.data.fileList[this.index];
    downloadLink.href = environment.fileHost + url;
    const fileName = url.split('/').pop();
    downloadLink.target = '_blank';
    downloadLink.download = fileName!;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  resize() {
    switch (this.data.screenType) {
      case ViewScreenType.FullScreen:
        this.height = document.body.clientHeight;
        this.width = document.body.clientWidth;
        break;
      case ViewScreenType.HalfBottom:
        this.height = document.body.clientHeight / 2;
        this.width = document.body.clientWidth;
        break;
      case ViewScreenType.Normal:
      default:
        if (this.fileTypes[this.index] === FilePreviewType.PDF) {
          this.height = document.body.clientHeight;
          this.width = document.body.clientWidth;
        } else {
          this.height = 900;
          this.width = 1200;
        }
        break;
    }
  }

  viewVertical() {
    this.dismiss(true);
  }

  remove() {
    this.data.fileList.splice(this.index, 1);
    this.fileTypes.splice(this.index, 1);
    this.data.onFileChange(this.data.fileList);
    if (this.data.fileList.length === 0) {
      this.dismiss();
    }
  }

  onFileListChange(isInit?: boolean) {
    const fileTypes: FilePreviewType[] = [];
    for (const i of this.data.fileList) {
      const extName = i.split('.').pop();
      switch (extName) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'bmp':
        case 'webp':
        case 'svg':
          fileTypes.push(FilePreviewType.Image);
          break;
        case 'pdf':
          fileTypes.push(FilePreviewType.PDF);
          break;
      }
    }
    this.fileTypes = fileTypes;
    if (!isInit) {
      this.data.onFileChange(this.data.fileList);
    }
  }

  changePreview(index: number) {
    this.index = index;
    this.pdfZoom = 1;
    this.pdfPages = 1;
    if (this.transformableImageElementRef) {
      this.transformableImageElementRef.removeElementListener();
    }
    setTimeout(() => {
      this.resize();
      const e = this.getImgElement();
      if (e) {
        let scale = 1;
        if (e.clientHeight > e.clientWidth) {
          scale = this.height / e.clientHeight;
        } else {
          scale = this.width / e.clientWidth;
        }
        if (scale > 1) {
          scale = 1;
        }
        this.transformableImageElementRef = new TransformableElement(e, {
          zoom: scale,
        });
      }
    }, 200);
  }

  private getEventMessage(event: HttpEvent<any>, index: number) {
    if (event.type === HttpEventType.UploadProgress) {
      const percentDone = Math.round((100 * event.loaded) / (event.total ?? 0));
      this.uploadProgresses[index].progress = percentDone;
      this.uploadProgresses[index].uploading = true;
    }

    this.progress.progress =
      this.uploadProgresses.reduce((acc, cur) => acc + cur.progress, 0) /
      this.uploadProgresses.length;

    if (
      event.type === HttpEventType.ResponseHeader ||
      event.type === HttpEventType.Response
    ) {
      this.uploadProgresses[index].uploading = false;
    }

    let uploading = false;
    for (const p of this.uploadProgresses) {
      if (p.uploading) {
        uploading = true;
        break;
      }
    }
    this.progress.uploading = uploading;

    if (event.type === HttpEventType.Response) {
      return event.body;
    }
  }

  upload(event: any) {
    let count = 0;
    const files = event.target.files;
    this.uploadProgresses = [];
    for (const file of files) {
      if (count >= this.data.limit - this.data.fileList.length) {
        break;
      }
      const index = count;
      count++;
      this.uploadProgresses.push({
        progress: 0,
        uploading: true,
        msg: '',
      });
      this.commonService
        .uploadFile(file)
        .pipe(
          map((event) => this.getEventMessage(event, index)),
          last()
        )
        .subscribe((data: FileResponse) => {
          this.data.fileList.push(data.fileId);
          this.onFileListChange();
        });
    }
  }

  private getImgElement(): HTMLElement {
    return this.elementRef.nativeElement.querySelector('img.preview');
  }
}

export class FilePreviewConfig {
  fileList!: string[];
  limit!: number;
  previewType!: FilePreviewType;
  screenType!: ViewScreenType;
  disabled?: boolean = false;
  index?: number = 0;
  onDismiss!: (changeToVertical?: boolean) => void;
  onFileChange!: (fileList: string[]) => void;
}

export enum ViewScreenType {
  FullScreen = 'FullScreen',
  Normal = 'Normal',
  HalfBottom = 'HalfBottom',
}
