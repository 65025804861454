import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProjectTypeExt } from '../models/project/project-type-ext.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectTypeExtService extends RequestService<ProjectTypeExt> {
  static PATH = '/project/type/ext';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, ProjectTypeExtService.PATH, globalService.debugUrl);
  }

  batchUpdate(data: ProjectTypeExt[]) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${ProjectTypeExtService.PATH}/batchUpdate`,
      data
    );
  }

  listProjectParentType() {
    return this.httpClient.get<ProjectTypeExt[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${ProjectTypeExtService.PATH}/listProjectParentType`
    );
  }
}
