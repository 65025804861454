import { Component } from '@angular/core';
import { GlobalService } from 'src/app/modules/shared/providers/global.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  mit = '';

  constructor(private globalService: GlobalService) {
    // this.post();
    this.mit = this.globalService.mit;
  }
  // post() {
  //   // 打开页面
  //   const openData = {
  //     type: 'open',
  //     url: 'http://104test.hzsksc.com:9092',
  //   };
  //   window.parent.postMessage(JSON.stringify(openData), '*');
  //   // 关闭页面
  //   const closeData = {
  //     type: 'close',
  //   };
  //   window.parent.postMessage(JSON.stringify(closeData), '*');
  // }
}
