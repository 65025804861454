<div class="view-container">
	<form class="table-container" [formGroup]="form" (ngSubmit)="login(form.value)" novalidate>
		<div class="dialog" *ngIf="init && !query">
			<div class="dialog-header">
				数智建管平台-质量模块

			</div>
			<div class="dialog-body">
				<div class="grid">
					<div class="row">
					</div>
					<div class="row">
						<div class="column text-right {{
							(phone.hasError('pattern') || phone.hasError('required')) && phone.touched ? 'color-danger' :
							'color-primary'
							}}" [style.width]="'7rem'">
							{{
							(phone.hasError('pattern') || phone.hasError('required')) && phone.touched ? "手机格式错误" :
							'请输入手机号'
							}}
						</div>
						<div class="column" [style.width]="'1rem'"></div>
						<div class="column center" [style.width]="'20rem'">
							<input type="tel" [formControl]="phone">
						</div>
						<div class="column" [style.width]="'1rem'"></div>
					</div>
					<div style="height: 0.5rem"></div>
					<div class="row">
						<div class="column text-right {{password.hasError('required') && password.touched ? 'color-danger text-left' : 'color-primary'}}"
							[style.width]="'7rem'">
							请输入密码
						</div>
						<div class="column" [style.width]="'1rem'"></div>
						<div class="column center" [style.width]="'20rem'">
							<input type="password" [formControl]="password">
						</div>
						<div class="column" [style.width]="'1rem'"></div>
					</div>
				</div>
			</div>
			<div class="dialog-footer">
				<button class="primary" type="submit" [disabled]="!form.valid || submitting">
					{{submitting ? '登录中' : '登录'}}
				</button>
			</div>
			<div class="dialog-mit">
				<div class="grid">
					<div class="row">
						<span>
							<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{mit}}</a>
						</span>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>