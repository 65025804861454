import { SearchRequest } from '../../providers/request/search-request.model';
import { AuthStatus } from './auth-status.enum';
import { OrganizationProjectSection } from './organization-project-section.model';
import { OrganizationSealUserInfo } from './organization-seal-user-info.model';
import { OrganizationType } from './organization-type.enum';
import { SealStyle } from './seal-style.enum';

export class OrganizationExtSearchRequest extends SearchRequest {
  organizationId?: number;
  blImgId?: string;
  taxId?: string;
  corporatePhone?: string;
  corporateName?: string;
  corporateIdCardNo?: string;
  authStatus?: AuthStatus;
  sealStyle?: SealStyle;
  sealUsers?: Array<OrganizationSealUserInfo>;
  comment?: string;
  projectId?: number;
  projectSections?: Array<OrganizationProjectSection>;
  name?: string;
  projectOrgName?: string;
  type?: OrganizationType;
  logoUrl?: string;
}
