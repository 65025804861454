export enum WorkFlowSignerTaskType {
  ALL = 'ALL',
  ANY = 'ANY',
  ONLY = 'ONLY',
}
export const WorkFlowSignerTaskTypeMap = {
  [WorkFlowSignerTaskType.ALL]: '多人签字',
  [WorkFlowSignerTaskType.ANY]: '任一签字',
  [WorkFlowSignerTaskType.ONLY]: '唯一签字',
};

export const WorkFlowSignerTaskTypeList = [
  {
    value: WorkFlowSignerTaskType.ALL,
    label: WorkFlowSignerTaskTypeMap[WorkFlowSignerTaskType.ALL],
  },
  {
    value: WorkFlowSignerTaskType.ANY,
    label: WorkFlowSignerTaskTypeMap[WorkFlowSignerTaskType.ANY],
  },
  {
    value: WorkFlowSignerTaskType.ONLY,
    label: WorkFlowSignerTaskTypeMap[WorkFlowSignerTaskType.ONLY],
  },
];
