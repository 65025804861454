import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventService, EventType } from './event.service';
import { GlobalService } from './global.service';

@Injectable()
export class SocketHandler {
  private socket: WebSocket | undefined;

  constructor(private events: EventService, private global: GlobalService) {}

  // init(): void {
  //   this.socket = new WebSocket(
  //     this.global.debugWs
  //       ? this.global.debugWs
  //       : environment.socket + '/' + this.global.userInfo.id
  //   );
  //   this.socket.onopen = (): any => {};
  //   this.socket.onmessage = (messageEvent: MessageEvent<string>) => {
  //     try {
  //       const data: PushMessage = JSON.parse(messageEvent.data) as PushMessage;
  //       this.events.broadcast(EventType.SocketEvent, data);
  //     } catch (error) {}
  //   };
  //   this.socket.onclose = (): any => {
  //     this.init();
  //   };
  //   this.socket.onerror = (): any => {
  //     this.socket?.close();
  //   };
  // }
}
