import { OrganizationType } from '../staff/organization-type.enum';

export class UserInfo {
  organizationType?: OrganizationType;
  organizationId?: number;
  organizationName?: string;
  organizationShortName?: string;
  departmentId?: number;
  departmentName?: string;
  postId?: number;
  postName?: string;
  postPerformance?: boolean;
  userId?: number;
  userName?: string;
  userPhone?: string;
  performance?: boolean;
  supervision?: boolean;
  relatedSupervision?: boolean;
  sealHolder?: boolean;
  editAble?: boolean = true;
  fileId?: string;
  createTime?: number;
  operateTime?: number;
  // 前端自用字段
  nameLabel?: string;
  orgLabel?: string;
  auto?: boolean;
  retention?: string;
}
