export enum WorkFlowActionType {
  FILL_IN = 'FILL_IN',
  SIGN = 'SIGN',
  SEAL = 'SEAL',
  SIGN_SEAL = 'SIGN_SEAL',
  SIGN_SYNC = 'SIGN_SYNC',
  ALL = 'ALL',
  ANY = 'ANY',
  PRESENT = 'PRESENT',
  CC = 'CC',
}

export const WorkFlowActionTypeMap = {
  [WorkFlowActionType.FILL_IN]: '填写',
  [WorkFlowActionType.SIGN]: '签字',
  [WorkFlowActionType.SEAL]: '盖章',
  [WorkFlowActionType.SIGN_SEAL]: '签章',
  [WorkFlowActionType.SIGN_SYNC]: '同步签',
  [WorkFlowActionType.ALL]: '多人签',
  [WorkFlowActionType.ANY]: '任一签',
  [WorkFlowActionType.PRESENT]: '呈递',
  [WorkFlowActionType.CC]: '抄送',
};

export const WorkFlowActionTypeList = [
  {
    label: WorkFlowActionTypeMap[WorkFlowActionType.FILL_IN],
    value: WorkFlowActionType.FILL_IN,
  },
  {
    label: WorkFlowActionTypeMap[WorkFlowActionType.SIGN],
    value: WorkFlowActionType.SIGN,
  },
  {
    label: WorkFlowActionTypeMap[WorkFlowActionType.SEAL],
    value: WorkFlowActionType.SEAL,
  },
  {
    label: WorkFlowActionTypeMap[WorkFlowActionType.SIGN_SEAL],
    value: WorkFlowActionType.SIGN_SEAL,
  },
  {
    label: WorkFlowActionTypeMap[WorkFlowActionType.SIGN_SYNC],
    value: WorkFlowActionType.SIGN_SYNC,
  },
  {
    label: WorkFlowActionTypeMap[WorkFlowActionType.ALL],
    value: WorkFlowActionType.ALL,
  },
  {
    label: WorkFlowActionTypeMap[WorkFlowActionType.ANY],
    value: WorkFlowActionType.ANY,
  },
  {
    label: WorkFlowActionTypeMap[WorkFlowActionType.PRESENT],
    value: WorkFlowActionType.PRESENT,
  },
  {
    label: WorkFlowActionTypeMap[WorkFlowActionType.CC],
    value: WorkFlowActionType.CC,
  },
];

export const WorkFlowActionTypeChangeableList = [
  {
    label: WorkFlowActionTypeMap[WorkFlowActionType.SIGN],
    value: WorkFlowActionType.SIGN,
  },
  {
    label: WorkFlowActionTypeMap[WorkFlowActionType.SIGN_SYNC],
    value: WorkFlowActionType.SIGN_SYNC,
  },
  {
    label: WorkFlowActionTypeMap[WorkFlowActionType.ALL],
    value: WorkFlowActionType.ALL,
  },
  {
    label: WorkFlowActionTypeMap[WorkFlowActionType.ANY],
    value: WorkFlowActionType.ANY,
  },
];
