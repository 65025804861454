import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProjectSectionUnitProgressSummaryRequest } from '../models/project/project-section-unit-progress-summary-request.model';
import { ProjectSectionUnitProgressSummary } from '../models/project/project-section-unit-progress-summary.model';
import { GlobalService } from '../providers/global.service';
import { FileResponse } from '../providers/request/file-response.model';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectProgressService extends RequestService<ProjectSectionUnitProgressSummary> {
  static PATH = '/project/progress';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, ProjectProgressService.PATH, globalService.debugUrl);
  }

  summary(searchRequest: ProjectSectionUnitProgressSummaryRequest) {
    return this.httpClient.post<ProjectSectionUnitProgressSummary[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${ProjectProgressService.PATH}/summary`,
      searchRequest
    );
  }

  uploadFileWithoutProgress(file: File, projectId: number, sectionId: number) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('projectId', projectId.toString());
    formData.append('sectionId', sectionId.toString());
    return this.httpClient.post<FileResponse>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${ProjectProgressService.PATH}/upload`,
      formData
    );
  }
}
