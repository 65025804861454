import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/zh-cn';
import {
  AlignMap,
  JustifyMap,
} from '../../models/form/cell-alignment-type.enum';
import { DetectionType } from '../../models/form/detection.enum';
import { ExcelTableRowColumn } from '../../models/form/excel-table-row-column.model';
import { ExcelTable } from '../../models/form/excel-table.model';
import { FormItemType } from '../../models/form/form-item-type.enum';
import { FormItemWidgetType } from '../../models/form/form-item-widget-type.enum';
import { OrganizationListRequest } from '../../models/staff/organization-list-request.model';
import { Organization } from '../../models/staff/organization.model';
import { NzTreeNode } from '../../models/tree-node.model';
import { WeatherInfo } from '../../models/weather/weather-info.model';
import { EventService, EventType } from '../../providers/event.service';
import { GlobalService } from '../../providers/global.service';
import { CommonService } from '../../services/common.service';
import { StaffOrganizationService } from '../../services/staff-organization.service';
import { FilePreviewType } from '../file-preview-entry/file-preview-entry.component';
import { LocationUtil } from '../location-cascader/location.model';
import { WidgetData } from '../../models/form/widget-data.model';

@Component({
  selector: 'spreadsheet-form',
  templateUrl: './spreadsheet-form.component.html',
  styleUrls: ['./spreadsheet-form.component.scss'],
})
export class SpreadsheetFormComponent {
  static ON_SCROLL = 'ON_SCROLL';
  selectedRowIndex = -1;
  selectedColIndex = -1;
  tabId = -1;

  AlignMap = AlignMap;
  JustifyMap = JustifyMap;
  FormItemType = FormItemType;
  DetectionType = DetectionType;

  fileType = FilePreviewType.Image;

  weatherSource: string[] = [];

  copyCache: ExcelTable = {};

  detectionGroup: { [key: string]: DetectionInfo } = {};

  editing: { [key: string]: boolean } = {};

  waitAddressList: string[] = [];

  _submited = false;

  @Input()
  set submited(value: boolean) {
    this._submited = value;
  }

  get submited() {
    return this._submited;
  }

  varCache: { [key: string]: any }[] = [];

  colMap: { [key: string]: ExcelTableRowColumn }[] = [];

  edit(key: string) {
    this.editing[key] = true;
  }

  onBlur() {
    this.editing = {};
  }

  get defaultMax() {
    return Infinity;
  }

  get defaultMin() {
    return -Infinity;
  }

  duplicate() {
    const configCopy = JSON.parse(JSON.stringify(this.copyCache));
    this._config = this.parseConfig([...this._config, configCopy]);
    this.handleWaitAddress();
    this.onChange.emit(this.config);
  }

  remove(index: number) {
    this._config.splice(index, 1);
    this.onChange.emit(this.config);
  }

  get wRate() {
    if (!this._config) {
      return 1;
    }
    if (this.isVertical) {
      if (this._config[0].height! > 800) {
        return 1.05;
      } else {
        return 1.01;
      }
    } else {
      if (this._config[0].height! > 550) {
        return 1.08;
      } else {
        return 1.02;
      }
    }
  }

  get hRate() {
    if (!this._config) {
      return 1;
    }
    if (this.isVertical) {
      if (this._config[0].height! > 800) {
        return 0.85;
      } else {
        return 0.97;
      }
    } else {
      if (this._config[0].height! > 550) {
        return 0.85;
      } else {
        return 0.97;
      }
    }
  }

  fRate = 1;

  get isVertical() {
    if (!this._config) {
      return true;
    }
    return this._config[0].height! > this._config[0].width!;
  }

  @Output()
  onChange = new EventEmitter<ExcelTable[]>();

  @Output()
  onFlowDisableChange = new EventEmitter<{
    address: string;
    disabled: boolean;
  }>();

  @Output()
  onCCChange = new EventEmitter<{
    ids?: string[];
    musted?: boolean;
    init?: boolean;
  }>();

  @Output()
  onPresentChange = new EventEmitter<{
    ids?: string[];
    musted?: boolean;
    init?: boolean;
  }>();

  FormItemWidgetType = FormItemWidgetType;

  disabled = (col: ExcelTableRowColumn) => {
    return this.disabledAddressList.includes(col.address!) || !!col.disable;
  };

  @Input()
  set config(value: ExcelTable[]) {
    this.waitAddressList = [];
    this._config = this.parseConfig(value);
    this.handleWaitAddress();
    if (value.length > 0) {
      this.tabId = this._config[0].tableId!;
    }
    this.copyCache = JSON.parse(JSON.stringify(this._config[0]));
  }

  get config(): ExcelTable[] {
    return this._config;
  }

  @Input()
  set projectData(value: WidgetData[]) {
    if (value) {
      this.projectWidgetData = value;
      this._config = this.parseConfig(this.config);
    }
  }

  projectWidgetData: WidgetData[] = [];

  scrolling = false;

  onScroll() {
    this.scrolling = true;
    this.events.broadcast(SpreadsheetFormComponent.ON_SCROLL);
  }

  onScrollEnd() {
    this.scrolling = false;
  }

  private getNodes(item: WidgetData): NzTreeNode[] {
    if (!item.child || item.child.length === 0) {
      return [
        {
          label: item.value,
          value: item.value,
          code: item.code,
          isLeaf: true,
        },
      ];
    }
    let nodes: NzTreeNode[] = [];
    for (const child of item.child) {
      nodes = nodes.concat(this.getNodes(child));
    }
    return nodes;
  }

  private buildTreeNodes(item: WidgetData, code?: string): NzTreeNode {
    const node: NzTreeNode = {
      label: item.value,
      value: item.value,
      children: [],
      isLeaf: false,
    };
    for (const child of item.child ?? []) {
      if (code && code.indexOf(child.code!) === -1) {
        continue;
      }
      node.children!.push(this.buildTreeNodes(child));
    }
    if (node.children?.length === 0) {
      node.isLeaf = true;
    }
    return node;
  }

  private parseConfig(config: ExcelTable[]) {
    let p = 0;
    for (const table of config) {
      if (!this.varCache[p]) {
        this.varCache[p] = {};
        this.colMap[p] = {};
      }
      for (const row of table.rows ?? []) {
        for (const col of row.columns ?? []) {
          this.colMap[p][col.address!] = col;
          if (!!col.formItem && col.formItem.type === FormItemType.VARIABLES) {
            if (col.formItem.widget === FormItemWidgetType.WORK_PROCEDURE) {
              console.log(col);
              if (col.formItem.dataSource === 'workProcedureDate') {
                col.formItem.widget = FormItemWidgetType.DATE;
                col.formItem.dataSource = col.formItem.format;
              }
              if (col.formItem.dataSource === 'workProcedureImage') {
                col.formItem.widget = FormItemWidgetType.IMAGE;
              }
            }
            if (col.formItem.widget === FormItemWidgetType.RADIO) {
              try {
                col.formItem.dataSourceObject = JSON.parse(
                  col.formItem.dataSource!
                );
                if (col.formItem.defaultValue === col.address && !col.value) {
                  col.valueRadio = col.address;
                  col.value = '☑';
                }
              } catch (error) {}
              if (col.value && col.value === '☑') {
                col.valueRadio = col.address;
              } else {
                col.valueRadio = '';
                col.value = '□';
              }
            }
            if (col.formItem.widget === FormItemWidgetType.SELECT) {
              if (!col.value) {
                col.value = col.formItem.defaultValue ?? '';
              }
              col.formItem.dataSourceList =
                col.formItem.dataSource?.split(',') ?? [];
            }
            if (col.formItem.widget === FormItemWidgetType.CHECKBOX) {
              if (!!col.formItem.defaultValue) {
                col.valueChecked = true;
                if (col.valueChecked?.toString() === 'true') {
                  col.value = '☑';
                } else {
                  col.value = '□';
                }
              }
            }
            if (col.formItem.widget === FormItemWidgetType.DATE) {
              if (!!col.formItem.defaultValue) {
                if (col.formItem.defaultValue === 'TODAY') {
                  const result = moment(
                    col.value,
                    col.formItem?.dataSource ?? 'YYYY年MM月DD日'
                  )
                    .locale('zh_cn')
                    .isValid();
                  if (!result) {
                    const d = new Date();
                    col.valueDate = d;
                    col.value = moment(d.toISOString())
                      .locale('zh_cn')
                      .format(col.formItem?.dataSource ?? 'YYYY年MM月DD日');
                  } else {
                    col.valueDate = moment(
                      col.value,
                      col.formItem?.dataSource ?? 'YYYY年MM月DD日'
                    ).toDate();
                  }
                } else if (col.formItem.defaultValue.indexOf('=') > -1) {
                  this.waitAddressList.push(
                    col.formItem.defaultValue.replace('=', '')
                  );
                }
              }
              if (!!col.value) {
                col.value = col.value
                  .replaceAll('<br/>', '\n')
                  .replaceAll('&ensp;', ' ');
                col.valueHtml = col.value
                  .replaceAll('\n', '<br/>')
                  .replaceAll(' ', '&ensp;');
              }
            }
            if (
              col.formItem.widget === FormItemWidgetType.PERCENT ||
              col.formItem.widget === FormItemWidgetType.NUMERIC ||
              col.formItem.widget === FormItemWidgetType.NORMAL
            ) {
              if (col.value === '') {
                col.value = col.formItem.defaultValue ?? '';
              }
              if (!!col.value) {
                col.value = col.value
                  .replaceAll('<br/>', '\n')
                  .replaceAll('&ensp;', ' ');
                col.valueHtml = col.value
                  .replaceAll('\n', '<br/>')
                  .replaceAll(' ', '&ensp;');
              }
              this.onPureValueChange(col);
            }
            if (col.formItem.widget === FormItemWidgetType.WEATHER) {
              this.weatherSource.push(col.formItem.formula ?? '');
              if (!col.value) {
                this.commonService
                  .getWeather(
                    moment(new Date().toISOString())
                      .locale('zh_cn')
                      .format('YYYY-MM-DD')
                  )
                  .subscribe((res: WeatherInfo) => {
                    if (res) {
                      col.value = col.formItem?.dataSource
                        ? res[col.formItem.dataSource]
                        : '';
                    }
                  });
              }
            }
            if (col.formItem.widget === FormItemWidgetType.MATERIAL) {
              col.formItem.dataSourceCascader = [];
              if (this.projectWidgetData.length > 0) {
                const treeNode: NzTreeNode[] = [];
                for (const item of this.projectWidgetData) {
                  if (
                    col.formItem.dataSource &&
                    col.formItem.dataSource.indexOf(item.code!) > -1
                  ) {
                    treeNode.push(
                      this.buildTreeNodes(item, col.formItem.dataSource)
                    );
                  }
                }
                col.formItem.dataSourceCascader = treeNode;
              }
            }
            if (col.formItem.widget === FormItemWidgetType.PROJECT) {
              col.formItem.dataSourceCascader = [];

              if (this.projectWidgetData.length > 0) {
                if (
                  col.formItem.dataSource &&
                  col.formItem.dataSource.includes('nameOrProjectOrgName')
                ) {
                  col.formItem.dataSource = col.formItem.dataSource.replace(
                    'nameOrProjectOrgName',
                    ''
                  );
                }
                let treeNode: NzTreeNode[] = [];
                for (const item of this.projectWidgetData) {
                  console.log(col.formItem.dataSource);
                  treeNode = treeNode.concat(this.getNodes(item));
                }
                console.log(treeNode);
                if (col.formItem.dataSource) {
                  col.formItem.dataSourceCascader = treeNode.filter((node) =>
                    node.code?.includes(col.formItem!.dataSource!)
                  );
                } else {
                  col.formItem.dataSourceCascader = treeNode;
                }

                // if (treeNode.length > 0) {
                //   if (treeNode.length === 1) {
                //     if (
                //       treeNode[0].children &&
                //       treeNode[0].children.length > 0
                //     ) {
                //       col.formItem.dataSourceCascader = treeNode[0].children;
                //     } else {
                //       col.formItem.dataSourceCascader = treeNode;
                //     }
                //   } else {
                //     col.formItem.dataSourceCascader = treeNode;
                //   }
                // } else {
                //   col.formItem.dataSourceCascader = [];
                // }
              }
            }
            if (col.formItem.widget === FormItemWidgetType.DETECTION) {
              if (
                col.formItem.formula &&
                !this.detectionGroup[col.formItem.formula]
              ) {
                this.detectionGroup[col.formItem.formula!] = {
                  designRealVal: [],
                  biasRealValMin: -Infinity,
                  biasRealValMax: +Infinity,
                  realVal1List: [],
                  realVal2List: [],
                  val1Min: -Infinity,
                  val1Max: +Infinity,
                  val2Min: -Infinity,
                  val2Max: +Infinity,
                  biasError: true,
                };
              }
              if (col.formItem.dataSource === DetectionType.PASS) {
                if (col.value && col.value.indexOf('%') > -1) {
                  col.valuePercent = col.value.replaceAll('%', '');
                }
              }
              if (col.formItem.dataSource === DetectionType.BIAS) {
                if (!col.value) {
                  col.value = col.formItem.defaultValue ?? '';
                }
                this.onDetectionBiasChange(col);
              }
            }
            if (
              col.formItem.widget === FormItemWidgetType.FLOW_CC ||
              col.formItem.widget === FormItemWidgetType.FLOW_PRESENT ||
              col.formItem.widget === FormItemWidgetType.FLOW_CC_ONE ||
              col.formItem.widget === FormItemWidgetType.FLOW_PRESENT_ONE
            ) {
              if (col.value) {
                try {
                  col.valueCascader = JSON.parse(col.value);
                } catch (error) {
                  col.valueCascader = [];
                }
              } else {
                col.valueCascader = [];
              }
              console.warn(col.valueCascader);
              // col.oldValueCascader = '[]';
              col.oldValueCascader = JSON.stringify(col.valueCascader);
              if (
                (col.formItem.widget === FormItemWidgetType.FLOW_CC_ONE ||
                  col.formItem.widget ===
                    FormItemWidgetType.FLOW_PRESENT_ONE) &&
                col.valueCascader &&
                col.valueCascader.length > 0
              ) {
                col.valueSingle = col.valueCascader[0];
              }
              setTimeout(() => {
                this.multiSelectChange(col, true);
              }, 1000);
            }
            if (!col.formItem.widget && !!col.value) {
              col.value = col.value
                .replaceAll('<br/>', '\n')
                .replaceAll('&ensp;', ' ');
              col.valueHtml = col.value
                .replaceAll('\n', '<br/>')
                .replaceAll(' ', '&ensp;');
              this.onPureValueChange(col);
            }
            if (this.disabled(col)) {
              col.value = '';
            }
            this.varCache[p][col.address!] = col.value;
          }
        }
      }
      p++;
    }
    setTimeout(() => {
      this.handleWaitAddress();
    }, 100);
    return config;
  }

  private handleWaitAddress() {
    let p = 0;
    for (const table of this.config) {
      for (const row of table.rows ?? []) {
        for (const col of row.columns ?? []) {
          if (!!col.formItem && col.formItem.type === FormItemType.VARIABLES) {
            if (
              col.formItem.widget === FormItemWidgetType.DATE &&
              !!col.formItem.defaultValue &&
              col.formItem.defaultValue.indexOf('=') === 0
            ) {
              const tarAddress = col.formItem.defaultValue.replace('=', '');
              if (
                !!this.varCache[p][tarAddress] &&
                !!this.colMap[p][tarAddress]
              ) {
                col.value = this.varCache[p][tarAddress];
                col.valueDate = this.colMap[p][tarAddress].valueDate;
              }
            }
            if (
              (col.formItem.widget === FormItemWidgetType.FLOW_CC ||
                col.formItem.widget === FormItemWidgetType.FLOW_PRESENT) &&
              !!col.formItem.defaultValue &&
              col.formItem.defaultValue.indexOf('=') === 0 &&
              col.valueCascader?.length === 0
            ) {
              const tarAddress = col.formItem.defaultValue.replace('=', '');
              if (
                !!this.varCache[p][tarAddress] &&
                !!this.colMap[p][tarAddress]
              ) {
                col.valueRef = this.varCache[p][tarAddress];
                this.multiSelectChange(col);
              }
            }
          }
        }
      }
      p++;
    }
  }

  private _disabledAddressList: string[] = [];
  @Input()
  set disabledAddressList(value: string[]) {
    this._disabledAddressList = value;
    console.log(this.config);
    this.config = this.parseConfig(this.config);
  }

  get disabledAddressList(): string[] {
    return this._disabledAddressList;
  }

  @Input()
  set canAdd(addable: boolean) {
    this._canAdd = addable;
  }

  get canAdd() {
    return this._canAdd;
  }

  _canAdd = false;

  private _config!: ExcelTable[];

  orgList: Organization[] = [];

  orgMap: { [key: number]: Organization } = {};

  constructor(
    private commonService: CommonService,
    private events: EventService,
    private staffOrganizationService: StaffOrganizationService,
    private globalService: GlobalService
  ) {
    this.staffOrganizationService
      .findAll<OrganizationListRequest>({
        projectId: this.globalService.projectId,
        projectSections: this.globalService.userInfo.projectSections ?? [],
      })
      .subscribe((res: Organization[]) => {
        this.orgList = res ?? [];
        for (const org of this.orgList) {
          this.orgMap[org.id!] = org;
        }
      });
  }

  signAddressMap: { [key: string]: string } = {};

  subsciption = (event: any) => {
    console.log(event);
    this.signAddressMap[event['address']] = event['content'];
  };

  ngOnInit(): void {
    this.events.on(EventType.SignUserSelected, this.subsciption);
  }

  ngOnDestroy(): void {
    this.events.destroyListener(EventType.SignUserSelected, this.subsciption);
  }

  // upload(event: any, key: string) {
  //   const files = event.target['files'];
  //   const files$: Observable<FileResponse>[] = [];
  //   for (const file of files) {
  //     files$.push(this.commonService.uploadFileWithoutProgress(file));
  //   }
  //   zip(files$).subscribe((urls: FileResponse[]) => {
  //     event.target['value'] = '';
  //     // if (url) {
  //     //   console.log(url);
  //     // this.result[key] = url;
  //     // }
  //     console.log(urls);
  //   });
  // }

  onImageChange(fileUrls: string[], cell: ExcelTableRowColumn) {
    if (fileUrls.length === 0) {
      cell.imgFileIds = [];
    } else {
      cell.imgFileIds = fileUrls;
    }
  }

  copy(configIndex: number, rowIndex: number) {
    for (const r of this._config[configIndex].rows ?? []) {
      for (const c of r.columns ?? []) {
        if (c.firstRow! < rowIndex && c.lastRow! >= rowIndex) {
          c.lastRow!++;
          c.rowSpan!++;
        }
      }
    }
    const rowCopy = JSON.parse(
      JSON.stringify(this._config[configIndex].rows![rowIndex])
    );
    this._config[configIndex].rows!.splice(rowIndex, 0, rowCopy);
  }

  onPureValueChange(cell: ExcelTableRowColumn) {
    if ((cell.formItem?.formula ?? '').indexOf('DH') === -1) {
      this.handleFormula();
      return;
    }
    cell.valueHtml = cell.value
      ?.replaceAll('\n', '<br/>')
      .replaceAll(' ', '&ensp;');
    const p = document.querySelector(`#${cell.address}`);
    if (p) {
      setTimeout(() => {
        for (const t of this.config) {
          for (const r of t.rows ?? []) {
            for (const c of r.columns ?? []) {
              if (c.address === cell.address) {
                r.dyHeight = (p.clientHeight + 20) / this.hRate;
              }
            }
          }
        }
      }, 100);
    }
    this.handleFormula();
  }

  onDateChange(cell: ExcelTableRowColumn, page: number) {
    if (!cell.valueDate) {
      cell.value = '';
      return;
    }
    const d = new Date(cell.valueDate);
    cell.value = moment(d.toISOString())
      .locale('zh_cn')
      .format(cell.formItem?.dataSource ?? 'YYYY年MM月DD日');
    this.varCache[page][cell.address!] = cell.value;
    this.colMap[page][cell.address!].valueDate = d;
    if (this.weatherSource.includes(cell.address!)) {
      this.commonService
        .getWeather(
          moment(d.toISOString()).locale('zh_cn').format('YYYY-MM-DD')
        )
        .subscribe((res: WeatherInfo) => {
          if (res) {
            this.onWeatherChange(res, cell.address!);
          }
        });
    }
    if (this.waitAddressList.includes(cell.address!)) {
      this.handleWaitAddress();
    }
  }

  onWeatherChange(info: WeatherInfo, address: string) {
    for (const table of this._config) {
      for (const row of table.rows ?? []) {
        for (const col of row.columns ?? []) {
          if (
            !!col.formItem &&
            col.formItem.type === FormItemType.VARIABLES &&
            col.formItem.widget === FormItemWidgetType.WEATHER &&
            col.formItem.formula === address
          ) {
            col.value = col.formItem.dataSource
              ? info[col.formItem.dataSource]
              : '';
          }
        }
      }
    }
    this.handleFormula();
  }

  onRadioChange(dataSourceObject: any) {
    for (const table of this._config) {
      for (const row of table.rows ?? []) {
        for (const col of row.columns ?? []) {
          if (
            col.formItem?.widget === FormItemWidgetType.RADIO &&
            !!col.formItem.dataSourceObject &&
            col.formItem.dataSourceObject.group === dataSourceObject.group
          ) {
            col.valueRadio = dataSourceObject.value;
            if (col.valueRadio === col.address) {
              col.value = '☑';
            } else {
              col.value = '□';
            }
          }
        }
      }
    }
    this.handleFormula();
  }

  onCheckChange(cell: ExcelTableRowColumn) {
    if (cell.valueChecked?.toString() === 'true') {
      cell.value = '☑';
    } else {
      cell.value = '□';
    }
    this.handleFormula();
  }

  onLocationChange(locationId: string, cell: ExcelTableRowColumn) {
    cell.valueLocationId = locationId;
    cell.value = LocationUtil.getLocationName(locationId);
    this.handleFormula();
  }

  fakeSelectChange(cell: ExcelTableRowColumn) {
    cell.valueCascader = cell.valueSingle ? [cell.valueSingle] : [];
    this.multiSelectChange(cell);
  }

  multiSelectChange(cell: ExcelTableRowColumn, init?: boolean) {
    cell.valueHtml = '';
    const nameList = [];
    if (cell.valueCascader) {
      if (cell.valueCascader?.length > 0) {
        for (const item of cell.valueCascader) {
          nameList.push(this.orgMap[item].name);
        }
      } else if (cell.valueCascader.length === 0 && !!cell.valueRef) {
        for (const id in this.orgMap) {
          if (this.orgMap[id].name === cell.valueRef) {
            nameList.push(this.orgMap[id].name);
            cell.valueCascader = [id];
          }
        }
      }
    }
    cell.valueHtml = nameList.join('<br/>');
    cell.value = cell.valueHtml;
    this.handleFormula(init);
  }

  onProjectDataValueChange(cell: ExcelTableRowColumn) {
    if (cell.valueCascader && cell.valueCascader.length > 0) {
      cell.value = cell.valueCascader[cell.valueCascader.length - 1];
    }
    this.handleWaitAddress();
  }

  onDetectionDesignChange(designVal: number[], cell: ExcelTableRowColumn) {
    cell.value = designVal.join(' ');
    const group = cell.formItem?.formula ?? '';
    if (group) {
      this.detectionGroup[group].designRealVal = designVal;
    }
    cell.value = designVal.join(' ');
    this.onDectionChange(group);
  }

  onDetectionRealChange(
    event: {
      realVals: [number[], number[]];
      passVal?: number;
    },
    cell: ExcelTableRowColumn
  ) {
    const group = cell.formItem?.formula ?? '';
    if (group) {
      this.detectionGroup[group].realVal1List = event.realVals[0];
      this.detectionGroup[group].realVal2List = event.realVals[1];
      this.detectionGroup[group].passVal = event.passVal;
      if (this.detectionGroup[group].designRealVal?.length === 2) {
        cell.value =
          event.realVals[0].join(' ') + '\n' + event.realVals[1].join(' ');
      } else {
        cell.value = event.realVals[0].join(' ');
      }
    } else {
      cell.value = event.realVals[0].join(' ');
    }

    this.onDectionChange(group);
  }

  onDetectionPassChange(cell: ExcelTableRowColumn) {
    if (!!cell.valuePercent && !isNaN(+cell.valuePercent)) {
      cell.value = cell.valuePercent + '%';
      if (cell.formItem?.formula) {
        this.detectionGroup[cell.formItem.formula].passVal = +cell.valuePercent;
      }
    } else {
      cell.value = '';
      if (cell.formItem?.formula) {
        this.detectionGroup[cell.formItem.formula].passVal = undefined;
      }
    }
    this.handleFormula();
  }

  onDetectionBiasChange(cell: ExcelTableRowColumn) {
    const group = cell.formItem?.formula ?? '';
    let hasError = false;
    let valStr = cell.formItem?.defaultValue
      ? cell.formItem?.defaultValue
          .replaceAll('&ensp;', ' ')
          .replaceAll('，', ',')
          .replaceAll('＋', '+')
          .replaceAll('－', '-')
          .replaceAll('，', ',')
          .replaceAll('<br/>', ' ')
      : '';
    if (valStr === '' || !group) {
      hasError = true;
    } else if (valStr.indexOf('±') > -1) {
      try {
        const val = valStr.replaceAll(' ', '').split('±')[1].trim();
        if (!isNaN(+val)) {
          this.detectionGroup[group].biasRealValMin = -+val;
          this.detectionGroup[group].biasRealValMax = +val;
          this.detectionGroup[group].biasNeedDesign = true;
        } else {
          hasError = true;
        }
      } catch (error) {
        hasError = true;
      }
    } else if (valStr.indexOf('≥') > -1) {
      const value = valStr.replaceAll('≥', '').trim();
      if (!isNaN(+value)) {
        this.detectionGroup[group].biasRealValMin = +value;
        this.detectionGroup[group].biasNeedDesign = false;
      } else {
        hasError = true;
      }
    } else if (valStr.indexOf('≤') > -1) {
      const value = valStr.replaceAll('≤', '').trim();
      if (!isNaN(+value)) {
        this.detectionGroup[group].biasRealValMax = +value;
        this.detectionGroup[group].biasNeedDesign = false;
      } else {
        hasError = true;
      }
    } else if (valStr.indexOf(',') > -1) {
      try {
        let vals: string[] = [];
        vals = valStr.replaceAll(' ', '').split(',');
        if (vals.length !== 2) {
          hasError = true;
        } else {
          if (isNaN(+vals[0]) || isNaN(+vals[1])) {
            hasError = true;
          } else {
            this.detectionGroup[group].biasRealValMin =
              +vals[0] > +vals[1] ? +vals[1] : +vals[0];
            this.detectionGroup[group].biasRealValMax =
              +vals[1] > +vals[0] ? +vals[1] : +vals[0];
            this.detectionGroup[group].biasNeedDesign = true;
          }
        }
      } catch (error) {
        hasError = true;
      }
    } else {
      hasError = true;
    }
    if (hasError) {
      this.detectionGroup[group].biasRealValMin = -Infinity;
      this.detectionGroup[group].biasRealValMax = +Infinity;
      this.detectionGroup[group].biasNeedDesign = false;
      this.detectionGroup[group].biasError = true;
    } else {
      this.detectionGroup[group].biasError = false;
    }
    this.onDectionChange(group);
  }

  onDectionChange(group: string) {
    const info: DetectionInfo = this.detectionGroup[group];
    if (!group || !info) {
      return;
    }
    if (info.designRealVal?.length === 0 || info.biasError) {
      info.val1Max = +Infinity;
      info.val1Min = -Infinity;
      info.val2Max = +Infinity;
      info.val2Min = -Infinity;
      return;
    }
    if (info.designRealVal) {
      if (info.designRealVal.length > 0) {
        if (info.biasNeedDesign) {
          info.val1Max = info.designRealVal[0] + info.biasRealValMax!;
          info.val1Min = info.designRealVal[0] + info.biasRealValMin!;
        } else {
          info.val1Max = info.biasRealValMax!;
          info.val1Min = info.biasRealValMin!;
        }
      }
      if (info.designRealVal.length > 1) {
        if (info.biasNeedDesign) {
          info.val2Max = info.designRealVal[1] + info.biasRealValMax!;
          info.val2Min = info.designRealVal[1] + info.biasRealValMin!;
        } else {
          info.val2Max = info.biasRealValMax!;
          info.val2Min = info.biasRealValMin!;
        }
      }
    }
    for (const table of this.config) {
      for (const row of table.rows ?? []) {
        for (const col of row.columns ?? []) {
          if (
            col.formItem?.formula === group &&
            col.formItem.widget === FormItemWidgetType.DETECTION &&
            col.formItem.dataSource === DetectionType.PASS
          ) {
            if (
              !info.biasError &&
              info.passVal !== undefined &&
              !isNaN(info.passVal)
            ) {
              col.value = info.passVal + '%';
              col.valuePercent = info.passVal + '';
            } else {
              col.value = '';
              col.valuePercent = '';
            }
            this.handleFormula();
            return;
          }
        }
      }
    }
  }

  submit() {
    this.onChange.emit(this.config);
  }

  private handleFormula(init?: boolean) {
    if (!this.config) return;
    let p = 0;
    for (const table of this.config) {
      if (!this.varCache[p]) {
        this.varCache[p] = {};
      }
      for (const row of table.rows ?? []) {
        for (const col of row.columns ?? []) {
          if (!!col.formItem && col.formItem.type === FormItemType.VARIABLES) {
            this.varCache[p][col.address!] = col.value;
          }
        }
      }
      p++;
    }
    p = 0;
    for (const table of this.config ?? []) {
      for (const row of table.rows ?? []) {
        for (const col of row.columns ?? []) {
          if (col.formItem?.formula) {
            const formulas = col.formItem.formula.split(';');
            for (const formula of formulas) {
              if (formula.indexOf('Disable') > -1) {
                const [key, value] = formula
                  .replace('Disable(', '')
                  .replace(')', '')
                  .split('=');
                col.disable = this.varCache[p][key] === value;
                this.onFlowDisableChange.emit({
                  address: col.address!,
                  disabled: this.varCache[p][key] === value,
                });
              }
              if (formula.indexOf('Avg') > -1) {
                const [start, end] = formula
                  .replace('Avg(', '')
                  .replace(')', '')
                  .split(':');
                const keys = this.getKeyList(start, end);
                let sum = 0;
                let count = 0;
                for (const key of keys) {
                  if (this.varCache[p][key] && !isNaN(+this.varCache[p][key])) {
                    sum += +this.varCache[p][key];
                    count++;
                  }
                }
                const avg = count !== 0 ? sum / count : 0;
                this.varCache[p][col.address!] = avg;
                col.value = avg.toString();
              }
            }
          }
          if (
            col.formItem?.widget === FormItemWidgetType.FLOW_PRESENT ||
            col.formItem?.widget === FormItemWidgetType.FLOW_PRESENT_ONE
          ) {
            if (JSON.stringify(col.valueCascader) !== col.oldValueCascader) {
              this.onPresentChange.emit({
                ids: col.valueCascader,
                musted: col.formItem?.musted,
              });
              col.oldValueCascader = JSON.stringify(col.valueCascader);
            }
            if (init) {
              this.onPresentChange.emit({
                ids: col.valueCascader,
                musted: col.formItem?.musted,
                init: true,
              });
            }
          }
          if (
            col.formItem?.widget === FormItemWidgetType.FLOW_CC ||
            col.formItem?.widget === FormItemWidgetType.FLOW_CC_ONE
          ) {
            if (JSON.stringify(col.valueCascader) !== col.oldValueCascader) {
              this.onCCChange.emit({
                ids: col.valueCascader,
                musted: col.formItem?.musted,
              });
              col.oldValueCascader = JSON.stringify(col.valueCascader);
            }
            if (init) {
              this.onCCChange.emit({
                ids: col.valueCascader,
                musted: col.formItem?.musted,
                init: true,
              });
            }
          }
        }
      }
      p++;
    }
  }

  private getKeyList(start: string, end: string): string[] {
    const startRow = +start.match(/\d+/)![0];
    const startCol = start.match(/[a-zA-Z]+/)![0];
    const endRow = +end.match(/\d+/)![0];
    const endCol = end.match(/[a-zA-Z]+/)![0];
    const keys: string[] = [];
    for (let i = startRow; i <= endRow; i++) {
      for (let j = startCol.charCodeAt(0); j <= endCol.charCodeAt(0); j++) {
        keys.push(String.fromCharCode(j) + i);
      }
    }
    return keys;
  }
}

export class DetectionInfo {
  designRealVal?: number[] = [];
  biasRealValMin?: number;
  biasRealValMax?: number;
  biasNeedDesign?: boolean = false;
  biasError?: boolean = false;
  realVal1List?: number[] = [];
  realVal2List?: number[] = [];
  passVal?: number = undefined;
  val1Min?: number;
  val1Max?: number;
  val2Min?: number;
  val2Max?: number;
}
