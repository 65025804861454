export enum FormVersion {
  OLD_VERSION = 'OLD_VERSION',
  NEW_VERSION = 'NEW_VERSION',
}

export const FormVersionLabelMap = {
  [FormVersion.OLD_VERSION]:
    '2023年11月01日之前旧版本：分项工程单元所有表单合成1个PDF',
  [FormVersion.NEW_VERSION]: '2023年11月01日之后新版本：每个表单独自为一个PDF',
};

export const FormVersionList = [
  {
    value: FormVersion.OLD_VERSION,
    label: FormVersionLabelMap[FormVersion.OLD_VERSION],
  },
  {
    value: FormVersion.NEW_VERSION,
    label: FormVersionLabelMap[FormVersion.NEW_VERSION],
  },
];
