import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormEntry } from '../models/form/form-entry.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class OmFormEntryService extends RequestService<FormEntry> {
  static PATH = '/om/form/entry';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, OmFormEntryService.PATH, globalService.debugUrl);
  }

  findByName(name: string) {
    return this.httpClient.get<FormEntry[]>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmFormEntryService.PATH}/byName/${name}`
    );
  }
}
