import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QaCertificate } from '../models/inspection/qa-certificate.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class InspectionQaCertificateService extends RequestService<QaCertificate> {
  static PATH = '/inspection/qaCertificate';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(
      httpClient,
      InspectionQaCertificateService.PATH,
      globalService.debugUrl
    );
  }
}
