import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
// import { UserType } from '../models/user/user-type.enum';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private globalService: GlobalService,
    private message: NzMessageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // return true;
    if (state.url.indexOf('login') > -1) {
      // this.globalService.clearLoginInfo();
      return true;
    } else {
      // this.globalService.passwordFree = false;
      if (!this.globalService.isLogin) {
        if (
          // state.url === '/forget' ||
          // state.url === '/signup' ||
          state.url === '/signin'
        ) {
          return true;
        } else {
          this.router.navigate(['/not-found']);
          return false;
        }
      } else {
        return this.checkByAudit(route, state);
      }
    }
  }

  private checkByAudit(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (
      state.url === '/signin' ||
      state.url === '/not-found' ||
      state.url === '/login'
    ) {
      this.router.navigate(['/']);
      return false;
    } else {
      if (this.globalService.userInfo.admin) {
        return true;
      }
      if (
        state.url === '/home/notice' ||
        state.url.indexOf('/preview') > -1 ||
        state.url.indexOf('/sign') > -1 ||
        state.url.indexOf('/custom-form') > -1
      ) {
        return true;
      } else {
        console.log(state.url);
        const testUrl = this.getTestUrl(state.url);
        if (
          !!this.globalService.urlAuditMap &&
          !!this.globalService.urlAuditMap[testUrl] &&
          !!this.globalService.urlAuditMap[testUrl].readAble
        ) {
        } else {
          this.message.error('没有权限！');
          this.router.navigate(['/home']);
          return false;
        }
        return true;
      }
    }
  }

  getTestUrl(testUrl: string) {
    const urlList = [
      '/qa/test',
      '/qa/material',
      '/projects-progress/progress',
      '/projects-manage/worker',
      '/projects-manage/device',
      '/projects-manage/material',
      '/operations-manage/forms',
      '/operations-manage/arch',
      '/operations-manage/pforms/CONSTRUCTION',
      '/operations-manage/pforms/CHECK',
      '/operations-manage/codes',
      '/operations-manage/account',
      '/operations-manage/iot',
    ];
    for (const url of urlList) {
      if (testUrl.indexOf(url) > -1) {
        return url;
      }
    }

    return testUrl;
  }
}
