<div class="alert-dialog">
	<div class="alert-dialog-header">{{title}}</div>
	<div class="alert-dialog-body">
		<div class="alert-dialog-body-prompt">
			<input type="text" [(ngModel)]="m" style="-webkit-text-security: disc;" type="tel">
		</div>
	</div>
	<div class="alert-dialog-footer">
		<button nz-button nzType="primary" (click)="cancel()">取消</button>
		<button nz-button nzType="primary" (click)="confirm()" [disabled]="!m || m.length !== 6">确定</button>
	</div>
</div>