<div class="view-container" *ngIf="workReport.form">
    <div class="grid middle">
        <div class="row sticky background-module" [style.top]=" 0">
            <div class="column background-primary color-white pointer" *ngIf="hideLeft" [style.width]="40 + 'px'"
                (click)="hideLeftChange(false)">
                →
            </div>
            <div class="column space-between"
                [style.width]="(centerWidth + (!hideLeft ? 0 : -40) + (!hideRight ? 0 : -40)) + 'px'">
                <div class="col"> </div>
                <h4>编辑表单</h4>
                <button class="color-primary" nz-button nzType="text" nz-dropdown [nzDropdownMenu]="menu2"
                    [style.margin-right]="'10px'" [nzPlacement]="'bottomRight'">
                    ↑ 上传主文件
                </button>
                <nz-dropdown-menu #menu2="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item>
                            <span class="color-light">
                                上传Excel表格（暂不支持）
                                <input type="file" (change)="upload($event, WorkReportDocType.EXCEL)"
                                    accept=".xlsx, .xls" [disabled]="true">
                            </span>
                        </li>
                        <li nz-menu-item>
                            <span class="color-light">
                                上传Word文件（暂不支持）
                                <input type="file" (change)="upload($event, WorkReportDocType.WORD)"
                                    accept=".doc, .docx" [disabled]="true">
                            </span>
                        </li>
                        <li nz-menu-item>
                            <span class="color-light">
                                上传PDF原始稿（暂不支持）
                                <input type="file" (change)="upload($event, WorkReportDocType.PDF_WAIT_SIGN)"
                                    accept=".pdf" [disabled]="true">
                            </span>
                        </li>
                        <li nz-menu-item>
                            <span class="color-danger">
                                上传PDF签完稿
                                <input type="file" (change)="upload($event, WorkReportDocType.PDF_SIGNED)"
                                    accept=".pdf">
                            </span>
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>

            <div class="column background-primary color-white pointer" *ngIf="hideRight" [style.width]="40 + 'px'"
                (click)="hideRightChange(false)">
                ←
            </div>
        </div>
        <spreadsheet-form *ngIf="!workReport.docId" [config]="workReport.form.formData!"
            [style.width]="centerWidth + 'px'" [projectData]="projectWidgetData"
            [canAdd]="workReport.formPagesType === FormPagesType.SCALABLE"
            (onFlowDisableChange)="onFlowDisableChange($event)" (onCCChange)="prccChange($event, true)"
            (onPresentChange)="prccChange($event, false)" [disabledAddressList]="disabledAddress" [submited]="submited"
            (onChange)="onChange($event)">
        </spreadsheet-form>
        <pdf-viewer *ngIf="!!workReport.docId" [src]="environment.fileHost + workReport!.docId" [render-text]="true"
            [zoom-scale]="'page-fit'" [original-size]="false" [c-maps-url]="'assets/cmaps'"
            [style.width]="centerWidth + 'px'" style="height: 100vh;  background-color: white;">
        </pdf-viewer>
    </div>

    <div class="right-panel" *ngIf="!hideRight">
        <div class="grid flow">
            <div class="row sticky" [style.top]="0">
                <div class="column background-primary color-white" [style.width]="260 + 'px'">
                    执行流程
                </div>
                <div class="column background-primary color-white pointer" [style.width]="40 + 'px'"
                    (click)="hideRightChange(true)">
                    →
                </div>
            </div>
            <div class="row sticky" [style.top]="2.5 + 'rem'">
                <div class="column background-page" [style.width]="20 + 'px'">
                    顺序
                </div>
                <div class="column background-page" [style.width]="50 + 'px'">
                    动作
                </div>
                <div class="column background-page" [style.width]="210 + 'px'">
                    执行人
                </div>
                <div class="column background-page" [style.width]="20 + 'px'">
                    操作
                </div>
            </div>
            <div class="row {{item.hiddenTrigger ? 'border-bottom-black' : ''}}"
                *ngFor="let item of historyList | historyFilter; let hisIdx = index;">
                <div class="column color-light" [style.width]="20 + 'px'">
                    {{item.execOrder}}
                </div>
                <div class="column spin color-light" *ngIf="!!item.execOrderSpan && !item.hide"
                    [style.width]="20 + 'px'" [style.height]="(2.5 * item.execOrderSpan) + 'rem'">
                    {{item.execOrder}}
                </div>
                <div class="column status {{item.hiddenTrigger ? 'color-danger' : 'color-light'}}"
                    [style.width]="50 + 'px'">
                    {{WorkFlowActionTypeMap[item.actionType!]}} <br *ngIf="item.actionType" /> {{item.hiddenTrigger ?
                    WorkFlowHistoryStatusMap[item.status!] : ''}}
                </div>
                <div class="column status" [style.flexDirection]="'column'" [style.width]="210 + 'px'"
                    [style.height]="item.comment ? (60 + 'px') : (40 + 'px')">
                    <div class="top-container">
                        <div class="name-container">
                            <div class="top-name">
                                <format-data class="hint" [contentStr]="item.nameLabel?item.nameLabel:''"
                                    [patternStr]="'E|90'">
                                </format-data>
                            </div>
                        </div>
                        <div class="top-time color-light">{{item.operateTime | date:'yyyy-MM-dd HH:mm'}}</div>
                    </div>
                    <div class="middle-container">
                        <format-data class="hint font-12" [contentStr]="item.executorPost?item.executorPost:''"
                            [patternStr]="'E|200'">
                        </format-data>
                    </div>
                    <div class="middle-container" *ngIf="item.comment">
                        <format-data class="hint color-danger" [contentStr]="item.comment" [patternStr]="'E|200'">
                        </format-data>
                    </div>
                </div>
                <div class="column text-center pointer" nz-dropdown [nzDropdownMenu]="menuHis"
                    [style.width]="20 + 'px'">
                    <span nz-icon nzType="more" nzTheme="outline" *ngIf="!item.hiddenTrigger"></span>
                    <span (click)="trig(item)" *ngIf="item.hiddenTrigger">{{!item.hide ? '↑' : '↓'}}</span>
                </div>
                <nz-dropdown-menu #menuHis="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item (click)="read(item)">
                            阅读此节点PDF
                        </li>
                        <li nz-menu-item (click)="download(item)">
                            下载此节点PDF
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>
            <div class="row" *ngFor="let item of workReport!.form!.formFlows ?? []; let flowIdx = index;">
                <div class="column" [style.width]="20 + 'px'" *ngIf="item.step !== FormFlowType.FILL_IN">
                    {{item.execOrder}}
                </div>
                <div class="column spin" *ngIf="!!item.execOrderSpanHeight" [style.width]="20 + 'px'"
                    [style.height]="(2.5 * item.execOrderSpanHeight) + 'rem'">
                    {{item.execOrder}}
                </div>
                <div class="column {{item.step === FormFlowType.SIGN && item.formEnable ? 'editable' : ''}} {{item.formEnable ? '' : 'color-light'}}"
                    [style.width]="50 + 'px'" *ngIf="item.step !== FormFlowType.FILL_IN">
                    <span *ngIf="item.step !== FormFlowType.SIGN">
                        {{FormFlowTypeMap[item.step!]}}
                    </span>
                    <select *ngIf="item.step === FormFlowType.SIGN" [(ngModel)]="item.actionType"
                        (ngModelChange)="actionTypeChange(flowIdx)" [disabled]="!item.formEnable || !!workReport.docId">
                        <option *ngFor="let item of WorkFlowActionTypeChangeableList" [value]="item.value">
                            {{item.label}}
                        </option>
                    </select>
                </div>
                <div class="column" *ngIf="item.step !== FormFlowType.FILL_IN" [style.flexDirection]="'column'"
                    [style.width]="210 + 'px'"
                    [style.height]="!item.userInfoList ? (40 + 'px') : (item.userInfoList.length * 40 + 'px')">
                    <user-select-popover *ngFor="let userInfo of item.userInfoList; let userIdx = index;" [flow]="item"
                        [userInfo]="userInfo" [userList]="userList" [archiveTreeNode]="postTreeNodeFull"
                        [required]="!!item.musted" [disabled]="!item.formEnable || !!workReport.docId"
                        (userInfoChange)="userInfoChange($event, flowIdx, userIdx)">
                    </user-select-popover>
                </div>
                <div class="column text-center pointer" [style.width]="20 + 'px'" nz-dropdown [nzDropdownMenu]="menu1"
                    *ngIf="item.step !== FormFlowType.FILL_IN && !workReport.docId">
                    <span nz-icon nzType="more" nzTheme="outline"></span>
                </div>
                <div class="column text-center pointer" [style.width]="20 + 'px'"
                    *ngIf="item.step !== FormFlowType.FILL_IN && workReport.docId">
                </div>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item (click)="addPrCC(false, true, flowIdx)">
                            +抄送
                        </li>
                        <li nz-menu-item (click)="addPrCC(false, false, flowIdx)">
                            +呈递
                        </li>
                        <li nz-menu-item
                            [nzDisabled]="(item.step !== FormFlowType.CC && item.step !== FormFlowType.PRESENT) || (item.newAdded && item.auto ) "
                            (click)="removeFlow(flowIdx)">
                            × 删除动作
                        </li>
                        <li nz-menu-item *ngIf="item.execOrder !== workReport.form.formFlows![flowIdx + 1]?.execOrder"
                            (click)="merge(flowIdx)">
                            - 合并[步骤]
                        </li>
                        <li nz-menu-item *ngIf="item.execOrder === workReport.form.formFlows![flowIdx + 1]?.execOrder"
                            (click)="split(flowIdx)">
                            = 拆分[步骤]
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>

        <div class="footer">
            <div class="grid" *ngIf="submitable">
                <div class="row">
                    <div class="column" [style.width]="310 + 'px'"></div>
                </div>
                <div class="row">
                    <div class="column {{submiting || !submitable ? 'disabled' : 'pointer'}} background-primary color-white"
                        [style.width]="80 + 'px'" (click)="save()">
                        保存
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">

                    </div>
                </div>
                <div class="row">
                    <div class="column {{submiting || !canConfirm || !submitable ? 'disabled' : 'pointer'}} background-primary color-white"
                        [style.width]="80 + 'px'" (click)="confirm()">
                        提交{{!!workReport.docId ? '' : '流程'}}
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">
                        走签字和盖章流程
                    </div>
                </div>
                <div class="row" *ngIf="workReport.id">
                    <div class="column pointer background-light" [style.width]="80 + 'px'" (click)="removeDraft()">
                        删除
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">
                        删除报表和附件等相关记录
                    </div>
                </div>
                <div class="row" *ngIf="!workReport.id">
                    <div class="column pointer background-light" [style.width]="80 + 'px'" (click)="cancelDraft()">
                        取消
                    </div>
                    <div class="column color-grey text-left" [style.width]="230 + 'px'">
                        取消编辑
                    </div>
                </div>
            </div>
            <div class="grid" *ngIf="!submitable" [style.width]="'100%'">
                <h1 class="hint">
                    {{editablePost}}<br />才有权限<br />编辑/提交此报表
                </h1>
            </div>
        </div>

    </div>
</div>