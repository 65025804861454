import { Component, Input } from '@angular/core';
import { DialogConfig } from 'src/app/modules/shared/components/alert-dialog/dialog-config';
import {
  FormVersionList,
  FormVersion,
} from 'src/app/modules/shared/models/form/form-version.enum';
import { WorkReport } from 'src/app/modules/shared/models/form/work-report.model';
import { GlobalService } from 'src/app/modules/shared/providers/global.service';

@Component({
  selector: 'app-dialog-gl105-date',
  templateUrl: './dialog-gl105-date.component.html',
  styleUrls: ['./dialog-gl105-date.component.scss'],
})
export class DialogGl105DateComponent {
  @Input()
  data!: DialogConfig<WorkReport>;

  editing: { [name: string]: boolean } = {};

  record: WorkReport = new WorkReport();

  FormVersionList = FormVersionList;

  FormVersion = FormVersion;

  constructor(private globalService: GlobalService) {}

  ngOnInit() {
    this.record = JSON.parse(JSON.stringify(this.data.data)) as WorkReport;
    if (this.record.numbersOfPdfPages && this.record.numbersOfPdfPages > 3) {
      this.record.formVersion = FormVersion.OLD_VERSION;
    } else {
      this.record.formVersion = FormVersion.NEW_VERSION;
    }
  }

  onVersionChange() {
    this.record.formFinishDateDate = undefined;
  }

  edit(name: string) {
    this.editing[name] = true;
  }

  onBlur() {
    this.editing = {};
  }

  isNaN(content: number) {
    return window.isNaN(content);
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    this.submit();
  }

  get canConfirm() {
    if (!this.record.sn) {
      return false;
    }

    if (this.record.formStarted && !this.record.formStartDateDate) {
      return false;
    }

    if (
      this.record.formStarted &&
      this.record.formVersion === FormVersion.OLD_VERSION &&
      !this.record.formFinishDateDate
    ) {
      return false;
    }

    if (this.record.formCompleted && !this.record.formFinishDateDate) {
      return false;
    }

    return true;
  }

  handleBeforeConfirm(report: WorkReport): WorkReport {
    const data = JSON.parse(JSON.stringify(report)) as WorkReport;
    if (data.formStartDateDate) {
      data.formStartDate = new Date(data.formStartDateDate).getTime();
    }
    if (data.formFinishDateDate) {
      data.formFinishDate = new Date(data.formFinishDateDate).getTime();
    }
    return data;
  }

  private submit() {
    this.data.onConfirm!(this.handleBeforeConfirm(this.record));
  }
}
