<div class="grid" *ngIf="workReport.attachs && workReport.attachs.length > 0">
	<div class="row">
		<div class="column {{currentFileId === workReport.docPdfId! ? 'active' : ''}}" [style.width]="300 + 'px'">
			<format-data [contentStr]="workReport.name ?? workReport.sn" patternStr="E+300" (click)="chooseMain() ">
			</format-data>
		</div>
	</div>
	<div class="row">
		<div class="column text-center" [style.width]="300 + 'px'">
			附件
		</div>
	</div>
	<div class="row" *ngFor="let item of workReport.attachs; let index = index">
		<div class="column {{currentFileId === item.fileId! ? 'active' : ''}}" [style.width]="260 + 'px'">
			<format-data [contentStr]="item.fileName" patternStr="E+260" (click)="chooseAttach(index) ">
			</format-data>
		</div>
		<div class="column">
			<format-data [contentStr]="'下载'" (click)="downloadAttach(index) ">
			</format-data>
		</div>
	</div>
</div>

<div class="preview-dialog" [style.minHeight]="height + 'px'" [style.minWidth]="width + 'px'">
	<div class="preview-dialog-header">
		<span>&ensp;</span>
		<div class="action">
			<button class="color-primary" nz-button nzType="text" nz-dropdown [nzDropdownMenu]="menu"
				[nzPlacement]="'bottomRight'">
				<span nz-icon nzType="vertical-align-bottom" nzTheme="outline"></span> 下载
			</button>
			<nz-dropdown-menu #menu="nzDropdownMenu">
				<ul nz-menu>
					<li nz-menu-item (click)="download(0)">
						<span class="color-danger">
							全文件（含签字盖章）
						</span>
					</li>
					<li nz-menu-item (click)="download(1)">
						<span class="color-dark-danger">
							半文件（签字不盖章）
						</span>
					</li>
					<li nz-menu-item (click)="download(2)">
						<span>
							原文件（无签章PDF）
						</span>
					</li>
					<li nz-menu-item (click)="download(3)"
						*ngIf="workReport.docType === WorkReportDocType.EXCEL || workReport.docType === WorkReportDocType.WORD">
						<span>
							原文件（{{workReport.docType}}）
						</span>
					</li>
				</ul>
			</nz-dropdown-menu>
			<!-- <button class="color-primary" nz-button nzType="text" *ngIf="fileTypes[index] === FilePreviewType.PDF">
				<span nz-icon nzType="left-circle" nzTheme="outline" (click)="prePage()"></span>
			</button> -->
			<!-- <span *ngIf="fileTypes[index] === FilePreviewType.PDF">
				{{pdfPages}} / {{pdfTotal}}
			</span> -->
			<!-- <button class="color-primary" nz-button nzType="text" *ngIf="fileTypes[index] === FilePreviewType.PDF">
				<span nz-icon nzType="right-circle" nzTheme="outline" (click)="nextPage()"></span>
			</button> -->
			<!-- <button class="color-primary" *ngIf="data.screenType !==  ViewScreenType.HalfBottom">
				<i class="icon icon-select-vertical-layout" (click)="viewVertical()"></i>
			</button> -->

			<button class="color-primary" nz-button nzType="text" (click)="zoomIn()">
				<span nz-icon nzType="zoom-in" nzTheme="outline"></span>
			</button>
			<button class="color-primary" nz-button nzType="text">
				<span nz-icon nzType="border-outer" nzTheme="outline" (click)="originSize()"></span>
			</button>
			<button class="color-primary" nz-button nzType="text" (click)="zoomOut()">
				<span nz-icon nzType="zoom-out" nzTheme="outline"></span>
			</button>
			<button nz-button nzType="text" (click)="remove()">
				<span nz-icon nzType="delete" nzTheme="outline"></span> 删除文件
			</button>
		</div>
		<button *ngIf="!singlePage" class="color-primary" nz-button nzType="text" (click)="dismiss()">
			<span nz-icon nzType="close" nzTheme="outline"></span> 关闭
		</button>
		<span *ngIf="singlePage">&ensp;</span>
	</div>
	<!-- <div class="preview-dialog-body">
		<pdf-viewer [src]="environment.fileHost + currentFileId" [render-text]="true" [zoom-scale]="'page-fit'"
			[original-size]="false" style="width: 100%; height: 100%" [zoom]="pdfZoom" [(page)]="pdfPages"
			[c-maps-url]="'assets/cmaps'" (pages-initialized)="onPageLoad($event)">
		</pdf-viewer>
	</div> -->


	<div class="preview-dialog-body" [ngSwitch]="currentFileType">
		<img class="preview" *ngSwitchCase="FileType.IMAGE" [src]="environment.fileHost + currentFileId" alt=""
			style="">
		<!-- <iframe *ngSwitchCase="FilePreviewType.PDF" [src]="data.fileList[index] | safeResourceUrl">
			</iframe> -->


		<pdf-viewer *ngSwitchCase="FileType.PDF" [src]="environment.fileHost + currentFileId"
			[c-maps-url]="'assets/cmaps'" [render-text]="true" [zoom-scale]="'page-fit'" [original-size]="false"
			style="width: 100%; height: 100%" [zoom]="pdfZoom" [(page)]="pdfPages"
			(pages-initialized)="onPageLoad($event)">
		</pdf-viewer>

		<p *ngSwitchDefault>
			暂时无法预览这个格式的文件
		</p>

	</div>
</div>