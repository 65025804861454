import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzCascaderFilter } from 'ng-zorro-antd/cascader';
import {
  ProjectItemType,
  ProjectItemTypeIndexList,
} from '../../models/project/project-item-type.enum';
import { ProjectSectionItem } from '../../models/project/project-section-item.model';
import { ProjectSection } from '../../models/project/project-section.model';
import { OrganizationType } from '../../models/staff/organization-type.enum';
import { NzTreeNode } from '../../models/tree-node.model';
import { EventService, EventType } from '../../providers/event.service';
import { GlobalService } from '../../providers/global.service';

@Component({
  selector: 'wbs-unit-cascader',
  templateUrl: './wbs-unit-cascader.component.html',
  styleUrls: ['./wbs-unit-cascader.component.scss'],
})
export class WbsUnitCascaderComponent {
  async onChange() {
    const section = await this.globalService.getWbsTreeRoot();
    const sectionIds: number[] = [];
    for (const item of this.globalService.orgInfo.projectSections ?? []) {
      sectionIds.push(item.sectionId!);
    }
    const treeNodes: NzTreeNode[] = [];
    for (const item of section?.child ?? []) {
      if (
        !sectionIds.includes(item.sectionId!) &&
        this.globalService.orgInfo.type !== OrganizationType.OPERATE
      ) {
        continue;
      }
      treeNodes.push(
        this.buildTreeNodes(item, this.type ?? ProjectItemType.SECTION, '')
      );
    }
    this.unitTreeNodes = treeNodes;
  }

  subsciption = () => this.onChange();

  @Input()
  set unitCascaderValue(value: any[]) {
    this._unitCascaderValue = value;
  }

  get unitCascaderValue(): any[] {
    return this._unitCascaderValue;
  }

  @Input()
  set type(value: ProjectItemType | undefined) {
    this._type = value;
  }

  get type(): ProjectItemType | undefined {
    return this._type;
  }

  _type?: ProjectItemType = ProjectItemType.SECTION;

  _unitCascaderValue: any[] = [];

  @Output() unitCascaderValueChange = new EventEmitter<any[]>();

  unitTreeNodes: NzTreeNode[] = [];

  sections: ProjectSection[] = [];

  constructor(
    private globalService: GlobalService,
    private events: EventService
  ) {}

  ngOnInit(): void {
    this.events.on(EventType.WbsTitleChangeEvent, this.subsciption);
    this.onChange();
  }

  ngOnDestroy(): void {
    this.events.destroyListener(
      EventType.WbsTitleChangeEvent,
      this.subsciption
    );
  }

  onUnitCascaderValueChange() {
    if (this._unitCascaderValue.length !== 0) {
      this.unitCascaderValueChange.emit(this._unitCascaderValue);
    }
  }

  onUnitCascaderClear() {
    this.unitCascaderValue = [];
    this.onUnitCascaderValueChange();
  }

  private buildTreeNodes(
    item: ProjectSectionItem,
    type: ProjectItemType,
    sortValue: string
  ): NzTreeNode {
    const sort = sortValue + item.name;
    const node: NzTreeNode = {
      label: item.name,
      value: item.id,
      // value:
      //   item.type === ProjectItemType.SECTION ||
      //   item.type === ProjectItemType.PROJECT_SECTION
      //     ? item.id
      //     : item.code,
      sort,
      children: [],
      isLeaf: false,
    };
    if (
      item.child?.length === 0 ||
      ProjectItemTypeIndexList.indexOf(type) <=
        ProjectItemTypeIndexList.indexOf(item.type!)
    ) {
      node.isLeaf = true;
      node.children = undefined;
    } else {
      for (const child of item.child ?? []) {
        node.children!.push(this.buildTreeNodes(child, type, sort));
      }
    }
    return node;
  }

  filter: NzCascaderFilter = (i, path) => {
    return path.some((o) => {
      if (i.length < 3) {
        return false;
      }
      if (!o.isLeaf) {
        return false;
      }
      const label = o['sort'] as string;
      return !!label && label.indexOf(i) !== -1;
    });
  };
}
