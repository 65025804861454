<div class="grid" [style.width]="totalWidth + 'px'">
	<ng-content></ng-content>
	<div class="header" [style.top]="stickyTop + 'px'">
		<div class="row" *ngIf="config.title">
			<div class="column color-primary" [style.width]="totalWidth + 'px'">
				{{config.title}}
			</div>
		</div>
		<div class="row grid-header" [style.font-size]="config.headerSize + 'px'">
			<div class="column grid-check {{config.freezeColumn! > 0 ? 'left-sticky' :''}}" *ngIf="config.hasCheckAll"
				[style.left]="0 + 'px'">
				<input type="checkbox" (change)="onCheckAllChange()" [(ngModel)]="_isCheckAll">
			</div>
			<div class="column grid-index {{config.freezeColumn! > 0 ? 'left-sticky' :''}}" *ngIf="config.hasIndex"
				[style.left]="(!config.freezeColumn ? 0 : config.hasCheckAll ? checkWidth : 0) + 'px'">
				<span nz-icon nzType="filter" nzTheme="fill" *ngIf="queryRow > 0 && config.hasIndex"
					class="state-toggle {{isQueryOpen ? 'on' : ''}} filter" (click)="queryPanelToggle()"></span>
				<span (click)="queryPanelToggle()">序号</span>
			</div>
			<div class="column {{config.freezeColumn! > index ? 'left-sticky' :''}} {{column.separator ? 'separator' : ''}} {{config.freezeColumn! === index + 1 ? 'freeze' : ''}} {{column.group ? 'has-group' : ''}}"
				*ngFor="let column of config.columns; let index = index;" [style.width]="column.width + 'px'"
				[style.left]="(config.freezeColumn! > index && culumnPositionsStart.length > index ? culumnPositionsStart[index] : 0) + 'px'">

				<span nz-icon nzType="filter" nzTheme="fill" *ngIf="queryRow > 0 && !config.hasIndex && index === 0"
					class="state-toggle {{isQueryOpen ? 'on' : ''}} filter" (click)="queryPanelToggle()"></span>
				<span class="color-warning" *ngIf="column.headerComment">? </span>

				<span nz-popover nzType="primary" [nzPopoverContent]="column.headerComment"
					class="{{column.headerLink? 'underline-link' : ''}}  {{!!column.headerComment ? 'underline-comment' :''}}"
					(click)="linkto(column.headerLink)" [innerHtml]="column.name">
				</span>

				<span
					class="icon state-toggle {{!!tableSearchConfig[index].sort && index === highLightIndex ? 'on' : ''}}"
					*ngIf="column.sortable && tableSearchConfig[index]" (click)="changeSortType(index)" nz-icon
					[nzType]="tableSearchConfig[index].sort === columnSortType.ASC ? 'arrow-up' : 'arrow-down'"
					nzTheme="outline"></span>

				<div class="group" [style.width]="(column.groupWidth ?? column.width) + 'px'" *ngIf="!!column.group">
					{{column.group}}
				</div>
			</div>
		</div>
		<div class="grid-query" *ngIf="isQueryOpen && queryRow > 0">
			<div class="row">
				<div class="column grid-check {{needFreezeWithReactiveScreen && config.freezeColumn! > 0 ? 'left-sticky' :''}}"
					*ngIf="config.hasCheckAll" [style.left]="0 + 'px'">
				</div>
				<div class="column grid-index {{needFreezeWithReactiveScreen && config.freezeColumn! > 0 ? 'left-sticky' :''}}"
					*ngIf="config.hasIndex"
					[style.left]="(config.freezeColumn && needFreezeWithReactiveScreen ? (config.hasCheckAll ? checkWidth : 0) : 0) + 'px'">
				</div>
				<div class="column {{needFreezeWithReactiveScreen && config.freezeColumn! > index ? 'left-sticky' :''}} {{column.separator ? 'separator' : ''}} {{config.freezeColumn! === index + 1 ? 'freeze' : ''}}"
					*ngFor="let column of config.columns; let index = index;" [style.width]="column.width + 'px'"
					[style.left]="(needFreezeWithReactiveScreen && config.freezeColumn! > index && culumnPositionsStart.length > index ? culumnPositionsStart[index] : 0) + 'px'">
					<div class="grid-form-item {{column.queryType ? column.queryType : column.type === columnType.percent ? 'percent':''}}"
						*ngIf="column.queryable && tableSearchConfig[index]"
						[ngSwitch]="column.queryType ? column.queryType : column.type">
						<input *ngSwitchCase="columnType.text" class="grid-form-item" nz-input
							[(ngModel)]="tableSearchConfig[index].value" (focus)="onQueryFocus(index)" />
						<!-- <input *ngSwitchCase="columnType.subjectSummary" class="grid-form-item"
							[(ngModel)]="tableSearchConfig[index].value" (focus)="onQueryFocus(index)" /> -->
						<input *ngSwitchCase="columnType.digital" class="grid-form-item"
							[(ngModel)]="tableSearchConfig[index].value" (focus)="onQueryFocus(index)" type="number" />
						<input *ngSwitchCase="columnType.amount" class="grid-form-item"
							[(ngModel)]="tableSearchConfig[index].value" (focus)="onQueryFocus(index)" type="number" />
						<input *ngSwitchCase="columnType.percent" class="grid-form-item"
							[(ngModel)]="tableSearchConfig[index].value" (focus)="onQueryFocus(index)" type="number" />

						<nz-time-picker *ngSwitchCase="columnType.time" [(ngModel)]="tableSearchConfig[index].value"
							class="grid-form-item" (focus)="onQueryFocus(index)"></nz-time-picker>

						<nz-date-picker *ngSwitchCase="columnType.date" class="grid-form-item" [nzSuffixIcon]="''"
							[nzFormat]="column.timeFormat ? column.timeFormat : 'yyyy-MM-dd'"
							[nzDisabledDate]="disabledDate(column.minTime ?? undefined , column.maxTime ?? undefined)"
							[nzMode]="column.mode ?? 'date'" [nzAllowClear]="true"
							[(ngModel)]="tableSearchConfig[index].value" (click)="onQueryFocus(index)">
						</nz-date-picker>

						<nz-date-picker *ngSwitchCase="columnType.datetime" class="grid-form-item" [nzSuffixIcon]="''"
							[nzFormat]="column.timeFormat ? column.timeFormat : 'yyyy-MM-dd HH:mm:ss'"
							[nzDisabledDate]="disabledDate(column.minTime ?? undefined , column.maxTime ?? undefined)"
							[nzMode]="column.mode ?? 'date'" [nzAllowClear]="true"
							[(ngModel)]="tableSearchConfig[index].value" (click)="onQueryFocus(index)"
							[nzShowTime]="true">
						</nz-date-picker>

						<select *ngSwitchCase="columnType.options" [(ngModel)]="tableSearchConfig[index].value"
							(focus)="onQueryFocus(index)">
							<option [value]="undefined">全部
							</option>
							<option *ngFor="let option of column.searchOptions ? column.searchOptions : column.options"
								[value]="option.value">{{option.label}}
							</option>
						</select>
						<select *ngSwitchCase="columnType.log" [(ngModel)]="tableSearchConfig[index].value"
							(focus)="onQueryFocus(index)">
							<option [value]="undefined">全部
							</option>
							<option *ngFor="let option of column.options" [value]="option.value">{{option.label}}
							</option>
						</select>
						<select *ngSwitchCase="columnType.file" [(ngModel)]="tableSearchConfig[index].value"
							(focus)="onQueryFocus(index)">
							<option [value]="undefined">全部
							</option>
							<option [value]="true">有
							</option>
							<option [value]="false">无
							</option>
						</select>

						<app-location-cascader *ngSwitchCase="columnType.location" [editable]="true" [forSearch]="true"
							[locationId]="tableSearchConfig[index].value ?? '000000'"
							(locationChange)="onLocationSearchChange($event, index)" (focus)="onQueryFocus(index)">
						</app-location-cascader>

						<nz-cascader *ngSwitchCase="columnType.tree" [nzShowSearch]="true"
							[(ngModel)]="treeModelCache[column.key!]" [nzPlaceHolder]="'全部'"
							[nzOptions]="column.treeNodes ?? null" [nzExpandTrigger]="'hover'"
							(click)="onQueryFocus(index)" (ngModelChange)="onTreeSelectChange($event, index)">
						</nz-cascader>
						<p *ngSwitchDefault></p>
					</div>
				</div>
			</div>
			<div class="row" *ngIf="queryRow > 1">
				<div class="column grid-check {{needFreezeWithReactiveScreen && config.freezeColumn! > 0 ? 'left-sticky' :''}}"
					*ngIf="config.hasCheckAll" [style.left]="0 + 'px'">
				</div>
				<div class="column grid-index {{needFreezeWithReactiveScreen && config.freezeColumn! > 0 ? 'left-sticky' :''}}"
					*ngIf="config.hasIndex"
					[style.left]="(config.freezeColumn && needFreezeWithReactiveScreen ? (config.hasCheckAll ? checkWidth : 0) : 0) + 'px'">
				</div>
				<div class="column {{needFreezeWithReactiveScreen && config.freezeColumn! > index ? 'left-sticky' :''}} {{column.separator ? 'separator' : ''}} {{config.freezeColumn! === index + 1 ? 'freeze' : ''}}"
					*ngFor="let column of config.columns; let index = index;" [style.width]="column.width + 'px'"
					[style.left]="(needFreezeWithReactiveScreen && config.freezeColumn! > index && culumnPositionsStart.length > index ? culumnPositionsStart[index] : 0) + 'px'">
					<div class="grid-form-item {{column.queryType ? column.queryType : column.type === columnType.percent ? 'percent':''}}"
						*ngIf="column.queryable && column.queryKey2 && tableSearchConfig[index]"
						[ngSwitch]="column.queryType ? column.queryType : column.type">
						<p *ngSwitchCase="columnType.text">
							<input class="grid-form-item" *ngIf="column.queryType === columnType.text"
								[(ngModel)]="tableSearchConfig[index].value2" (focus)="onQueryFocus(index)" />
						</p>
						<input *ngSwitchCase="columnType.amount" class="grid-form-item" (focus)="onQueryFocus(index)"
							[(ngModel)]="tableSearchConfig[index].value2" type="number" />

						<input *ngSwitchCase="columnType.digital" class="grid-form-item" (focus)="onQueryFocus(index)"
							[(ngModel)]="tableSearchConfig[index].value2" type="number" />

						<input *ngSwitchCase="columnType.percent" class="grid-form-item percent"
							(focus)="onQueryFocus(index)" [(ngModel)]="tableSearchConfig[index].value2" type="number" />

						<nz-time-picker *ngSwitchCase="columnType.time" [(ngModel)]="tableSearchConfig[index].value2"
							class="grid-form-item" (focus)="onQueryFocus(index)"></nz-time-picker>

						<nz-date-picker *ngSwitchCase="columnType.date" class="grid-form-item" [nzSuffixIcon]="''"
							[nzFormat]="column.timeFormat ? column.timeFormat : 'yyyy-MM-dd'"
							[nzDisabledDate]="disabledDate(column.minTime ?? undefined , column.maxTime ?? undefined)"
							[nzMode]="column.mode ?? 'date'" [nzAllowClear]="true"
							[(ngModel)]="tableSearchConfig[index].value2" (click)="onQueryFocus(index)">
						</nz-date-picker>

						<nz-date-picker *ngSwitchCase="columnType.datetime" class="grid-form-item" [nzSuffixIcon]="''"
							[nzFormat]="column.timeFormat ? column.timeFormat : 'yyyy-MM-dd HH:mm:ss'"
							[nzDisabledDate]="disabledDate(column.minTime ?? undefined , column.maxTime ?? undefined)"
							[nzMode]="column.mode ?? 'date'" [nzAllowClear]="true"
							[(ngModel)]="tableSearchConfig[index].value2" (click)="onQueryFocus(index)"
							[nzShowTime]="true">
						</nz-date-picker>

						<p *ngSwitchDefault></p>
					</div>
				</div>
			</div>
			<div class="row grid-query-bar">
				<div class="column left-sticky" *ngIf="!!config.freezeColumn && needFreezeWithReactiveScreen"
					[style.width]="freezeWidth + 'px'" [style.left]="0">
					<div *ngIf=" config.freezeColumn > queryButtonIndex" class="grid-query-button-group"
						[style.right]="queryButtonPositionInFreezebar + 'px'">
						<button nz-button nzType="default" class="clear" (click)="reset()">重置</button>
						<button nz-button nzType="primary" class="search" (click)="search()">查询</button>
					</div>
				</div>
				<div class="column"
					[style.width]="(!!config.freezeColumn && needFreezeWithReactiveScreen ? (totalWidth - freezeWidth) : totalWidth) + 'px'"
					[style.left]="0 + 'px'">
					<div class="grid-query-button-group" [style.right]="queryButtonPosition + 'px'">
						<button nz-button nzType="default" class="clear" (click)="reset()">重置</button>
						<button nz-button nzType="primary" class="search" (click)="search()">查询</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="body">
		<div class="row {{!!config.rowConfig && config.rowConfig.disabled && !!config.rowConfig.disabled!(rowIndex) && !!config.rowConfig.disableStyle ? config.rowConfig.disableStyle : ''}}"
			*ngFor="let item of tableData; let rowIndex = index;" [style.font-size]="config.bodySize + 'px'">
			<div class="column grid-check {{config.freezeColumn! > 0 ? 'left-sticky' :''}} {{config.rowConfig?.blacklineUnder && config.rowConfig?.blacklineUnder(rowIndex) ? 'black-underline' : ''}}"
				*ngIf="config.hasCheckAll" [style.zIndex]="10000 - rowIndex" [style.left]="0 + 'px'">
				<input type="checkbox" (change)="onCheckRowChange()" [(ngModel)]="checkList[rowIndex]">
			</div>
			<div class="column grid-index {{config.freezeColumn! > 0 ? 'left-sticky' :''}} {{config.rowConfig?.blacklineUnder && config.rowConfig?.blacklineUnder(rowIndex) ? 'black-underline' : ''}}"
				*ngIf="config.hasIndex" [style.zIndex]="10000 - rowIndex"
				[style.left]="(!config.freezeColumn ? 0 : config.hasCheckAll ? checkWidth : 0) + 'px'">
				{{(rowIndex + 1) + (currentPage ? (+currentPage - 1) : 0) * (currentPageSize ? +currentPageSize: 0)}}
			</div>
			<div *ngFor="let column of config.columns; let colIndex = index;" [ngSwitch]="column.type" class="column {{column.editable && column.editable(rowIndex) ? 'editable' : ''}} {{column.align !== 'center' ?  'text-' + column.align : ''}} {{config.freezeColumn! > colIndex ? 'left-sticky' :''}}
				{{!!selectedMatrix[rowIndex][colIndex] && column.type !== columnType.actions && column.type !== columnType.file ? 'selected' : ''}} {{config.rowConfig?.blacklineUnder && config.rowConfig?.blacklineUnder(rowIndex) ? 'black-underline' : ''}}
				{{lastPositionRow === rowIndex && lastPositionColumn === colIndex && !!selectedMatrix[rowIndex][colIndex] && column.type !== columnType.actions && column.type !== columnType.file? 'selected-pivot' : ''}}
				{{column.separator ? 'separator' : ''}} {{config.freezeColumn! === colIndex + 1 ? 'freeze' : ''}}"
				[style.width]="column.width + 'px'" nz-tooltip
				[nzTooltipTitle]="column.bodyToolTipKey ? item[column.bodyToolTipKey!] : ''"
				[style.left]="(config.freezeColumn! > colIndex && culumnPositionsStart.length > colIndex ? culumnPositionsStart[colIndex] : 0) + 'px'"
				(click)="selectWithKeyboard(rowIndex, colIndex)" (dblclick)="copy()"
				[style.zIndex]="config.freezeColumn! > colIndex ? 10000 - rowIndex : 1200 - rowIndex"
				(mousedown)="selectWithMouseStart(rowIndex, colIndex)"
				(mousemove)="selectWithMouseMove(rowIndex, colIndex)"
				(mouseup)="selectWithMouseEnd(rowIndex, colIndex)">

				<div class="grid-action-group" *ngSwitchCase="columnType.actions">
					<button *ngFor="let action of column.actions" nz-button nzType="text"
						class="grid-button {{action.style ? 'color-' + action.style : ''}}"
						(click)="action.callback(rowIndex)">
						{{action.name}}
					</button>
					<button *ngFor="let action of column.dynamicActions" nz-button nzType="text"
						class="grid-button {{action.style ? 'color-' + action.style(rowIndex) : ''}}"
						(click)="action.callback(rowIndex)">
						{{action.name ? action.name(rowIndex) : ''}}
					</button>
				</div>

				<div class="grid-action-img" *ngSwitchCase="columnType.imgAction" (click)="column.view!(rowIndex)">
					<img [src]="column.cover!(rowIndex)" alt="">
				</div>

				<format-data dPopover bsStyle="primary"
					[content]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? item[column.bodyPopoverComponentKey!].templateRef :''"
					[trigger]="'hover'" [position]="['bottom','top', 'right',  'left']"
					[controlled]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					[appendToBody]="true" [popoverStyle]="{ backgroundColor: 'rgba(0,0,0,0.6)', padding: 0 }"
					[doNotShowEllipsis]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					class="{{!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? 'underline-comment' : ''}}
					{{column.preview ? (!column.preview.hideUnderline || !column.preview.hideUnderline(rowIndex) ? '' : 'underline-link') : ''}}
					{{column.preview?.style ? 'color-' + column.preview?.style : ''}}" *ngSwitchCase="columnType.time"
					[contentStr]="item[column.key!] ? item[column.key!] : ''" [fontSize]="config.bodySize ?? 14"
					[patternStr]="!!column.patternStr ? column.patternStr : !!column.dynamicPatternStr ? column.dynamicPatternStr(rowIndex) : 'TSHH:mm:ss'">
				</format-data>

				<format-data dPopover bsStyle="primary"
					[content]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? item[column.bodyPopoverComponentKey!].templateRef :''"
					[trigger]="'hover'" [position]="['bottom','top', 'right',  'left']"
					[controlled]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					[appendToBody]="true" [popoverStyle]="{ backgroundColor: 'rgba(0,0,0,0.6)', padding: 0 }"
					[doNotShowEllipsis]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					class="{{!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? 'underline-comment' : ''}}
									{{column.preview ? ((!column.preview.hideUnderline || !column.preview.hideUnderline(rowIndex)) ? 'underline-link' : '') : ''}}
									{{column.preview?.style ? 'color-' + column.preview?.style : ''}}" *ngSwitchCase="columnType.date"
					[contentStr]="item[column.key!] ? (+item[column.key!]).toString() : ''" [needStrink]="true"
					[shrinkLength]="column.width" [fontSize]="config.bodySize ?? 14"
					[nanString]="column.nanString ? column.nanString : column.dynamicNanString ? column.dynamicNanString(rowIndex) : '-'"
					[patternStr]="!!column.patternStr ? column.patternStr : !!column.dynamicPatternStr ? column.dynamicPatternStr(rowIndex) : 'TSYYYY-MM-DD'">
					<nz-date-picker *ngIf="!!column.editable && column.editable(rowIndex)" class="grid-form-item"
						[nzFormat]="column.timeFormat ? column.timeFormat : 'yyyy-MM-dd'"
						[nzDisabledDate]="disabledDate(column.minTime ?? undefined , column.maxTime ?? undefined)"
						[nzMode]="column.mode ?? 'date'" [nzAllowClear]="false" [nzSuffixIcon]="''"
						[(ngModel)]="formCopy[rowIndex][column.key!]" (blur)="onDateBlur(rowIndex, column.key)"
						[nzShowTime]="true">
					</nz-date-picker>
				</format-data>

				<format-data dPopover bsStyle="primary"
					[content]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? item[column.bodyPopoverComponentKey!].templateRef :''"
					[trigger]="'hover'" [position]="['bottom','top', 'right',  'left']"
					[controlled]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					[appendToBody]="true" [popoverStyle]="{ backgroundColor: 'rgba(0,0,0,0.6)', padding: 0 }"
					[doNotShowEllipsis]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					class="{{!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? 'underline-comment' : ''}}
									{{column.preview ? ((!column.preview.hideUnderline || !column.preview.hideUnderline(rowIndex)) ? 'underline-link' : '') : ''}}
									{{column.preview?.style ? 'color-' + column.preview?.style : ''}}" *ngSwitchCase="columnType.datetime"
					[contentStr]="item[column.key!] ? (+item[column.key!]).toString() : ''" [needStrink]="true"
					[shrinkLength]="column.width" [fontSize]="config.bodySize ?? 14"
					[nanString]="column.nanString ? column.nanString : column.dynamicNanString ? column.dynamicNanString(rowIndex) : '-'"
					[patternStr]="!!column.patternStr ? column.patternStr : !!column.dynamicPatternStr ? column.dynamicPatternStr(rowIndex) : 'TSYYYY-MM-DD HH:mm:ss'">
					<nz-date-picker *ngIf="!!column.editable && column.editable(rowIndex)" class="grid-form-item"
						[nzFormat]="column.timeFormat ? column.timeFormat : 'yyyy-MM-dd HH:mm:ss'"
						[nzDisabledDate]="disabledDate(column.minTime ?? undefined , column.maxTime ?? undefined)"
						[nzMode]="column.mode ?? 'date'" [nzAllowClear]="false" [nzSuffixIcon]="''"
						[(ngModel)]="formCopy[rowIndex][column.key!]" (blur)="onDateBlur(rowIndex, column.key)"
						[nzShowTime]="true">
					</nz-date-picker>
				</format-data>

				<format-data dPopover bsStyle="primary"
					[content]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? item[column.bodyPopoverComponentKey!].templateRef :''"
					[trigger]="'hover'" [position]="['bottom','top', 'right',  'left']"
					[controlled]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					[appendToBody]="true" [popoverStyle]="{ backgroundColor: 'rgba(0,0,0,0.6)', padding: 0 }"
					[doNotShowEllipsis]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					class="{{!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? 'underline-comment' : ''}}
					{{column.preview ? ((!column.preview.hideUnderline || !column.preview.hideUnderline(rowIndex)) ? 'underline-link' : '') : ''}}
					{{column.preview?.style ? 'color-' + column.preview?.style : ''}}" *ngSwitchCase="columnType.digital"
					[contentStr]="(+item[column.key!]).toString()" [needStrink]="true" [shrinkLength]="column.width"
					[fontSize]="config.bodySize ?? 14"
					[nanString]="column.nanString ? column.nanString : column.dynamicNanString ? column.dynamicNanString(rowIndex) : '-'"
					[patternStr]="!!column.patternStr ? column.patternStr + '@@NN' : !!column.dynamicPatternStr ? column.dynamicPatternStr(rowIndex) + '@@NN' : 'NN'">
					<input *ngIf="!!column.editable && column.editable(rowIndex)" type="text" appDigitalFormat
						[(ngModel)]="formCopy[rowIndex][column.key!]" (blur)="onAfterCopyEdit(rowIndex, column.key)"
						[placeholder]="column.placeholder ?? ''">
				</format-data>

				<format-data dPopover bsStyle="primary"
					[content]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? item[column.bodyPopoverComponentKey!].templateRef :''"
					[trigger]="'hover'" [position]="['bottom','top', 'right',  'left']"
					[controlled]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					[appendToBody]="true" [popoverStyle]="{ backgroundColor: 'rgba(0,0,0,0.6)', padding: 0 }"
					[doNotShowEllipsis]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					class="{{!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? 'underline-comment' : ''}}
					{{column.preview ? ((!column.preview.hideUnderline || !column.preview.hideUnderline(rowIndex)) ? 'underline-link' : '') : ''}}
					{{column.preview?.style ? 'color-' + column.preview?.style : ''}}" *ngSwitchCase="columnType.amount"
					[contentStr]="(+item[column.key!] / 100).toString()" [needStrink]="true"
					[shrinkLength]="column.width" [fontSize]="config.bodySize ?? 14"
					[nanString]="column.nanString ? column.nanString : column.dynamicNanString ? column.dynamicNanString(rowIndex) : '-'"
					[patternStr]="!!column.patternStr ? column.patternStr + '@@NN4' : !!column.dynamicPatternStr ? column.dynamicPatternStr(rowIndex)  + '@@NN4' : 'NN4'">
					<input *ngIf="!!column.editable && column.editable(rowIndex)" type="text" appAmountFormat
						[(ngModel)]="formCopy[rowIndex][column.key!]" (blur)="onAfterCopyEdit(rowIndex, column.key)"
						[placeholder]="column.placeholder ?? ''">
				</format-data>

				<format-data dPopover bsStyle="primary"
					[content]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? item[column.bodyPopoverComponentKey!].templateRef :''"
					[trigger]="'hover'" [position]="['bottom','top', 'right',  'left']"
					[controlled]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					[appendToBody]="true" [popoverStyle]="{ backgroundColor: 'rgba(0,0,0,0.6)', padding: 0 }"
					[doNotShowEllipsis]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					class="{{!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? 'underline-comment' : ''}}
					{{column.preview ? ((!column.preview.hideUnderline || !column.preview.hideUnderline(rowIndex)) ? 'underline-link' : '') : ''}}
					{{column.preview?.style ? 'color-' + column.preview?.style : ''}}" *ngSwitchCase="columnType.percent"
					[contentStr]="(+item[column.key!] / 100).toString()" [fontSize]="config.bodySize ?? 14"
					[nanString]="column.nanString ? column.nanString : column.dynamicNanString ? column.dynamicNanString(rowIndex) : '-'"
					[patternStr]="!!column.patternStr ? column.patternStr : !!column.dynamicPatternStr ? column.dynamicPatternStr(rowIndex) : 'NP2'">
					<input *ngIf="!!column.editable && column.editable(rowIndex)" type="text" appAmountFormat
						[(ngModel)]="formCopy[rowIndex][column.key!]" (blur)="onAfterCopyEdit(rowIndex, column.key)"
						[placeholder]="column.placeholder ?? ''">
				</format-data>

				<format-data dPopover bsStyle="primary"
					[content]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? item[column.bodyPopoverComponentKey!].templateRef :''"
					[trigger]="'hover'" [position]="['bottom','top', 'right',  'left']"
					[controlled]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					[appendToBody]="true" [popoverStyle]="{ backgroundColor: 'rgba(0,0,0,0.6)', padding: 0 }"
					[doNotShowEllipsis]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					class="{{!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? 'underline-comment' : ''}}
					{{column.preview ? ((!column.preview.hideUnderline || !column.preview.hideUnderline(rowIndex)) ? 'underline-link' : '') : ''}}
					{{column.preview?.style ? 'color-' + column.preview?.style : ''}}" *ngSwitchCase="columnType.options"
					[fontSize]="config.bodySize ?? 14"
					[contentStr]="!!column.preview && !!column.preview.name ? column.preview.name : mapOptions(item, column.key, column.options!, column.undefinedOptionHint)"
					[patternStr]="!!column.statusColorPatterns ? mapStatus(item, column.key, column.statusColorPatterns!) : !!column.patternStr ? column.patternStr : 'P'">

					<select *ngIf="column.editable && column.editable(rowIndex)" [(ngModel)]="item[column.key!]"
						(ngModelChange)="onAfterEdit(rowIndex, column.key)" [style.opacity]="0">
						<option *ngFor="let option of column.options" [value]="option.value">{{option.label}}
						</option>
						<option *ngIf="column.undefinedOptionHint" [value]="undefined" [disabled]="true">
							{{column.undefinedOptionHint}}</option>
					</select>
				</format-data>

				<div class="grid-action-group" *ngSwitchCase="columnType.file">
					<file-preview-entry
						*ngIf="!!item[column.key!] && (column.editable && column.editable(rowIndex) || item[column.key!].length > 0)"
						[fileType]="column.fileType ? column.fileType : FilePreviewType.All"
						[fileList]="Array.isArray(item[column.key!]) ? item[column.key!] : [item[column.key!]]"
						[limit]="column.fileLimit !== undefined ? +column.fileLimit : 1"
						[disabled]="!column.editable || !column.editable(rowIndex)" [required]="!!column.required"
						[cover]="column.cover && column.cover(rowIndex) ? column.cover(rowIndex) : ''"
						(fileChange)="onFileListChange($event, rowIndex, column.key)">
					</file-preview-entry>
				</div>

				<div class="grid-upload" *ngSwitchCase="columnType.upload">
					<format-data
						class="clickable color-{{!!column.uploadStyle ? column.uploadStyle(rowIndex) : 'dark'}}"
						[contentStr]="column.uploadText!(rowIndex)"
						[patternStr]="!!column.patternStr ? column.patternStr : !!column.dynamicPatternStr ? column.dynamicPatternStr(rowIndex) : 'P'"
						*ngIf="column.uploadStatus && column.uploadStatus(rowIndex)">
						<input type="file" (change)="column.upload!($event, rowIndex)"
							[accept]="column.fileType ? FilePreviewTypeListMap[column.fileType].accept : ''">
					</format-data>
					<format-data
						class="clickable color-{{!!column.uploadStyle ? column.uploadStyle(rowIndex) : 'dark'}}"
						[contentStr]="column.uploadText!(rowIndex)"
						[patternStr]="!!column.patternStr ? column.patternStr : !!column.dynamicPatternStr ? column.dynamicPatternStr(rowIndex) : 'P'"
						*ngIf="column.uploadStatus && !column.uploadStatus(rowIndex)" (click)="column.view!(rowIndex)">
					</format-data>
				</div>

				<app-location-cascader *ngSwitchCase="columnType.location" [locationId]="item[column.key!] ?? ''"
					[patternStr]="!!column.patternStr ? column.patternStr : 'P'"
					[forSearch]="column.locationMode === 'all'"
					[editable]="column.editable && column.editable(rowIndex)"
					(locationChange)="onLocationChange($event, colIndex, column.key)">
				</app-location-cascader>

				<format-data dPopover bsStyle="primary"
					[content]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? item[column.bodyPopoverComponentKey!].templateRef :''"
					[trigger]="'hover'" [position]="['bottom','top', 'right',  'left']"
					[controlled]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					[appendToBody]="true" [popoverStyle]="{ backgroundColor: 'rgba(0,0,0,0.6)', padding: 0 }"
					[doNotShowEllipsis]="!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef"
					class="{{!!item[column.bodyPopoverComponentKey!] && !!item[column.bodyPopoverComponentKey!].templateRef ? 'underline-comment' : ''}}
					{{column.preview ? ((!column.preview.hideUnderline || !column.preview.hideUnderline(rowIndex)) ? 'underline-link' : '') : ''}}
					{{column.preview?.style ? 'color-' + column.preview?.style : ''}}" *ngSwitchDefault
					[contentStr]="item[column.key!] ? item[column.key!] : ''" [fontSize]="config.bodySize ?? 14"
					[patternStr]="!!column.patternStr ? column.patternStr : !!column.dynamicPatternStr ? column.dynamicPatternStr(rowIndex) : 'P'">
					<input class="{{column.required && !formCopy[rowIndex][column.key]? 'required' : ''}}"
						*ngIf="column.editable && column.editable(rowIndex)" type="text" [(ngModel)]="item[column.key!]"
						(blur)="onAfterEdit(rowIndex, column.key)" [placeholder]="column.placeholder ?? ''">
				</format-data>

			</div>
		</div>
		<ng-content select="[body]"></ng-content>
	</div>
</div>