import { ChangeDetectorRef, Component, Input } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/zh-cn';
import { GlobalService } from '../../providers/global.service';
import { CharWidthCalcUtil } from './../../utils/char-width-calc.util';

@Component({
  selector: 'format-data',
  templateUrl: './format-data.component.html',
  styleUrls: ['./format-data.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 *
 * 将几种不同类型的文字格式化成对应的格式，并且风格化
 *
 * 一、格式化
 * 允许格式化的数据类型：数字格式、文本格式、时间格式，同时只会存在一种格式，以最后一次声明的格式为准
 *
 * 1. 文本格式格式化
 * P/H             +/-             n                               *
 * \P 普通文本格式    \从头/从尾开始   \1～9的数字，表示每多少位分割一次     \单个任意字符，表示分隔符是什么
 * \H 任意字符前缀
 *
 * 举例：
 * Input data:18812345678 pattern:P-4^
 * Output: 188^1234^5678
 *
 * Input data:6262123456 pattern:P+4_
 * Output: 6262_1234_56
 *
 * Input data:张三 pattern:♂
 * Output: ♂张三
 *
 * 2. 数字格式格式化
 * N/S            N/P/C          n                               *                            n
 * \N 无需显示+     \N 常规数字    \1～9的数字，表示每多少位分割一次     \单个任意字符，表示分隔符是什么   \1～9的数字，表示精确到多少位
 * \S 需显示+       \C 前缀符号
 *                 \P 百分数
 *
 * 核心是使用正则表达式处理分割的格式化问题，格式化完成后再来对前缀和后缀进行处理
 * const numberWithCommas = (x) => {
 *    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
 * }
 *
 * 举例：
 * Input data:123456789 pattern:S¥4 2
 * Output: +¥1 2345 6789.00
 *
 * Input data:1234.16 pattern:NP3,1
 * Output: 1,234.2%
 *
 * Input data:1234 pattern:NN2
 * Output: +1234.00
 *
 *
 * 3. 时间格式，参照moment时间格式 https://jsfiddle.net/epnut0r8/51/
 *
 * T             T/S                     pattern
 * \表示时间格式     \T表示时间字符串            \moment 格式
 *                 \S表示时间戳
 * 举例：moment('2011-01-01 00:00Z').locale('zh_cn').format(...)
 *
 * Input data:2011-01-01 00:00 pattern:TMMMM-Do-YYYY, h:mm:ss a \[dd]
 * Output: 一月-1日-2011, 8:00:00 早上 [六]
 *
 * Input data:2011-01-01 00:00 pattern:TLL {dddd}
 * Output: 2011年1月1日 {星期六}
 *
 * Input data:2011-01-01 00:00 pattern:TL-ddd
 * Output: 2011/01/01-周六
 *
 *
 * 二、风格化，风格化和风格化项之间用@@隔开
 *
 * 1. 省略器：仅会出现一次，且在做完所有的其他风格化逻辑之后执行
 * E            +
 * \表示省略器    \+ 头部省略
 *              \- 尾部省略，可不传
 *              \| 中间省略
 *
 * 举例：
 * Input data:123456789 style:E
 * Output: 12345...
 *
 * 举例：
 * Input data:123456789 style:E+
 * Output: ...56789
 *
 * 2. 加粗、斜体、下划线、删除线
 *
 * B/I            s                                       ,               n
 *  \B 加粗        \区域开始                                \个数分隔符       \渲染的字符个数
 *  \I 斜体        \正数，表示从头数第n个字符开始往后
 *                \负数，表示从尾数第n个字符开始，-0会当作0处理
 *
 * 举例：
 * Input data:123456789 style:B1,2
 * Output: 1<b>23</b>456789
 *
 * Input data:123456789 style:I-2,3@@U
 * Output: <u>12345<i>678</i>9</u>
 *
 *
 * 3. 范围着色：如果范围冲突，以最后一个执行的颜色范围为准
 *
 * C######           s                                       ,               n
 * \C 范围着色        \区域开始                                \个数分隔符       \渲染的字符个数
 * \###### 颜色Hax   \正数，表示从头数第n个字符开始往后
 *                  \负数，表示从尾数第n个字符开始，-0会当作0处理
 *
 * 举例：
 * Input data:123456789 style:B1,2@@C0011FF0,2
 * Output: <span style="color: #0011FF;">1</span><b><span style="color: #0011FF;">2</span>3</b>456789
 *
 *
 * 4. 逻辑着色：逻辑着色是对整个字符串进行着色，逻辑如果成立，范围着色失效
 *
 * L            ######                            statement
 * \L 逻辑着色      \ 符合逻辑判断，使用改颜色进行着色       \ 条件语句
 *
 * statement宏
 ** _IN_: 判断原始数据是否处在区间内。原始数据如果不是数字则使用默认颜色。使用开区间、闭区间和半开半闭区间。
 *    - (0,15): 0 < 对象 < 15
 *    - [0,15]: 0 <= 对象 <= 15
 *    - [0,15): 0 <= 对象 < 15
 *    - (0,15]: 0 < 对象 <= 15
 *    - [15,15]: 对象 === 15
 *    - ,15): 对象 < 15
 *    - ,15]: 对象 <= 15
 *    - (0,: 0 < 对象
 *    - [0,: 0 <= 对象
 *
 *
 *
 ** _MATCHR_: 原始数据的字符串匹配。使用正则表达式。
 *
 ** _MATCHF_: 格式化数据的字符串匹配。使用正则表达式。
 *
 * (1) 条件函数
 * Input data:12.5 style:L000000_IN_(0,15]
 * Output: <span style="color: #000000;">12.5</span>
 *
 *
 * (2) 匹配函数
 * Input data:12.5 style:D$2@@L000000_MATCHR_"^\$.*$"
 * Output: $12.50
 *
 *
 * Input data:12.5 style:D$2@@L000000_MATCHF_"^\$.*$"
 * Output: <span style="color: #000000;">$12.50</span>
 *
 */
export class FormatDataComponent {
  @Input()
  set contentStr(value: string | undefined) {
    this._contentStr = value;
    this.detectChangesWithTimeout();
  }

  get contentStr(): string | undefined {
    return this._contentStr;
  }

  _contentStr?: string;

  @Input()
  set patternStr(value: string | undefined) {
    this._patternStr = value;
    this.detectChangesWithTimeout();
  }

  get patternStr(): string | undefined {
    return this._patternStr;
  }

  _patternStr?: string;

  @Input()
  set debug(value: boolean) {
    this._debug = value;
    this.detectChangesWithTimeout();
  }

  get debug(): boolean {
    return !!this._debug;
  }

  _debug?: boolean;

  @Input()
  set doNotShowEllipsis(value: boolean) {
    this._doNotShowEllipsis = value;
    this.detectChangesWithTimeout();
  }

  get doNotShowEllipsis(): boolean {
    return !!this._doNotShowEllipsis;
  }

  _doNotShowEllipsis?: boolean;

  @Input()
  set nanString(value: string | undefined) {
    this._nanString = value;
    this.detectChangesWithTimeout();
  }

  get nanString(): string | undefined {
    return this._nanString;
  }

  _nanString?: string;

  @Input()
  set hide(value: boolean) {
    this._hide = value;
    this.detectChangesWithTimeout();
  }

  get hide(): boolean {
    return !!this._hide;
  }

  _hide?: boolean = false;

  @Input()
  set fontSize(value: number) {
    this._fontSize = value;
    this.detectChangesWithTimeout();
  }

  get fontSize(): number {
    return this._fontSize!;
  }

  _fontSize?: number = 14;

  @Input()
  set needStrink(value: boolean) {
    this._needStrink = value;
    this.detectChangesWithTimeout();
  }

  get needStrink(): boolean {
    return !!this._needStrink;
  }

  _needStrink?: boolean = false;

  @Input()
  set shrinkLength(value: number) {
    this._shrinkLength = value;
    this.detectChangesWithTimeout();
  }

  get shrinkLength(): number {
    return this._shrinkLength!;
  }

  _shrinkLength?: number = 0;

  formatRule: string = '';
  ellipsisRule: string = '';
  logicColorRuleList: string[] = [];
  logicColor: string = '';
  styleRuleList: string[] = [];

  // font-weight: bold;
  boldList: boolean[] = [];
  // font-style: italic;
  italicList: boolean[] = [];
  // color: ######;
  colorList: string[] = [];

  charList: string[] = [];

  formattedStr: string = '';
  innerHTMLStr: string = '';
  notEllipsisStr: string = '';

  timeout: any = undefined;

  constructor(
    private globalService: GlobalService,
    private ref: ChangeDetectorRef
  ) {
    // ref.detach();
  }

  detectChangesWithTimeout() {
    // if (this.timeout) {
    //   clearTimeout(this.timeout);
    // }
    // this.timeout = setTimeout(() => {
    //   console.log('this.ref.detectChanges()');
    //   this.onChanges();
    //   // this.ref.detectChanges();
    // }, 100);

    this.onChanges();
  }

  onChanges(): void {
    this.init();
    if (!this.patternStr || !this.contentStr) {
      return;
    }
    if (this.hide) {
      this.innerHTMLStr = '';
      return;
    }
    this.prepare();
    if (this.formatRule !== '') {
      this.formatWithPattern();
    }
    if (this.ellipsisRule !== '') {
      this.ellipsisWithPattern();
    }
    if (this.styleRuleList.length > 0 || this.logicColorRuleList.length > 0) {
      this.styleWithPattern();
    }
  }
  private init() {
    this.formatRule = '';
    this.ellipsisRule = '';
    this.logicColorRuleList = [];
    this.logicColor = '';
    this.styleRuleList = [];
    this.boldList = [];
    this.italicList = [];
    this.colorList = [];
    this.formattedStr = this.contentStr!;
    this.innerHTMLStr = this.formattedStr;
  }

  private prepare() {
    const rules = this.patternStr!.split('@@');
    for (const rule of rules) {
      if (rule === '') {
        continue;
      }
      switch (rule[0]) {
        case 'B':
        case 'I':
          if (rule.match(/^[BI](-?\d+|-?\d+,\d+)?$/g)) {
            this.styleRuleList.push(rule);
          }
          break;
        case 'E':
          if (rule.match(/^E[\+\-\|]\d+$/g)) {
            this.ellipsisRule = rule;
          }
          break;
        case 'C':
          if (rule.match(/^C[0-9a-fA-F]{6}(-?\d+|-?\d+,\d+)?$/g)) {
            this.styleRuleList.push(rule);
          }
          break;
        case 'L':
          if (
            rule.match(
              /^L[0-9a-fA-F]{6}(_IN_((\[|\()-?\d+(\.\d+)?)?,(-?\d+(\.\d+)?(\]|\)))?|_MATCHR_".*"|_MATCHF_".*")$/g
            )
          ) {
            this.logicColorRuleList.push(rule);
          }
          break;
        default:
          if (
            rule.match(/^P.?([\+\-][1-9].)?$/g) ||
            rule.match(/^[NS].(\d|(\d.)|(\d.\d))?$/g) ||
            rule.match(/^T[TS].*$/g)
          ) {
            this.formatRule = rule;
          }
      }
    }
  }

  private formatWithPattern() {
    this.formattedStr = this.contentStr!;
    switch (this.formatRule[0]) {
      case 'P':
        this.formatAsText();
        break;
      case 'T':
        this.formatAsTime();
        break;
      case 'N':
      case 'S':
        this.formatAsDigital();
        break;
      default:
    }
    this.innerHTMLStr = this.formattedStr;
  }

  private formatAsTime() {
    if (this.formatRule.length < 2) {
      return;
    }
    let rule = this.formatRule.substring(2);
    if (rule === '') {
      rule = 'L';
    }
    this.formattedStr = moment(
      this.formatRule[1] === 'S' && !isNaN(+this.contentStr!)
        ? new Date(parseInt(this.contentStr!)).toISOString()
        : this.contentStr
    )
      .locale('zh_cn')
      .format(rule);
  }

  private formatAsDigital() {
    const digital = parseFloat(this.contentStr!);
    if (isNaN(digital)) {
      this.formattedStr = this.nanString ?? this.contentStr!;
      this.innerHTMLStr = this.formattedStr;
      return;
    }
    let cache = this.contentStr!;
    const needSign = this.formatRule[0] === 'S';
    const currencySymbol =
      this.formatRule[1] !== 'N' && this.formatRule[1] !== 'P'
        ? this.formatRule[1]
        : '';
    const isPercent = this.formatRule[1] === 'P';
    let needFixed = false;
    let fixed = 0;
    if (this.formatRule.length === 3 || this.formatRule.length === 5) {
      needFixed = true;
      fixed = parseInt(
        this.formatRule.length === 5 ? this.formatRule[4] : this.formatRule[2]
      );
    }
    let separator = '';
    if (this.formatRule.length === 4 || this.formatRule.length === 5) {
      separator = this.formatRule[3];
    }
    if (needFixed) {
      // console.log(fixed);
      cache = digital.toFixed(fixed);
    }
    if (separator) {
      const fixedCache = cache.split('.');
      const reg = new RegExp(
        `\\B(?=(\\d{${parseInt(this.formatRule[2])}})+(?!\\d))`,
        'g'
      );
      fixedCache[0] = fixedCache[0].replace(reg, separator);
      cache = fixedCache.join('.');
    }
    if (isPercent) {
      cache += '%';
    }
    if (needSign && digital > 0) {
      cache = '+' + cache;
    }
    if (currencySymbol) {
      if (cache[0] === '+' || cache[0] === '-') {
        cache = cache.slice(0, 1) + currencySymbol + cache.slice(1);
      } else {
        cache = currencySymbol + cache;
      }
    }
    this.formattedStr = cache;
    this.innerHTMLStr = this.formattedStr;
  }

  private formatAsText() {
    let prefix = '';
    if (this.formatRule.length === 2 || this.formatRule.length === 5) {
      prefix = this.formatRule[1];
    }
    let cache = this.contentStr!.toString();

    if (this.formatRule.length > 2) {
      let subRule = this.formatRule.substring(
        this.formatRule.length === 5 ? 2 : 1
      );
      // console.log(subRule);

      let needReverse = subRule[0] === '-';
      if (needReverse) {
        cache = cache.split('').reverse().join('');
      }
      const reg = new RegExp(`.{1,${parseInt(subRule[1])}}`, 'g');
      if (cache.match(reg)) {
        cache = cache.match(reg)!.join(subRule[2]);
      }
      if (needReverse) {
        cache = cache.split('').reverse().join('');
      }
    }

    this.formattedStr = prefix + cache;
    this.innerHTMLStr = this.formattedStr;
  }

  private ellipsisWithPattern() {
    const ecount = parseInt(this.ellipsisRule.substring(2));
    const strWidth = this.getStrWidth(this.formattedStr.toString());
    const m = this.getStrWidthMap();
    if (strWidth <= ecount) {
      this.notEllipsisStr = '';
      return;
    }
    this.notEllipsisStr = this.formattedStr;
    let l = this.getStrWidth('...');
    if (this.ellipsisRule.indexOf('+') > 0) {
      let c = '';
      for (let i = 0; i < this.notEllipsisStr.length; i++) {
        const w = m[this.notEllipsisStr[i]] ?? this.fontSize;
        if (l + w > ecount) {
          break;
        }
        l += w;
        c += this.notEllipsisStr[i];
      }
      this.formattedStr = c + '...';
    }
    if (this.ellipsisRule.indexOf('-') > 0) {
      let c = '';
      for (let i = this.notEllipsisStr.length - 1; i >= 0; i--) {
        const w = m[this.notEllipsisStr[i]] ?? this.fontSize;
        if (l + w > ecount) {
          break;
        }
        l += w;
        c = this.notEllipsisStr[i] + c;
      }
      this.formattedStr = '...' + c;
    }
    if (this.ellipsisRule.indexOf('|') > 0) {
      // console.log('e middle');
      let f = '';
      let b = '';
      for (let i = 0; i < this.notEllipsisStr.length / 2; i++) {
        const wf = m[this.notEllipsisStr[i]] ?? this.fontSize;
        if (l + wf > ecount) {
          break;
        }
        l += wf;
        f += this.notEllipsisStr[i];
        const wb =
          m[this.notEllipsisStr[this.notEllipsisStr.length - 1 - i]] ??
          this.fontSize;
        if (l + wb > ecount) {
          break;
        }
        l += wb;
        b = this.notEllipsisStr[this.notEllipsisStr.length - 1 - i] + b;
      }
      this.formattedStr = f + '...' + b;
    }
    this.innerHTMLStr = this.formattedStr;
  }

  private getStrWidth(str: string) {
    const m = this.getStrWidthMap();
    let l = 0;
    for (const i of str) {
      l += m[i] ?? this.fontSize;
    }
    return l;
  }

  private getStrWidthMap() {
    if (this.fontSize === 16) {
      return this.globalService.s16;
    }
    if (this.fontSize === 13) {
      return this.globalService.s13;
    }
    if (this.fontSize === 12) {
      return this.globalService.s12;
    }
    return this.globalService.s14;
  }

  private styleWithPattern() {
    this.charList = this.formattedStr.toString().split('');
    for (const i of this.charList) {
      this.boldList.push(false);
      this.italicList.push(false);
      this.colorList.push('');
    }

    for (const rule of this.styleRuleList) {
      switch (rule[0]) {
        case 'B':
        case 'I':
          this.fontStyleWithSingleRule(rule);
          break;
        case 'C':
          this.colorStyleWithSingleRule(rule);
          break;
        case 'E':
          break;
      }
    }

    for (const rule of this.logicColorRuleList) {
      this.logicColorWithRule(rule);
    }
    this.assemble();
  }

  private fontStyleWithSingleRule(rule: string) {
    let start = 0;
    let end = this.charList.length;
    const pos = rule.substring(1).split(',');
    if (!isNaN(parseInt(pos[0]))) {
      if (parseInt(pos[0]) < 0) {
        end = end + parseInt(pos[0]) + 1;
      } else {
        start = parseInt(pos[0]);
      }
    }
    if (pos[1] && !isNaN(parseInt(pos[1]))) {
      if (parseInt(pos[0]) < 0) {
        start = end - parseInt(pos[1]);
      } else {
        end = start + parseInt(pos[1]);
      }
    }
    if (start < 0) {
      start = 0;
    }
    if (end > this.charList.length) {
      end = this.charList.length;
    }
    for (let i = start; i < end; i++) {
      if (rule[0] === 'B') {
        this.boldList[i] = true;
      }
      if (rule[0] === 'I') {
        this.italicList[i] = true;
      }
    }
  }
  private colorStyleWithSingleRule(rule: string) {
    let color = rule.substring(0, 7).replace('C', '#');
    let start = 0;
    let end = this.charList.length;
    const pos = rule.substring(7).split(',');
    if (!isNaN(parseInt(pos[0]))) {
      if (parseInt(pos[0]) < 0) {
        end = end + parseInt(pos[0]) + 1;
      } else {
        start = parseInt(pos[0]);
      }
    }
    if (pos[1] && !isNaN(parseInt(pos[1]))) {
      if (parseInt(pos[0]) < 0) {
        start = end - parseInt(pos[1]);
      } else {
        end = start + parseInt(pos[1]);
      }
    }
    if (start < 0) {
      start = 0;
    }
    if (end > this.charList.length) {
      end = this.charList.length;
    }
    for (let i = start; i < end; i++) {
      this.colorList[i] = color;
    }
  }

  private logicColorWithRule(rule: string) {
    // console.log(rule);
    const color = rule.substring(0, 7).replace('L', '#');
    const subrule = rule.substring(7);

    if (subrule.indexOf('_IN_') === 0) {
      if (this.logicColorWithRule_IN_(subrule.substring(4))) {
        this.logicColor = color;
      }
    }

    if (subrule.match(/^_MATCH[RF]_.*$/g)) {
      try {
        if (
          this.logicColorWithRule_MATCH_(
            subrule.indexOf('_MATCHF_') === 0
              ? this.formattedStr
              : this.contentStr!,
            new RegExp(subrule.substring(9, subrule.length - 1), 'g')
          )
        ) {
          this.logicColor = color;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  private logicColorWithRule_IN_(params: string): boolean {
    const conditions = params.split(',');
    let leftCondition = false;
    let rightCondition = false;
    if (conditions[0]) {
      const sign = conditions[0].substring(0, 1);
      const arg = parseFloat(conditions[0].substring(1));
      if (sign === '(') {
        leftCondition = arg < parseFloat(this.contentStr!);
      } else {
        leftCondition = arg <= parseFloat(this.contentStr!);
      }
    } else {
      leftCondition = true;
    }
    if (conditions[1]) {
      // console.log(conditions[1]);
      const sign = conditions[1].substring(conditions[1].length - 1);
      const arg = parseFloat(
        conditions[1].substring(0, conditions[1].length - 1)
      );
      if (sign === ')') {
        rightCondition = parseFloat(this.contentStr!) < arg;
      } else {
        rightCondition = parseFloat(this.contentStr!) <= arg;
      }
    } else {
      rightCondition = true;
    }

    return leftCondition && rightCondition;
  }

  private logicColorWithRule_MATCH_(content: string, reg: RegExp): boolean {
    let result = content.match(reg);
    return result !== null && result.length > 0;
  }

  private assemble() {
    let result = '';
    let strink = false;

    if (this.needStrink) {
      if (
        CharWidthCalcUtil.calcCharWidth(this.formattedStr, this.fontSize!) >
        this.shrinkLength!
      ) {
        strink = true;
      }
    }
    for (let i = 0; i < this.charList.length; i++) {
      let style = '';
      if (this.boldList[i]) {
        style += 'font-weight: bold;';
      }
      if (this.italicList[i]) {
        style += 'font-style: italic;';
      }
      if (!this.logicColor && this.colorList[i]) {
        style += `color: ${this.colorList[i]};`;
      }
      if (strink) {
        style += 'font-size: 12px;';
      }
      result += `<span style="${style}">${this.charList[i]}</span>`;
    }
    this.innerHTMLStr = result;
  }
}
