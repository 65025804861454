import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormFlow } from '../models/form/form-flow.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class OmFormItemFlowService extends RequestService<FormFlow> {
  static PATH = '/om/form/item/flow';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, OmFormItemFlowService.PATH, globalService.debugUrl);
  }

  moveUp(id?: number) {
    return this.httpClient.get<boolean>(
      `${this.debugUrl ? this.debugUrl : environment.host}${
        OmFormItemFlowService.PATH
      }/moveUp/${id}`
    );
  }

  moveDown(id?: number) {
    return this.httpClient.get<boolean>(
      `${this.debugUrl ? this.debugUrl : environment.host}${
        OmFormItemFlowService.PATH
      }/moveDown/${id}`
    );
  }

  updateExecOrder(id: number, execOrder: number) {
    return this.httpClient.post<boolean>(
      `${this.debugUrl ? this.debugUrl : environment.host}${
        OmFormItemFlowService.PATH
      }/updateExecOrder`,
      {
        id,
        execOrder,
      }
    );
  }

  cc(id?: number) {
    return this.httpClient.get<boolean>(
      `${this.debugUrl ? this.debugUrl : environment.host}${
        OmFormItemFlowService.PATH
      }/cc/${id}`
    );
  }

  present(id?: number) {
    return this.httpClient.get<boolean>(
      `${this.debugUrl ? this.debugUrl : environment.host}${
        OmFormItemFlowService.PATH
      }/present/${id}`
    );
  }

  stepMerging(data: { id: number; nextId: number }) {
    return this.httpClient.post<boolean>(
      `${this.debugUrl ? this.debugUrl : environment.host}${
        OmFormItemFlowService.PATH
      }/stepMerging`,
      data
    );
  }

  stepSpliting(data: { id: number; nextId: number }) {
    return this.httpClient.post<boolean>(
      `${this.debugUrl ? this.debugUrl : environment.host}${
        OmFormItemFlowService.PATH
      }/stepSpliting`,
      data
    );
  }
}
