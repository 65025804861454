import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[detectionDesignVal]',
})
export class DetectionDesignValDirective {
  format?: string;

  charset = '+-0123456789 ./';

  constructor(private el: ElementRef, public ngModel: NgModel) {}

  @HostListener('blur') onblur() {
    let value = this.el.nativeElement.value;
    let realValue = '';
    for (let i = 0; i < value.length; i++) {
      if (this.charset.indexOf(value[i]) === -1) {
        continue;
      } else {
        realValue += value[i];
      }
    }
    this.el.nativeElement.value = realValue;
    this.ngModel.control.setValue(realValue);
  }

  @HostListener('input') onInputEvent() {
    let value = this.el.nativeElement.value;
    let realValue = '';
    for (let i = 0; i < value.length; i++) {
      if (this.charset.indexOf(value[i]) === -1) {
        continue;
      } else {
        realValue += value[i];
      }
    }
    this.el.nativeElement.value = realValue;
    this.ngModel.control.setValue(realValue);
  }
}
