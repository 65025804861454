import { ProjectItemTypeMap } from './../../models/project/project-item-type.enum';
import { Component, Input } from '@angular/core';
import { DialogConfig } from '../alert-dialog/dialog-config';
import { ProjectItemType } from '../../models/project/project-item-type.enum';

@Component({
  selector: 'app-dialog-wbs-choose',
  templateUrl: './dialog-wbs-choose.component.html',
  styleUrls: ['./dialog-wbs-choose.component.scss'],
})
export class DialogWbsChooseComponent {
  @Input()
  data!: DialogConfig<ProjectItemType>;

  editing: { [name: string]: boolean } = {};

  unitCascaderValue: any[] = [];

  type: ProjectItemType = ProjectItemType.SECTION;

  ProjectItemTypeMap = ProjectItemTypeMap;

  onUnitCascaderValueChange(event: any[]) {
    this.unitCascaderValue = event;
  }

  constructor() {} // private bankService: BankService

  ngOnInit() {
    if (this.data.data) {
      this.type = this.data.data;
    }
    console.log(this.type);
  }

  onBlur() {
    this.editing = {};
  }

  // private handleDataBeforeInit(response: Bank): Bank {
  //   const data = JSON.parse(JSON.stringify(response)) as Bank;
  //   if (!data.bankLogoUrl) {
  //     data.bankLogoUrlList = [];
  //   } else {
  //     data.bankLogoUrlList = JSON.parse(data.bankLogoUrl);
  //   }

  //   return data;
  // }

  isNaN(content: number) {
    return window.isNaN(content);
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    this.submit();
  }

  // private handleDataBeforeConfirm(bank: Bank): Bank {
  //   const data = JSON.parse(JSON.stringify(bank)) as Bank;
  //   data.id = undefined;
  //   data.bankLogoUrl = JSON.stringify(data.bankLogoUrlList);
  //   return data;
  // }

  get canConfirm() {
    return this.unitCascaderValue.length > 0;
  }

  private submit() {
    this.data.onConfirm!(this.unitCascaderValue);
    // this.bankService
    //   .create(this.handleDataBeforeConfirm(this.record))
    //   .subscribe((response: any) => {
    //     if (response) {
    //       this.data.onConfirm!();
    //     }
    //   });
  }
}
